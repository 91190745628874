import Vue from 'vue';
import Router from 'vue-router';
import PrivacyPolicy from '@components/PrivacyPolicy.vue';
import SiteOwner from '@layouts/civilization/SiteOwner.vue';
import Libero from '@layouts/home/Libero';
import SignUp from '@components/authorization/SignUp';
import ProjectDev from '@layouts/project-developer/ProjectDev';
import Admin from '@layouts/admin/Admin';
import Standards from '@layouts/standards/Standards';
import WebsiteTermsOfService from '@components/WebsiteTermsOfService';
import PlatformTermsOfService from '@components/PlatformTermsOfService';
import ConfirmationPage from '@layouts/project-developer/components/ConfirmationPage.vue';
import ResetPassword from '@components/authorization/ResetPassword';

const isAuthorized = (to, from, next) => {
  if (!localStorage.token) next('');
  else next();
};

// used to disable a page for everyone
// const protect = (to, from, next) => {
//   next('');
// };
const typeDeveloper = (to, from, next) => {
  if (!localStorage.loggedUser) return;
  const type = JSON.parse(localStorage.loggedUser);
  if (type.userType === 1) next();
  else next('');
};

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/:lang',
      redirect: '/:lang/home',
    },
    {
      path: '/:lang/home',
      redirect: '/:lang/home/site-owner',
    },
    {
      path: '/:lang/platform-tos',
      component: PlatformTermsOfService,
    },
    {
      path: '/:lang/website-tos',
      component: WebsiteTermsOfService,
    },
    {
      path: '/:lang/privacy-policy',
      component: PrivacyPolicy,
    },
    {
      path: '/:lang/standards',
      component: Standards,
    },
    {
      path: '/:lang/home/:userType?/:loginType?',
      name: 'Libero',
      component: Libero,
    },
    {
      path: '/:lang/project-developer/register',
      name: 'Register',
      component: SignUp,
    },
    {
      path: '/:lang/project-developer/confirmation',
      component: ConfirmationPage,
      beforeEnter: isAuthorized && typeDeveloper,
    },
    {
      path: '/:lang/reset-password',
      component: ResetPassword,
    },
    {
      path: '/:lang/site-owner',
      name: 'SiteOwner',
      component: SiteOwner,
      alias: [
        '/:lang/site-owner/matching',
        '/:lang/site-owner/project',
        '/:lang/site-owner/i',
        '/:lang/site-owner/project/i',
        '/:lang/site-owner/matching/i',
      ],
    },
    //todo if user profile is done - go to matchmaking
    {
      path: '/:lang/dev/profile',
      name: 'ProjectDeveloper',
      component: ProjectDev,
      alias: [
        '/:lang/dev/matching',
        '/:lang/dev/project',
        '/:lang/dev',
        '/:lang/dev/i',
        '/:lang/dev/project/i',
        '/:lang/dev/matching/i',
        '/:lang/dev/profile/i',
      ],
      beforeEnter: isAuthorized,
    },
    {
      path: '/:lang/admin',
      name: 'Admin',
      component: Admin,
      alias: [
        '/:lang/admin/regTech',
        '/:lang/admin/matching',
        '/:lang/admin/project',
        '/:lang/admin/cms/users',
      ],
      beforeEnter: isAuthorized,
    },
  ],
});
