<template>
  <div ref='yearAvgContainer' class='svg-container'>
    <div class='section yearly'>
      <img src='/assets/yearlyAvgChart.svg' alt=''>
      <p><span class='text'>Average 2000 solar hours</span></p>
    </div>
    <div class='line-avg'>
      <p><span class='line-text'>Yearly avg</span></p>
      <hr>
    </div>
    <svg :width='svgWidth' :height='svgHeight' id='yearlyAvg'>
      <g>
        <rect v-for='item in data'
              class='bar-positive'
              :key='item[xKey]'
              :x='xScale(item[xKey])'
              :y='yScale(0)'
              :width='xScale.bandwidth()'
              :height='0' />
      </g>
      <text v-for='item in data' class='bar-text' :key='item[xKey]'/>
    </svg>
  </div>
</template>

<script>
import { max, min, scaleBand, scaleLinear, selectAll } from 'd3';

export default {
  name: 'BarChart',
  props: {
    title: String,
    xKey: String,
    yKey: String,
    data: Array
  },
  mounted () {
    this.addResizeListener();
  },
  data: () => ({
    svgWidth: 262
  }),
  methods: {
    animateLoad () {
      this.recalculateWidth();
      if (!this.svgWidth) return;

      selectAll('rect')
        .data(this.data)
        .transition()
        .attr('y', d => {
          return this.yScale(d[this.yKey]) - 10;
        })
        .attr('height', d => {
          return this.svgHeight - this.yScale(d[this.yKey]) - 5;
        });

      selectAll('text.bar-text')
        .data(this.data)
        .text(function(d) {
          return d.name;
        })
        .attr('fill', '#1D226F')
        .attr('font-size', 9)
        .attr('x', function(e, i) {
          return 7 + (21 * i);
        })
        .attr('y', 80);
    },

    addResizeListener () {
      const self = this;
      window.addEventListener('resize', () => {
        setTimeout(() => {
          self.animateLoad();
        }, 200);
      });
      this.animateLoad();
    },

    recalculateWidth () {
      const n = this.$refs.yearAvgContainer?.offsetWidth * 0.75;
      if (n > 0) this.svgWidth = n;
    }
  },
  computed: {
    dataMax () {
      return max(this.data, d => {
        return d[this.yKey];
      });
    },
    dataMin () {
      return min(this.data, d => {
        return d[this.yKey];
      });
    },
    xScale () {
      return scaleBand()
        .rangeRound([0, this.svgWidth])
        .padding(0.4)
        .domain(
          this.data.map(d => {
            return d[this.xKey];
          })
        );
    },
    yScale () {
      return scaleLinear()
        .rangeRound([this.svgHeight, 0])
        .domain([this.dataMin > 0 ? 0 : this.dataMin, this.dataMax]);
    },
    svgHeight () {
      return this.svgWidth / 3;
    }
  }
};
</script>

<style scoped>
.bar-positive {
  fill: #93C47D;
}

.section {
  margin-bottom: -20px;
}

.svg-container {
  display: inline-block;
  align-content: center;
  position: relative;
  width: 100%;
  padding-bottom: 1%;
  vertical-align: top;
  overflow: hidden;
  height: 205px;
  margin-bottom: 10px;
  z-index: 2;
  left: -15px;
}

.wrap-text {
  display: flex;
  margin-left: 19px;
  position: relative;
  top: 155px;
}

.month-name {
  color: #1D226F;
  font-size: 9px;
  position: relative;
  top: 15px;
  margin-left: 3.3px;
  margin-right: -1px;
}

.line-avg {
  position: relative;
  z-index: -2;
  top: 70px
}

.line-text {
  color: #1D226F;
  font-size: 12px;
  position: relative;
  right: 100px;
  top: 10px;
}

.line-avg hr {
  width: 85%;
  border-top: 2px dashed #1D226F;
}

.yearly {
  position: relative;
  left: 15px;
}
</style>
