<template>
  <div class='chevron'>
    <div
      v-for='(tab, index) of tabs'
      :key='index'
      class='nav-link'
      @click='
        onTabChange(tab.disabled ? undefined : tab.link) ||
          toggleDropdown(tab.click)
      '
      :disabled='tab.disabled'
      :class="{
        'router-link-active': currentTab === tab.link,
        'router-link-disabled': tab.disabled,
        'dropdown-svg' : tab.title === ' '
      }"
      :ref='`tab${index}`' 
    >
      <span
        v-show='currentTab === tab.link'
        class='nav-route'
        :style='{ background: tab.backgroundActive }'
      >
        {{ tab.title }}
        <div v-show='tab.dotValue' class='dot'> {{ tab.dotValue }}</div>
      </span>
      <span
        v-show='currentTab !== tab.link && tab.title'
        class='nav-route'
        :style='{ background: tab.background }'
        @click="openGuideBox($event,tab.popupText,tab.title,tab.disabled)"
      >
        {{ tab.title }}
        <div v-show='tab.dotValue' class='dot'> {{ tab.dotValue }}</div>

        <popup-controller :refName='`tab${index}`'  border-color='#55b364' v-if='tab.disabled && tab.popupText'
                          :text= 'tab.popupText' />

      </span>
      <span
        v-if='tab.click'
        class='nav-route'
        :class="[(currentTab === 'map') ? 'dropdown' : 'projects-dropdown']"
        :style='{ background: tab.background }'
      >
<!--        todo to translations-->
      <div> 
        <p class='project-empty' v-if=' !siteName'>Empty</p>
        <p class='project-name' v-if='siteName'>{{ projectName }}</p>
        <p class='site-name' v-if="(currentTab === 'map')">{{ siteName }}</p>
      </div>
        <img
          v-if='tab.click'
          class='dd-arrow'
          src='/assets/caret-down.svg'
          :class='{ change: change }'
          alt=''
        />
      </span>
    </div>
  </div>
</template>

<script>
import PopupController from './popup/popup-controller';


export default {
  name: 'Chevron',
  components:{
    PopupController,
  },
  data () {
    return {
      change: false,
      siteName: undefined,
      projectName: undefined,
      role: JSON.parse(localStorage.loggedUser).userType
    };
  },
  created () {
    this.$store.subscribe(this.onStoreChange);
    
  },
  watch: {
    dropdown (e) {
      this.change = e;
    },
 
  },

  props: {
    currentTab: String,
    info: Boolean,
    tabs: Array,
    items: Array,
    dropdown: Boolean
  },
  computed: {
    token () {
      return localStorage.token;
    },

  },
  methods: {
    onTabChange (tab) {
      if (tab === undefined) return;
      this.$emit('change-tab', tab);

    },
    openGuideBox (event, popupText, title, disabled) {
      if(popupText && disabled){
        this.$emit('openGuideBox');
        this.$router.push(`i?section=${title}&subsection=projectname`);
      }
    },
    toggleDropdown (ev) {
      if (ev) {
        this.change = !this.change;
        this.$emit('openDropdown', this.change);
      }
    },
    onStoreChange (mutation) {
      switch (mutation.type) {
        case 'siteOwner/setSiteSelected':
        case 'siteOwner/setSelectedProjectId':
          this.setSelectedProject();
          break;
        case 'inputs/updateParentAnswers':
          const siteNameChange = mutation.payload?.newAnswers?.gaid185;
          const projectNameChange = mutation.payload?.newAnswers?.gaid230;

          // Break if the detected answer changes aren't concerned with
          // the names of the sites/projects.
          if (!(siteNameChange || projectNameChange)) break;
          this.setSelectedProject();
          break;
      }
    },
    setSelectedProject () {
      const { selectedProjectId, selectedSiteId } = this.$store.state.siteOwner;
     
      this.projectName = undefined;
      this.siteName = undefined;

      //todo strings to translations
      if (selectedProjectId || selectedSiteId) {
        if (this.items) {
          const project = this.items.find((v) => v.id === selectedProjectId);
          this.projectName = project ? project.text : '';
          const site = this.items.find((v) => v.id === selectedSiteId);
          this.siteName = site ? site.text : '';
        } else {
          const projectAnswers = this.$store.state.inputs.parents[selectedProjectId]?.answers;
          this.projectName = projectAnswers && projectAnswers['gaid230'] ? projectAnswers['gaid230'] : '';

          const siteAnswers = this.$store.state.inputs.parents[selectedSiteId]?.answers;
          //todo add the site number to the default
          this.siteName = siteAnswers && siteAnswers['gaid185'] ? siteAnswers['gaid185'] : '';
        }
      }
      console.log (this.projectName)
      console.log(this.siteName)
      console.log( this.projectAnswers)
    }

  }
  
};
</script>

<style lang='scss'>
@import '@styles/base/forms.scss';
@import '@styles/base/_typography.scss';
@import '@styles/chevron.scss';

.dd-arrow {
  margin: auto 5px auto auto;
  width: 10px;
  position: absolute;
  top: 27px;
  right: 10px;

  &.change {
    transform: rotate(180deg);
  }
}

.dropdown-svg {
  span.nav-route {
    padding: 45px 0 21px 0 !important;
   // margin-right: 2px;
    z-index: 1000;
    clip-path: polygon(85% 0%, 100% 50%, 85% 100%, 0% 100%, 4% 50%, 0% 0%) !important;
  }

  // & + .router-link-active {
  //   span.nav-route {
  //     margin-left: -64px !important;
  //   }
  // }
}
</style>
