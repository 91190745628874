/**
 *
 * This module manages the data for all inputs:
 *      - Buttons
 *          - active/non-active states.
 *      - Text inputs
 *          - Input value
 *          - TODO: (ii) Validations?
 *      - Toggle groups
 *          - Deep deactivation of groups and sub-groups.
 * by reading and editing the "answers" object for the current selected
 * site or logged PD.
 *
 */

function getDefaultState() {
  return {
    parents: {},

    finishedUpdate: false,
  };
}

export default {
  namespaced: true,

  state: getDefaultState(),

  actions: {},

  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    removeParent(state, parentId) {
      delete state.parents[parentId];
    },

    /**
     * Mass-populate the parents object.
     *
     * @param {*} parents - an object like:
     * {
     *     6064e78ca1c35638086bc140: {
     *         answers: {
     *             gaid1: true,
     *             gaid2: false
     *         }
     *     },
     *
     *     6064e762fe51f43808e26485: {
     *         answers: {
     *             gaid3: true,
     *             gaid2: true
     *         }
     *     }
     * }
     */
    addParents(state, parents) {
      for (let parent of Object.keys(parents))
        if (!parents[parent].answers) parents[parent].answers = {};

      Object.assign(state.parents, parents);
    },

    addSketchToParent(state, sketch) {
      state.parents[sketch.projectId].sketches.push(sketch)
    },

    /**
     * - Creates a new parent if it doesn't exist, or
     * modifies it if otherwise.
     * - Initializes this parent's answers if they
     * don't exist, or modifies them if otherwise.
     */
    updateParentAnswers(state, { parentId, newAnswers, objType }) {
      if (!parentId || !newAnswers) return;

      if (!state.parents[parentId]) state.parents[parentId] = {};

      if (!state.parents[parentId].answers)
        state.parents[parentId].answers = {};

      if (!state.parents[parentId].objType)
        state.parents[parentId].objType = objType;


      if (newAnswers)
        Object.assign(state.parents[parentId].answers, newAnswers);
    },

    emitValueChange() {
      // This mutation is only listened for.
      // It's called by every input when its value is changed.
      // We need it for updating the validations.
      // We do it through the global store because there are many inputs
      // and passing the event from children to parents will be tedious.
    },
    
    confirmedAccount(){
      // Called when the account is confirmed.
    },

    finishUpdateParent(state) {
      state.finishedUpdate = true;
    },

    updateModel() {
      // This mutation is only listened for.
      // It's called when the model data is changed
    },

    resetParents(state) {
      state.parents = {};
    },
  },
};
