<template>
  <div class='showHidePassword eyeImg' id='ShowHidePass'>

    
    <img
      src='/assets/eye1.svg'
      alt=''
      ref='eye'
     :style='`top: ${eyeTop}px; right: ${eyeRight}px`'
      @click='showPassword()'
      class='showPassword'
      v-if="visibility === 'password'"
    />
    <img
      src='/assets/eye.svg'
      alt=''
      ref='eye'
       :style='`top: ${eyeTopHide}px; right: ${eyeRight}px`'
      @click='hidePassword()'
      class='hidePassword'
      v-if="visibility === 'text'"
    />
    </div>
 
</template>
<script>
export default {
  data () {
    return {
      visibility: 'password',
      refs: undefined,
      elPosition: undefined,
      inputElement: undefined,
      eyeTop: 0,
      eyeTopHide: 0,
      eyeRight: 0
    };
  },
  props: {
    field: String
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      this.refs = this.$parent.$refs[this.field];
      if (!this.refs) return;
      if (Array.isArray(this.refs)) this.refs = this.refs[0];
      if (!this.refs) return;
      this.elPosition = this.refs.getBoundingClientRect();
      this.eyeTop = ((this.elPosition.height - 16) / 2);
      this.eyeTopHide = this.eyeTop - 2;
      this.eyeRight = parseFloat(window.getComputedStyle(this.refs).marginRight) + 10;
    },
    showPassword () {
      this.visibility = this.refs.type = 'text';
    },
    hidePassword () {
      this.visibility = this.refs.type = 'password';
    }
  }
};
</script>
<style lang="scss">
 .showHidePassword {
  position: relative;
}

.showPassword {
  position: absolute;
  z-index: 100;
  
}

.hidePassword {
  position: absolute;
  z-index: 100;
} 


</style>

