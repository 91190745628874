<template>
  <div class='forgotten-password'>
     <div class='components col-12 freeArea'> 
      <div class='above-texts'>
        <img class='logo' src='/assets/logo.png' />
        <p class='header-text'> {{ translation.forgotten.title }} </p>
        <label class='grey-text'>{{ translation.forgotten.description }}</label>
      </div>
      <div class='inputs inputs-forgot-pass'>
        <input type='email' class='email-forgot-pass' placeholder='Email address' v-model="email"/>
        <p v-if='sent' class='green-text'>{{ translation.forgotten.success }}</p>
        <button class='green' @click="sendEmail(email,lang)">
          {{ translation.forgotten.send }}
        </button>
        <p class='back-to-login clickable' @click="$emit('openLogin')">{{ translation.forgotten.back }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import TranslationsEn from '@content/en/auth/auth.yaml';
import TranslationsNl from '@content/nl/auth/auth.yaml';
import axios from '@axios';


export default {
  data () {
    return {
      return: false,
      email:null,
      sent:false,
      lang:localStorage.lang,
      translation: {}
    };
  },
  created () {
    this.init();
  },
  methods: {
    init () {
      this.translation = localStorage.lang === 'en' ? TranslationsEn : TranslationsNl;

    },
    sendEmail(email,lang){
      if(email){
      axios
      .post('/.netlify/functions/forgottenPassword',{
        email,
        lang
      });
      this.sent = true;
    }
    }
  }
};
</script>
