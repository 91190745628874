<template>
  <div class='site'>
    <div class='header'>
      <p class="section-name head" >{{title}}</p>
    </div>
    <div class='line' />
    <div class='big-row'>
      <div class='data'>
        <div v-for='(step, index) of questionnaire' :key='index'>
          <div v-if='step.boring !== false && (!step.if || isAnswered(step.if))'>
            <div v-if='step.section'>
              <p class='section-name'>{{ step.section }}</p>
              <div class='line' />
            </div>
            <div v-if='!isTextOnly(step.inputs)'
                 :class="questionnaireActive && answerEdit === index ? 'row editable' : 'row'">
              <div :class="step.subsection ? 'vertical-sub sub-static' : 'static'" v-if="project && description">
                <!-- {{ ids[step.title][lang].boringName || ids[step.title][lang].name }} -->
                  <div class="description">
                    <div 
                    :class="questionnaireActive && answerEdit === index ? 'row editable' : 'row'">
                    <p :class="step.subsection ? 'vertical-sub sub-static' : 'static'">
                        <img v-show='answerEdit === index && editingStepType === 1 && editButton === true'
                            class='edit-pen'
                            src='/assets/close-blue.svg'
                            @click='closeStep(index)'
                        />
                        <img v-show='answerEdit !== index || editingStepType !== 1 || editButton === false'
                            class='edit-pen'
                            src='/assets/edit-pen.svg'
                            alt=''
                            @click='editStep(index)'
                        />
                        {{ ids[step.title][lang].boringName || id[step.title][lang].name }}
                    </p>
                    <div>
                      <div class="variable" v-if="inputParents[projectId].answers[step.inputs[0].gaid]">
                        <div v-for="(input, i) of step.inputs" :key="i">
                          <p v-if="inputParents[projectId].answers[input.gaid]" style="color: black; font-weight: 600; word-break: break-word; width: 300px">
                            {{ids[input.gaid][lang].boringName || ids[input.gaid][lang].name }}
                          </p>
                        </div>
                      </div>
                      <div v-else-if="!inputParents[projectId].answers[step.inputs[0].gaid]">
                        <div v-for="(input,i) of step.inputs" :key="i">
                          <p v-if="inputParents[projectId].answers[input.gaid]" style="color: black">
                            <b>{{ids[input.gaid][lang].boringName || ids[input.gaid][lang].name}}</b>
                          </p>
                        </div>
                      </div>
                      <p v-else style="color: black">
                        <b>{{translations.unanswered}}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="description">
              <div v-for='(field, textField) of step.inputs' :key='textField'
                   :class="questionnaireActive && answerEdit === index ? 'row editable' : 'row'">
                <p :class="step.subsection ? 'vertical-sub sub-static' : 'static'" v-if="project && description">
                    <img v-show='answerEdit === index && editingStepType === 1 && editButton === true'
                        class='edit-pen'
                        src='/assets/close-blue.svg'
                        @click='closeStep(index)'
                    />
                    <img v-show='answerEdit !== index || editingStepType !== 1 || editButton === false'
                        class='edit-pen'
                        src='/assets/edit-pen.svg'
                        alt=''
                        @click='editStep(index)'
                    />
                    {{ ids[field.gaid][lang].boringName || ids[field.gaid][lang].name }}
                </p>
                <p :class="step.subsection ? 'vertical-sub sub-static' : 'static'" v-else>
                    {{(ids[field.gaid][lang].boringName || ids[field.gaid][lang].name)}}
                </p>
                <div v-if="project && description">
                  <p class="variable"  v-if="inputParents[projectId].answers[step.inputs[0].gaid]">
                    {{inputParents[projectId].answers[step.inputs[0].gaid]}}
                  </p>
                  <p v-else style="color: black">
                    <b>{{translations.unanswered}}</b>
                  </p>
                </div>
                <div v-else-if="project">
                  <p class="variable"
                    v-if="projectId">
                    <a v-if="field.gaid === 'gaid539'">
                      {{(currentUser.answers['gaid273'] + ' ' + currentUser.answers['gaid274']) ||
                        (currentUser.answers['firstName'] + ' ' + currentUser.answers['lastName'])}}</a>
                    <a v-if="field.gaid === 'gaid538'">{{currentUser.email}}</a>
                    <a v-if="field.gaid === 'gaid540'">{{currentUser.phone || 'Unanswered'}}</a>
                  </p>
                  <p v-else style="color: black">
                    <b>{{translations.unanswered}}</b>
                  </p>
                </div>
                <div v-else>
                  <p class='variable'
                    v-if="siteId && inputParents[siteId].answers[field.gaid]">
                    {{inputParents[siteId].answers[field.gaid]}}
                  </p>
                  <p class='variable'
                    v-else-if="siteId &&
                    !(inputParents[siteId].answers['gaid502'] || 
                      inputParents[siteId].answers['gaid270'] || 
                      inputParents[siteId].answers['gaid358']
                    )">
                    <a v-if="field.gaid === 'gaid539'">
                      {{(currentUser.answers['gaid273'] + ' ' + currentUser.answers['gaid274']) ||
                        (currentUser.answers['firstName'] + ' ' + currentUser.answers['lastName'])}}</a>
                    <a v-if="field.gaid === 'gaid538'">{{currentUser.email}}</a>
                    <a v-if="field.gaid === 'gaid540'">{{currentUser.phone || 'Unanswered'}}</a>
                  </p>
                  <p v-else style="color: black">
                    <b>{{translations.unanswered}}</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ids from '@common/ids.yaml';
import translationsEn from '@content/en/owner/boring-page.yaml';
import translationsNl from '@content/nl/owner/boring-page.yaml';
import RequestHelper from '@common/helpers/request.helper';

export default {
  name: 'BoringSection',
  props: {
    projectId: String,
    title: String,
    questionnaire: Object,
    questionnaireActive: Boolean,
    siteId: String,
    project: Boolean,
    description: Boolean,
    editButton: Boolean,
    // used to check if the dev is in the project page
    boring: { type: Boolean, default: false }
  },

  created () {
    this.$store.subscribe(this.updateAnswers);
    this.sectionCompleted = this.isCompleted();
    if (localStorage.token && localStorage.loggedUser) {
      this.currentUser = JSON.parse(localStorage.loggedUser);
    }
    this.getUserData();
  },
  data () {
    return {
      ids: ids,
      lang: localStorage.lang,
      answerEdit: undefined,
      inputEdit: undefined,
      minimizeInputBox: false,
      role: localStorage.loggedUser ? JSON.parse(localStorage.loggedUser).userType : 0,
      requestHelper: new RequestHelper(this.$router),
      sectionCompleted: true,
      translations: this.lang === 'en' ? translationsEn : translationsNl,
      ownership: false,
      currentUser: undefined,
      user: undefined,
      projectUser: undefined,
      editingStepType: 0,
      editingSiteId: undefined,
    };
  },
  computed: {
    inputParents () {
      return this.$store.state.inputs.parents;
    },
    answers () {
      return this.inputParents[this.projectId]?.answers;
    },
    userSites () {
      return this.$store.state.siteOwner.userSites;
    },
    lastSelectedSiteId () {
      return this.$store.state.siteOwner.selectedSiteId;
    },
  },
  methods: {
    isTextOnly (step) {
      for (const stepIndex of step)
        if (ids[stepIndex.gaid].type === 'boolean')
          return false;
      return true;
    },
    isCompleted () {
      for (let questionnaireKey in this.questionnaire) {
        const step = this.questionnaire[questionnaireKey];
        if (step.boring !== false && (!step.if || this.isAnswered(step.if))) {
          const selectedAnswers = this.getSelectedAnswer(questionnaireKey);
          if (selectedAnswers.length > 0 && selectedAnswers[0] === this.translations.unanswered) {
            return false;
          }
        }
      }
      return true;
    },

    async getUserData () {
      if(!this.inputParents[this.projectId]?.users) return;
      for(let userP of this.inputParents[this.projectId]?.users){
        if(userP.relation === 4) this.user = userP;
      }
      let { data } = await this.requestHelper.getQuery(`allProjectUsers`);
      for (const users of data) {
        if(this.user._id === users._id){
          this.projectUser = users;
        }
      }
    },

    closeStep () {
      this.$emit('editAnswer');
      this.clearSelectedAnswer();
      this.editingStepType = 0;
    },

    clearSelectedAnswer () {
      this.answerEdit = undefined;
      if (this.$refs.section)
        this.$refs.section.clearSelectedAnswer();
    },

    editStep (step) {
      this.closeStep();
      this.$emit('editAnswer', { step , projectId: this.projectId });
      this.editingStepType = 1;
      this.answerEdit = step;
    },

    updateAnswers () {
      this.sectionCompleted = this.isCompleted();
      this.$forceUpdate();
    },
    getSelectedAnswer: function(stepIndex) {
      let step = this.questionnaire[stepIndex];
      if (!step) return [];

      if (step.group) return [this.getGroupAnswer(step.inputs)];

      if (!step.inputs) return [];
      return this.getAnswers(...step.inputs);
    },

    getAnswers: function(...gaids) {
      if (!this.answers) return [this.translations.unanswered];

      // Collects all answered
      let output = [];
      for (let gaid of gaids) {
        const answer = this.answers[gaid.gaid];
        if (answer && answer !== '')
          output.push(
            answer === true ? ids[gaid.gaid][this.lang].name : answer
          );
        else
          output.push('');
      }
      return output.length > 0 ? output : [this.translations.unanswered];
    },

    getGroupAnswer (gaids) {
      if (this.answers)
        for (let gaid of gaids) {
          if (this.answers[gaid.gaid] && this.answers[gaid.gaid] !== '') {
            if (typeof this.answers[gaid.gaid] == 'boolean') {
              return ids[gaid.gaid][this.lang].name;
            } else {
              return this.answers[gaid.gaid];
            }
          }
        }

      return this.translations.unanswered;
    },

    isAnswered: function(...gaids) {
      if (!this.answers) return false;

      // Returns the first answered
      for (let gaid of gaids) {
        const answer = this.answers[gaid];
        if (answer && answer !== '') return answer;
      }

      return false;
    }
  }
};
</script>

<style lang='scss' scoped>
@import '@styles/boring-page.scss';
</style>
