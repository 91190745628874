<template>
  <div>
    <p v-if="!hideTitle">Feasibility tab</p>
    <PieChart :data='chartData' ref='chart' :chartId="chartId"/>

    <div v-if="showLegend">
      <div v-for="(elem, i) of chartData" :key="i">
        <div id="feasibility-legend-element"><img :src='elem[4]' :alt="elem[0]"> {{elem[0]}}: <span :style="{'color': elem[2]}" >{{elem[3]}}</span></div>
      </div>
    </div>
  </div> 
</template>
<script>
import PieChart from './PieChart.vue';
import RequestHelper from '@common/helpers/request.helper.js';
import feasibility from '@common/feasibility.yaml';

export default {
  components: { PieChart },
  props: {
    showLegend: Boolean,
    hideTitle: Boolean,
    selectedSiteId: String,
    chartId: String
  },
  data () {
    return {
      requestHelper: new RequestHelper(this.$router),
      chartData: []
    };
  },
  mounted () {
    localStorage.lang = this.lang = this.$router.history.current.params.lang;
    // this.getChartsData('feasibilityChart');
    this.getChartsData();
    this.$store.subscribe(this.onStoreChange);
  },
  computed: {
    siteId () {
      return this.selectedSiteId || this.$store.state.siteOwner.selectedSiteId;
    },
    inputParents () {
      return this.$store.state.inputs.parents;
    }
  },
  watch: {
    siteId () {
      this.colorChange();
      this.getChartsData();
    },
    ["$props.selectedSiteId"]: {
      handler() {
        this.getChartsData();
      },
      immediate: true,
    },
  },
  methods: {
    getChartsData () {
      this.chartData =
        [
          [
            'Grid access',
            20,
            '#949494',
            'meters',
            '/assets/ionic-md-grid.svg',
            'invert(60%) sepia(7%) saturate(22%) hue-rotate(37deg) brightness(98%) contrast(88%)'
          ],
          [
            'Restricted Area',
            20,
            '#949494',
            'Not in restricted area',
            '/assets/Group4893.svg',
            'invert(60%) sepia(7%) saturate(22%) hue-rotate(37deg) brightness(98%) contrast(88%)'
          ],
          [
            'Business case',
            20,
            '#949494',
            'This project meets the criteria',
            '/assets/awesome-euro-sign.svg',
            'invert(60%) sepia(7%) saturate(22%) hue-rotate(37deg) brightness(98%) contrast(88%)'
          ],
          [
            'Site size',
            20,
            '#949494',
            'size',
            '/assets/material-photo-size-select-small.svg',
            'invert(60%) sepia(7%) saturate(22%) hue-rotate(37deg) brightness(98%) contrast(88%)'
          ],
          [
            'Grid congestion',
            20,
            '#949494',
            'text',
            '/assets/weather-lightning.svg',
            'invert(60%) sepia(7%) saturate(22%) hue-rotate(37deg) brightness(98%) contrast(88%)'
          ]
        ];
      this.colorChange();
    },
    colorChange () {
      if (!this.siteId) return;
      let answers = { ...this.inputParents[this.siteId]?.answers };
      if (!answers) return;
      if (!answers.gaid39) answers.gaid39 = answers.gaid291;

      for (const chart of Object.keys(feasibility)) {
        if (!feasibility[chart].checks) continue;
        for (const check of feasibility[chart].checks) {
          let colorChange = true;
          if (check.conditions) {
            for (const condition of check.conditions) {
              switch (condition.type) {
                case 'min':
                  if (answers[condition.gaid] < condition.value)
                    colorChange = false;
                  break;
                case 'answered':
                  let answer = answers[condition.gaid];
                  if (Array.isArray(answer) && condition.value) {
                    colorChange = false;
                    for (const a of answer)
                      if (condition.value === a) {
                        colorChange = true;
                        break;
                      }
                    if (!colorChange) break;
                  } else if (!answer || (condition.value && answer !== condition.value))
                    colorChange = false;
                  break;
                case 'max':
                  if (answers[condition.gaid] >= condition.value)
                    colorChange = false;
                  break;
              }
              if (!colorChange) break;
            }
          }
          if (colorChange) {
            //icons color chage
            if(check.color === '#90d690')this.chartData[feasibility[chart].chartPos][5] = 'invert(90%) sepia(10%) saturate(1364%) hue-rotate(62deg) brightness(88%) contrast(90%)'; //light green
            else if(check.color === '#E84141')this.chartData[feasibility[chart].chartPos][5] = 'invert(43%) sepia(26%) saturate(4906%) hue-rotate(335deg) brightness(93%) contrast(94%)'; //red
            else if(check.color === '#FEBB4E')this.chartData[feasibility[chart].chartPos][5] = 'invert(95%) sepia(18%) saturate(4220%) hue-rotate(20deg) brightness(102%) contrast(159%)'; //orangе 
            else if(check.color === '#E8C941')this.chartData[feasibility[chart].chartPos][5] = 'invert(90%) sepia(60%) saturate(900%) hue-rotate(16deg) brightness(150%) contrast(80%)'; //yellow
            else if(check.color === '#54A54F')this.chartData[feasibility[chart].chartPos][5] = 'invert(59%) sepia(10%) saturate(2196%) hue-rotate(69deg) brightness(93%) contrast(89%)'; // green
            this.chartData[feasibility[chart].chartPos][2] = check.color;
            const v = answers[feasibility[chart].value];
            this.chartData[feasibility[chart].chartPos][3] = check[this.lang]?.message ||
              (v ?
              (typeof v === 'number' ? +v.toFixed(2) : v) + (' ' + (feasibility[chart].suffix || '')) :
              'Loading data...');
            this.$refs.chart?.init();
            break;
          }
        }
      }
    },
    onStoreChange (mutation) {
      switch (mutation.type) {
        case 'siteOwner/setSiteSelected':
        case 'inputs/updateParentAnswers':
          this.getChartsData();
          break;
      }
    }
  }
};
</script>
<style>
#feasibility-legend-element {
  padding-top: 7px;
  text-align: left;
  padding-left: 50px;
  padding-left: 50px;
}
</style>
