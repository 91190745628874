/**
 * The structure and usage of this VueX store is based
 * on an official example:
 * https://github.com/vuejs/vuex/tree/dev/examples/shopping-cart
 */

import Vue from 'vue';
import Vuex from 'vuex';
import inputs from './modules/inputs';
import siteOwner from './modules/siteOwner';
import map from './modules/map';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        inputs,
        siteOwner,
        map,
        user,
    },

    // TODO: (ii) Activate only when not in production
    //   strict: debug,

    actions: {
        resetState(context) {
            for (let moduleName in context.state) {
                context.commit(`${moduleName}/resetState`);
            }
        }
    }
})
