module.exports = {
  logout(context) {
    delete localStorage.token;
    delete localStorage.loggedUser;

    if (context && context.$router || context.userType) {
      if (context.userType) context.$router.replace(context.userType === 0 ? `/${localStorage.lang}/home/site-owner` : `/${localStorage.lang}/home/project-developer`).catch(()=>{});
      else context.$router.replace(`/${localStorage.lang}`)
      context.$store.commit('siteOwner/resetState');
      context.$store.commit('inputs/resetState');
      context.$store.commit('map/resetState');
    }
  },
};
