<template>
  <div class="p-dev">
    <div class="row">
      <div class="logo">
        <router-link :to="`/${$router.history.current.params.lang}`">
          <img src="/assets/logo.png" />
        </router-link>
      </div>
    </div>
    <div class="language-switch">
      <router-link :to="getURL('en')">
        <button
          :class="
            this.currentLang === 'nl' ? 'lang-button active' : 'lang-button'
          "
        >
          {{ language['nl-lang'] }}
        </button>
      </router-link>
      <router-link :to="getURL('nl')">
        <button
          :class="
            this.currentLang === 'en' ? 'lang-button active' : 'lang-button'
          "
        >
          {{ language['en-lang'] }}
        </button>
      </router-link>
    </div>
    <div class="content-box">
      <p class="title">{{ translation.title }}</p>
      <p class="description">
        <span class="green-color">{{ translation['green-description'] }}</span>
        {{ translation.description }}
      </p>
      <div class="confirmation-box">
        <div class="container">
          <p class="header">{{ translation['confirmation-box'].title }}</p>
          <p class="description">
            {{ translation['confirmation-box'].description }}
          </p>
        </div>
        <gaInput gaid="gaid435" @isConfirmed="confirmed($event)"/>
        <p class="error">
            <img v-if="errorMessage" src="/assets/exclamation-small.svg" />
            {{ errorMessage }}
          </p>
        <div class="buttons">
          <button class="sign-up" @click="confirm()">
            {{ translation['confirmation-box']['confirm-button'] }}
          </button>
          <button class="resend" @click="resend()">
            <img src="/assets/reload-arrow.svg" />
            {{ translation['confirmation-box']['resend-button'] }}
          </button>
          <p>{{resendMessage}}</p>
        </div>
      </div>
    </div>
    <Footer class="mt-5" />
  </div>
</template>

<script>
import langTranslationEn from '@content/en/general/navigation.yaml';
import langTranslationNl from '@content/nl/general/navigation.yaml';
import confirmationsNl from '@content/nl/developer/confirmation.yaml';
import confirmationsEn from '@content/en/developer/confirmation.yaml';
import gaInput from '@components/inputs/ga-input';
import Footer from '@components/Footer';
import RequestHelper from '@common/helpers/request.helper';
export default {
  components: { gaInput, Footer },
  data() {
    return {
      lang: '',
      language: {},
      translation: {},
      requestHelper: new RequestHelper(this.$router),
      isConfirmed: false,
      errorMessage: undefined,
      resendMessage: ''
    };
  },
  computed: {
    currentLang() {
      return this.$route.path.substr(1, 2);
    },
    currentPage() {
      return this.$route.path;
    },
    inputParents() {
      return this.$store.state.inputs.parents;
    },
  },
  watch: {
    $route() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.lang = this.$route.params.lang;
      this.language =
        this.lang === 'en' ? langTranslationEn : langTranslationNl;
      this.translation = this.lang === 'en' ? confirmationsEn : confirmationsNl;
    },
    getURL(prevLanguage) {
      return this.currentPage.replace(
        prevLanguage,
        prevLanguage === 'nl' ? 'en' : 'nl'
      );
    },
    confirmed(isConfirmed) {
      this.isConfirmed = isConfirmed;
    },
    confirm() {
      this.requestHelper.getQuery(
        `confirmAccount?confirmationCode=${this.inputParents['user']?.answers['gaid435']}`,
        ({ data }) => {
          if ('error' in data) {
            //   TODO: design for the error message
            this.errorMessage = data.error;
          } else {
            localStorage.loggedUser = JSON.stringify(data);
            this.$router.push(`/${localStorage.lang}/dev/profile`);
          }
        }
      );
    },
    resend() {
      this.requestHelper.getQuery('resendCode', (data) => {
        if (data) this.resendMessage = this.translation['confirmation-box']['resend-success']
      }, (error) => {
        if (error) this.resendMessage = this.translation['confirmation-box']['resend-fail']
      })
    }
  },
};
</script>

<style lang="scss">
@import '@styles/main.scss';
@import '@styles/error/error-massage.scss';
@import '@styles/authorization-project-dev.scss';
.error{
  margin: 10px 0 0 15px;
}
</style>