function getDefaultState() {
  return {
    userProjects: [],
    userSites: [],

    currentStepId: 'qgaid1',

    selectedSiteId: undefined,
    selectedProjectId: undefined,
    notificationsCount: 0
  };
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },

    initUserProjects(state, userProjects) {
      state.userProjects = userProjects || [];
    },

    pushToUserProjects(state, project) {
      state.userProjects.push(project);
    },

    changeStatus(state, payload) {
      for (let p of state.userProjects.find((value) => value._id === payload.id)
        ?.connected)
        p.status = payload.status;
    },

    initUserSites(state, userSites) {
      state.userSites = userSites || [];
    },

    pushToUserSites(state, site) {
      state.userSites.push(site);
    },
    removeUserSite(state, siteId) {
      state.userSites = state.userSites.filter(
        (site) => site.options.id != siteId
      );
    },
    removeUserProject(state, projectId) {
      state.userProjects = state.userProjects.filter(
        (project) => project._id != projectId
      );
    },

    setSiteProjectId(state, { siteId, projectId }) {
      const siteIndex = state.userSites.findIndex(
        (site) => site.options?.id === siteId
      );
      state.userSites[siteIndex].options.projectId = projectId;

      // Set the passed project id as selected
      state.selectedProjectId = projectId;
    },

    setSiteSelected(state, siteId) {
      if (siteId === undefined) {
        state.selectedSiteId = undefined;
        state.selectedProjectId = undefined;
        state.currentStepId = 'qgaid1';
        for (const site of state.userSites)
          if (site.options?.selected) site.options.selected = false;
        return;
      }
      for (let site of state.userSites) {
        if (site.options?.id === siteId || site.options.projectId === siteId) {
          site.options.selected = true;

          // Set the selected site's projectId as selected
          state.selectedProjectId = site.options.projectId;

          // set selectedSiteId
          state.selectedSiteId = site.options.id;

          // set questionnaire step (1 being the default step)
          if (!site.options.currentStepId)
            site.options.currentStepId = 'qgaid1';

          state.currentStepId = site.options.currentStepId;
          break;
        } else if (site.options?.selected) {
          site.options.selected = false;
        }
      }
    },

    // This is only listened for
    updateEmail() {
    },

    updateNotificationsCount(state, count) {
      state.notificationsCount = count;
    },

    setCurrentStepId(state, currentStepId) {
      state.currentStepId = currentStepId;

      const selectedSite = state.userSites.find(
        (site) => site.options.id === state.selectedSiteId
      );
      if (selectedSite) {
        selectedSite.options.currentStepId = state.currentStepId;
      }
    },

    setSelectedProjectId(state, projectId) {
      state.selectedProjectId = projectId;
    },

    setSelectedSketch(state, selectedSketch) {
      state.selectedSketch = selectedSketch;
    },

    updateMarkerTooltip() {
    },
  },

  initUserSites(state, userSites) {
    state.userSites = userSites || [];
  },

  pushToUserSites(state, site) {
    state.userSites.push(site);
  },

  setSiteProjectId(state, { siteId, projectId }) {
    let siteIndex = state.userSites.findIndex(
      (site) => site.options?.id === siteId
    );
    state.userSites[siteIndex].options.projectId = projectId;

    // Set the passed project id as selected
    state.selectedProjectId = projectId;
  },

  setSiteSelected(state, siteId) {
    if (siteId === undefined) {
      state.selectedSiteId = undefined;
      state.selectedProjectId = undefined;
      state.currentStepId = 'qgaid1';
      for (let site of state.userSites)
        if (site.options?.selected) site.options.selected = false;
      return;
    }
    for (let site of state.userSites) {
      if (site.options.id === siteId || site.options.projectId === siteId) {
        site.options.selected = true;

        // Set the selected site's projectId as selected
        state.selectedProjectId = site.options.projectId;

        // set selectedSiteId
        state.selectedSiteId = site.options.id;

        // set questionnaire step (1 being the default step)
        if (!site.options.currentStepId) site.options.currentStepId = 'qgaid1';

        state.currentStepId = site.options.currentStepId;
        break;
      } else if (site.options?.selected) {
        site.options.selected = false;
      }
    }
  },

  setCurrentStepId(state, currentStepId) {
    state.currentStepId = currentStepId;

    const selectedSite = state.userSites.find(
      (site) => site.options.id === state.selectedSiteId
    );
    if (selectedSite) {
      selectedSite.options.currentStepId = state.currentStepId;
    }
  },

  setSelectedProjectId(state, projectId) {
    state.selectedProjectId = projectId;
  },
};
