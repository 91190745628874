import RequestHelper from '../../helpers/request.helper';

export class Project {
  // Automatically initialized
  _id;

  // Optional / Modified during the lifespan of the object
  answers;

  requestHelper;

  // Constructor and validation
  constructor(other) {
    this.answers = other.answers || {};
  }

  // Getters and setters

  async updateAnswers(answers) {
    await this.requestHelper.putQuery(`projects?id=${this._id}`, { answers });
    Object.assign(this.answers, answers);
  }

  getId() {
    return this._id;
  }

  // Static methods

  /**
   * An async method that creates a project in the db and returns a class instance.
   */
  static async createInstance(other, requestHelper) {
    const project = new Project(other);
    if (localStorage.token) {
      const response = await requestHelper.postQuery('projects', project);
      project._id = response?.data.projectId;
    } else {
      // set a temporary id for the project when the user isn't registered
      project._id = `p-${Date.now()}`;
    }

    return project;
  }

  static async getAllForUser() {
    return (await new RequestHelper().getQuery('projects'))?.data;
  }
}
