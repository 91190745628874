<template>
  <div class="segment-content">
    <h3>{{ segment.segment }}</h3>
    <div class="content">
      <div class="segment-map-container">
        <div class="map-placeholder" id="map-placeholder">
          <div class="spinning-square" />
        </div>
        <Map ref="leafMapComponent" mapName="regTech" :showLayers="{}" />
      </div>

      <div class="card">
        <div>
          <gaInput :readOnly="readOnly" gaid="gaid231" :parentId="parentId" />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid234"
            group="gaid237"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid235"
            group="gaid237"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid236"
            group="gaid237"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid256"
            group="gaid237"
            :parentId="parentId"
          />
          <div class="space" />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid238"
            group="gaid262"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid239"
            group="gaid262"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid240"
            group="gaid262"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid257"
            group="gaid262"
            :parentId="parentId"
          />
        </div>
        <div>
          <gaInput :readOnly="readOnly" gaid="gaid232" :parentId="parentId" />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid242"
            group="gaid245"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid243"
            group="gaid245"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid244"
            group="gaid245"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid258"
            group="gaid245"
            :parentId="parentId"
          />
          <div class="space" />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid246"
            group="gaid263"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid247"
            group="gaid263"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid248"
            group="gaid263"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid259"
            group="gaid263"
            :parentId="parentId"
          />
        </div>
        <div>
          <gaInput :readOnly="readOnly" gaid="gaid233" :parentId="parentId" />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid249"
            group="gaid252"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid250"
            group="gaid252"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid251"
            group="gaid252"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid260"
            group="gaid252"
            :parentId="parentId"
          />
          <div class="space" />
          <gaInput :readOnly="readOnly" gaid="gaid253" :parentId="parentId" />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid254"
            group="gaid264"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid255"
            group="gaid264"
            :parentId="parentId"
          />
          <gaInput
            :readOnly="readOnly"
            gaid="gaid261"
            group="gaid264"
            :parentId="parentId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import gaInput from "@components/inputs/ga-input";
import Map from "@components/Map";
import RequestHelper from "@common/helpers/request.helper.js";

export default {
  name: "SegmentInfo",

  data() {
    return {
      lang: "",
      zoom: 20,
      center: [51.926779, 4.4930619],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      selectedPolygonId: undefined,
      minimizeInputBox: false,
      parentId: "",
      requestHelper: new RequestHelper(this.$router),
    };
  },
  components: {
    gaInput,
    Map,
  },
  props: {
    segment: Object,
    readOnly: Boolean,
  },
  watch: {
    segment(newValue) {
      if (newValue && !!newValue.segmentType) {
        this.parentId = newValue.parentId;
        this.getSegmentCords(newValue);
        this.requestHelper.getAnswers(
          this.$store,
          this.parentId,
          this.segment.segmentType
        );
      }
    },
  },
  created() {
    this.$store.subscribe(this.updateAnswers);
  },

  methods: {
    getSegmentCords() {
      const map = this.$store.state.map.instances["regTech"];
      map.invalidateSize();
      const mapLoader = document.getElementById("map-placeholder");
      mapLoader.style.display = "flex";
      this.requestHelper.getQuery(
        `segmentCoordsQuery?segment=${this.segment.segment}&segmentType=${this.segment.segmentType}`,
        ({ data }) => {
          map.eachLayer((layer) => {
            if (layer instanceof L.Polygon) map.removeLayer(layer);
          });
          if (!map) return;

          mapLoader.style.display = "none";

          this.reverseCoordinatesRecursively(data.geometry.coordinates);

          const segment = L.polygon(data.geometry.coordinates);
          map.addLayer(segment);

          const center = segment.getCenter();

          map.panTo([center.lat, center.lng], this.maxZoom, {
            animate: false,
            duration: 0,
          });

          // Zoom to fit
          map.fitBounds(segment.getBounds());
        }
      );
    },
    updateAnswers(mutation) {
      if (this.role !== 2) return
      
      if (mutation.type !== 'inputs/updateParentAnswers' ||
          mutation.payload.parentId !== this.parentId) return

      const answers = this.$store.state.inputs.parents[this.parentId].answers;
      const data = {
        answers,
        parentId: this.parentId,
        segmentType: this.segment.segmentType,
      };

      this.requestHelper.postQuery("segmentAnswers", data);
    },

    reverseCoordinatesRecursively(coordinates) {
      if (coordinates.length === 2 && typeof coordinates[0] == "number")
        return coordinates.reverse();

      return coordinates.map(this.reverseCoordinatesRecursively);
    },
  },
};
</script>

<style lang="scss">
@import "@styles/regtech.scss";
</style>
