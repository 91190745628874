<template>
  <div class='popup-box dimming' @click='closeGuideBox($event, true)'>
    <div class='guide-popup box-style'>
      <div class="padding">
        <Chevron v-if="user === 1"
          @change-tab='changeTab($event)'
          :currentTab='selectedTab'
          :tabs="[
            {
              link: tabs[0].navigation,
              title: tabs[0].navigation,
              backgroundActive: `url('/assets/chevron.tabs/active_dev-map.svg') no-repeat`,
              background: `url('/assets/chevron.tabs/dev-map.svg') no-repeat`
            },
            {
              link: tabs[6].navigation,
              title: tabs[6].navigation,
              backgroundActive: `url('/assets/chevron.tabs/active_my-profile.svg') no-repeat`,
              background: `url('/assets/chevron.tabs/my-profile.svg') no-repeat`
            },
            {
              link: tabs[2].navigation,
              title: tabs[2].navigation,
              backgroundActive: `url('/assets/chevron.tabs/active_boring-page.svg') no-repeat`,
              background: `url('/assets/chevron.tabs/boring-page.svg') no-repeat`
            },
            { link: tabs[5].navigation,
              title: ' ',
              backgroundActive: `url('/assets/chevron.tabs/guide-dropdown.svg') no-repeat`,
              background: `url('/assets/chevron.tabs/guide-dropdown.svg') no-repeat`
            },
            {
              link: tabs[1].navigation,
              title: tabs[1].navigation,
              backgroundActive: `url('/assets/chevron.tabs/active_boring-page.svg') no-repeat`,
              background: `url('/assets/chevron.tabs/boring-page.svg') no-repeat`
            },
            {
              link: tabs[7].navigation,
              title: tabs[7].navigation,
              background: `url('/assets/chevron.tabs/matching.svg') no-repeat`,
              backgroundActive: `url('/assets/chevron.tabs/active_matching.svg') no-repeat`
            },
            {
              link: tabs[3].navigation,
              title: tabs[3].navigation,
              background: `url('/assets/chevron.tabs/review.svg') no-repeat`,
              backgroundActive: `url('/assets/chevron.tabs/active_review.svg') no-repeat`
            },
            {
              link: tabs[4].navigation,
              title: tabs[4].navigation,
              background: `url('/assets/chevron.tabs/project-launch.svg') no-repeat`,
              backgroundActive: `url('/assets/chevron.tabs/active_project-launch.svg') no-repeat`
            }
          ]"
        />
        <Chevron v-else
          @change-tab='changeTab($event)'
          :currentTab='selectedTab'
          :tabs="[
            {
              link: tabs[0].navigation,
              title: tabs[0].navigation,
              backgroundActive: `url('/assets/chevron.tabs/active_map.svg') no-repeat`,
              background: `url('/assets/chevron.tabs/map.svg') no-repeat`
            },
            { link: tabs[5].navigation,
              title: ' ',
              backgroundActive: `url('/assets/chevron.tabs/guide-dropdown.svg') no-repeat`,
              background: `url('/assets/chevron.tabs/guide-dropdown.svg') no-repeat`
            },
            {
              link: tabs[7].navigation,
              title: tabs[7].navigation,
              backgroundActive: `url('/assets/chevron.tabs/active_map.svg') no-repeat`,
              background: `url('/assets/chevron.tabs/map.svg') no-repeat`
            },
            {
              link: tabs[1].navigation,
              title: tabs[1].navigation,
              backgroundActive: `url('/assets/chevron.tabs/active_boring-page.svg') no-repeat`,
              background: `url('/assets/chevron.tabs/boring-page.svg') no-repeat`
            },
            {
              link: tabs[2].navigation,
              title: tabs[2].navigation,
              backgroundActive: `url('/assets/chevron.tabs/active_matching.svg') no-repeat`,
              background: `url('/assets/chevron.tabs/matching.svg') no-repeat`
            },
            {
              link: tabs[3].navigation,
              title: tabs[3].navigation,
              background: `url('/assets/chevron.tabs/review.svg') no-repeat`,
              backgroundActive: `url('/assets/chevron.tabs/active_review.svg') no-repeat`
            },
            {
              link: tabs[4].navigation,
              title: tabs[4].navigation,
              background: `url('/assets/chevron.tabs/project-launch.svg') no-repeat`,
              backgroundActive: `url('/assets/chevron.tabs/active_project-launch.svg') no-repeat`
            }
          ]"
        />
        <!-- </div> -->
        <div class='guide-content'>
          <div class='img-column' v-if="selectedSection.images">
            <img v-for='(img, index) in images' :key='index' :src='`/assets/${img}`' />
          </div>

          <div class='instructions'>
            <span class='title'>{{ selectedSection.title }}</span>
            <ul class='bullets'>
              <li
                v-for='(item, index) of selectedSection.description'
                :key='index'
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </div>

        <div class='tags'>
          <span
            class='tag'
            v-for='(section, index) of tabSections'
            :class='{ active: selectedSectionId === section.section }'
            @click='selectSection(section.section)'
            :key='index'
          >
          <span class="titles">{{ section.name }}</span>
          </span>
        </div>

        <button class='close-btn' @click='closeGuideBox()'>
          <img src='/assets/guide-close-icon.svg' alt='img' />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import firstTab from '@content/nl/i/1.yaml';
import secondTab from '@content/nl/i/2.yaml';
import thirdTab from '@content/nl/i/3.yaml';
import fourthTab from '@content/nl/i/4.yaml';
import fifthTab from '@content/nl/i/5.yaml';
import sixthTab from '@content/nl/i/dropdown.yaml';
import seventhTab from '@content/nl/i/7.yaml';
import myProfile from '@content/nl/i/profile.yaml';

import firstTabEn from '@content/en/i/1.yaml';
import secondTabEn from '@content/en/i/2.yaml';
import thirdTabEn from '@content/en/i/3.yaml';
import fourthTabEn from '@content/en/i/4.yaml';
import fifthTabEn from '@content/en/i/5.yaml';
import seventhTabEn from '@content/en/i/7.yaml';
import sixthTabEn from '@content/en/i/dropdown.yaml';
import myProfileEn from '@content/en/i/profile.yaml';
import Chevron from '@components/Chevron';

export default {
  components: {
    Chevron
  },

  data () {
    return {
      tabs: localStorage.lang === 'en' ? [firstTabEn, secondTabEn, thirdTabEn, fourthTabEn, fifthTabEn, sixthTabEn, myProfileEn,seventhTabEn] : [firstTab, secondTab, thirdTab, fourthTab, fifthTab, sixthTab, myProfile,seventhTab],
      tabSections: [],
      selectedTab: firstTab.navigation,
      selectedSectionId: 0,
      images: [],
      user: JSON.parse(localStorage.loggedUser).userType
    };
  },

  watch: {
    $route () {
      this.updateSelected();
      this.updateImages();
    }
  },

  computed: {
    selectedSection () {
      return this.tabSections.find(section => section.section == this.selectedSectionId);
    }
  },
  created () {
    this.selectTab(firstTab.navigation);
    this.updateImages();
  },
  mounted () {
    this.updateSelected();
  },

  methods: {
    changeTab (navigationName) {
      const tab = this.tabs.find(tab => tab.navigation == navigationName);
      if (!tab) throw new Error(`Tab with navgitaion: ${navigationName} was not found`);

      const subsection = tab.sections[0].section;

      this.$router.push({ query: { section: navigationName, subsection } }).catch(() => {
      });
    },
    selectSection (sectionId) {
      this.$router.push({ query: { section: this.selectedTab, subsection: sectionId } }).catch(() => {
      });
    },
    selectTab (navigation) {
      const tab = this.tabs.find((tab) => tab.navigation === navigation);
      this.selectedTab = tab.navigation;

      this.tabSections = [];
      tab.sections.forEach((section, index) => {
        if (index === 0) this.selectedSectionId = tab.sections[0].section;
        this.tabSections.push(section);
      });
      this.$forceUpdate();
    },
    updateSelected () {
      if (this.$route.query.section)
        this.selectTab(this.$route.query.section);
      if (this.$route.query.subsection)
        this.selectedSectionId = this.$route.query.subsection;

    },
    updateImages () {
      this.images = this.selectedSection.images;
      if (typeof this.images === 'string')
        this.images = [this.images];
    },
    closeGuideBox (ev, isBackgroundClick) {
      if (isBackgroundClick && !ev.target.className.includes('popup-box'))
        return;

      this.$emit('closeGuideBox');
    }
  }
};
</script>
<style lang='scss'>
@import '@styles/guide.scss';
@import '@styles/main.scss';


@media only screen and (max-width: 1100px){
  .popup.box-style{
    zoom: 0.8
  }
}
@media only screen and (max-width: 920px) {
  .popup.box-style {
    zoom: 0.6 !important
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #afafaf 0% 0% no-repeat padding-box;
  border: 1px solid #afafaf;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #808080;
}


@media (orientation:portrait) and (min-width: 768px)   {
    .guide-popup {
      width: 90%;
      zoom: 0.7;
    }

    .chevron{
      zoom: normal;
      
    }
    .nav-link{
      padding: 16px !important;
    }
}


@media (orientation: landscape) and (max-width: 1024px) {
  .guide-popup {
      width: 90%;
      zoom: 0.8;
    }

    .chevron{
      zoom: normal;
     }

.nav-link{
    padding: 16px !important;


    }

}

@media (orientation:portrait) and  (max-width: 834px)   {
    .guide-popup {
      width: 90%;
      zoom: 0.7;
    }

    .chevron{
      zoom: 0.7;
      
    }
    .nav-link{
      padding: 16px !important;
    }
}


@media (orientation: landscape) and (max-width: 1112px)  {
  .guide-popup {
      width: 90%;
      zoom: 0.8;
    }

    .chevron{
      zoom: normal;
     }

.nav-link{
    padding: 16px !important;


    }

}

</style>
