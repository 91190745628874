<template>
  <div class='popup-box dimming' @click='closeSiteReject($event, true)'>
    <div class='popup box-style'>

      <!-- </div> -->

      <div class='popup-content'>
        <button class='close-btn' @click='closeSiteReject()'>
          <img src='/assets/guide-close-icon.svg' alt='img' />
        </button>
        <div class='popup-top-section'>
          <div class='row'>
            <p class='popup-header'>{{ translations.reject }}</p>
          </div>

          <div class='row'>
            <p class='info-text'>
              {{ translations['info-text'] }}
            </p>
            <div class='text-box'>
              <p>{{ translations.reason }}</p>
              <GaInput key='gaid439' gaid='gaid439' />
            </div>
          </div>
        </div>

        <div class='popup-bot-section'>
          <div class='row'>
            <gaInput class='checkboxInput' gaid='gaid453' :text='translations.confirm' @data='onData($event)' />
          </div>

          <div class='popup-buttons'>
            <button class='reject' @click='approveRejection()' :disabled='!rejectAllowed'
                    :style="{ cursor: rejectAllowed ? 'pointer' : 'not-allowed' }">{{ translations['reject-button'] }}
            </button>
            <button class='cancel' @click='closeSiteReject()'>{{ translations['cancel-button'] }}</button>
          </div>
          <div class='lower-texts'>
            <img src='/assets/exclamation-small.svg' />
            <p>{{ translations.action }}</p>
          </div>
        </div>
      </div>


    </div>

  </div>
</template>

<script>
import siteRejectNl from '@content/nl/admin/site-reject.yaml';
import siteRejectEn from '@content/en/admin/site-reject.yaml';
import GaInput from '@components/inputs/ga-input';

export default {
  data () {
    return {
      checkbox: false,
      translations: localStorage.lang === 'en' ? siteRejectEn : siteRejectNl
    };
  },
  components: {
    GaInput
  },
  props: {
    site: Object
  },
  computed: {
    rejectAllowed () {
      return this.checkbox !== false;
    }
  },
  methods: {
    closeSiteReject (ev, isBackgroundClick) {
      if (isBackgroundClick && !ev.target.className.includes('popup-box'))
        return;

      this.$emit('closeSiteReject');
    },
    approveRejection () {
      this.$emit('approveRejection');
    },
    onData (ev) {
      this.checkbox = ev.gaid453;
    }
  }
};
</script>

<style lang='scss' scoped>
@import '@styles/main.scss';
@import '@styles/reject-site.scss';
@import '@styles/popup.scss';
</style>
