<template>
  <div id='app'>
    <div class='content'>
      <router-view />
    </div>

    <!-- TODO (Milen) create cookie.yaml for the texts -->
    <cookie-law class='cookie'>
      <div slot-scope='props'>
        <div class='row'>
          <img src='/assets/cookie.png' />
          <p>
            This site uses
            <span class='undeline'>cookies</span>
          </p>
        </div>
        <div class='row'>
          <button class='skew' @click='props.accept'>
            <span>OK</span>
          </button>
        </div>
      </div>
    </cookie-law>

    <!-- The definition shown when you hover -->
    <info-popup />

  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law';
import store from './store';
import InfoPopup from './components/popup/info-popup';
import RequestHelper from '@common/helpers/request.helper';
import { logout } from '@common/helpers/logout.helper.js';

export default {
  name: 'App',

  /**
   * VueX store
   */
  store,

  components: {
    InfoPopup,
    CookieLaw
  },
  data () {
    return{
    requestHelper: new RequestHelper(this.$router),
    };
  },
  mounted () {
    if (!this.$router.history.current.params.lang) {
      this.$router.push('en');
    }
  },
   watch:{
    $route (){
      if(localStorage.token){
        this.requestHelper.getQuery(`validateToken`, 
        () => {}, 
        () => {logout(this);
        })
      }
    }
  }
};

</script>

<style lang='scss'>
@import "@styles/main.scss";
@import "@styles/mobile-mode.scss";
@import "@styles/lazy-images.scss";
@import "@styles/mobile-mode.scss";
@import "@styles/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: $light-text;
  font-family: $font__IBM;
}

body.no-scroll {
  overflow: hidden;
}

body {
  overflow-x: hidden;

  .background-slot {
    .lazy-background-slot {
      z-index: 2;
    }
  }

  .unclicked-login-button {
    z-index: -1 !important;
  }
}

.Cookie--bottom {
  left: 100px !important;
  opacity: 0.9;
}

.Cookie--base {
  background-color: white;
}

.cookie {
  width: 340px !important;
  height: 171px;
  justify-content: center !important;
  left: 90px;

  img {
    margin-right: 20px;
  }

  p {
    font-size: 14px;
    color: $headers;
    font-family: "Rubik", sans-serif;
    margin-top: 20px;

    span {
      text-decoration: underline;
    }
  }

  button {
    margin-left: auto;
    margin-right: auto;
    background-color: #ffc800;
    border-radius: 10px;
    border: none;
    padding: 13px 70px;
    margin-top: 20px;

    span {
      font-family: "Rubik", sans-serif;
      font-size: 14px;
    }
  }
}

.ga-button-definition-box {
  position: absolute;
  border: 2px solid $primary;
  border-radius: 7px;
  padding: 5px;
  color: $text;
  font-weight: 300;
  font-size: 12px;
  z-index: 50000;
  background: white;
  display: none;
  max-width: 400px;
  border-top-left-radius: 0px;
}
</style>
