<template>
  <div class="tos">
    <LanguageSwitcher :content-en="this.tosEn" :content-nl="this.tosEn"></LanguageSwitcher>

    <div class="pt-5">
      <div class="pt-3">
        <div class="design" v-html="html"></div>
      </div>
    </div>
    <Footer :lang='lang' />
  </div>
</template>

<script>
import { marked } from 'marked';
import tosNl from '@content/nl/tos-platform.md';
import tosEn from '@content/en/tos-platform.md';
import Footer from '@components/Footer';
import LanguageSwitcher from '@layouts/home/components/LanguageSwitcher';

export default {
  name: 'PlatformTermsOfService',
  components: { LanguageSwitcher, Footer },
  data() {
    return {
      html: '',
      lang: '',
      tosEn: this.tosEn,
      tosNl: this.tosNl
    };
  },
  created() {
    this.init();
  },
  watch: {
    $route() {
      this.init();
    }
  },
  computed: {
    currentLang() {
      return this.$route.path.substr(1, 2);
    },
    currentPage() {
      return this.$route.path;
    }
  },
  methods: {
    init() {
      localStorage.lang = this.lang = this.$router.history.current.params.lang;
      let data = this.lang === 'en' ? tosEn : tosNl;
      this.html = marked(data);
    },
    getURL(prevLanguage) {
      return this.currentPage.replace(
        prevLanguage,
        prevLanguage === 'nl' ? 'en' : 'nl'
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@styles/main.scss";

</style>
