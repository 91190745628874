<template>
  <div class="marker-modal-box" :style="{height: fullScreenMode ? '149px' : '44px'}" v-if="sketch">
    <gaInput :key="`${sketchId}-title`" :showActionLabels="false" v-if="sketch.title || sketch.title === ''"  gaid='gaid536' @data='onData($event)' :defaultValue="sketch.title"/>
   <!-- <input type="text" placeholder="Enter your title" id="marker-title" v-model="sketch.title" @input="onData"> -->
    <div class="expand-button" @click="expandModal()"><img :class="fullScreenMode ? 'button-img' : 'expand-button-img'" src='/assets/window.svg'></div>
    <!-- <textarea v-show="fullScreenMode" id="marker-text-content" placeholder="Enter your text" v-model="sketch.text" @input="onData"></textarea> -->
    <gaInput :key="`${sketchId}-text`" :showActionLabels="false" id="marker-text-content" v-if="sketch.text || sketch.text === ''" v-show="fullScreenMode" :defaultValue="sketch.text" gaid='gaid537' @data='onData($event)' />
  </div> 
</template>
<script>
import RequestHelper from '@common/helpers/request.helper.js';
import gaInput from '@components/inputs/ga-input';

export default {
  components: { gaInput },

  props: {
   
  },

  data () {
    return {
        requestHelper: new RequestHelper(this.$router),
        fullScreenMode: false,
        sketchId: "",
        projectId: "",
        project: {},
        sketch: {}
    };
  },
  created () {
    this.$store.subscribe(this.onStoreChange);
  },
  mounted () {
  },

  computed: {
    siteId () {
      return this.$store.state.siteOwner.selectedSiteId;
    },
    parents () {
      return this.$store.state.inputs.parents;
    },
  },

  watch: {
  },

  methods: {
    onData (e) {
        if (Object.keys(e).includes('gaid536')) this.sketch.title = e['gaid536'];
        else this.sketch.text = e['gaid537']

        this.save();
    },
    expandModal () {
        this.fullScreenMode = !this.fullScreenMode;
    },
    async save () {
        this.requestHelper.putQuery(`sketches?sketchId=${this.sketchId}&projectId=${this.projectId}`, this.sketch).then(() => {
          this.$store.commit("siteOwner/updateMarkerTooltip", { sketchId: this.sketchId, title: this.sketch.title, text: this.sketch.text})
        });
    },
    onStoreChange (mutation) {
      switch (mutation.type) {
        case 'siteOwner/setSelectedSketch':
            this.sketchId = mutation.payload.sketchId;
            this.projectId = mutation.payload.projectId;
            this.project = this.parents[this.projectId];
            this.sketch = (this.project?.sketches || []).find(s => s._id == this.sketchId);
            if (!this.sketch.title) this.sketch.title = "";
            if (!this.sketch.text) this.sketch.text = "";
            break;
      }
    },
  }
};
</script>
<style lang="scss">

.marker-modal-box {
    width: 320px;
    height: 44px;
    left: 320px;

    input {
        color: #B5B8CC !important;
        border: none;
        font-style: italic;
        min-height: 44px !important;
        height: 44px !important;
        padding-left: 0px;

        ::placeholder {
            color: #B5B8CC;
        }
    }

    textarea {
        color: #B5B8CC !important;
        font-style: italic;
        width: 285px;
        height: 85px;
        margin-top: -28px;
        border: none;
        resize: none;
        outline: none;

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        ::placeholder {
            color: #B5B8CC !important;
        }
    }
}

.expand-button {
    position: relative;
    bottom: 31px;
    left: 288px;
    height: 18px;
    width: 18px;
    cursor: pointer;

    .expand-button-img {
        transform: rotate(180deg);
    }

    .button-img {
        transform: rotate(0deg);
    }

    img {
        height: inherit;
        width: inherit;
    }
}

</style>
