<template>
  <div class='site-wrapper'>
    <div class='header'>
      <div class='header-left'>
        <p class='site-text'>Site:</p>
        <p class='site-name-variable'>{{ getAnswerDefault("New Site", { gaid: 'gaid185' }) }}</p>
      </div>
      <div class='header-right'>
        <button @click='minimizeInputBox = !minimizeInputBox'>
          <img class='icon' :class="minimizeInputBox === false ? 'rotated-arrow':'' "
               src='/assets/arrow-down-boring.svg' />
        </button>
      </div>
    </div>
    <div class='header'>
      <div class='site-line' />
    </div>
    <div
      class='site'
      :style="{ height: 'unset' }"
      :class='{ minimized: minimizeInputBox === true }'
    >
      <p class='section-name head'>{{ translations.site.title }}</p>
      <div class='line' />
      <div class='big-row'>
        <div class='data'>
          <div class='mini-map'>
            <Map
              ref='leafMapComponent'
              :mapName="'project' + site.options.id"
            />
          </div>
        </div>
      </div>
      <!-- Site Answers -->
      <div class='big-row'>
        <div class='data'>
          <div class='map-info'>
            <p class='section-name'>Site information</p>
              <div class='line' />
            <div class='row'>
              <p class='static'>{{ translations.site.country }}</p>
              <p class='variable'>
                {{ translations.site['country-name'] }}
              </p>
            </div>

            <div class='row'>
              <p class='static'>{{ translations.site.res }}</p>
              <p class='variable'>
                <span v-if="!answers['gaid442']">{{ translations.site['if-no-info'] }}</span>
                <span v-else>{{ answers['gaid442'].join(', ') }}</span>
              </p>
            </div>

            <div class='row'>
              <p class='static'>{{ translations.site.province }}</p>
              <p class='variable'>
                <span v-if="!answers['gaid440']">{{ translations.site['if-no-info'] }}</span>
                <span v-else>{{ answers['gaid440'].join(', ') }}</span>
              </p>
            </div>

            <div class='row'>
              <p class='static'>{{ translations.site.municipality }}</p>
              <p class='variable'>
                <span v-if="!answers['gaid441']">{{ translations.site['if-no-info'] }}</span>
                <span v-else>{{ answers['gaid441'].join(', ') }}</span>
              </p>
            </div>
          </div>
          <div v-for='(step, index) of journeyConfiguration' :key='index'>
            <div v-if='step.section'>
              <p class='section-name'>{{ step.section }}</p>
              <div class='line' />
              <div class="feasibilityChart" v-if="step.section === 'Feasibility'" >
                <Feasibility :hideTitle='true' id="feasibility-projectPage" :selectedSiteId='site.options.id' :chartId="'chart'+site.options.id"/>
              </div>
              <div class="energyModel" v-if="step.section === 'Economics'">
                <Model :isProjectPage='true' :selectedSiteId='site.options.id' :chartId="'chart'+site.options.id" id='model-projectPage'/>
              </div>
            </div>
            <div v-show='step.inputs && (!step.if || isIfAnswered(step.if))'
                 :class="answerEdit === index && editingStepType === 1 ? 'row editable' : 'row'">
              <p :class="step.subsection ? 'sub-static' : 'static'">
                <img v-show='answerEdit === index && editingStepType === 1'
                     class='edit-pen'
                     src='/assets/close-blue.svg'
                     @click='closeStep(index)'
                />
                <img
                  v-if='role !== 1 && step.editable !== false'
                  v-show='answerEdit !== index || editingStepType !== 1'
                  class='edit-pen'
                  src='/assets/edit-pen.svg'
                  alt=''
                  @click='editStep(index)'
                />
                {{
                  ids[step.title][lang].boringName || ids[step.title][lang].name
                }}
                <span
                  class='asterix'
                  v-show='
                    ids[step.title].validations &&
                    ids[step.title].validations.mandatory === true'
                >*</span>
              </p>
              <!-- If it's size, show between input and auto calculated -->
              <p v-if='ids[step.title].sizeLabel' class='variable'>
                {{ getAnswer({ gaid: step.title }, { gaid: 'gaid291' }) }} sq.m.
              </p>
              <div v-for='(input, i) of step.inputs' :key='i' 
                  v-else-if="step.measurmentUnit" class='variable'>
                  <p
                    v-for='(line, i) of getSelectedAnswer(index)'
                    :key='i'
                    class='variable'
                  >
                    <span class='variable' v-if="input.gaid === 'gaid429'">
                      {{ round(line) + ' ' + ids[input.gaid].measurmentUnit }}
                    </span>
                    <span class='variable' v-else-if="line !== 'Unanswered'">
                      {{ line + ' ' + ids[input.gaid].measurmentUnit}}
                    </span>
                    <span class='variable' v-else>
                      {{ line }}
                    </span>
                  </p>
              </div>
              <div v-else>
                <p
                  v-for='(line, i) of getSelectedAnswer(index)'
                  :key='i'
                  class='variable'
                >
                  {{ mapArrayValues(step.inputs[0].gaid, line) }}
                </p>
              </div>
            </div>
          </div>          
        </div>
      </div>
    </div>
    <BoringSection
      v-show='!minimizeInputBox'
      v-if="user && user.answers"
      ref='section'
      :siteId='site.options.id'
      :questionnaire='boringPageOwnerQuestions'
      :boring='true'
      :project='false'
      title='Site Representative'
    />
    <div
      class='site'
      :style="{ height: 'unset' }"
      :class='{ minimized: minimizeInputBox === true }'
    >
      <!--      todo move to BoringSection-->
      <p class='section-name head'>{{ translations.preferences.title }}</p>
      <div class='line' />
      <div class='big-row'>
        <div class='data'>
          <!-- Site Answers -->
          <div
            v-for='(step, index) of journeyConfigurationPreferences'
            :key='index'
          >
            <div v-if='step.section'>
              <p class='section-name'>{{ step.section }}</p>
              <div class='line pref' />
            </div>
            <div
              v-if='step.inputs && (!step.if || isIfAnswered(step.if))'
              :class="answerEdit === index && editingStepType === 2 ? 'row editable' : 'row'"
            >
              <p
                :class="step.subsection ? 'sub-static' : 'static'"
              >
                <img v-show='answerEdit === index && editingStepType === 2'
                     class='edit-pen'
                     src='/assets/close-blue.svg'
                     @click='closeStep(index)'
                />
                <img
                  v-if='role !== 1 && step.editable !== false'
                  v-show='answerEdit !== index || editingStepType !== 2'
                  class='edit-pen'
                  src='/assets/edit-pen.svg'
                  alt=''
                  @click='editPreferencesStep(index)'
                />
                {{
                  ids[step.title][lang].boringName || ids[step.title][lang].name
                }}
                <span
                  class='asterix'
                  v-show='
                    ids[step.title].validations &&
                    ids[step.title].validations.mandatory === true'
                >*</span>
              </p>
              <!-- If it's size, show between input and auto calculated -->
              <p v-if='ids[step.title].sizeLabel' class='variable'>
                {{ getAnswer({ gaid: step.title }, { gaid: 'gaid291' }) }} sq.m.
              </p>
              <div v-else>
                <p
                  v-for='(line, i) of getSelectedAnswerPreferences(index)'
                  :key='i'
                  class='variable'
                >
                  {{ line }}
                </p>
              </div>
            </div>
          </div>
          <!--        gaid with type textarea-->
          <!--        see static-text.vue-->
        </div>
      </div>
    </div>

    <div
      class='site'
      :style="{ height: 'unset' }"
      :class='{ minimized: minimizeInputBox === true }'
    >
      <p class='section-name head'>{{ translations.additional.title }}</p>
      <div class='line' />
      <div class='big-row'>
        <div class='text-box'>
          <img class='upload-icon' src='/assets/upload-cloud.svg'>
          <p class='drag-and-drop'>{{ translations.additional['drag-and-drop'] }} <span
            class='blue'>{{ translations.additional['browse'] }}</span> {{ translations.additional['files'] }}</p>
        </div>
      </div>
      <div class='big-row'>
        <div class='data'>
          <p class='section-name'>{{ translations.additional.section }}</p>
          <div class='line pref' />
          <!-- <div class='row'>
            <p class='uploaded-files'>
              <button class='delete'>
                <img class='trash-icon' src='/assets/trash-icon.svg'>
              </button>
            </p>
        </div> -->
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import ids from '@common/ids.yaml';
import translationsEn from '@content/en/owner/boring-page.yaml';
import translationsNl from '@content/nl/owner/boring-page.yaml';
import Map from '@components/Map';
import questionnaire_site from '@common/questionnaire/boring_page_site_owner_site.yaml';
import questionnaire_site_preferences from '@common/questionnaire/boring_page_site_owner_site_preferences.yaml';
import questionnaire_boring_page_site_owner from '@common/questionnaire/boring_page_site_owner.yaml';
import feasibility from '@common/feasibility.yaml';
import RequestHelper from '@common/helpers/request.helper.js';
import BoringSection from '@layouts/boring/components/BoringSection';
import Feasibility from '@layouts/civilization/Feasibility';
import Model from '@layouts/civilization/Model';

export default {
  props: {
    site: Object
  },
  components: {
    Map,
    Feasibility,
    Model,
    BoringSection
  },

  data () {
    return {
      name: '',
      siteOwnerPreferencesQuestions: { ...questionnaire_site_preferences },
      boringPageOwnerQuestions: { ...questionnaire_boring_page_site_owner },
      editingStepType: 0,

      initialized: false,
      journeyConfiguration: { ...questionnaire_site },
      journeyConfigurationPreferences: { ...questionnaire_site_preferences },
      lang: '',
      minimizeInputBox: false,
      answerEdit: undefined,
      role: localStorage.loggedUser ? JSON.parse(localStorage.loggedUser).userType : 0,
      translations: undefined,
      ids: ids,
      answers: {},
      requestHelper: new RequestHelper(this.$router),
      user: undefined,
    };
  },

  // Creating short names.
  computed: {
    mapName () {
      return `project${this.site.options.id}`;
    },
    map () {
      return this.$store.state.map.instances[this.mapName];
    },
    inputParents () {
      return this.$store.state.inputs.parents;
    },
    userProjects () {
      return this.$store.state.siteOwner.userProjects;
    },
    userSites () {
      return this.$store.state.siteOwner.userSites;
    },
    siteId () {
      return this.site.options.id;
    },
    selectedProjectId () {
      return this.$store.state.siteOwner.selectedProjectId;
    }
  },

  created () {
    localStorage.lang = this.lang = this.$router.history.current.params.lang;
    this.init();
    if (localStorage.token && localStorage.loggedUser) {
      this.user = JSON.parse(localStorage.loggedUser);
    }
  },

  mounted () {
    this.$nextTick(() => this.renderSite());
  },

  watch: {
    lang () {
      localStorage.lang = this.lang = this.$router.history.current.params.lang;
    }
  },
  methods: {
    init () {
      this.translations = this.lang === 'en' ? translationsEn : translationsNl;
      this.answers = this.inputParents[this.siteId]?.answers;

      this.initialized = true;

      this.$store.subscribe(this.onStoreChange);
    },

    isIfAnswered: function(gaids) {
      if (!this.answers) return false;
      if (typeof gaids === 'string')
        gaids = [gaids];

      // Returns the first answered
      for (const gaid of gaids) {
        const answer = this.answers[gaid];
        if (answer && answer !== '') return answer;
      }

      return false;
    },


    getSelectedAnswerPreferences: function(stepIndex) {
      if (!this.initialized) return;

      let step = this.siteOwnerPreferencesQuestions[stepIndex];
      if (!step) return [];

      if (step.group) return [this.getGroupAnswer(step.inputs)];

      return this.getAnswers(...step.inputs);
    },

    onStoreChange (mutation) {
      switch (mutation.type) {
        case 'inputs/updateParentAnswers':
          this.answers = this.inputParents[this.siteId]?.answers;
          this.$forceUpdate();
          break;
        case 'map/initInstance':
          if (mutation.payload.mapName !== this.mapName) return;
          this.$nextTick(() => this.renderSite());
          break;
        case 'map/updateMapName':
          if (!this.mapName.includes(mutation.payload)) return;
          this.$nextTick(() => this.renderSite());
          break;
        case 'siteOwner/setSiteSelected':

          const selectedSite = this.userSites.find(
            site => site.options?.id === mutation.payload
          );
          if (!selectedSite || selectedSite.options.projectId !== this.site.options.projectId) return;
          this.$nextTick(() => this.renderSite());
          break;
        case 'siteOwner/setSelectedProjectId':
          if (mutation.payload !== this.site.options.projectId) return;
          this.$nextTick(() => this.renderSite());
      }
    },
    renderSite () {
      if (!this.map) return;

      if (!Object.values(this.map._layers).find((layer) => layer?.options.id === this.site.options.id)) {
        const polygon = L.polygon([...this.site._latlngs], { ...this.site.options });
        this.map.addLayer(polygon);
      }

      this.map.invalidateSize();

      // Zoom to fit
      try {
        this.map.fitBounds(this.site.getBounds(), {
          animate: false,
          duration: 0,
          padding: [10, 10]
        });
      } catch (ignored) {
        // ignored
      }
    },

    editStep (step) {
      this.closeStep();
      this.$emit('editAnswer', { step, siteId: this.site.options.id });
      this.answerEdit = step;
      this.editingStepType = 1;
    },

    closeStep () {
      this.$emit('editAnswer');
      this.clearSelectedAnswer();
    },

    clearSelectedAnswer () {
      this.answerEdit = undefined;
      if (this.$refs.section)
        this.$refs.section.clearSelectedAnswer();
    },

    editPreferencesStep (step) {
      this.$emit('editPreferencesAnswer', { step, siteId: this.site.options.id });
      this.answerEdit = step;
      this.editingStepType = 2;
    },

    editOwnerStep (step) {
      if (!step) {
        this.$emit('editAnswer');
        return;
      }
      this.$emit('editOwnerDetails', { step, siteId: this.site.options.id });
      this.editingStepType = 0;
    },

    getSelectedAnswer: function(stepIndex) {
      if (!this.initialized) return;

      let step = this.journeyConfiguration[stepIndex];
      if (!step) return [];

      if (step.group) return [this.getGroupAnswer(step.inputs)];

      return this.getAnswers(...step.inputs);
    },

    //this combine the next 2 functions
    getAnswerDefault(defaultNotFound, ...gaids){
      const answers = this.answers;

      if (!answers) return defaultNotFound;

      // Returns the first answered
      for (let gaid of gaids) {
        const answer = answers[gaid.gaid];
        if (answer && answer !== '') return answer;
      }

      return defaultNotFound;
    },

    getAnswer: function(...gaids) {
      const answers = this.answers;

      if(answers.gaid406 === null || answers.gaid406 === 0){
        return answers.gaid406 = '0';
      }
      if (!answers) return this.translations.unanswered;

      // Returns the first answered
      for (let gaid of gaids) {
        const answer = answers[gaid.gaid];
        if (answer && answer !== '') return answer;
      }

      return this.translations.unanswered;
    },

    getAnswers: function(...gaids) {
      const answers = this.answers;

      if (!answers) return this.translations.unanswered;

      // Collects all answered
      let output = [];
      for (let gaid of gaids) {
        const answer = answers[gaid.gaid];
        if (answer && answer !== '')
          output.push(
            answer === true ? this.ids[gaid.gaid][this.lang].name : answer
          );
      }

      return output.length > 0 ? output : [this.translations.unanswered];
    },

    getGroupAnswer (gaids) {
      if (!this.initialized) return;

      const answers = this.answers;

      if (answers)
        for (let gaid of gaids) {
          // If type is project selector
          if (this.ids[gaid.gaid].type === 'project-selector')
            for (let project of this.userProjects)
              if (answers[project._id])
                return this.inputParents[project._id]?.answers?.gaid230;

          if (answers[gaid.gaid] && answers[gaid.gaid] !== '') {
            if (typeof answers[gaid.gaid] == 'boolean') {
              return this.ids[gaid.gaid][this.lang].name;
            } else {
              return answers[gaid.gaid];
            }
          }
        }

      return this.translations.unanswered;
    },
    saveAnswer() {
      this.requestHelper.putQuery('sitesQuery', {
          id: this.siteId,
          answers: this.inputParents[this.siteId].answers
      });
    },
    emitUserName (userName) {
      this.name = userName;
    },
    onStepFinish () {
      this.editingSiteId = undefined;
      this.editingStep = undefined;
      this.calculatedArea = undefined;
    },

    mapArrayValues(gaid, values) {
      let answers = [];
      if(gaid == "gaid527" || gaid == "gaid526") {
        for(let obj of feasibility[gaid == "gaid527" ? "restrictedArea" : "gridCongestion"].checks) {
          if(obj.conditions && values.includes(obj.conditions[0]?.value)) {
            answers.push(obj[this.lang].message);
          }
        }
        if(answers.length < 1){
          answers.push(gaid === 'gaid527' ? 'No restricted area.' : 'No grid congestion.');
        }
        return answers.join(", ");
      }
      return values;
    },

    round(number){
      return parseFloat(number).toFixed(2);
    }
  }
};
</script>

<style lang='scss' scoped>
@import '@styles/boring-page.scss';

</style>
