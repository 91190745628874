<template>
  <div class='d-flex nav-content'>
    <img
      class='logo'
      src='/assets/logo.png'
      :style="role === 1 ? 'margin-right: 120px' : ''"
      :class="role === 1 ? 'ipad-resolution' : ''"
      @click='homePage()'
    />

    <!-- Site Owner Map Dropdown -->
    <div
      class='select-container'
      v-if='role === 0'
      v-show="currentTab === 'map'"
    >
      <dropdown
        :items='sitesByProject'
        :placeHolder='translations.selectSite'
        :matrix='true'
        :preSelectedId='selectedSiteId'
        :dropdown='dropdownToggle'
        emptyMessage='No sites created'
        @data='siteSelectOnChange($event)'
        @toggleDropdown='dropdownOpen($event)'
      />

      <div class='add-site-icon'>
        <img
          ref='img'
          src='/assets/add-site-golden.svg'
          @click='openNewModal'
          alt=''
        />
      </div>
      <popup-controller
        refName='img'
        border-color='#55b364'
        text='Create a new site or add new sites to your existing project'
      />
</div>

      <!-- Map | Plus button popup -->

      <div class='modal-plus' v-if="openPlus && currentTab === 'map'">
        <div class='leaflet-popup-tip' />
        <img
          @click='close'
          class='close'
          src='/assets/X-image.svg'
          alt='x'
        />
        <p>Navigate to your site</p>
        <p class='info'>
          Navigate to the place of your site or enter your address below
        </p>
        <GoogleAddress @callback='this.openGifModal' class="leaflet-gac-control-custom pac-target-input" />
        
        <div class='button'>
          <button
            class='next-step'
            @click='openGifModal'>
            Next Step
            <img src='/assets/blue-arrow.svg' />
          </button>
        </div>
      </div>

      <!-- GIF MODAL -->

      <div class='gif-modal' v-if="openGif && currentTab === 'map'">
        <div class='leaflet-popup-tip' />
        <img
          @click='close'
          class='close'
          src='/assets/X-image.svg'
          alt='x'
        />
        <p class='title'>Draw your site</p>
        <p class='description'>
         Mark the bounds of the site with a polygon to create a new site and unlock the questionare
        </p>
        <img class='gif' src='/assets/create-site.gif' />
      </div>

    
    <!--     Site Owner Boring & Matching Dropdown -->
    <div
      v-if='role === 0'
      class='select-container'
      v-show="currentTab === 'project' || currentTab === 'matching'"
    >
      <dropdown
        :items='dropdownProjects'
        :placeHolder='translations.selectProject'
        :preSelectedId='selectedProjectId'
        :dropdown='dropdownToggle'
        emptyMessage='No projects created'
        @data='projectSelectOnChange($event)'
        @toggleDropdown='dropdownOpen($event)'
      />
    </div>

    <!-- Admin Dropdown -->
    <div
      v-if='role === 2'
      class='select-container'
      v-show="
        currentTab === 'project' ||
        currentTab === 'matching' ||
        currentTab === 'map'
      "
    >
      <dropdown
        :items='dropdownProjects'
        :placeHolder='translations.selectProject'
        :preSelectedId='selectedProjectId'
        :dropdown='dropdownToggle'
        emptyMessage='No projects submitted'
        @data='projectAsSiteSelectOnChange($event)'
        @toggleDropdown='dropdownOpen($event)'
      />
    </div>

    <div class='d-flex mt-auto mb-auto scale' v-if='role === 1'>
      <dropdown
        :items='dropdownProjects'
        :placeHolder='translations.selectProject'
        :preSelectedId='selectedProjectId'
        :dropdown='dropdownToggle'
        emptyMessage='No projects submitted'
        @data='projectAsSiteSelectOnChange($event)'
        @toggleDropdown='dropdownOpen($event)'
      />
      <Chevron
        :tabs="[
          {
            link: 'profile',
            title: translations.profile,
            backgroundActive: `url('/assets/chevron.tabs/active_map.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/map.svg') no-repeat`,
          },
          {
            link: 'matching',
            title: translations.matchmaking,
            backgroundActive: `url('/assets/chevron.tabs/active_boring-page.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/boring-page.svg') no-repeat`,
            disabled: !token,
            dotValue: notificationCount,
          },
          {
            click: true,
            background: `url('/assets/chevron.tabs/dropdown.svg') no-repeat`,
          },
          {
            link: 'project',
            title: translations.myProfile,
            backgroundActive: `url('/assets/chevron.tabs/active_boring-page.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/boring-page.svg') no-repeat`,
            disabled: !token,
          },
          {
            link: 'map',
            title: translations.map,
            backgroundActive: `url('/assets/chevron.tabs/active_matching.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/matching.svg') no-repeat`,
            disabled: !token,
          },
          {
            title: translations.review,
            background: `url('/assets/chevron.tabs/review.svg') no-repeat`,
            backgroundActive: `url('/assets/chevron.tabs/active_review.svg') no-repeat`,
            disabled: true,
            popupText: 'This message is about Review page',
          },
          {
            title: translations.projectLaunch,
            background: `url('/assets/chevron.tabs/project-launch.svg') no-repeat`,
            backgroundActive: `url('/assets/chevron.tabs/active_project-launch.svg') no-repeat`,
            disabled: true,
            popupText: 'This message is about Launch',
          },
        ]"
        :currentTab='currentTab'
        :items='dropdownProjects'
        :dropdown='dropdownToggle'
        @change-tab='onTabChange($event)'
        @openGuideBox='openGuideBox($event)'
        @openDropdown='dropdownOpen($event)'
        class='mt-auto mb-auto'
      />
    </div>
    <div v-else class='mt-auto mb-auto scale'>
      <Chevron
        :tabs="[
          {
            click: true,
            background: `url('/assets/chevron.tabs/dropdown.svg') no-repeat`,
          },
          {
            link: 'map',
            title: translations.map,
            backgroundActive: `url('/assets/chevron.tabs/active_map.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/map.svg') no-repeat`,
          },
          {
            link: 'project',
            title: translations.myProfile,
            backgroundActive: `url('/assets/chevron.tabs/active_boring-page.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/boring-page.svg') no-repeat`,
          },
          {
            link: 'matching',
            title: translations.matchmaking,
            backgroundActive: `url('/assets/chevron.tabs/active_matching.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/matching.svg') no-repeat`,
            disabled: !token,
            popupText: 'This massage is about matchmaking page',
          },
          {
            title: translations.review,
            background: `url('/assets/chevron.tabs/review.svg') no-repeat`,
            backgroundActive: `url('/assets/chevron.tabs/active_review.svg') no-repeat`,
            disabled: true,
            popupText: 'This message is about review page',
          },
          {
            title: translations.projectLaunch,
            background: `url('/assets/chevron.tabs/project-launch.svg') no-repeat`,
            backgroundActive: `url('/assets/chevron.tabs/active_project-launch.svg') no-repeat`,
            disabled: true,
            popupText: 'This message is about Launch',
          },
        ]"
        :currentTab='currentTab'
        :dropdown='dropdownToggle'
        @change-tab='onTabChange($event)'
        @openGuideBox='openGuideBox($event)'
        @openDropdown='dropdownOpen($event)'
        class=''
      />
    </div>
    <!--    <div @click="openGuideBox(['Project', 'gaid3'])">-->
    <!--      &lt;!&ndash; todo: this should replace the string with the guide yaml file &ndash;&gt;-->
    <!--      <button>info</button>-->
    <!--    </div>-->

    <!-- Greeting & menu -->
    <div
      class='right-container d-flex'
      :class="role === 1 ? 'ipad-right-container' : ''"
    >
      <div
        v-if='role === 0 || role === 1'
        class='guide-icon'
        @click='openGuideBox()'
      >
        <img src='/assets/info-icon.svg' />
      </div>
      <div v-if='token' class='kebab-menu' @click='toggleProfileMenu()'>
        <img style='width: 38px' src='/assets/kebab-menu.svg' />
      </div>

      <button
        class='login-button'
        @click='openLogin()'
        v-if='role === 0 && !token'
      >
        {{ translations.login }}
      </button>

      <div class='profile-menu box-style' v-if='profileOpened'>
        <div class='user'>
          <img src='/assets/profile-picture.png' alt='profile' />
          <div class='user-container'>
            <p class='title'>{{ profileMenu.title }}</p>
            <p
              class='names'
              v-if='
                (parents[projectDevId] && parents[projectDevId].answers) ||
                (loggedUser && loggedUser.answers)
              '
            >
              {{
                projectDevId
                  ? parents[projectDevId].answers.gaid273
                  : loggedUser.answers.gaid273
              }}
              {{
                projectDevId
                  ? parents[projectDevId].answers.gaid274
                  : loggedUser.answers.gaid274
              }}
            </p>
            <p class='email'>{{ loggedUser.email }}</p>
          </div>
        </div>
        <div
          class='profile-navigation'
          v-for='(nav, index) of profileMenu.navigation'
          :key='index'
        >
          <p
            @click='profileNavigation(nav.key)'
            :id='nav.key'
            v-if='!nav.role || role === nav.role'
          >
            {{ nav.description }}
          </p>
        </div>

        <div class='logout-button'>
          <button ref='button' @click='logout()'>
            {{ translations.signout }}
          </button>
        </div>
      </div>
    </div>
    <AccountSettings
      v-if='accountSettingsOpened'
      @closeAccountSettings='accountSettingsOpened = false'
    />
    <ContactForm
      v-if='contactForm'
      gaid='gaid520'
      @closeContactForm='contactForm = false'
    />
    <Authorization v-if='showAuth' @close='onAuthorizationClose($event)' />
  </div>
</template>

<script>
import translationsEn from '@content/en/general/navbar.yaml';
import translationsNl from '@content/nl/general/navbar.yaml';
import profileMenuEn from '@content/en/general/navbar/profile-menu.yaml';
import profileMenuNl from '@content/nl/general/navbar/profile-menu.yaml';
import dropdown from '@components/inputs/generic/dropdown';
import RequestHelper from '../../common/helpers/request.helper';
import { logout } from '@common/helpers/logout.helper.js';
import Chevron from '@components/Chevron';
import ids from '@common/ids.yaml';
import PopupController from './popup/popup-controller';
import AccountSettings from '@components/popup/AccountSettings.vue';
import ContactForm from '@layouts/matching/ContactForm/';
import Authorization from '../layouts/home/components/Authorization.vue';
import GoogleAddress from '../layouts/questionnaire/components/GoogleAddress';
import { EventBus } from '@/event-bus';

export default {
  name: 'Nav',

  components: {
    PopupController,
    dropdown,
    Chevron,
    AccountSettings,
    ContactForm,
    Authorization,
    GoogleAddress,
  },

  props: {
    name: String,
    currentTab: String,
    definition: String,
    projectDevId: String,
    
  },
  data () {
    return {
      lang: localStorage.lang,
      sitesByProject: [],
      dropdownProjects: [],
      profileOpened: false,
      ids: ids,
      role: localStorage.loggedUser
        ? JSON.parse(localStorage.loggedUser).userType
        : 0,
      loggedUser: undefined,
      requestHelper: new RequestHelper(this.$router),
      hover: false,
      dropdownToggle: undefined,
      accountSettingsOpened: false,
      contactForm: false,
      notificationCount: 0,
      showAuth: false,
      openPlus: false,
      openGif: false
    };
  },

  computed: {
    // siteOwner store module.

    userProjects () {
      return this.$store.state.siteOwner.userProjects;
    },

    token () {
      return localStorage.token;
    },

    userSites () {
      return this.$store.state.siteOwner.userSites;
    },

    selectedProjectId () {
      return this.$store.state.siteOwner.selectedProjectId;
    },

    selectedSiteId () {
      return this.$store.state.siteOwner.selectedSiteId;
    },

    // inputs store module.

    parents () {
      return this.$store.state.inputs.parents;
    },

    mapInstances () {
      return this.$store.state.map.instances;
    }
  },

  created () {
    this.init();
    if (localStorage.token && localStorage.loggedUser) {
      this.loggedUser = JSON.parse(localStorage.loggedUser);
      this.role = this.loggedUser.userType;
    } else this.$nextTick(() => this.openNewModal());
  },

  methods: {
    dropdownOpen (e) {
      this.dropdownToggle = e;
    },
    onAuthorizationClose (isLogged) {
      this.showAuth = false;
      if (isLogged) {
        this.isLogged = true;
        //todo reload without a refresh
        this.$router.go(0);
      }
    },

    openGuideBox () {
      this.$emit('openGuideBox');
    },

    profileNavigation (key) {
      if (!key) return;

      switch (key) {
        case 'gaid1':
          this.$router.push(`/${this.lang}/privacy-policy`).catch(() => {});
          break;
        case 'gaid2':
          this.contactForm = true;
          break;
        case 'gaid3':
          
          this.$router.push({
            path:`/${this.lang}/home/${this.role ? "project-developer" : "site-owner"}/#our-platform`,
            }).catch(() => {}); 
          break;
        case 'gaid4':
          this.accountSettingsOpened = true;
          break;
        case 'gaid5':
          this.$router.push(`/${this.lang}/admin/cms/users`).catch(() => {});
          break;
      }
    },
    toggleProfileMenu () {
      this.profileOpened = !this.profileOpened;
    },

    init () {
      this.translations = this.lang === 'en' ? translationsEn : translationsNl;
      this.$store.commit('map/updateMapName', this.currentTab);

      this.$store.subscribe(this.onStoreChange);
      this.profileMenu = this.lang === 'en' ? profileMenuEn : profileMenuNl;
    },

    onStoreChange (mutation) {
      // Attention: Breaks between cases are omitted intentionally.
      switch (mutation.type) {
        // When global answers are updated
        case 'siteOwner/updateNotificationsCount':
          this.notificationCount = mutation.payload;
          if (this.notificationCount > 9) this.notificationCount = '9+';

          break;
        case 'inputs/updateParentAnswers':
          const siteNameChange = mutation.payload?.newAnswers?.gaid185;
          const projectNameChange = mutation.payload?.newAnswers?.gaid230;

          // Break if the detected answer changes aren't concerned with
          // the names of the sites/projects.
          if (!(siteNameChange || projectNameChange)) break;

        // When a site is assigned to a project.
        case 'siteOwner/setSiteProjectId':
        case 'siteOwner/pushToUserSites':
        case 'siteOwner/changeStatus':
        case 'siteOwner/initUserSites':
        case 'siteOwner/removeUserSite':
          this.initDropdownData();
          break;
        case 'siteOwner/updateEmail':
          if (localStorage.token && localStorage.loggedUser) {
            this.loggedUser = JSON.parse(localStorage.loggedUser);
            this.role = this.loggedUser.userType;
          }
          break;
        case 'map/drawSite':
          this.close();
          break;
      }
    },

    logout () {
      logout(this);
    },

    initDropdownData () {
      this.sitesByProject = [];
      this.dropdownProjects = [];
      switch (this.role) {
        case 0:
          this.setupUserData();
          break;
        case 1:
          this.setupRoleData([
            'Cancelled',
            '',
            'Submitted',
            '',
            'Approved',
            'Denied'
          ]);
          break;
        case 2:
          this.setupRoleData([
            'Cancelled',
            'Submitted',
            'Approved',
            'Returned',
            'Dev Approved',
            'Dev Denied'
          ]);
          break;
      }
    },

    setupRoleData (/*statuses*/) {
      if (this.dropdownProjects.length > 0) return;
      let i = 0;
      for (let project of this.userProjects) {
        i += 1;
        // if (!project.connected) continue;
        let con = project.connected?.find(
          (value) => value._id === this.projectDevId
        ) || { status: 4 };
        //todo to translation
        const projectName =
          con.status !== 4
            ? 'Matched Project ' + i
            : this.parents[project._id]?.answers?.gaid230;
        const dropdownProject = {
          text: projectName,
          // todo add the statuses when they are needed
          // status: statuses[project.connected[0].status],
          id: project._id
        };
        this.dropdownProjects.push(dropdownProject);
      }
    },

    setupUserData () {
      for (let project of this.userProjects) {
        // If the iterated project has no sites assigned, don't add it to the dropdown
        const sites = this.userSites.filter(
          (site) => site.options?.projectId === project._id
        );

        if (!sites || sites.length === 0) continue;

        const projectName = this.parents[project._id]?.answers?.gaid230;

        // Dropdown-component-compatible object containing the project data
        const dropdownProject = {
          text: projectName,
          id: project._id
        };

        // Push the project object to the nested sites-by-projects dropdown, with
        // an additional "children" property (where the sites will be populated.)
        this.sitesByProject.push({ ...dropdownProject, children: [] });

        // Push the project object to the dropdown projects array (which is shown on
        // the project page.)
        this.dropdownProjects.push(dropdownProject);

        // Populate the project object with nested site objects
        for (let site of sites) this.addSiteToLastProject(site);
      }

      const sites = this.userSites.filter(
        (site) => site.options?.projectId === undefined
      );

      if (sites && sites.length > 0) {
        //todo replace gaid399 with translation
        const undefinedProject = {
          text:
            this.parents[this.selectedSiteId]?.answers['gaid230'] ||
            this.ids['gaid399'][this.lang].name,
          id: undefined
        };

        this.sitesByProject.push({ ...undefinedProject, children: [] });

        for (let site of sites) this.addSiteToLastProject(site);
      }
    },

    addSiteToLastProject (site) {
      const inputStorageName = this.parents[site.options.id]?.answers?.gaid185;

      // Dropdown-component-compatible object containing the site data
      //todo replace gaid400 with translation
      const dropdownSite = {
        text:
          inputStorageName ||
          this.ids['gaid400'][this.lang].name.replace(
            '{num}',
            this.userSites.indexOf(site) + 1
          ),
        id: site.options.id
      };

      this.sitesByProject[this.sitesByProject.length - 1].children.push(
        dropdownSite
      );
    },

    siteSelectOnChange (projectSiteIds) {
      // set last selected site in global storage
      this.$store.commit('siteOwner/setSiteSelected', projectSiteIds.childId);

      this.setLastSelectedSiteForUser(projectSiteIds.childId);
    },

    setLastSelectedSiteForUser (siteId) {
      this.$store.commit('siteOwner/setSiteSelected', siteId);

      if (localStorage.token)
        this.requestHelper.putQuery('userState', {
          lastSelectedSite: siteId
        });
    },

    projectSelectOnChange (data) {
      this.$store.commit('siteOwner/setSelectedProjectId', data.id);
      this.$store.commit('siteOwner/setSiteSelected', data.id);
      
      if (localStorage.token)
        this.requestHelper.putQuery('userState', {
          lastSelectedSite: data.id
        });
    },

    projectAsSiteSelectOnChange (data) {
      this.$store.commit('siteOwner/setSiteSelected', data.id);
      this.$store.commit('siteOwner/setSelectedProjectId', data.id);

      if (localStorage.token)
        this.requestHelper.putQuery('userState', {
          lastSelectedSite: data.id
        });
    },

    onTabChange (tab) {
      this.$emit('change-tab', tab);
    },
    homePage () {
      this.$router.push(`/${this.lang}/home/${this.role ? "project-developer" : "site-owner"}`).catch(()=> {});
      this.$router.currentRoute.path;
    },

    openLogin () {
      this.showAuth = true;
      this.$router.history.current.params.loginType = 'login';
      this.$store.state.user.userType = 0;
    },

    openNewModal () {
      this.mapInstances['map']?.closePopup();
      if (this.openGif)
        this.mapInstances['map']?.pm.disableDraw('Polygon');
      this.openGif = false;
      this.openPlus = true;
    },

    openGifModal () {
      this.openPlus = false;
      this.openGif = true;
      EventBus.$emit('gifOpened', this.openGif);
      this.mapInstances['map']?.pm.enableDraw('Polygon');
    },

    close () {
      if (this.openGif)
        this.mapInstances['map']?.pm.disableDraw('Polygon');
      this.openPlus = false;
      this.openGif = false;
    }
  }
};
</script>

<style lang='scss' scoped>
@import '@styles/base/forms.scss';
@import '@styles/base/_typography.scss';
@import '@styles/nav.scss';
@import '@styles/ipad.scss';
@import '@styles/main.scss';
</style>
