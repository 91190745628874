<template>
  <div class='container renewable-energy' id='renewable-energy'>
    <div class='col-12'>
      <h1>{{ title }}</h1>
    </div>
    <div class='row'>
      <div class='col-12 col-sm-12 col-md-6 col-xl-4' v-for='(card, i) of cards'
           :class='card.image ? `image` : `info-energy-card link-style`'
           :key='i'>
        <img v-if='card.image' :src='card.image' alt='' />
        <div v-else class='style-card'>
          <h2>{{ card.label }}</h2>
          <p>{{ card.information }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import energy from '@content/nl/general/renewable-energy.yaml';
import energyEn from '@content/en/general/renewable-energy.yaml';

export default {
  name: 'RenewableEnergy',
  data () {
    return {
      cards: {},
      firstCardLabel: '',
      firstCardInformation: '',
      cardImage: '',
      title: ''
    };
  },
  props: {
    lang: String
  },
  mounted () {
    this.init();
  },
  watch: {
    lang () {
      this.init();
    }
  },
  methods: {
    init () {
      let data = this.lang === 'en' ? energyEn : energy;
      this.cards = data.cards;
      this.title = data.title;
      this.firstCardLabel = data['first-card-label'];
      this.firstCardInformation = data['first-card-information'];
      this.cardImage = data.image;
    }
  }
};
</script>

<style lang='scss' scoped>
@import "@styles/mobile-mode.scss";
@import "@styles/main.scss";
@import "@styles/home-page.scss";
</style>
