<template>
  <div class='container team' id='team'>
    <div class='col-12'>
      <h1>{{ title }}</h1>
    </div>
    <div class='row team-content'>
      <div
        class='col-12 col-sm-12 col-md-6 col-xl-4 team-info-card'
        v-for='member of team'
        :key='member.image'>
        <div class='style-card'>
          <img class='member-image' :src='member.image' />
          <h2>{{ member.name }}</h2>
          <p>{{ member.information }}</p>
          <div class='row links'>
            <a :href='`mailto:${member.email}`'
            ><img class='first-link' src='/assets/envelope.svg' />
            </a>
            <a :href='member.linkedin' target='_blank'
            ><img src='/assets/linkedin.svg'
            /></a>
          </div>
        </div>
      </div>
      <div class='col-12 col-sm-12 col-md-6 col-xl-4 team-info-card'>
        <div
          v-for='(c, i) of contact'
          :key='i'
          class='style-card'
        >
          <img class='member-image' src='/assets/team-you.png' />
          <h2>{{ c.label }}</h2>
          <p>
            {{ c.information }}
          </p>
          <a
            v-for='(button, i) of c.button'
            :key='i'
          >
            <div class='row links'>
              <button class='contactBtn' @click='contactFormOpened = true'>
                {{ button.text }}
              </button>
            </div>
          </a>
        </div>
      </div>
    </div>
    <ContactForm v-if='contactFormOpened' gaid='gaid520'
                 @closeContactForm='contactFormOpened=false' />
  </div>
</template>

<script>
import team from '@content/nl/general/team.yaml';
import teamEn from '@content/en/general/team.yaml';
import ContactForm from '@layouts/matching/ContactForm';

export default {
  name: 'OurTeam',
  components: {
    ContactForm
  },

  data () {
    return {
      team: {},
      contact: {},
      title: '',
      contactFormOpened: false
    };
  },
  props: {
    lang: String
  },
  mounted () {
    this.init();
  },
  watch: {
    lang () {
      this.init();
    }
  },
  methods: {
    init () {
      let data = this.lang === 'en' ? teamEn : team;
      this.team = data.team;
      this.contact = data.contact;
      this.title = data.title;
    }
  }
};
</script>

<style scoped lang='scss'>
@import "@styles/main.scss";
@import "@styles/home-page.scss";
</style>
