function getDefaultState() {
    return {
        userType: 0,
    }
}

export default {
    namespaced: true,

    state: getDefaultState(),

    mutations: {
        setType(state, type) {
            state.userType = type;
        },
        
        resetState(state) {
            Object.assign(state, getDefaultState());
        },
    },
};
