<template>
  <div class='google-autocomplete' />
</template>

<script>
import 'leaflet-google-places-autocomplete';
import L from 'leaflet';

export default {
  name: 'GoogleAddress',
  mounted () {
    this.init();
  },
  computed: {
    mapInstances () {
      return this.$store.state.map.instances;
    }
  },
  data () {
    return {
      marker: undefined,
      created: false
    };
  },
  methods: {
    init () {
      if (this.created) return;
      const autocomplete = new L.Control.GPlaceAutocomplete().addTo(
        this.mapInstances['map']
      );

      this.buildAutocomplete(autocomplete);
      document.querySelector('.leaflet-gac-container')?.remove();
    },
    buildAutocomplete (obj) {
      obj.container = L.DomUtil.create(
        'div',
        'leaflet-gac-container-custom leaflet-bar'
      );
      const searchWrapper = L.DomUtil.create(
        'div',
        'leaflet-gac-wrapper-custom'
      );
      obj.searchBox = L.DomUtil.create('input', 'leaflet-gac-control-custom');
      obj.searchBox.addEventListener('click', ev => ev.target.focus());
      // eslint-disable-next-line no-undef
      obj.autocomplete = new google.maps.places.Autocomplete(
        obj.searchBox,
        obj.options.autocomplete_options
      );

      searchWrapper.appendChild(obj.searchBox);
      // create and bind autocomplete
      document
        .querySelector('.google-autocomplete')
        ?.appendChild(searchWrapper);

      const callback = this.onLocationComplete;
      const map = this.mapInstances['map'];

      const self = this;
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(
        obj.autocomplete,
        'place_changed',
        function() {
          callback(obj.autocomplete.getPlace(), map);
          self.$emit('callback');
        }
      );
      this.created = true;
    },
    onLocationComplete (place, map) {
      // default callback
      if (!place.geometry) return;

      const coords = [
        place.geometry.location.lat(),
        place.geometry.location.lng()
      ];

      map.setView(coords, 20, {
        animate: false,
        duration: 0
      });
      this.marker?.remove();
      this.marker = L.marker(coords).addTo(map);
      this.marker.bindTooltip(place.formatted_address).openTooltip();
    }
  }
};
</script>

<style scoped></style>
