<!-- Matching

     This is the central matching component.

     All matching variations (eg. match site w/ developer, match specialist w/ developer, etc.)
     take place here.

     If needed, some types of matching can be sub-components in this folder, eg. specialist.vue.

     When do we match?

         We match every time we switch to this component w/ the navigation (eg. press the Matching link in the navigation).

            TODO

            https://v3.vuejs.org/guide/instance.html#lifecycle-diagram

    How do we match?

        We match one to many (eg. one site to many project developers).

        The matches are recorded in the many (eg. project developers).

            Format of the stored matches

                A. Match/Does Not Match (boolean)

                B. If Match, there is a rangking on the base of which to store (integer)

                C. Complex storage for the Matching Criteria Display and other Matching UX/UI decisions

                1. gaid1 / gaid1

                2. gaid1 / gaid2

                3. gaid1, gaid2 / gaid3, gaid4, gaid5

                TODO

                Propose how to store these...

    Sorting

        Based on the matches recorded in the many (eg. project developers) we sort.

            1st we divide them in groups match/no match.

            Those which do not match we do not sort at the moment at all.

            Those which match we sort at the momnet only one way.

                We count the number of matching points and put those w/ most on top and then down.

        The end result of sorting is an array containing the many (eg. project developers), which are sorted.

     How do we display?

         The disaply begins to cycle the sorted array w/ the many.

         ! We only display based on data in the array, we do not do _any_ computations in this area !

     Once the matching in the array is done, we display.
-->
<template>
  <div class="dev-matching-container">
    <div class="dev-matching-content">
      <SuccessfulPayment
        v-if="successfulPaymentOpened"
        @closeSuccessfulPayment="successfulPaymentOpened = false"
      />
      <!-- Info Card -->
      <InfoCard
        :text="translations.nfoCardText"
        :buttonText="translations.nfoCardBtnText"
        buttonIcon="/assets/calendly-white.svg"
        color="green"
      />
      <div class="sorted-table" v-if="role === 1">
        <p>
          <span class="title">{{ translations.display.title }}:</span>
          <span class="sorted-button"
            ><button
              @click="(allLead = !allLead), display.fill(allLead)"
              :class="allLead ? '' : 'unactive'"
            >
              {{ translations.display.all }}
            </button></span
          >
          <span
            class="sorted-button"
            v-for="(button, i) of translations.display.buttons"
            :key="i"
          >
            <button
              @click="
                (display[i] = !display[i]),
                  (display = [...display]),
                  isAllActivated()
              "
              :class="display[i] ? '' : 'unactive'"
            >
              {{ button }}
            </button>
          </span>
        </p>
        <p>
          <span class="title">{{ translations.sort.title }}</span>
          <span class="sorted-button selected-name"
            ><button @click="sortedDropdown = !sortedDropdown">
              {{ sortName }}
              <img
                :src="
                  sortedDropdown
                    ? '/assets/arrow-up.svg'
                    : '/assets/arrow-down.svg'
                "
              /></button
          ></span>
        </p>
        <div class="dropdown" v-if="sortedDropdown">
          <p
            v-for="(names, index) of translations.sort.buttons"
            :key="index"
            @click="(sortName = names), (sortedDropdown = false), sortBy(index)"
            :style="sortName === names ? 'color: #2783FF' : ''"
          >
            {{ names }}
          </p>
        </div>
        <div class="line" />
      </div>

      <!-- Title & Search -->
      <!-- <div class='d-flex title-search'> -->
      <!-- <h2 class='title'>{{ role !== 1 ? translations['owner-title'] : translations['developer-title'] }}</h2> -->

      <!-- TODO: Search SVG -->
      <!--        <input-->
      <!--          class='search'-->
      <!--          type='text'-->
      <!--          :placeholder='[[translations.searchPlaceholder]]'-->
      <!--        />-->
      <!-- </div> -->

      <!-- Table -->
      <div class="matches-table" v-if="matches.length || allDevs.length">
        <!-- Title Row -->
        <table class="title-row matches-row">
          <span class="title-col matches-col">
            <span v-if="role === 0">{{ translations.company }}</span>
            <span v-else>{{ translations.siteOwner }}</span>
            <!--            <img src='/assets/nfo.svg' />-->
          </span>

          <td class="title-col matches-col">
            <div class="pair">
              <span>{{ translations.matchRating }}</span>
            </div>
          </td>


          <div class='title-col matches-col'>
            <span v-if='role === 0'>{{ translations.developerInfo }}</span>
            <span v-else>Project Name</span>
          </div>

          <td class="title-col matches-col">
            <span ref="exclusivity">{{ translations.keyConditions.name }}</span>
            <popup-controller
              refName="exclusivity"
              border-color="#55b364"
              :text="translations.keyConditions.definition"
            />
          </td>

          <td class="title-col matches-col">
            <span>{{ translations.requirements }}</span>
            <!-- <img src='/assets/nfo.svg' /> -->
          </td>

          <td class="title-col matches-col">
            <span>{{ translations.action }}</span>
            <!-- <img src='/assets/nfo.svg' /> -->
          </td>

          <td class="title-col matches-col">
            <span> STATUS</span>
          </td>
        </table>

        <div v-for="(devs, i) in [matches, notMatches]" :key="i">
          <!-- Iterated row for each match -->
          <div
            class="atlas-card"
            v-for="(match, index) in devs"
            :key="index"
            :class="{ expanded: match.expanded }"
            v-show="role === 1 ? display[match.status] : 'unset'"
            @isOpened="isOpened($event)"
          >
            <div v-if="match.hasNotification">
              <img src="/assets/red-mark.svg" class="exclamation" alt="" />
            </div>
            <div class="matches-row">
              <div class="matches-col" v-if="role === 2 || match.status === 4">
                <p class="company-name bold">
                  {{
                    role === 1
                      ? parents[match.projectId].answers['gaid230']
                      : match.answers.gaid241
                  }}
                </p>
              </div>

              <div class="matches-col" v-else>
                <img
                  alt="name"
                  :ref="'name' + index"
                  src="/assets/blurred-name.png"
                  class="company-name-blurred"
                />
                <popup-controller
                  :refName="'name' + index"
                  :width="250"
                  border-color="#55b364"
                  :text="translations.popupControler"
                />
              </div>
              <h2
                class="matches-col match-percent"
                v-bind:class="{
                  'percent-green': match.matchRating === 100,
                  'percent-yellow':
                    match.matchRating < 100 && match.matchRating >= 75,
                  'percent-red': match.matchRating < 75,
                }"
              >
                {{ match.matchRating }}%
              </h2>
              <div class="matches-col">
                <div class="expandable" :class="{ 'd-none': !match.expanded }">
                  <div
                    class="pair"
                    v-for="(row, index) of summaryInformation"
                    :key="index"
                  >
                    <p class="title">
                      {{
                        ids[row.title][lang].boringName ||
                        ids[row.title][lang].name
                      }}
                    </p>
                    <div v-if="role === 0">
                      <p
                        class="answer"
                        v-for="(answer, i) of getAnswers(
                          match.answers,
                          row.answers
                        )"
                        :key="i"
                      >
                        {{ answer }}
                      </p>
                    </div>
                    <p
                      v-else
                      v-for="(answer, i) of getAnswers(
                        match.firstSiteAnswers,
                        row.answers
                      )"
                      :key="i"
                    >
                      {{ answer }}
                    </p>
                  </div>
                </div>
              </div>

              <!--              RATING PART -->

              <!--              <div class="matches-col d-flex">-->
              <!--                ({{ match.gaRating }})-->

              <!--                &lt;!&ndash; TODO: Move logic inside script &ndash;&gt;-->
              <!--                <div class="d-flex">-->
              <!--                  <img-->
              <!--                      class="rating-star"-->
              <!--                      src="/assets/star-full.svg"-->
              <!--                      v-for="index in match.gaRating - (match.gaRating % 1)"-->
              <!--                      v-bind:key="index"-->
              <!--                  />-->
              <!--                  <img-->
              <!--                      class="rating-star"-->
              <!--                      src="/assets/star-full.svg"-->
              <!--                      v-if="match.gaRating % 1 === 0.9"-->
              <!--                  />-->

              <!--                  &lt;!&ndash; TODO: Uncomment once the half-star SVG is available &ndash;&gt;-->
              <!--                  &lt;!&ndash;                   <img class="rating-star" src="/assets/star-half.svg" v-if="match.gaRating % 1 > 0 && match.gaRating % 1 < 0.9">&ndash;&gt;-->
              <!--                </div>-->
              <!--              </div>-->

              <div class="matches-col">
                <div class="text-image-container">
                  <p class="bold">{{ match.keyConditions.title }}</p>
                  <img
                    class="yellow-exclamation"
                    src="/assets/exclamation.svg"
                    v-if="!match.expanded"
                  />
                </div>

                <div
                  class="expandable"
                  v-bind:class="{ 'd-none': !match.expanded }"
                >
                  <p>{{ match.keyConditions.description }}</p>
                </div>
              </div>

              <div class="matches-col">
                <div
                  class="expandable"
                  v-bind:class="{ 'd-none': !match.expanded }"
                >
                  <div
                    v-for="warning in match.keyConditions.warnings"
                    v-bind:key="warning"
                    class="match-warning"
                  >
                    <div class="container">
                      <img
                        class="mr-3 d-inline-flex"
                        src="/assets/exclamation.svg"
                      />
                      <p class="text">
                        {{ warning }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="matches-col buttons-col">
                <div class="buttons-col">
                  <div v-if="role === 0" class="button-col">
                    <button
                      v-if="match.connected"
                      @click="connectToDev(devs, index)"
                      class="dark-green-filled pr-5 pl-5 pt-2 pb-2"
                    >
                      {{ translations.connected }}
                    </button>

                    <button
                      v-else
                      @click="connectToDev(devs, index)"
                      class="dark-blue-filled pr-5 pl-5 pt-2 pb-2"
                    >
                      {{ translations.connect }}
                    </button>

                    <button class="light-blue-filled-status">
                      <!--                      Empty are not visible for role-->
                      {{
                        [
                          'Cancelled',
                          'Cancelled',
                          'Matched',
                          'Cancelled',
                          'Lead Accepted',
                          'Lead Rejected',
                          'Project Accepted',
                        ][match.status]
                      }}
                    </button>
                  </div>

                  <div v-else-if="role === 1" class="button-col">
                    <button
                      class="dark-blue-filled pr-5 pl-5 pt-2 pb-2"
                      @click="paymentOpened = true"
                      v-if="role === 1"
                    >
                      {{ translations.connect }}
                    </button>

                    <button class="light-blue-filled-status">
                      <!--                      Empty are not visible for role-->
                      {{
                        [
                          'Cancelled',
                          '',
                          'Matched',
                          '',
                          'Lead Accepted',
                          'Lead Rejected',
                          'Project Accepted',
                        ][match.status]
                      }}
                    </button>
                  </div>
                  <div v-else-if="role === 2">
                    <button class="light-blue-filled-status">
                      {{
                        [
                          'Cancelled',
                          'Matched',
                          'In Review',
                          'Admin Approved',
                          'Admin Returned',
                          'Lead Accepted',
                          'Lead Rejected',
                          'Project Accepted',
                        ][match.status]
                      }}
                    </button>
                  </div>

                  <div
                    class="expandable"
                    v-bind:class="{
                      'd-none': !match.expanded,
                      'site-owner-div': role !== 1,
                    }"
                  >
                    <div v-if="role === 1">
                      <Payment
                        :lang="lang"
                        v-if="paymentOpened"
                        :devId="match.id"
                        :projectId="match.projectId"
                        @closePayment="paymentOpened = false"
                      />
                      <!--                               @pay='changeProjectStatus(devs, index, true); paymentOpened = false'-->
                      <button
                        class="
                          dark-red-filled
                          justify-content-center
                          d-flex
                          pt-2
                          pb-2
                        "
                        @click="siteRejectedOpened = true"
                      >
                        {{ translations.reject }}
                        <img
                          class="pt-2 pl-1"
                          src="/assets/arrow-right-white.png"
                        />
                      </button>
                      <SiteReject
                        v-if="siteRejectedOpened"
                        @approveRejection="
                          changeProjectStatus(devs, index, false);
                          siteRejectedOpened = false;
                        "
                        @closeSiteReject="siteRejectedOpened = false"
                      />
                    </div>

                    <div v-else-if="role === 2">
                      <button
                        @click="changeProjectStatus(devs, index, true)"
                        class="
                          dark-green-filled
                          d-flex
                          pt-2
                          pb-2
                          justify-content-center
                        "
                      >
                        Approve
                      </button>
                      <button
                        @click="changeProjectStatus(devs, index, false)"
                        class="
                          dark-red-filled
                          d-flex
                          pt-2
                          pb-2
                          justify-content-center
                        "
                      >
                        Return
                      </button>
                    </div>

                    <p class="italic">{{ translations.connectDescription }}</p>

                    <!-- <hr/> -->

                    <!--                    <p>{{ translations.chatDescription }}</p>-->
                    <!--                    <button class='light-blue-filled d-flex pt-2 pb-2 justify-content-center'>-->
                    <!--                      {{ translations.startConv }}-->
                    <!--                    </button>-->

                    <button
                      class="light-blue-filled"
                      @click="contactFormOpened = true"
                    >
                      <img
                        class="calendly-icon"
                        src="/assets/calendly-blue.svg"
                      />
                      {{ translations.nfoCardBtnText }}
                    </button>
                    <p>{{ translations.helpDescription }}</p>
                    <ContactForm
                      v-if="contactFormOpened"
                      gaid="gaid520"
                      @closeContactForm="contactFormOpened = false"
                    />
                  </div>
                </div>
              </div>

              <div
                class="match-arrow-container"
                v-on:click="match.expanded = !match.expanded"
              >
                <div class="match-arrow" />
              </div>
            </div>
            <div>
              <div
                class="matching-criteria expandable"
                v-bind:class="{ 'd-none': !match.expanded }"
              >
                <div class="header">
                  <img class="match-img" src="/assets/Matching-Criteria.png" />
                  <p class="matching-title">{{ translations.matchingTitle }}</p>
                </div>

                <table class="table" id="matching-criteria">
                  <tr>
                    <!--                    PROJECT DEVELOPER -->
                    <th>
                      <!--                        <div class="left-side">-->
                      <p class="site-name">{{ translations.developer }}</p>
                      <!--                        </div>-->
                    </th>
                    <!--                    END PROJECT DEVELOPER-->

                    <!--                    SITE OWNERS-->

                    <th
                      v-for="(site, i) of filterSiteByProject(
                        allSitesAnswers,
                        match.projectId
                      )"
                      :key="i"
                    >
                      <p
                        v-if="role === 1 && match.status === 4"
                        class="site-name"
                      >
                        {{ site.gaid185 }}
                      </p>
                      <p v-else-if="role === 1" class="site-name">
                        {{ `Site ${index}` }}
                      </p>
                      <p v-else class="site-name">
                        {{ site.gaid185 || `Site ${index}` }}
                      </p>
                    </th>

                    <!--                    END SITE OWNERS-->

                    <th class="match-summary">
                      <p class="site-name">{{ translations.summary }}</p>
                    </th>
                    <!--                    END MATCH SUMMARY-->
                  </tr>

                  <!--                  sections project dev -->
                  <tr v-for="(section, i) of match.devSections" :key="i">
                    <td class="matching-site">
                      <p v-if="match.sections[i]" class="section-name-matching">
                        {{
                          ids[match.sections[i]][lang].boringName ||
                          ids[match.sections[i]][lang].name
                        }}
                      </p>
                      <div
                        v-for="(answers, index) of match.devSections[i]"
                        :key="index"
                      >
                        <div v-for="(gaid, index) of answers" :key="index">
                          <div v-if="lang && ids[gaid]" class="boxes">
                            <p
                              v-if="ids[gaid].type === 'number'"
                              class="dev-answer"
                            >
                              {{ match.answers[gaid] }}
                            </p>
                            <p v-else class="dev-answer">
                              {{
                                ids[gaid][lang].boringName ||
                                ids[gaid][lang].name
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>

                    <!--                    sites -->

                    <td
                      v-for="(site, index) of match.sites"
                      :key="index"
                      class="matching-site"
                    >
                      <p v-if="match.sections[i]" class="section-name-matching">
                        {{
                          ids[match.sections[i]][lang].boringName ||
                          ids[match.sections[i]][lang].name
                        }}
                      </p>
                      <div v-for="(answer, ind) of site.sections[i]" :key="ind">
                        <div class="boxes" v-if="lang && ids[answer.gaid]">
                          <p
                            v-if="ids[answer.gaid].type === 'number'"
                            :class="
                              answer.match
                                ? 'matching-site-answer'
                                : 'not-matching-site-answer'
                            "
                          >
                            {{ answer.value }}
                          </p>
                          <p
                            v-else
                            :class="
                              answer.match
                                ? 'matching-site-answer'
                                : 'not-matching-site-answer'
                            "
                          >
                            {{
                              ids[answer.gaid][lang].boringName ||
                              ids[answer.gaid][lang].name
                            }}
                          </p>
                        </div>
                      </div>
                    </td>

                    <!--                    percentage -->
                    <td class="match-summary">
                      <div class="boxes">
                        <p class="section-name-matching">
                          {{ translations.match }}
                        </p>
                        <p
                          :class="{
                            'high-match': match.matchPercentages[i] === 100,
                            'average-match':
                              match.matchPercentages[i] >= 75 &&
                              match.matchPercentages[i] < 100,
                            'low-match': match.matchPercentages[i] < 75,
                          }"
                        >
                          {{ match.matchPercentages[i] }}% match
                        </p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>


      <!-- Project Developer  Matching -->
        <div class="pd" v-if="role === 1">
          <div
            v-for="(project, i) in matchedProjects"
            :key="i"
            class="atlas-card"
>
            <div class="pdCard">
              <table class="cardTable">
                <td class="siteOwner" v-if="connectedToSite[project._id]">
                  {{ projectUserName[project._id] }}
                </td>
                <td v-else class="siteOwner">
                  <img src="/assets/blurred-name.png" alt="" class="blurImg" />
                </td>
                <td class="percentage">
                  <p class="text">99%</p>
                </td>
                <td class="name">{{ project.answers['gaid230'] }}</td>
                <td class="space"></td>
                <div class="connect">
                  <button
                    class="button"
                    v-if="!connectedToSite[project._id]"
                    @click="connect(project)"
                  >
                    {{ translations.connect }}
                  </button>
                  <button
                    class="button"
                    v-if="connectedToSite[project._id]"
                    @click="connect(project)"
                    style="background-color: #54a54f"
                  >
                    {{ translations.connected }}
                  </button>
                </div>
                <td class="status"><button class="button">Status</button></td>
                <td class="expand"></td>
                <div
                  class="match-arrow-container"
                  @click="expandCard(project)"
                  v-on:click="match.expanded = !match.expanded"
                >
                  <div class="match-arrow" />
                </div>
              </table>
              <div class="matching-criteria" v-if="expandPdCard[project._id]">
                <div class="header">
                  <img class="match-img" src="/assets/Matching-Criteria.png" />
                  <p class="matching-title">{{ translations.matchingTitle }}</p>
                </div>
                <table class="table" id="matching-criteria">
                  <tr>
                    <th>
                      <p class="site-name">{{ translations.developer }}</p>
                    </th>
                    <th
                      v-for="(site, i) of findSitesByProject(
                        matchedProjectsSites,
                        project._id
                      )"
                      :key="i"
                    >
                      <p class="site-name">
                        {{
                          site.answers['gaid185'] || `Site ${parseInt(i) + 1}`
                        }}
                      </p>
                    </th>
                    <td class="match-summary" >
                      <p class="site-name">{{ translations.summary }}</p>
                    </td>
                  </tr>
                  <tr
                    v-for="(section, i) of matchedDevSections"
                    :key="i"
                  >
                    <td class="matching-site" style="border-top: none !important;">
                      <p class="section-name-matching">
                        {{
                          ids[section.section][lang].boringName ||
                          ids[section.section][lang].name
                        }}
                      </p>
                      <div
                        v-for="(answers, index) of matchedDevSections[i]"
                        :key="index"
                      >
                        <div v-for="(gaid, index) of answers" :key="index">
                          <div v-if="lang && ids[gaid]" class="boxes">
                            <p
                              v-if="ids[gaid].type === 'number'"
                              class="dev-answer"
                            >
                              {{ loggedProjectDev.answers[gaid] }}
                            </p>
                            <p v-else class="dev-answer">
                              {{
                                ids[gaid][lang].boringName ||
                                ids[gaid][lang].name
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      v-for="(site, index) of findSitesByProject(
                        matchedProjectsSites,
                        project._id
                      )"
                      :key="index"
                      class="matching-site" style="border-top: none !important;"
                    >
                      <p class="section-name-matching">
                        {{
                          ids[section.section][lang].boringName ||
                          ids[section.section][lang].name
                        }}
                      </p>
                      <div class="boxes" v-if="lang">
                        <div
                          v-for="(answer, i) of findAnswersBySection(
                            section,
                            site.answers
                          )"
                          :key="i"
                        >
                          <p
                            v-if="ids[answer].type !== 'number'"
                            :class="
                              answer.match
                                ? 'matching-site-answer'
                                : 'not-matching-site-answer'
                            "
                          >
                            {{
                              ids[answer][lang].boringName ||
                              ids[answer][lang].name
                            }}
                          </p>
                          <p
                            v-else-if="ids[answer].type === 'number'"
                            :class="
                              answer.match
                                ? 'matching-site-answer'
                                : 'not-matching-site-answer'
                            "
                          >
                            {{ site.answers[section.answers] }}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <!-- <td class='match-summary'>
                      <div class='boxes'>
                        <p class='section-name-matching'>{{ translations.match }}</p>
                        <p :class='{
                          "high-match": match.matchPercentages[i] === 100,
                          "average-match": match.matchPercentages[i] >= 75 && match.matchPercentages[i] < 100,
                          "low-match": match.matchPercentages[i] < 75,
                          }'>{{ match.matchPercentages[i] }}% match</p>
                      </div>
                    </td> -->
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="pd" v-if="role === 1" 
              >
          <div v-for="(project, i) in matchedProjects" :key="i" class='atlas-card'>
            <div class="pdCard">
              <table class="cardTable">
                <td class="siteOwner" v-if="connectedToSite[project._id]">{{projectUserName[project._id]}}</td>
                <td v-else class="siteOwner">
                  <img src='/assets/blurred-name.png' alt="" class="blurImg">
                </td>
                <td class="percentage">
                  <p class="text">99%</p>
                </td>
                <td class="name">{{project.answers['gaid230']}}</td>
                <td class="space"></td>
                <div class="connect" >
                  <button class="button" v-if="!connectedToSite[project._id]" @click="connect(project)">
                    {{translations.connect}}
                  </button>
                  <button class="button" v-if="connectedToSite[project._id]" @click="connect(project)" style="background-color: #1D226F">
                    {{translations.connected}}
                  </button>
                </div>
                <td class="status" ><button class="button">Status</button></td>
                <td class="expand"></td>
                <div class='match-arrow-container' @click="expandCard(project)"
                v-on:click='match.expanded = !match.expanded'>
                <div class='match-arrow'/>
              </div>
              
              </table>
              <div class="matching-criteria" v-if="expandPdCard[project._id]">
                <div class='header'>
                  <img class='match-img' src='/assets/Matching-Criteria.png' />
                  <p class='matching-title'>{{ translations.matchingTitle }}</p>
                </div>
                <table class='table' id='matching-criteria'>
                  <tr>
                    <th>
                      <p class='site-name'>{{ translations.developer }}</p>
                    </th>
                    <th v-for='(site, i) of findSitesByProject(matchedProjectsSites, project._id)' :key='i'>
                      <p class='site-name'>{{ site.answers['gaid185'] || `Site ${parseInt(i) + 1}` }}</p>
                    </th>
                    <td class='match-summary'>
                      <p class='site-name'>{{ translations.summary }}</p>
                    </td>
                  </tr>
                  <tr v-for='(section, i) of matchedDevSections' :key='i' style="width:100%; display:block;">
                    <td class='matching-site'>
                      <p class='section-name-matching'>{{ids[section.section][lang].boringName || ids[section.section][lang].name}}  </p>
                      <div v-for='(answers, index) of matchedDevSections[i]' :key='index'>
                        <div v-for='(gaid, index) of answers' :key='index'>
                          <div v-if='lang && ids[gaid]' class='boxes'>
                            <p v-if='ids[gaid].type === "number"' class='dev-answer'>{{ loggedProjectDev.answers[gaid] }}</p>
                            <p v-else class='dev-answer'> {{ ids[gaid][lang].boringName || ids[gaid][lang].name }} </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td v-for='(site, index) of findSitesByProject(matchedProjectsSites, project._id)' :key='index' class='matching-site'>
                        <p class='section-name-matching'>{{ids[section.section][lang].boringName || ids[section.section][lang].name}}</p>
                        <div class='boxes' v-if='lang'>
                          <div v-for="(answer, i) of findAnswersBySection(section, site.answers)" :key='i'>
                            <p v-if='ids[answer].type !== "number"'
                              :class="answer.match ? 'matching-site-answer' : 'not-matching-site-answer'">
                              {{ ids[answer][lang].boringName || ids[answer][lang].name}}
                            </p>
                            <p v-else-if='ids[answer].type === "number"'
                              :class="answer.match ? 'matching-site-answer' : 'not-matching-site-answer'">
                              {{ site.answers[section.answers] }}
                            </p>
                          </div>
                        </div>
                    </td>
                  </tr>
                    <!-- <td class='match-summary'>
                      <div class='boxes'>
                        <p class='section-name-matching'>{{ translations.match }}</p>
                        <p :class='{
                          "high-match": match.matchPercentages[i] === 100,
                          "average-match": match.matchPercentages[i] >= 75 && match.matchPercentages[i] < 100,
                          "low-match": match.matchPercentages[i] < 75,
                          }'>{{ match.matchPercentages[i] }}% match</p>
                      </div>
                    </td> -->
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer :lang="lang"></Footer>
  </div>
</template>

<script>
import Footer from '@components/Footer.vue';
import devMatchingTranslationsEn from '@content/en/owner/dev-matching.yaml';
import devMatchingTranslationsNl from '@content/nl/owner/dev-matching.yaml';
import matchingConfigurationOwner from '@common/matching/matching_configuration_owner.yaml';
import matchingConfigurationDev from '@common/matching/matching_configuration_dev.yaml';
import { devMatchDummy } from '@common/data/dev-matches-dummy.data.ts';
import IDs from '@common/ids.yaml';
import devAnswersConfiguration from '@common/matching/matching_answers_dev.yaml';
import ownerAnswersConfiguration from '@common/matching/matching_answers_owner.yaml';
import questionnaire from '@common/questionnaire/site.yaml'
import RequestHelper from '../../../common/helpers/request.helper';
import SiteReject from '@layouts/matching/SiteReject';
import SuccessfulPayment from '@layouts/matching/SuccessfulPayment';
import Payment from '@layouts/matching/Payment';
import PopupController from '@components/popup/popup-controller';
import InfoCard from '@components/InfoCard';
import ContactForm from '@layouts/matching/ContactForm';

export default {
  name: 'DevMatching',
  components: {
    InfoCard,
    Payment,
    PopupController,
    SiteReject,
    SuccessfulPayment,
    Footer,
    ContactForm,
  },
  props: {
    toggleId: String,
  },
  // TODO
  // Move data to vuex
  data() {
    return {
      lang: '',
      translations: {},
      matchingConfiguration: matchingConfigurationOwner,
      summaryInformation: undefined,

      ids: IDs,

      projectIds: [],
      matchedProjects: [],
      matchedProjectsSites: [],
      allDevs: [],
      user: JSON.parse(localStorage.loggedUser),
      role: localStorage.loggedUser ? JSON.parse(localStorage.loggedUser).userType : 0,
      matches: [],
      notMatches: [],

      allSitesAnswers: {},

      requestHelper: new RequestHelper(this.$router),
      siteRejectedOpened: false,
      accountSettingsOpened: false,
      successfulPaymentOpened: false,
      paymentOpened: false,
      connectedToSite: [],
      projectUser: [],
      projectUserName: [],
      projectDev: undefined,
      expandPdCard: [],
      loggedProjectDev: [],
      matchedDevSections: [],
      projectSites: [],
      questionnaire: questionnaire,
      contactFormOpened: false,
      guideBoxOpened: false,
      display: [],
      sortedDropdown: false,
      sortName: '',
      allLead: true,
    };
  },
  watch: {
    selectedProjectId() {
      this.selectProjects();
      this.initMatches();
    },
  },
  // TODO
  //
  // As long as we have a few sites and a few developers,
  // we will load the developers _only_ on created().
  //
  // This means, that change of developer will require
  // logout/login for the site.
  //
  // Or?
  //
  // Later, when we have many users,
  // we should begin loading only
  // changed developer properties
  // and only when we display
  // the matching.
  created() {
    this.init();
    if (this.$route.query.payment === 'success') this.successfulPayment();
  },
  computed: {
    parents() {
      return this.$store.state.inputs.parents;
    },

    selectedProjectId() {
      return this.$store.state.siteOwner.selectedProjectId;
    },

    userProjects() {
      return this.$store.state.siteOwner.userProjects.map((value) => {
        return value._id;
      });
    },
  },

  methods: {
    sortBy(sort) {
      if (sort == 0) {
        this.sortMatches();
      } else if (sort == 4) {
        this.sortAllMatchesByName(true);
      } else if (sort == 5) {
        this.sortAllMatchesByName();
      }
    },
    sortAllMatchesByName(asc = false) {
      this.notMatches.sort(this.sortByName.bind(this, asc));
      this.matches.sort(this.sortByName.bind(this, asc));
    },
    sortByName(asc, a, b) {
      const returnValue = asc ? -1 : 1;
      if (a.status == 5) return 1;
      if (b.status == 5) return -1;
      const name =
        this.role === 1
          ? this.parents[a.projectId].answers['gaid230']
          : a.answers.gaid241;
      const name2 =
        this.role === 1
          ? this.parents[b.projectId].answers['gaid230']
          : b.answers.gaid241;

      if (name > name2) return returnValue;
      else if (name < name2) return returnValue * -1;

      return 0;
    },
    isAllActivated() {
      this.allLead = !this.display.some((el) => !el);
    },
    successfulPayment() {
      this.successfulPaymentOpened = true;
    },
    openGuideBox() {
      this.$emit('openGuideBox');
    },
    isOpened() {
      this.openContact = true;
    },

    findSitesByProject(sites, projectId){
      let outputSites = [];

      for(let site of sites){
        if(site.projectId === projectId){
          outputSites.push(site);
        }
      }
      if(outputSites.length >=6){
        outputSites.splice(6,100);
      }

      return outputSites;
    },

    findAnswersBySection(section, answers) {
      if (this.role === 1) {
        let output = [];
        let config = matchingConfigurationOwner;
        for (let qgaid of config) {
          if (section.section === qgaid.section) {
            for (let gaid of qgaid.criteries) {
              for (let answer in answers) {
                if (gaid === answer) {
                  output.push(answer);
                }
              }
            }
          }
        }
        return output;
      }
    },

    filterSiteByProject (sites, projectId) {
      let outputSites = {};

      Object.keys(sites).forEach((site) => {
        if (sites[site][projectId]) outputSites[site] = sites[site];
      });

      return outputSites;
    },

    async init() {
      // Here we set the language.
      localStorage.lang = this.lang = this.$router.history.current.params.lang;

      this.translations =
        this.lang === 'en'
          ? devMatchingTranslationsEn
          : devMatchingTranslationsNl;

      this.summaryInformation =
        this.role === 0 ? devAnswersConfiguration : ownerAnswersConfiguration;

      this.sortName = this.translations.sort.buttons[0];

      let arrayKeys = Object.keys(this.translations.display.buttons);
      for (let i of arrayKeys) {
        this.display[i] = true;
      }

      // Refresh matches on answers change
      await this.getProjectDevelopers();
      await this.getMatchedSites();
      await this.loadConnections();
      this.selectProjects();
      this.initMatches();
      this.getDevSections();
    },

    selectProjects() {
      this.projectIds =
        this.role === 1 ? this.userProjects : [this.selectedProjectId];
    },

    initSiteAnswers() {
      this.allSitesAnswers = {};
      for (const parent in this.parents)
        for (const projectId of this.projectIds) {
          if (this.parents[parent]?.answers[projectId]) {
            this.allSitesAnswers[parent] = { ...this.parents[parent]?.answers };
            break;
          }
        }
    },

    connectToDev(devs, i) {
      const dev = devs[i];

      dev.connected = !dev.connected;
      this.$forceUpdate();
      this.requestHelper.putQuery('connectDevProject', {
        devId: dev.id,
        projectId: dev.projectId,
        connected: dev.connected,
      });

      // Make the changes also on the project object
      const project = this.parents[dev.projectId];
      if (!project.connected) project.connected = [];
      else {
        let con = project.connected.find((value) => value._id === dev.id);
        if (con) con.connected = dev.connected;
        else project.connected.push({ _id: dev.id, connected: dev.connected });
      }
    },

    async connect(project) {
      this.connectedToSite[project._id] = !this.connectedToSite[project._id];
      if (!this.projectDev.connected) {
        await this.requestHelper.putQuery('connectDevToSite', {
          projectDevId: this.user.projectDevId,
          siteoProjectId: project._id,
          connected: this.connectedToSite[project._id],
        });
        this.getProjectDevelopers();
      } else {
        await this.requestHelper.postQuery('connectDevToSite', {
          projectDevId: this.user.projectDevId,
          siteoProjectId: project._id,
          connected: this.connectedToSite[project._id],
        });
      }
      await this.getProjectUser(project);
      this.$forceUpdate();
    },

    async getProjectUser(project) {
      let response = await this.requestHelper.postQuery(
        'getMatchedUserByProject',
        project.users[0]._id
      );
      this.projectUser = response.data;
      this.projectUserName[project._id] =
        this.projectUser.answers['gaid273'] ?? 'Not answered';
    },

    async loadConnections() {
      for (let connection of this.projectDev.connected) {
        for (let project of this.matchedProjects) {
          if (project._id === connection.siteoProjectId) {
            if (connection.status === 1) {
              this.connectedToSite[project._id] = true;
              await this.getProjectUser(project);
            }
          }
        }
      }
    },

    expandCard(project) {
      this.expandPdCard[project._id] = !this.expandPdCard[project._id];
      this.$forceUpdate();
    },

    getDevSections() {
      for (const section of this.matchingConfiguration) {
        let devSection = {
          answers: [],
          visible: false,
          section: section.section,
        };
        for (const gaid of section.criteries) {
          const match = this.isMatching(gaid, this.loggedProjectDev.answers);
          if (match) devSection.answers.push(gaid);
        }
        if (devSection.answers.length >= 1) {
          this.matchedDevSections.push(devSection);
        }
      }
    },

    changeProjectStatus(devs, i, approve) {
      const dev = devs[i];

      dev.status = this.role === 2 ? 2 : 4;
      if (!approve) dev.status += 1;
      this.requestHelper.putQuery('projectStatus', {
        projectId: dev.projectId,
        devId: dev.id,
        approve,
      });

      this.$store.commit('siteOwner/changeStatus', {
        id: dev.projectId,
        status: dev.status,
      });
      this.$forceUpdate();
    },

    initMatches() {
      if (this.role !== 0)
        this.matchingConfiguration = matchingConfigurationDev;

      this.initSiteAnswers();

      // Here we will collect all the Project Developers that match the Site
      this.matches = [];
      this.notMatches = [];
      let notificationsCount = 0;
      // Find matched answers between site and each dev
      for (let projectId of this.projectIds) {
        const project = this.parents[projectId];

        for (let mainDev of this.allDevs) {
          if (!mainDev) continue;
          let dev = { ...mainDev };

          // Set the connected status of the site
          if (project && project.connected) {
            let devConnected = project.connected.find((v) => v._id === dev.id);
            dev.status = devConnected?.status;
            dev.hasNotification = devConnected?.hasNotification;
            if (dev.hasNotification) notificationsCount++;
            dev.connected = !!dev.status;
          } else {
            dev.status = 0;
            dev.hasNotification = false;
            dev.connected = false;
          }

          //todo this should be checked in the backend
          // if the user is a project dev and the site is not connected to him
          if (this.role !== 0 && !dev.connected) continue;
          dev.projectId = projectId;

          dev.matchRating = 0;
          // Find all answers of the dev
          dev.devSections = [];
          dev.sections = [];
          for (const section of this.matchingConfiguration) {
            let devSection = {
              answers: [],
              visible: false,
              section: section.section,
            };
            for (const gaid of section.criteries) {
              const match = this.isMatching(gaid, dev.answers);
              if (match) devSection.answers.push(gaid);
            }
            dev.devSections.push(devSection);
            dev.sections.push(section.section);
          }

          // Find all answers for the sites
          dev.sites = [];
          for (const siteId in this.allSitesAnswers) {
            const siteAnswers = this.allSitesAnswers[siteId];
            if (!siteAnswers) continue;
            if (!siteAnswers[projectId]) continue;
            if (!dev.firstSiteAnswers) dev.firstSiteAnswers = siteAnswers;

            let site = { sections: [] };

            // Check all section of the criteria
            let i = 0;
            for (const section of this.matchingConfiguration) {
              let siteSection = [];
              let ifChecks = section.if || [];
              if (typeof ifChecks === 'string') ifChecks = [ifChecks];

              let goNext = false;
              for (let ifCheck of ifChecks) {
                if (ifCheck && !this.isMatching(ifCheck, siteAnswers)) {
                  goNext = true;
                  break;
                }
              }
              if (goNext) {
                i++;
                site.sections.push(siteSection);
                continue;
              }

              for (const gaid of section.criteries) {
                let id = this.ids[gaid];
                if (id.type === 'number' && this.ids[gaid].matchingLess) {
                  if (dev.answers[gaid]) {
                    if (section.siteIf && !siteAnswers[section.siteIf])
                      continue;

                    for (const matchGaid of id.matchingLess) {
                      if (siteAnswers[matchGaid]) {
                        if (
                          Number(dev.answers[gaid]) <=
                          Number(siteAnswers[matchGaid])
                        ) {
                          siteSection.push({
                            gaid: matchGaid,
                            match: true,
                            value: siteAnswers[matchGaid],
                          });
                          dev.devSections[i].visible = true;
                          break;
                        } else {
                          siteSection.push({
                            gaid: matchGaid,
                            match: false,
                            value: siteAnswers[matchGaid],
                          });
                          dev.devSections[i].visible = true;
                          break;
                        }
                      }
                    }
                  }
                } else {
                  if (!this.isMatching(gaid, siteAnswers)) continue;

                  dev.devSections[i].visible = true;
                  siteSection.push({
                    gaid,
                    match: this.isMatching(gaid, dev.answers),
                  });
                }
              }
              i++;
              site.sections.push(siteSection);
            }

            dev.sites.push(site);
          }

          for (let i = 0; i < dev.devSections.length; i++) {
            if (!dev.devSections[i].visible) {
              for (const site of dev.sites) site.sections.splice(i, 1);
              dev.sections.splice(i, 1);
              dev.devSections.splice(i--, 1);
              continue;
            }
            dev.matchRating += this.ids[dev.devSections[i].section].weight || 1;
          }

          if (dev.sites.length === 0) continue;

          // Calculate the match percentages
          dev.matchPercentages = [];

          let currentSection = 0;
          let matchingScore = 0;
          while (dev.sites[0].sections[currentSection] !== undefined) {
            if (dev.devSections[currentSection].visible) {
              let ok = 0;
              let count = 0;
              let hasMatch = true;
              for (const site of dev.sites) {
                for (const section of site.sections[currentSection]) {
                  if (section.match) {
                    ok += 100;
                  } else {
                    hasMatch = false;
                  }
                  count++;
                }
              }
              if (hasMatch)
                matchingScore +=
                  this.ids[dev.sections[currentSection]].weight || 1;
              const percent = Math.floor(ok / count);
              dev.matchPercentages.push(isNaN(percent) ? 0 : percent);
            }
            currentSection++;
          }

          dev.matchRating = Math.floor((matchingScore / dev.matchRating) * 100);

          if (isNaN(dev.matchRating)) dev.matchRating = 0;

          if (dev.matchRating === 100) this.matches.push(dev);
          else this.notMatches.push(dev);
        }
      }

      this.$store.commit(
        'siteOwner/updateNotificationsCount',
        notificationsCount
      );

      this.sortMatches();
    },

    sortMatches() {
      // Sort devs by number of matched answers
      this.matches.sort((m2, m1) => m1.matchRating - m2.matchRating);
      this.notMatches.sort((m2, m1) => m1.matchRating - m2.matchRating);
    },

    async getMatchedSites() {
      if (this.role === 1) {
        const response = await this.requestHelper.getQuery('getMatchedSites');
        this.matchedProjects = response.data[0];
        this.matchedProjectsSites = response.data[1];
        this.loggedProjectDev = response.data[2][0];
      }
    },

    isMatching(gaid, answers) {
      let matches = this.ids[gaid]?.matchingAnd;
      if (matches) {
        for (const match of matches) {
          if (!answers[match]) return answers[gaid];
        }
        return true;
      }

      matches = this.ids[gaid]?.matchingOr;
      if (matches) {
        for (const match of matches) {
          if (answers[match]) return true;
        }
      }

      matches = this.ids[gaid]?.matchingLess;
      if (matches) {
        for (const match of matches) {
          if (answers[match]) return true;
        }
      }

      return answers[gaid];
    },

    /**
     * Returns true if the site has the given gaid answered, either
     * directly or indirectly.
     */
    isAnswered(answers, gaid) {
      if (answers[gaid]) {
        // Return true if the site has the given id answered directly.
        return true;
      } else {
        // If the gaid has a matching property, check if it is answered by
        // the site indirectly
        if (this.ids[gaid].matchingOr) {
          for (const match of this.ids[gaid].matchingOr)
            if (answers[match]) return true;
        } else if (this.ids[gaid].matchingAnd) {
          for (const match of this.ids[gaid].matchingAnd)
            if (!answers[match]) return false;
          return true;
        }
      }

      return false;
    },

    async getProjectDevelopers() {
      const response = await this.requestHelper.getQuery('projectDevQuery');

      for (const developer of response.data) {
        if (!developer.answers || !developer.answers.gaid241) continue;

        const matchModel = { ...devMatchDummy };
        matchModel.answers = developer.answers || {};
        matchModel.id = developer._id;

        this.allDevs.push(matchModel);
        this.projectDev = developer;
      }
    },

    getAnswers: function (answers, gaids) {
      if (!answers) return ['Unanswered'];
      // Collects all answered
      let output = new Set();
      for (let gaid of gaids) {
        const answer = answers[gaid.gaid];
        if (answer && answer !== '') {
          Array.isArray(answer)
            ? output.add(answer.join(', '))
            : // if(Array.isArray(answer)){
              //   output.add(answer.join(", "))

              output.add(
                answer === true
                  ? gaid.answer
                    ? this.ids[gaid.answer][this.lang].name
                    : this.ids[gaid.gaid][this.lang].name
                  : answer
              );
        }
      }

      return output.size > 0 ? [...output] : ['Unanswered'];
    },
  },
};
</script>

<style scoped lang='scss'>
@import '@styles/base/forms.scss';
@import '@styles/base/_typography.scss';
@import '@styles/matching.scss';
@import '@styles/colors.scss';

h1 {
  color: rgb(255, 255, 204);
}
</style>
