<template>
  <div class='profile'>
    <button class='scroll-to-last-site' @click='centerOnPolygon()'>
      <img src='/assets/geo-alt.png' />
    </button>
    <Map
      :isSatelliteView='isSatelliteView'
      :showLayers='showLayers'
      :selectedAddress='selectedAddress'
      :colorPickerColors="colorPickerColors"
      mapName='map'
      :projectDevId='projectDevId'
      :renderToolsPopup='renderToolsPopup'
      @getResponse='getResponse'
      @updateUserStatus='updateUserStatus'
      @onPolygonsRendered='onPolygonsRendered'
      @onNewPolygon='onNewPolygon'
      @showColorPicker='showColorPicker = $event'
    />
    <color-picker v-show="showColorPicker" id="color-picker" v-model="colorPickerColors" />
    <div class='drop-filters' :class="renderToolsPopup ? 'active' : 'nonactive'">
      <button class='popup-render' @click='onFilterBtnClick()'>
        <img
          v-if='!renderToolsPopup'
          class='last-image'
          :src='"/assets/caret-down.svg"'
        />
        <img
          v-if='renderToolsPopup'
          class='last-image'
          :src='"/assets/caret-up.svg"'
        />
        <img class='first-image' src='/assets/Icon awesome-tools.svg' />
      </button>
    </div>
    <div
      class='drop-layers map-layer-popup'
      :class="renderLayerPopup ? 'active' : 'nonactive'"
    >
      <button class='popup-render' @click='onLayerBtnClick()'>
        <img class='first-image' src='/assets/layers.svg' />
        <img
          v-if='!renderLayerPopup'
          class='last-image'
          :src='"/assets/caret-down.svg"'
        />
        <img
          v-if='renderLayerPopup'
          class='last-image'
          :src='"/assets/caret-up.svg"'
        />
      </button>

      <div class='layer-selector' v-if='renderLayerPopup === true'>
        <div
          v-for='(layer, index) of layerConfig'
          :key='index'
          class='designAlignment'
          :class="showLayers[layer.key] ? 'cadaster-active' : 'cadaster-disactive'"
          @mouseover="mouseOverKey= layer.key"
          @mousleave="mouseOverKey = ''"
        > 
          <div class="cadasterPopUp" v-if="layer.key == 'Cadasters'" v-show="mouseOverKey == 'Cadasters'"  :style="{'border-color': layer.color}">
            <p class="text">Zoom to 10m to see the cadaster</p>
          </div>
           <label class="switch">
            <input type="checkbox">
            <div class="slider round" 
              @click='!isLayerDisabled(layer) ? onMapLayerClick(layer.key) : notLoggedMapLayerClick(layer.key)' 
              :style="!showLayers[layer.key] ? '#ccc' : {'background-color': layer.color}">
              <span class="slider circle" 
                :style="{'background-color': layer.color}"
                :class="showLayers[layer.key] ? 'active' : 'disactive'">
              </span>
            </div>
          </label>
          <button
            class='areaButtons'
            @click='!isLayerDisabled(layer) ? onMapLayerClick(layer.key) : notLoggedMapLayerClick(layer.key)'
            :class="showLayers[layer.key] ? 'cadaster-active' : 'cadaster-disactive'">
            <p>{{ layer.title }}</p>
          </button>
        </div>
      </div>
    </div>
    <button
      class='map-type-view'
      @click='isSatelliteView = !isSatelliteView'
    >
      <img :src='isSatelliteView ? "/assets/layer-map.png" : "/assets/layer-satellite.png"' />
    </button>

    <div class='hidden-box'>
      <ConnectedProfileBoxes ref='boxes' @redraw="$emit('redraw')" />
    </div>

     <div class='hidden-box'>
      <MarkerModal ref='markerModal' />
    </div>

  </div>
</template>

<script>
import ids from '@common/ids.yaml';
import Map from '@components/Map';
import { MapLayer } from '@common/data/enums/MapLayer.js';
import { Project } from '@common/models/site-owner/project.model.js';
import RequestHelper from '@common/helpers/request.helper.js';
import modelTranslationNl from '@content/nl/model/translations.yaml';
import modelTranslationEn from '@content/en/model/translations.yaml';
import ConnectedProfileBoxes from '@layouts/civilization/ConnectedProfileBoxes';
import MarkerModal from '@layouts/civilization/MarkerModal';
import Sketch from '../../vue-color/components/Sketch.vue';

export default {
  name: 'Profile',
  components: {
    Map,
    ConnectedProfileBoxes,
    MarkerModal,
    'color-picker': Sketch
  },
  props: {
    projectDevId: String
  },
  data () {
    return {
      ids: ids,
      lang: 'en',
      isSatelliteView: true,
      outputTop: (window.innerHeight - Number(690)) / 2,
      outputLeft: window.innerWidth - Number(450),
      outputWidth: 300,
      inputOutputBoxHeight: Number(690),
      role: localStorage.loggedUser ? JSON.parse(localStorage.loggedUser).userType : 0,
      lastSiteId: undefined,
      answers: {},
      confirmed: JSON.parse(localStorage.loggedUser || {}).confirmed || false,

      minimizeOutputBox: undefined,
      renderToolsPopup: false,
      activatedInputBox: false,
      activatedOutputBox: false,
      renderLayerPopup: false,
      showLayers: {
        'Cadasters': false, 
        'EnergyProjects': false,
        'Grid': false,
        'GridCongestion': false,
        'Municipalities': false,
        'Provinces': false, 
        'ResRegions': false, 
        'RestrictedAreas': false, 
        'SearchAreas': false
      },
      layerConfig: [],
      allAddresses: '',
      selectedAddress: undefined,

      MapLayer: MapLayer,

      selectedOutputTab: 'advantages',
      regtechDetails: undefined,

      mouseOverKey: '',
      selectedSiteProvinces: [],
      selectedSiteMunicipalities: [],
      selectedSiteResRegions: [],
      selectedSegment: '',

      regtechAnswers: [
        {
          id: 'gaid231',
          statusAnswers: ['gaid234', 'gaid235', 'gaid236', 'gaid256'],
          trendAnswers: ['gaid238', 'gaid239', 'gaid240', 'gaid257']
        },
        {
          id: 'gaid232',
          statusAnswers: ['gaid242', 'gaid243', 'gaid244', 'gaid258'],
          trendAnswers: ['gaid245', 'gaid246', 'gaid247', 'gaid259']
        },
        {
          id: 'gaid233',
          statusAnswers: ['gaid249', 'gaid250', 'gaid251', 'gaid260'],
          trendAnswers: ['gaid253', 'gaid254', 'gaid255', 'gaid261']
        }
      ],
      requestHelper: new RequestHelper(this.$router),
      modelTranslation: undefined,
      imgSrc: undefined,
      modelDescription: undefined,
      modelSubDescription: undefined,
      buttons: [
        { name: 'green-half-chart' }
      ],
      tabs: [
        {
          imgSrc: '/assets/green-half-chart.svg', title: 'Metrics',
          info: 'Site size:'
        },
        {
          imgSrc: '/assets/column-chart.svg', title: 'Economics',
          info: 'You save: 8,040 €/year'
        },
        {
          imgSrc: '/assets/donut-chart.svg', title: 'Feasibility',
          info: 'Overview: Great project'
        },
        {
          imgSrc: '/assets/padlock.svg', title: 'Constrains',
          info: 'RegTech: Locked'
        }
      ],
      selectedTab: 'Economics',
      lastSite: undefined,
      colorPickerColors: {
        hex: '#28a745'
      },
      showColorPicker: false
    };
  },
  created () {
    const loggedUser = JSON.parse(localStorage.loggedUser).resultBoxCoordinates;

    if (!loggedUser) {
      if (window.innerWidth > 767 && window.innerWidth <= 1100) {
        this.outputTop = loggedUser ? loggedUser?.top : window.innerHeight - 600;
        this.outputLeft = loggedUser ? loggedUser?.left : window.innerWidth - 150;
      }
    } else {
      this.outputTop = loggedUser?.top || this.outputTop;
      this.outputLeft =
        loggedUser?.left || this.outputLeft;
      this.minimizeOutputBox =
        loggedUser?.active === undefined
          ? false
          : !loggedUser?.active;
    }
    this.modelTranslation = localStorage.lang === 'en' ? modelTranslationEn : modelTranslationNl;
  },

  mounted () {
    this.init();
  },

  computed: {
    userSites () {
      return this.$store.state.siteOwner.userSites;
    },

    token () {
      return localStorage.token;
    },

    mapInstances () {
      return this.$store.state.map.instances;
    },

    selectedSiteId () {
      return this.$store.state.siteOwner.selectedSiteId;
    },

    inputParents () {
      return this.$store.state.inputs.parents;
    }
  },

  methods: {
    updateUserStateSelectedSite () {
      // Update user in the DB
      if (localStorage.token && this.selectedSiteId)
        this.requestHelper.putQuery('userState', {
          lastSelectedSite: this.selectedSiteId
        });
    },
    getResponse (address) {
      this.allAddresses = address.results;
    },

    async init () {
      localStorage.lang = this.lang = this.$router.history.current.params.lang;
      this.renderLayerPopup = JSON.parse( localStorage.getItem("popupOpened"))
      this.renderToolsPopup = JSON.parse( localStorage.getItem("toolsOpened"))
      // Subscribe to changes in the global storage
      this.$store.subscribe(this.onStoreChange);

      this.layerConfig = (await this.requestHelper.getQuery("mapLayerQuery?type=config")).data;
      this.layerConfig = this.layerConfig.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
      
      if (this.inputParents[this.selectedSiteId] && this.inputParents[this.selectedSiteId].answers)
        this.answers = this.inputParents[this.selectedSiteId].answers;
      this.initShowLayers();
      await this.initDynamicInputData();
    },

    async initSelectedSite () {
      // get last selected site from the DB
      const response = await this.requestHelper.getQuery('userState');
      const lastSelectedSite = response?.data.lastSelectedSite;

      // store it in the global store
      if (lastSelectedSite)
        this.$store.commit('siteOwner/setSiteSelected', lastSelectedSite);
    },

    async initDynamicInputData () {
      if (!localStorage.token) return;
      const projects = await Project.getAllForUser();

      // Initialize all projects for the current user in the global store
      this.$store.commit('siteOwner/initUserProjects', projects);
      // Init input data for projects.
      if (!projects || !Array.isArray(projects)) return;
      for (let project of projects) {
        if (project.answers) {
          const parents = {
            [project._id]: {
              answers: project.answers,
              objType: 'project',
              users: project.users,
              connected: project.connected,
              sketches: project.sketches || []
            }
          };

          this.$store.commit('inputs/addParents', parents);

          const commitData = {
            parentId: project._id,
            newAnswers: project.answers
          };

          this.$store.commit('inputs/updateParentAnswers', commitData);
        }
      }
      this.$store.commit('inputs/finishUpdateParent');
    },

    onStoreChange (mutation) {
      switch (mutation.type) {
        // When a a site is selected.
        case 'siteOwner/setSiteSelected':
          this.centerOnPolygon();
          this.updateUserStateSelectedSite();
        //this also executes the next case
        case 'inputs/updateParentAnswers':
          if (this.inputParents[this.selectedSiteId] && this.inputParents[this.selectedSiteId].answers)
            this.answers = this.inputParents[this.selectedSiteId].answers;
          if (this.answers['gaid291'])
            this.tabs[0].info = `Site size: ${this.answers['gaid291']} sq.m`;
          break;

        case 'map/updateMapName':
          if (!mutation.payload || mutation.payload !== 'map') break;

          if (this.mapInstances['map']) {
            this.$nextTick(() => {
              this.mapInstances['map'].invalidateSize();

              if (this.selectedSiteId) this.centerOnPolygon();
            });
          }
          break;

        case 'inputs/confirmedAccount':
          this.confirmed = JSON.parse(localStorage.loggedUser || '{}').confirmed || false;
        break;

      }
    },

    async onPolygonsRendered (polygons) {
      // Init global sites
      this.$store.commit('siteOwner/initUserSites', polygons);
      await this.initSelectedSite();
    },

    onNewPolygon (polygon) {
      if (this.role !== 0) return;

      // Commit empty answers to the global store
      const commitData = {
        parentId: polygon.layer.options.id,
        objType: 'site',
        newAnswers: {}
      };

      // When a new polygon is drawn, the process is getting reset to the 3rd step, so we need to recreate the stack
      this.$emit('initStepStack');

      this.$store.commit('inputs/updateParentAnswers', commitData);

      this.$store.commit('siteOwner/pushToUserSites', polygon.layer);
      this.$store.commit('siteOwner/setSiteSelected', polygon.layer.options.id);
    },

    redraw () {
      this.$emit('redraw');
    },

    centerOnPolygon () {
      const selectedSite = this.userSites.find(
        site => site.options?.id === this.selectedSiteId
      );

      if (selectedSite) {
        if (this.projectDevId && this.inputParents[selectedSite.options.projectId]?.connected
            .find((value) => value._id === this.projectDevId)?.status !== 4)
            return;

        if(this.lastSite != selectedSite.options.id){
          // Center on site & Zoom to fit
          try {
            this.mapInstances['map'].fitBounds(selectedSite.getBounds(), {
              animate: false,
              duration: 0
          });
          } catch (ignored) {
            //ignored
          }
        }
        this.lastSite = selectedSite.options.id
      }
    },

    updateUserStatus (mapInfo) {
      if (!localStorage.loggedUser) return;
      const loggedUser = JSON.parse(localStorage.loggedUser);

      loggedUser.resultBoxCoordinates = {
        top: this.outputTop,
        left: this.outputLeft,
        active: !this.minimizeOutputBox
      };

      if (mapInfo) {
        loggedUser.mapCoordinates = mapInfo.mapCoordinates;
        loggedUser.zoomLevel = mapInfo.zoomLevel;
      }
      // delete loggedUser.token

      // Last selected site is tracked by the global store
      delete loggedUser.lastSelectedSite;
      if (localStorage.token)
        this.requestHelper.putQuery('userState', loggedUser, ({ data }) => {
          localStorage.loggedUser = JSON.stringify({ ...loggedUser, ...data });
        });
    },

    /**
     * Toggles the layers popup and saves the result in the DB.
     */
    onLayerBtnClick () {
      this.renderLayerPopup = !this.renderLayerPopup;
      localStorage.setItem("popupOpened",(this.renderLayerPopup))  
    },

    onFilterBtnClick () {
      this.renderToolsPopup = !this.renderToolsPopup;
      localStorage.setItem("toolsOpened",(this.renderToolsPopup))
    },

    /**
     * Toggles one of the layers and saves the result in the DB.
     */
    onMapLayerClick (layer) {
      this.showLayers[layer] = !this.showLayers[layer];
      this.saveLayerSelection(layer);
    },

    notLoggedMapLayerClick(layer){
      this.showLayers[layer] = !this.showLayers[layer];
    },

    saveLayerSelection (layer) {
      const loggedUser = JSON.parse(localStorage.loggedUser);

      if (!loggedUser.showLayers) loggedUser.showLayers = this.showLayers;
      else loggedUser.showLayers[layer] = this.showLayers[layer];

      localStorage.loggedUser = JSON.stringify(loggedUser);

      // Last selected site is tracked by the global store
      delete loggedUser.lastSelectedSite;

      if (localStorage.token)
        this.requestHelper.putQuery('userState', loggedUser);
    },

    /**
     * Automatically selects all layers, marked as selected in the DB &
     * opens the layers popup.
     */
    initShowLayers () {
      const localLayers = JSON.parse(localStorage.loggedUser).showLayers;

      if (!localLayers) return;

      this.showLayers = localLayers;
      for (let key in this.showLayers) {
        if (this.showLayers[key]) {
          this.renderLayerPopup = true;
          break;
        }
      }
    },

    isLayerDisabled(layer) {
      return (!layer.free && (!this.token || !this.confirmed)) || !layer.enabled;
    }
  }
};
</script>

<style lang='scss'>
@import '@styles/profile.scss';
@import '@styles/main.scss';
</style>
