<template>
<div class='popup-box dimming' @click='closePayment($event, true)'>
    <div class='popup box-style'>
        <!-- </div> -->
        <div class='popup-content'>
            <button class='close-btn' @click='closePayment()'>
                <img src='/assets/guide-close-icon.svg' alt='img' />
            </button>
            <div class='popup-top-section'>
                <div class='row'>
                    <p class='popup-header'> {{ translations.header }}</p>
                </div>
                <div class='row'>
                    <p class='info-text'>
                        {{ translations.infoText }}
                    </p>
                </div>

                <div class='payment-texts'>
                    <div v-for='(text, i) of translations.paymentInfoTexts.text' :key='i'>
                        <img src='/assets/green-correct.svg' class='icon' />
                        <p class='grey-text'>
                            {{ text }}
                        </p>
                    </div>
                </div>
            </div>

            <div class='popup-bot-section'>
                <stripe-checkout ref='checkoutRef' mode='payment' :clientReferenceId='`${devId}-${projectId}`' :pk='publishableKey' :line-items='lineItems' :customerEmail='customerEmail' :success-url='successURL' :cancel-url='cancelURL' @loading='v => isLoading = v' />
                <GaInput class='popup-checkbox-input' gaid='gaid453' />
                <div class='buttons-row'>
                    <button class='pay-button' @click='submit()'>
                        {{ translations.button.pay }}
                        <img src='/assets/right arrow.svg' class='right-arrow'>
                    </button>
                    <button class='cancel-button' @click='closePayment()'>
                        {{ translations.button.cancel }}
                    </button>
                </div>

                <div class='stripe-text'>
                    <p class='section-text mr-2 mt-auto mb-0'>
                        {{ translations.poweredBy }}
                    </p>
                    <img src='/assets/stripe.svg' class='mt-auto' />
                </div>

            </div>
        </div>

    </div>
</div>
</template>

<script>
import {
    StripeCheckout
} from '@vue-stripe/vue-stripe';
import paymentEN from '@content/en/general/payment.yaml';
import paymentNL from '@content/nl/general/payment.yaml';
import GaInput from '@components/inputs/ga-input';

export default {
    name: 'Payment',
    components: {
        GaInput,
        StripeCheckout
    },
    props: {
        lang: String,
        projectId: String,
        devId: String
    },
    data() {
        return {
            publishableKey: 'pk_test_51JbmPbL6HoU2pLYejHRHMdNyaRzhraWq6yUBBKOakyzHBqEP6kE44K7YJhUu0rNLuPorOk0y6pdo2OIYaFKvQt6y005qAQYBzQ',
            translations: this.lang === 'en' ? paymentEN : paymentNL,
            checkbox: false,
            isLoading: false,
            lineItems: [{
                price: 'price_1Jc4Q7L6HoU2pLYeBXz3sgBT', // The id of the one-time price you created in your Stripe dashboard
                quantity: 1
            }],
            successURL: `${window.location.origin}/${this.lang}/dev/matching?payment=success`,
            cancelURL: `${window.location.origin}/${this.lang}/dev/matching`,
            customerEmail: localStorage.loggedUser ? JSON.parse(localStorage.loggedUser).email : ''
        };
    },
    methods: {
        closePayment(ev, isBackgroundClick) {
            if (isBackgroundClick && !ev.target.className.includes('popup-box'))
                return;

            this.$emit('closePayment');
        },
        submit() {
            // You will be redirected to Stripe's secure checkout page
            this.$refs.checkoutRef.redirectToCheckout();
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@styles/main.scss';
@import '@styles/payment.scss';
@import '@styles/popup.scss';
</style>
