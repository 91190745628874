var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type === 'boolean')?_c('staticButton',{attrs:{"readOnly":_vm.readOnly,"type":_vm.type,"gaid":_vm.gaid,"group":_vm.group,"image":_vm.image,"multiSelectGroup":_vm.multiSelectGroup,"active":_vm.value,"page":_vm.page},on:{"click":function($event){return _vm.onData($event)}}}):_vm._e(),(
      _vm.type === 'text' ||
        _vm.type === 'password' ||
        _vm.type === 'password-showable' ||
        _vm.type === 'textarea' ||
        _vm.type === 'project-selector' ||
        _vm.type === 'number' ||
        _vm.type === 'email'
    )?_c('staticText',{ref:"childInput",attrs:{"readOnly":_vm.readOnly,"type":_vm.type,"gaid":_vm.gaid,"group":_vm.group,"value":_vm.value,"page":_vm.page,"multiSelectGroup":_vm.multiSelectGroup,"preventAutoEmit":_vm.preventAutoEmit,"showActionLabels":_vm.showActionLabels},on:{"change":function($event){return _vm.onData($event)},"onSavedIndicator":_vm.onSavedIndicator}}):_vm._e(),(_vm.type === 'checkbox')?_c('staticCheckbox',{attrs:{"type":_vm.type,"gaid":_vm.gaid,"value":_vm.value,"text":_vm.text},on:{"change":function($event){return _vm.onData($event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }