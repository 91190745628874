<template>
  <div class="container" id="FAQ">
    <div class="faq">
      <div>
        <h1>{{ title }}</h1>
      </div>
      <div v-for="(faq, i) of questions.questions" :key="i">
        <button @click="faq.opened = !faq.opened">
          <div class="row style-card mt-4">
            <div class="green-plus">
              <div
                class="green-plus-bottom-line"
                :class="{ accordion: faq.opened }"
              ></div>
              <div class="green-plus-right-line"></div>
            </div>
            <h2>{{ faq.questionName }}</h2>
          </div>
        </button>
        <div v-if="faq.questions && faq.questions.length > 0">
          <div class="sub-question" v-for="(subQ, i) of faq.questions" :key="i">
            <button
              @click="subQ.opened = !subQ.opened"
              class="secondary-question mt-3"
              :class="{ accordion: faq.opened }"
            >
              <div>
                <div
                  class="green-plus-bottom-line"
                  :class="{ accordion2nd: subQ.opened }"
                ></div>
                <div class="green-plus-right-line"></div>
                <h2>{{ subQ.subquestion }}</h2>
                <p :class="{ accordion2nd: !subQ.opened }">{{ subQ.answer }}</p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import faq from "@content/nl/general/faq.yaml";
import faqEn from "@content/en/general/faq.yaml";
export default {
  name: "FAQ",
  data() {
    return {
      title: "",
      questions: {},
      accordion: false,
      accordion2nd: false,
    };
  },
  props: {
    lang: String,
  },
  mounted() {
    this.init();
  },
  watch: {
    lang() {
      this.init();
    },
  },
  methods: {
    init() {
      let data = this.lang === "en" ? faqEn : faq;
      this.title = data.title;
      this.questions = data;
      this.addOpenedProperty();
    },
    addOpenedProperty() {
      this.questions.questions = this.questions.questions.map((question) => ({
        ...question,
        opened: false,
        questions: (question.questions || []).map((subQ) => ({
          ...subQ,
          opened: false,
        })),
      }));
    },
  },
};
</script>

<style scoped lang="scss">
@import "@styles/main.scss";
@import "@styles/home-page.scss";
</style>