<template>
  <div class='acceptInvitation'>

    <div class='components col-12'>

      <img :class="exists && !error ? 'logo' : 'logo-middle'" src='/assets/logo.png' alt='' />

      <p v-if='error' class='header-text'>{{ error }}</p>
      <p v-else class='header-text'>{{ exists ? translation.invitation.title : translation.invitation.description }}</p>

      <div v-if='error'>
        <p @click="$emit('startNow')" class='start-now-link clickable'>
          {{ translation.account.description }}
          <span class='yellow-line'>{{ translation.account['underline-description'] }} </span>
        </p>
      </div>
      <div v-else class='inputs'>
        <p>{{ email }}</p>
        <div v-if='!exists'>
          <div class='row'>
            <div class='input-with-eye'>
              <ShowHidePassword field="pass"/>
              <input id='pass' class='pass' type='password' placeholder='Password'
                     v-model='password' @change='passRules' ref='pass'/>
            </div>
          </div>
          <div class='col-12' v-if='!isError'>
<!--            todo use component-->
            <ul>
              <li :class='{ valid: isLongEnough }'>{{ translation.requirements.symbols }}</li>
              <li :class='{ valid: hasNumber }'>{{ translation.requirements.number }}</li>
              <li :class='{ valid: hasLowerCase && hasUpperCase }'>
                {{ translation.requirements.letter }}
              </li>
              <!--                todo this is not checked-->
              <li>{{ translation.requirements.confirmed }}</li>
            </ul>
          </div>
          <div class='password-error-icon'>
            <div class='confirm'>
              <input type='password' name='password' placeholder='Confirm Password' v-model='confirmPassword'
                     :class='{ error: isError }' />
            </div>
            <div v-if='isError' class='confirm'>
              <img src='/assets/warning.svg' />
              <p>{{ translation.invitation.confirm }}</p>
            </div>
          </div>

          <gaInput gaid='gaid453' @data='onData($event)' />

        </div>

        <button class='green' @click='acceptInvitation()' :disabled='!signupAllow'
                :style="{ cursor: signupAllow ? 'pointer' : 'not-allowed' }">
          {{ translation.invitation.accept }}
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import RequestHelper from '@common/helpers/request.helper';
import translationsEn from '@content/en/auth/auth.yaml';
import translationsNl from '@content/nl/auth/auth.yaml';
import gaInput from '@components/inputs/ga-input';
import ShowHidePassword from '@components/authorization/ShowHidePassword';

export default {
  components: {
    gaInput,
    ShowHidePassword
  },
  data () {
    return {
      password: '',
      active: false,
      checkbox: false,
      email: '',
      exists: false,
      token: '',
      projectName: '',
      error: '',
      confirmPassword: '',
      isError: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasNumber: false,
      isLongEnough: false,
      requestHelper: new RequestHelper(this.$router),
      translation: localStorage.lang === 'en' ? translationsEn : translationsNl,
    };
  },
  mounted () {
    this.token = this.$router.history.current.query.token;
    if (!this.token) {
      this.$router.replace({
        name: 'Libero',
        params: {
          userType: 'site-owner',
          loginType: 'login'
        }
      });
      this.$emit("cancel");
      return;
    }
    this.requestHelper.getQuery(
      `ownerInvitation?token=${this.token}`,
      (response) => {
        this.email = response.data.email;
        this.exists = response.data.exists;
        this.projectName = response.data.projectName;
      },
      (response) => {
        this.error = response.data.error;
      }
    );

  },

  computed: {
    signupAllow () {
      if (this.exists) return true;
      if (!this.email || !this.password || !this.confirmPassword) return false;
      if (this.checkbox === false) return false;

      return this.passRules();
    },
    passMatch () {
      return (
        this.confirmPassword.length > 0 &&
        this.password === this.confirmPassword
      );
    }
  },
  methods: {
    acceptInvitation () {
      if (this.signupAllow && this.passMatch) {
        this.isError = false;
        this.$emit('accept-invitation', {
          password: this.password,
          token: this.token
        });
      } else {
        this.isError = true;
      }
    },
    onData (ev) {
      this.checkbox = ev.gaid453;
    },
    checkForLowerLetters () {
      this.hasLowerCase = this.password.match(/[a-z]/g);
    },
    checkForUpperLetters () {
      this.hasUpperCase = this.password.match(/[A-Z]/g);
    },
    checkForNumbers () {
      this.hasNumber = this.password.match(/[0-9]/g);
    },
    checkForLength () {
      this.isLongEnough = this.password.length >= 6;
    },

    passRules () {
      this.checkForLength();
      this.checkForLowerLetters();
      this.checkForUpperLetters();
      this.checkForNumbers();
      return (
        this.hasLowerCase &&
        this.hasUpperCase &&
        this.hasNumber &&
        this.isLongEnough
      );
    },
  }
};
</script>

<style lang='scss'>
@import "@styles/main.scss";
@import "@styles/colors.scss";
</style>
