<template>
  <div>
    <dynamicInput
      v-if='dynamic'
      :readOnly='readOnly'
      :gaid='gaid'
      :group='group'
      :dynamicType='dynamicType'
      :dynamicObject='dynamicObject'
      :valueAnswer='valueAnswer'
      :parentId='parentId'
      @data='onData'
    />
    <staticInput
      v-else
      :readOnly='readOnly'
      :gaid='gaid'
      :group='group'
      :defaultValue='defaultValue'
      :dynamic='dynamic'
      :preventAutoEmit='preventAutoEmit'
      :parentId='parentId'
      :multiSelectGroup='multiSelectGroup'
      :page='page'
      :text='text'
      :showActionLabels='showActionLabels'
      @data='onData'
      @onSavedIndicator='onSavedIndicator'
      ref='childInput'
    />
  </div>
</template>

<script>
import staticInput from '@components/inputs/types/static/static-input';
import dynamicInput from '@components/inputs/types/dynamic/dynamic-input';
import ids from '@common/ids.yaml';

export default {
  name: 'ga-input',

  components: {
    staticInput,
    dynamicInput
  },
  mounted () {
    this.validator();
  },

  props: {
    page: String,
    gaid: String,

    /**
     * Used for toggles. When multiple inputs have the same group,
     * only one of them can be active at a time.
     * */
    group: String,

    // Dynamic input properties

    /** Whether the input is dynamic or not. */
    dynamic: Boolean,

    /** The value written by default in the field. */
    defaultValue: String,

    /**
     * The same type as described in ids.yaml, but
     * passed manually for dynamic inputs .
     * */
    dynamicType: String,

    /** The MongoDB object, with which the input will be working. */
    dynamicObject: {},

    /**
     * The gaid stored in the dynamicObject's "answers" property,
     * the value of which will be used as the input's value.
     * */
    valueAnswer: String,

    preventAutoEmit: Boolean,

    preventStorageChange: Boolean,

    /**
     * - What is a "parent"?
     * A parent is the object to which the answer of the input belongs.
     * For example: a site, a project developer, a project, etc.
     * parentId is the mongo _id of that object.
     *
     * - What is this property used for?
     * The global storage answers are separated by parents. By this
     * property the input knows where to save its answer.
     */
    parentId: String,

    multiSelectGroup: String,

    readOnly: Boolean,

    /**
     The text property is only for checkbox inputs. Without text property, the checkbox text field
     is with default message(for privacy and terms). With text property, the default message
     should replace with the written text
     */
    text: String,
    showActionLabels: { type: Boolean, default: true }
  },

  data () {
    return {
      ids: ids
    };
  },

  computed: {
    inputParents () {
      return this.$store.state.inputs.parents;
    },
    currentSavedStep () {
      return this.$store.state.siteOwner.currentStepId;
    }
  },

  updated () {
    this.validator();
  },

  methods: {
    /**
     * Triggered every time an input emits new data.
     */
    onData (data) {
      this.deepDeactivate([this.group], data);
      this.checkExclusive(data);
      if (!this.preventStorageChange) {
        const commitData = {
          parentId: this.parentId || 'user',
          newAnswers: data
        };

        this.$store.commit('inputs/updateParentAnswers', commitData);
      }
      this.validator();

      // // Emitting a changed data event in case the parent wants to
      // // perform any special actions for this input.
      this.$emit('data', data);
    },
    validator () {
      const validation = ids[this.gaid]?.validations;

      if (!validation) return;

      if (validation.minLength)
        this.$emit('hasMinLength', this.inputParents[this.parentId]?.answers[this.gaid]?.length >= validation.minLength);
      if (validation.lowerCase)
        this.$emit('hasLowerCase', this.inputParents[this.parentId]?.answers[this.gaid]?.match(/[a-z]/g));
      if (validation.upperCase)
        this.$emit('hasUpperCase', this.inputParents[this.parentId]?.answers[this.gaid]?.match(/[A-Z]/g));
      if (validation.number)
        this.$emit('hasNumber', this.inputParents[this.parentId]?.answers[this.gaid]?.match(/[0-9]/g));
      if (validation.mustMatch)
        this.$emit('isConfirmed', this.inputParents[this.parentId]?.answers[this.gaid] ===
          this.inputParents[this.parentId]?.answers[validation.mustMatch] && this.inputParents[this.parentId]?.answers[validation.mustMatch]?.length > 0);
    },

    checkExclusive (answers) {
      const container = document.querySelectorAll(`[step=${this.currentSavedStep}]`)[0];
      
      if(!container)return;

      const inputs = container.getElementsByClassName('ga-input');

      if (!this.ids[this.gaid]) return;

      if (this.ids[this.gaid]?.exclusiveAnswer) {
        for (const input of inputs) {
          // Store as false in answers obj
          const gaid = input.getAttribute('gaid');
          if (this.gaid !== gaid)
            answers[gaid] = false;
        }
      } else {
        let allAnswers = this.inputParents[this.parentId]?.answers;
        
        if (!allAnswers) return;
        
        for (const input of inputs) {
          // Store as false in answers obj
          const gaid = input.getAttribute('gaid');
          if (this.ids[gaid]?.exclusiveAnswer && allAnswers[gaid])
            answers[gaid] = false;
        }
      }
    },

    /**
     * Deactivates all inputs that have group = this.group,
     * except the current input (which has gaid = this.gaid).
     */
    deepDeactivate (groups, answers) {
      for (const group of groups) {
        if (!group) continue;
        // For all other buttons of the same group...
        this.deactivateGroup(group, answers);

        // For all groups except that of the current input
        if (this.group !== group) {
          // For all group containers with the same id as the current iterated group...
          const groupContainers = document.querySelectorAll(`[group-container=${group}]`);

          for (let groupContainer of groupContainers) {
            // For all inputs inside each group container...
            let inputs = groupContainer.getElementsByClassName('ga-input');

            for (let input of inputs) {
              // Store as false in answers obj
              answers[input.getAttribute('gaid')] = false;
            }
          }
        }
      }
    },

    deactivateGroup (group, answers) {
      for (let element of document.querySelectorAll(
        `[group='${group}']:not([gaid='${this.gaid}'])`
      )) {
        // Store as false in answers obj
        answers[element.getAttribute('gaid')] = false;
      }
    },

    triggerManualDataEmit () {
      this.$refs.childInput.triggerManualDataEmit();
    },

    onSavedIndicator (data) {
      this.$emit('onSavedIndicator', data);
    }

  }
};
</script>

<style lang='scss'>

</style>
