<template>
  <div class='p-dev'>
    <div class='row'>
      <div class='logo'>
        <router-link :to='`/${$router.history.current.params.lang}`'>
          <img src='/assets/logo.png' />
        </router-link>
      </div>
    </div>
    <div class='language-switch'>
      <router-link :to="getURL('en')">
        <button :class="this.currentLang === 'nl' ? 'lang-button active' : 'lang-button'">
          {{ language['nl-lang'] }}
        </button>
      </router-link>
      <router-link :to="getURL('nl')">
        <button :class="this.currentLang === 'en' ? 'lang-button active' : 'lang-button'">
          {{ language['en-lang'] }}
        </button>
      </router-link>
    </div>
    <div class='content-box'>
      <div class='header'>
        <div class='header-text'>
          <p class='header-text'>{{ regTranslations.title }}</p>
          <p class='header-text sub first'>
            {{ regTranslations['green-description'] }}
          </p>
          <p class='header-text sub second'>
            {{ regTranslations.description }}
          </p>
        </div>
        <div class='header-chevron'>
          <img src="/assets/chevron-pdev-register.svg"/>
          <div class="row">
            <div class="col-2">
              <p class="chevron-text">1: {{ regTranslations['chevron-info'][1] }}</p>
            </div>
            <div class="col-2">
              <p class="chevron-text">2: {{ regTranslations['chevron-info'][2] }}</p>
            </div>
            <div class="col-2">
              <p class="chevron-text">3: {{ regTranslations['chevron-info'][3] }}</p>
            </div>
            <div class="col-2">
              <p class="chevron-text">4: {{ regTranslations['chevron-info'][4] }}</p>
            </div>
          </div>
        </div>

      </div>

      <div class='row'>
        <div class='register-box'>
          <p class='header-text text-center'>{{ regTranslations['box-title'] }}</p>
          <form class='inputs'>
<!--            <SearchKvK/>-->
            <input
              id='first-name'
              placeholder='First name'
              autocomplete='given-name'
              v-model='firstName'
            />
            <input
              id='last-name'
              placeholder='Second name'
              autocomplete='family-name'
              v-model='secondName'
            />
            <input
              type='email'
              id='email'
              autocomplete='email'
              placeholder='Email address '
              v-model='email'
            />
            <div class='input-eye'>
              <ShowHidePassword field="pass"/>
              <input
                id='pass'
                class='pass'
                type='password'
                placeholder='Password'
                autocomplete='new-password'
                v-model= 'password'
                ref="pass"
              />
            </div>
            <PasswordValidator :password="password"
             :confirmPassword="confirmPassword" ref="passRule"/>
            <div class='password-error-icon'>
              <div class='input-eye'>
                <div class='confirm'>
                  <ShowHidePassword field="confirmPass"/>
                  <input
                    name='password'
                    placeholder='Confirm Password'
                    type='password'
                    v-model='confirmPassword'
                    autocomplete='new-password'
                    :class='{ error: isError }'
                    ref="confirmPass"
                  />
                </div>
                <div v-if='isError' class='confirm'>
                  <img src='/assets/warning.svg' />
                  <p>{{ regTranslations.confirm }}</p>
                </div>
              </div>
            </div>

            <gaInput gaid='gaid453' @data="onData($event)"/>
            
            <div v-show='errorMessage'>
              <p class='error-message'>{{ errorMessage }}</p>
            </div>

            <button
              class='sign-up'
              @click='register()'
              type='button'
              :disabled='!signupAllow'
              :style="{ cursor: signupAllow ? 'pointer' : 'not-allowed' }"
            >
              {{ regTranslations['register-button'] }}
            </button>
          </form>
        </div>
        <div class='information-box'>
          <div class='grid' v-for="(card, i) of regTranslations.info" :key="i">
            <img src='/assets/logo-no-text.svg' />
            <p class='text'>{{ card.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <Footer class='mt-5' />
  </div>    
</template>
<script>
import langTranslationEn from '@content/en/general/navigation.yaml'
import langTranslationNl from '@content/nl/general/navigation.yaml'
import registrationTranslationEn from '@content/en/developer/registration.yaml'
import registrationTranslationNl from '@content/nl/developer/registration.yaml'
import Footer from '@components/Footer';
import PasswordValidator from '@components/inputs/types/dynamic/password-validator';
import gaInput from '@components/inputs/ga-input';
import axios from '@/axios';
import ShowHidePassword from './ShowHidePassword.vue';
// import SearchKvK from './SearchKvK.vue'

export default {
  components: { Footer, gaInput, PasswordValidator, ShowHidePassword },
  data () {
    return {
      lang: '',
      password: '',
      showPassword: false,
      showConfirmPassword: false,
      active: false,
      checkbox: false,
      email: '',
      kvk: '',
      companyName:'',
      firstName: '',
      secondName: '',
      confirmPassword: '',
      errorMessage: '',
      isError: false,
      language: {},
      regTranslations: {},
      requirements: {},
    };
  },
  computed: {
    currentLang () {
      return this.$route.path.substr(1, 2);
    },
    currentPage () {
      return this.$route.path;
    },
    signupAllow () {
      if (!this.email || !this.password || !this.confirmPassword ||
        this.checkbox === false)
        return false;

      return this.$refs.passRule.isValid();
    },
  },
  created () {
    this.init();
  },
  watch: {
    $route () {
      this.init();
    },
  },
  methods: {
    init () {
      this.lang = this.$route.params.lang;
      this.language = this.lang === "en" ? langTranslationEn : langTranslationNl;
      this.regTranslations = this.lang === "en" ? registrationTranslationEn : registrationTranslationNl;
    },
    onData (ev) {
      this.checkbox = ev.gaid453
    },
    async register () {
      const credentials = {
        email: this.email,
        password: this.password,
        userType: 1,
        answers: {
          gaid273: this.firstName,
          gaid274: this.secondName,
          // gaid361: this.kvk,
          // gaid241: this.companyName
        }
      };
      axios
        .post('/.netlify/functions/registerQuery', {
          ...credentials,
          ...this.data
        })
        .then(({ data }) => {
          this.doLogin(data);
        });
    },
    doLogin (data) {
      if ('error' in data) {
        this.errorMessage = data.error;
      } else {
        localStorage.token = data.token;
        localStorage.loggedUser = JSON.stringify(data);

        this.$emit('close', localStorage.token);
        this.$router.push(`/${localStorage.lang}/project-developer/confirmation`);
      }
    },
    getURL (prevLanguage) {
      return this.currentPage.replace(
        prevLanguage,
        prevLanguage === 'nl' ? 'en' : 'nl'
      );
    }
  }
};
</script>
<style lang='scss' scoped>
@import "@styles/authorization-project-dev.scss";
</style>

