<template>
  <MainPage />
</template>

<script>
import MainPage from './MainPage.vue';
import { getUserTypeRoute } from '@src/helpers/routes.helper.js';

export default {
  name: 'App',
  data () {
    return {};
  },

  computed: {
    userType () {
      return this.$store.state.user.userType;
    }
  },

  components: {
    MainPage
  },

  mounted () {
    if (!this.$router.history.current.params.lang)
      this.$router.push('nl');

    this.changeUserTypeByRoute();

    this.$store.subscribe(this.onStoreChange);
  },

  methods: {
    onStoreChange (mutation) {
      switch (mutation.type) {
        case 'user/setType':
          this.changeRouteByUserType();
          break;
      }
    },

    changeRouteByUserType () {
      const userTypeRoute = getUserTypeRoute(this.userType === 2 ? 0 : this.userType);
      if (this.$router.history.current.params.userType === userTypeRoute) return;

      this.$router.replace({
        name: 'Libero',
        params: {
          userType: userTypeRoute,
          loginType: this.$router.history.current.params.loginType
        }
      });
    },

    changeUserTypeByRoute () {
      const userTypeRoute = this.$router.history.current.params.userType;

      const userType = [0, 1, 2].find(value => getUserTypeRoute(value) === userTypeRoute);
      if (userType) this.setUserType(userType);
      else this.changeRouteByUserType();
    },

    setUserType (userType) {
      this.$store.commit('user/setType', userType);
    }
  }
};
</script>
