<template>
  <div :id='mapName' class='leafmap'>
  </div>
</template>

<script>
import L, { LatLng } from 'leaflet';
import '../pattern/leaflet.pattern';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import * as turf from '@turf/turf';
import { MapLayer } from '@common/data/enums/MapLayer.js';
import { Voltage } from '@common/data/enums/grid';
import RequestHelper from '@common/helpers/request.helper.js';
import 'leaflet-measure-path';
import 'leaflet-measure-path/leaflet-measure-path';
import ids from '@common/ids.yaml';
import mapPatterns from '@common/maps/patterns.yaml';
import FreeDraw, { CREATE, NONE } from 'leaflet-freedraw';
import '../leaflet-arrowheads';
import '../leaflet-ruler/leaflet-ruler';
import '../leaflet-ruler/leaflet-ruler.css';
import { EventBus } from '@/event-bus';
// import Sketch from '../vue-color/components/Sketch.vue';

export default {
  name: 'Map',
  components: {
    // 'color-picker': Sketch
  },
  async mounted () {
    this.createMap();
    await this.loadMapLayersConfig();
    this.$store.subscribe((mutation) => {
      if (!this.map) return;
      switch (mutation.type) {
        case 'map/updateMapName':
          this.map.invalidateSize();
          break;
        case 'map/resetMap':
          this.getAndRenderPolygons();
          break;
        case 'map/updateSelectedPolygonStep':
          this.popupMenu(undefined, mutation.payload.polygonId);
          break;
      }
    });
    this.handleLayers(this.showLayers);
  },

  props: {
    mapName: String,
    projectDevId: String,
    isSatelliteView: { type: Boolean },
    showLayers: { type: Object },
    testSearch: { type: String },
    selectedAddress: { type: Object },
    colorPickerColors: { type: Object },
    renderToolsPopup: {type: Boolean}
  },
  data () {
    return {
      ids: ids,
      // urlGrayView: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      // urlSatelite: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      urlGrayView: 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      urlSatelite: 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
      //TODO: add copyright attributtes for google
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      attribution2: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
      fillPatterns: {},
      customControlsCreated: false,
      maxNativeZoom: 21,
      maxZoom: 23,
      measurementsEnabled: true,
      //todo this should be in a separate configuration
      mapLayers: {},
      renderedMapLayers: {},

      isPolygonClicked: false,

      /**
       * Used to cancel axios requests for grid data
       */
      gridCancelTokenSource: undefined,
      requestHelper: new RequestHelper(this.$router),
      popup: L.popup({
        closeButton: false
      }),
      siteName: undefined,
      projectName: undefined,
      siteSize: undefined,
      user: JSON.parse(localStorage.loggedUser || '{}'),
      freeDraw: null,
      freeFormDrawEnabled: false,
      sketches: {},
      removeModeToggled: false,
      editModeToggled: false,
      ruler: undefined,
      toggleRulerMeasurementEnabled: false,
      showColor: false,
      defaultColor: "#28a745",
      isSiteModalEnabled: true,
      cadastreLastCoordinates: ""
    };
  },
  computed: {
    map () {
      return this.$store.state.map.instances[this.mapName];
    },
    userSites () {
      return this.$store.state.siteOwner.userSites;
    },
    parents () {
      return this.$store.state.inputs.parents;
    },
    finishedUpdate () {
      return this.$store.state.inputs.finishedUpdate;
    },
    selectedProjectId () {
      return this.$store.state.siteOwner.selectedProjectId;
    },
    selectedSiteId () {
      return this.$store.state.siteOwner.selectedSiteId;
    }
  },

  created () {
    this.zoom = this.user.zoomLevel || 10;
    this.center = this.user.mapCoordinates || [52.3628434, 4.8443875];
    this.$store.subscribe(this.onStoreChange);
  },

  methods: {
    onStoreChange (mutation) {
      switch (mutation.type) {
        case 'inputs/updateParentAnswers':
          const siteNameChange = mutation.payload?.newAnswers?.gaid185;
          const siteSizeChange = mutation.payload?.newAnswers?.gaid39;
          const projectNameChange = mutation.payload?.newAnswers?.gaid230;
          const calculatedSiteSize =
            this.parents[this.selectedSiteId]?.answers?.gaid291;

          if (siteNameChange) this.siteName = siteNameChange;
          if (projectNameChange) this.projectName = projectNameChange;
          this.siteSize = siteSizeChange || calculatedSiteSize;
        case 'siteOwner/updateMarkerTooltip':
          if (mutation.payload?.sketchId && mutation.payload?.title) {
            const marker = Object.values(this.map._layers).find(e => e.options.sketchId == mutation.payload?.sketchId);
            
            if (marker) this.toggleTooltipForMarker(marker, mutation.payload?.title, mutation.payload?.text);
          }

      }
    },
    setSitePopupContent () {
      if (!this.popup || !this.$parent.$refs.boxes) return;
      this.popup.setContent(this.$parent.$refs.boxes.$el);
    },

    async loadMapLayersConfig() {
      const obejct = await this.requestHelper.getQuery(
        `mapLayerQuery`
      );
      this.mapLayers = obejct.data

      for(const key of Object.keys(this.mapLayers)) {
        this.mapLayers[key]["displayedLayers"] = [];
      }
    },

    createMarkerPopup () {
      const markerPopup = L.popup({
        closeButton: false
      });

      if (!this.$parent.$refs.markerModal) return markerPopup;
      markerPopup.setContent(this.$parent.$refs.markerModal.$el);
      
      return markerPopup;

    },
    createMap () {
      try {
        const instance = L.map(this.mapName, {
          center: this.center,
          zoom: this.zoom,
          zoomControl: false,
          tap: false
        });
        this.$store.commit('map/initInstance', {
          instance,
          mapName: this.mapName
        });
        this.freeDraw = new FreeDraw({
          mode: NONE,
          mergePolygons: false
        });

      } catch (e) {
        return;
      }

      if (
        this.mapName.includes('project') ||
        this.mapName.includes('regTech')
      ) {
        this.map.doubleClickZoom.disable();
        this.map.keyboard.disable();
        this.map.dragging.disable();
        this.map.scrollWheelZoom.disable();
        this.map.zoomControl?.disable();
      }
      if (this.mapName === 'map' && this.map) {
        // this.generatePatterns(); // Disabled due depricated dependency leaflet.pattern https://github.com/teastman/Leaflet.pattern
        this.addDrawControls();
        this.map.on('moveend', (e) => {
          if (this.map) {
            this.addDrawControls();
            this.triggerZoomAndCoordinates(e);
            this.tryRerenderActiveLayers();
            this.getGridAndDisplay();

            if(this.measurementsEnabled)this.enableMeasurements();
            else this.disableMeasurements();
          
          }
        });
        L.control.scale().addTo(this.map);
        L.control.zoom({ position: 'bottomleft' }).addTo(this.map);
      }

      this.map.on('pm:create', this.addSite);

      this.map.on('pm:remove', async (e) => {
        if (e.shape.toLowerCase() !== 'polygon')
          await this.requestHelper.deleteQuery(`sketches?sketchId=${e.layer.options.sketchId}&projectId=${e.layer.options.projectId}`);
      });

      this.grayView = L.tileLayer(this.mapName.startsWith('project') ?
        this.urlSatelite : this.urlGrayView, {
        attribution: this.attribution,
        maxNativeZoom: this.maxNativeZoom,
        maxZoom: this.maxZoom,
        minZoom: 3,
        noWrap: true
      });
      this.satelliteView = L.tileLayer(this.urlSatelite, {
        attribution: this.attribution2,
        maxNativeZoom: this.maxNativeZoom,
        maxZoom: this.maxZoom,
        minZoom: 3,
        noWrap: true
      });

      const bounds = L.latLngBounds([[85, 180], [-85, -180]]);

      this.map.setMaxBounds(bounds);
      this.map.on('drag', () => {
        this.map.panInsideBounds(bounds, { animate: false });
      });

      this.satelliteView.addTo(this.map);
      this.getAndRenderPolygons();
  
    },

    getMunicipality () {
      if (!this.showLayers[MapLayer.Municipalities]) return;

      const lat = this.map.getCenter().lat;
      const lon = this.map.getCenter().lng;

      this.requestHelper.getQuery(
        `municipalityQuery?lat=${lat}&lon=${lon}`,
        (response) => {
          this.$emit('MunicipalityData', response.data[0]);
        }
      );
    },

    async getSegmentedMapLayer (layer) {
      const bounds = this.map.getBounds();

      const NORTH = bounds._northEast.lat;
      const EAST = bounds._northEast.lng;
      const SOUTH = bounds._southWest.lat;
      const WEST = bounds._southWest.lng;

      const queryName = 'getLayerSegmentDataByType';
      let type = layer;

      const response = await this.requestHelper.getQuery(
        `${queryName}?north=${NORTH}&east=${EAST}&south=${SOUTH}&west=${WEST}&type=${type}`
      );

      this.mapLayers[layer].displayedLayers = response?.data;
    },

    getReducedRadiusMapLayer (layer) {
      if (!this.showLayers[layer]) return;

      const bounds = this.map.getBounds();
      const NORTH = bounds._northEast.lat;
      const EAST = bounds._northEast.lng;
      const SOUTH = bounds._southWest.lat;
      const WEST = bounds._southWest.lng;

      let queryName;
      let type = '';
      // NOTE: this will be added to the end of the query,
      // so it should have the format &param=VALUE
      let datasetParam = '';
      let queryBody = {};

      let compareFn;

      switch (layer) {
        case MapLayer.Cadasters:
          queryName = 'cadastreQuery';
          break;

        case MapLayer.GridCongestion:
          queryName = 'getLayerDataByType';
          type = MapLayer.GridCongestion;
          break;

        case MapLayer.RestrictedAreas:
          queryName = 'restrictedSegmentQuery';
          compareFn = (i, j) => {
            const m = (x) => {
              switch (x) {
                case 'cultural':
                  return 0;
                case 'nnn':
                  return 1;
                case 'nature2000':
                  return 2;
                case 'park':
                  return 3;
                default:
                  return -10;
              }
            };
            const t1 = m(i.properties.type);
            const t2 = m(j.properties.type);
            return t1 - t2;
          };
          // NOTE: this is not the most efficient. A more efficient way
          // would be to save this ids in a separete layer in this component
          queryBody = this.mapLayers[layer].displayedLayers.map((i) => i._id);
          break;

        case MapLayer.SearchAreas:
          queryName = 'searchAreaQuery';
          break;

        case MapLayer.EnergyProjects:
          queryName = 'getLayerDataByType';
          type = MapLayer.EnergyProjects;
          break;

        default:
          throw Error(`Invalid ReducedRadiusMapLayer type: ${layer}`);
      }

      if (layer == MapLayer.Cadasters) {
        if (this.map._zoom <= 18 || `${NORTH},${EAST},${SOUTH},${WEST}` == this.cadastreLastCoordinates) return;
        this.cadastreLastCoordinates = `${NORTH},${EAST},${SOUTH},${WEST}`;

        this.requestHelper.postQuery(
        `${queryName}?north=${NORTH}&east=${EAST}&south=${SOUTH}&west=${WEST}&type=${type}${datasetParam}`,
        queryBody,
        (response) => {

          if (response.data.length === 0) return;

          const layers = response.data.filter(
            (currentLayer) =>
              this.mapLayers[layer].displayedLayers.findIndex(
                (search) => search.id === currentLayer.id
              ) === -1
          );

          // Append all received layers to existing array
          this.mapLayers[layer].displayedLayers = this.mapLayers[layer].displayedLayers.concat(layers);

          // Display layers
          if (this.showLayers[layer]) this.displayReducedRadiusMapLayer(layer);
          

        })
      } else {
      this.requestHelper.postQuery(
        `${queryName}?north=${NORTH}&east=${EAST}&south=${SOUTH}&west=${WEST}&type=${type}${datasetParam}`,
        queryBody,
        (response) => {

          if (response.data.length === 0) return;

          const layers = response.data.filter(
            (currentLayer) =>
              this.mapLayers[layer].displayedLayers.findIndex(
                (search) => search._id === currentLayer._id
              ) === -1
          );

          // Append all received layers to existing array
          this.mapLayers[layer].displayedLayers = [...this.mapLayers[layer].displayedLayers, ...layers];

          if (compareFn) this.mapLayers[layer].displayedLayers.sort(compareFn);

          // Display layers
          if (this.showLayers[layer]) this.displayReducedRadiusMapLayer(layer);
        }
      );
      }
    },

    getGridAndDisplay () {
      if (!this.showLayers[MapLayer.Grid]) return;

      const bounds = this.map.getBounds();

      const NORTH = bounds._northEast.lat;
      const EAST = bounds._northEast.lng;
      const SOUTH = bounds._southWest.lat;
      const WEST = bounds._southWest.lng;

      // Cancel all previous requests for grid data
      this.gridCancelTokenSource?.cancel();
      // Create new cancel token
      this.gridCancelTokenSource = require('axios').CancelToken.source();

      this.getGridAndAddToMap(NORTH, EAST, SOUTH, WEST);
    },

    /**
     * Gets a grid (for a given company & dataset) asynchronously, so that we can request
     * them all at the same time, as opposed to one by one.
     */
    async getGridAndAddToMap (NORTH, EAST, SOUTH, WEST) {
      const response = await this.requestHelper.getQuery(
        `gridQuery?north=${NORTH}&east=${EAST}&south=${SOUTH}&west=${WEST}`,
        undefined,
        undefined,
        this.gridCancelTokenSource.token
      );

      if (!response) return;

      this.mapLayers[MapLayer.Grid].displayedLayers = [];

      //todo this should work the same way as the other layers
      // Additional "showLayer" check, since this can be toggled off while we're awaiting the response.
      if (this.showLayers[MapLayer.Grid])
        for (let gridItem of response.data) {
          const dataset = gridItem.dataset;
          const provider = gridItem.company;
          const color = this.getGridItemColor(dataset);
          const source = this.mapLayers[MapLayer.Grid].sources.find(s => s.type == provider.toLowerCase());
          // Initial options & styles
          let gridItemOptions = {
            pmIgnore: true,
            style: { color },
            [`isOf${MapLayer.Grid}`]: true,
            db_id: gridItem._id
          };

          // Convert pins to vectors
          if (gridItem.geometry.type === 'Point')
            gridItemOptions['pointToLayer'] = function(feature, latlng) {
                return L.circleMarker(latlng, {
                  radius: 8,
                  fillColor: color,
                  color: color
                });
              };

          // Init GeoJSON with above options
          let gridItemGeoJson = L.geoJSON(gridItem, gridItemOptions);

          const v = this.getValueByKeyContains(
            gridItem.properties,
            'SPANNINGSNIVEAU'
          );

          const ob = {
            'Type': this.getGridItemName(dataset),
            'Provider': provider,
            'Voltage': v ? v + 'kV' : v,
            'Operational status': this.getValueByKeyContains(gridItem.properties, 'BEDRIJFSSTATUS')
          };

          // Bind popup with details
          //todo this should be in the other place
          gridItemGeoJson.bindPopup(`<div class='layer-popup'>
              <p style='color: #d84949; font-size:14px; text-align:left; margin-top: -8px;'>Grid</p>
              ${this.createTable(ob)}
            </div><a target='_blank' href='${source.url}'><p style='font-size: 14px;color: #2783FF;font-weight: normal; margin: 0'>
            View source: ${source.title} <img class='external-link' src='/assets/external-link.svg'/>
            </p></a><span style=' font-size:12px;  color:#505158; font-weight: 300; display: flex;
            justify-content: center;padding-bottom: 1px;'>Last update: ${new Date(this.mapLayers[MapLayer.Grid].lastEditDate).toLocaleDateString("en-NL")}</span>`);

          const existingGridItem = Object.values(this.map._layers).find(
            (layer) => layer.options?.db_id === gridItemGeoJson.options.db_id
          );

          // Add to map only if it doesn't exist already
          if (!existingGridItem) gridItemGeoJson.addTo(this.map);

        }
    },

    getValueByKeyContains (obj, key) {
      if (obj)
        for (let oKey in obj)
          if (oKey.includes(key))
            return obj[oKey];
      return undefined;
    },

    getGridItemColor (dataset) {
      const c = voltage => dataset.includes(Voltage[voltage]);

      if (c('HV_MV')) return '#ff9966';
      if (c('MV_LV')) return '#9933ff';
      if (c('HV')) return '#ff3300';
      if (c('MV') || c('IV')) return '#ff00ff';
      if (c('LV')) return '#0099ff';

      return '#ffffff';
    },

    getGridItemName (dataset) {
      const c = (voltage) => dataset.includes(Voltage[voltage]);

      if (c('HV_MV')) return 'High Voltage<br>Medium Voltage';
      if (c('MV_LV')) return 'Medium Voltage<br>Low Voltage';
      if (c('HV')) return 'High Voltage';
      if (c('MV') || c('IV')) return 'Medium Voltage';
      if (c('LV')) return 'Low Voltage';

      return 'Unknown';
    },

    // TODO: (ii) Move texts in a translations file
    getGridItemVoltageAndType (dataset) {
      let voltage = 'Unknown';
      let type = 'Unknown';

      const c = (voltage) => dataset.includes(Voltage[voltage]);

      if (c('HV_MV')) voltage = 'High-Medium';
      else if (c('MV_LV')) voltage = 'Medium-Low';
      else if (c('HV')) voltage = 'High';
      else if (c('MV')) voltage = 'Medium';
      else if (c('IV')) voltage = 'Intermediate';
      else if (c('LV')) voltage = 'Low';

      type = dataset.replace(/[^a-z]/g, '');

      return `${voltage} Voltage ${type}`;
    },

    async addSite (newSite) {
      if (newSite.shape.toLowerCase() != 'polygon') {
        this.addSketch(newSite.shape, newSite);
      } else {
        

        const type = newSite.shape.toLowerCase();
        const coordinates = newSite.layer._latlngs?.map((cords) =>
          cords.map((coordinate) => [coordinate.lat, coordinate.lng])
        );

        newSite.layer.setStyle({
          color: this.colorPickerColors.hex
        });

        this.getSiteBorders(newSite.layer);

        this.$store.commit('map/drawSite');
        if (!!localStorage.token && this.user.userType === 0) {
          this.requestHelper.postQuery(
            'sitesQuery',
            { coordinates, type },
            (response) => {
              const shapeId = response.data._id;

              newSite.layer.options.id = shapeId;

              this.showSite(newSite);

              this.siteSize = this.parents[shapeId].answers.gaid291 = turf
                .area(newSite.layer.toGeoJSON())
                .toFixed(2);
        
              this.$store.commit('inputs/updateParentAnswers', {
                parentId: shapeId,
                newAnswers:  { 'gaid291': this.parents[shapeId].answers.gaid291,
                               'gaid548': turf.area(newSite.layer.toGeoJSON()).toFixed(2) }
              });

              const siteBounds = newSite.layer.pm._layer._bounds;

              const NORTH = siteBounds._northEast.lat;
              const EAST = siteBounds._northEast.lng;
              const SOUTH = siteBounds._southWest.lat;
              const WEST = siteBounds._southWest.lng;

              this.requestHelper.getQuery(
                `nearestGrid?north=${NORTH}&east=${EAST}&south=${SOUTH}&west=${WEST}`,
                (res) => {
                  if (res.data.length > 0) {
                    if (!this.parents[shapeId] || !this.parents[shapeId].answers) this.parents[shapeId] = { answers: {} };

                    this.parents[shapeId].answers.gaid429 =
                      res.data[0].distance;
                    this.parents[shapeId].answers.gaid430 = res.data[0].company;
                    this.$store.commit('inputs/updateParentAnswers', {
                      parentId: shapeId,
                      newAnswers: {
                        ...this.parents[shapeId].answers
                      }
                    });
                  }
                },
                undefined
              );

              this.requestHelper.putQuery('sitesQuery', {
                id: newSite.layer.options.id,
                answers: this.parents[newSite.layer.options.id]?.answers,
                color: this.colorPickerColors.hex || this.defaultColor
              });
            }
          );
        } else {
          // create a temp id as the date timestamp for the site so we can work with it
          newSite.layer.options.id = `s-${Date.now()}`;
          this.showSite(newSite);
        }
      
        //  }
      }
      this.showMeasurements(newSite.layer);
    },

    addSketch (shape, newSite) {
      if (!this.selectedProjectId || !localStorage.token) {
        this.map.removeLayer(newSite.layer);
        return;
      }

      switch (shape.toLowerCase()) {
        case 'line':
          newSite.marker.setStyle({
            color: this.colorPickerColors.hex
          });
          let arrow = this.map._layers[newSite.layer._leaflet_id];
          this.map.removeLayer(arrow);
          this.map.addLayer(arrow.arrowheads());

          arrow.addTo(this.map);

          this.saveSketchShape(newSite, 'arrow', arrow._latlngs);
          break;
        case 'circle':
          newSite.marker.setStyle({
            color: this.colorPickerColors.hex
          });
          this.saveSketchShape(newSite, 'circle', newSite.layer._latlng, newSite.layer.options);
          break;
        case 'marker':
          this.saveSketchShape(newSite, 'marker', newSite.layer._latlng, {}, "", "");
          break;
      }
      this.bindMarkerPopup(newSite.marker);

    },

    changeSiteColor(e) {
      if(this.showColor) {
        e.target.setStyle({
          color: this.colorPickerColors.hex
        });
        
        if (localStorage.token) {
          this.requestHelper.putQuery('sitesQuery', {
            id: e.target.options.id,
            color: this.colorPickerColors.hex
          });
        }
      }
    },

    showSite (newSite) {
      this.attachEventListenersToPolygons(newSite.layer);
      newSite.layer.on('click', (e) => {
        this.changeSiteColor(e);
        this.popupMenu(e, newSite.layer.options.id);
        this.$store.commit(
          'siteOwner/setSiteSelected',
          newSite.layer.options.id
        );
      });
      this.$emit('onNewPolygon', newSite);
      this.popupMenu(undefined, newSite.layer.options.id);
    },

    bindMarkerPopup (e) {
      e.addEventListener("click", () => {
        this.$store.commit("siteOwner/setSelectedSketch", e.options);
        if (e.options.type == "freeDraw") { 
          let popup = this.createMarkerPopup();
          popup.setLatLng(new LatLng(e.getCenter().lat, e.getCenter().lng)).openOn(this.map);
        }
      })

      if (e.options.type != "freeDraw") {
        let popup = this.createMarkerPopup();
        e.bindPopup(popup);
    }
    },

    addDrawControls () { 
      this.map.pm.addControls({
        position: 'topleft',
        drawCircle: true,
        dragMode: false,
        drawPolyline: false,
        drawRectangle: false,
        rotateMode: false,
        drawCircleMarker: false
      });
      EventBus.$once('gifOpened', (openGif) => {
        if(openGif && !this.map.pm.Toolbar.isVisible) this.map.pm.toggleControls()
      });
      if(this.map.pm.Toolbar.isVisible && !this.renderToolsPopup) this.map.pm.toggleControls();

      if (this.customControlsCreated) return;
      this.customControlsCreated = true;

      this.map.pm.Toolbar.createCustomControl({
        name: 'Color picker',
        title: 'Toggle color picker',
        block: 'custom',
        toggle: false,
        className: 'pm-color-picker-icon',
        onClick: () => { this.showColor = !this.showColor; this.$emit('showColorPicker', this.showColor); }
      });

      this.map.pm.Toolbar.createCustomControl({
        name: 'Measurements',
        title: 'Toggle Measurements',
        block: 'custom',
        toggle: false,
        className: 'pm-measurements-icon',
        onClick: this.toggleMeasurements
      });

      this.ruler = L.control.ruler()

      this.ruler.addTo(this.map)

      this.map.addLayer(this.freeDraw);
      this.map.pm.Toolbar.createCustomControl({
        name: 'Free form drawing',
        title: 'Toggle free form drawing',
        block: 'custom',
        disabled: !localStorage.token,
        toggle: true,
        className: 'pm-draw-icon',
        onClick: this.enterFreeFormDrawMode
      });

      this.map.pm.Toolbar.buttons["drawMarker"]._button["disabled"] = !localStorage.token;
      this.map.pm.Toolbar.buttons["drawCircle"]._button["disabled"] = !localStorage.token;

      this.map.pm.Toolbar.createCustomControl({
        name: 'Ruler',
        title: 'Toggle ruler',
        block: 'custom',
        toggle: true,
        className: 'measurements-ruler-icon',
        onClick: this.toggleRulerMeasurements
      });

      this.map.on('pm:globalremovalmodetoggled', () => {
        this.removeModeToggled = !this.removeModeToggled;
      });

      this.map.on("pm:globalcutmodetoggled", () => {
        this.isSiteModalEnabled = !this.isSiteModalEnabled;
        this.map.closePopup();
      })

      this.map.pm.Toolbar.createCustomControl({
        name: 'Draw arrow',
        disabled: !localStorage.token,
        title: 'Toggle draw arrow',
        block: 'custom',
        toggle: false,
        className: 'pm-draw-arrow-icon',
        onClick: () => { 
          this.map.pm.enableDraw('Line', { finishOn: 'click' }); }
      });
    },
    async saveSketchShape (shape, type, coordinates, options = {}, title, text) {

      this.requestHelper.postQuery(
        'sketches', {
          projectId: this.selectedProjectId,
          color: this.colorPickerColors.hex || options.color || this.defaultColor,
          type: type,
          coordinates: coordinates,
          title,
          text,
          options
        },
        (response) => {
          this.$store.commit('inputs/addSketchToParent', response.data);
          shape.layer.options.sketchId = response.data.sketchId;
          shape.layer.options.projectId = response.data.projectId;
          shape.layer.options.type = response.data.type;
          //todo do we need activate the listeners for all shapes every time a new one is created?
          //todo can't we just attach the listener to the new one?
          //mk: the attching of listeners checks if the update listener already exists.
          this.listenForEditSketch();
        }
      );
    },

    async updateSketchShape (sketchId, projectId, coordinates, options = null) {
      await this.requestHelper.putQuery(
        `sketches?sketchId=${sketchId}&projectId=${projectId}`,
        {
          color: this.colorPickerColors.hex || options.color || this.defaultColor,
          coordinates: coordinates,
          options
        });
    },

    renderSketches () {
      for (let key of Object.keys(this.parents)) {
        let parent = this.parents[key];
        if (!(parent.objType === 'project' && parent.sketches)) continue;

        let freeDrawIndex = 0;
        for (let sketch of parent.sketches) {
          if (!sketch.coordinates) continue;

          switch (sketch.type) {
            case 'freeDraw':
              try {
              this.sketches[JSON.stringify(sketch.coordinates)] = { sketchId: sketch._id, projectId: key };
              this.freeDraw.create(sketch.coordinates);
              const id = `freeDraw${freeDrawIndex + 1}`;
              this.freeDraw.all()[freeDrawIndex]._path.id = id;

              document.getElementById(id).style.fill = sketch.color;
              document.getElementById(id).style.stroke = sketch.color;
              document.getElementById(id).style['fill-opacity'] = 0.5;

              this.freeDraw.all()[freeDrawIndex].options['sketchId'] = sketch._id;
              this.freeDraw.all()[freeDrawIndex].options['projectId'] = key;
              this.freeDraw.all()[freeDrawIndex].options['type'] = sketch.type;

              const layer = Object.values(this.map._layers).find(l => l.options.sketchId == sketch._id);
              this.bindMarkerPopup(layer);
              this.toggleTooltipForMarker(layer, sketch.title, sketch.text);
              
              freeDrawIndex++;

              }catch(e) { /* ignore */ }
              break;

            case 'arrow':
              this.createArrow(sketch.coordinates, { color: sketch.color, sketchId: sketch._id, projectId: key, type: sketch.type }, sketch);
              break;

            case 'circle':
              const circle = L.circle(sketch.coordinates, {
                ...sketch.options,
                sketchId: sketch._id,
                projectId: key,
                type: sketch.type,
                color: sketch.color
              }).addTo(this.map);

              this.bindMarkerPopup(circle);
              this.toggleTooltipForMarker(circle, sketch.title, sketch.text)
              break;

            case 'marker':
              const marker = L.marker(sketch.coordinates, { sketchId: sketch._id, projectId: key, text: sketch.text, title: sketch.title, type: sketch.type }).addTo(this.map);
              this.bindMarkerPopup(marker);
              this.toggleTooltipForMarker(marker, sketch.title, sketch.text)
              break;

          }
        }
      }
      this.listenForDeleteSketch();
      this.listenForEditSketch();
    },

    toggleTooltipForMarker(marker, title, text) {
      if (marker) {
        if (marker._tooltip || marker.options["tooltip"]) {
          if (marker.options.type == "freeDraw") {
            marker.options["tooltip"].setContent(`${title || ""}`);
          } else {
            marker._tooltip.setContent(`${title || ""}`);
          }
        } else {
          if (title || text) { 
          if (marker.options.type == "freeDraw") {
            marker.options["tooltip"] = L.tooltip({ permanent: true, direction: 'right' }).setContent(`${title || ""}`).setLatLng(new LatLng(marker.getCenter().lat, marker.getCenter().lng));
            marker.options["tooltip"].addTo(this.map);
          } else {
            marker.bindTooltip(`${title || ""}`, 
              {
                permanent: true, 
                direction: 'right'
              }
            );    
          }
          }
        }
      }
    },

    createArrow (coordinates, options, sketch) {
      let arrow = L.polyline(coordinates, options);
      this.map.addLayer(arrow.arrowheads());
      arrow.addTo(this.map);
      this.bindMarkerPopup(arrow);
      this.toggleTooltipForMarker(arrow, sketch.title, sketch.text)
    },

    listenForDeleteSketch () {
      for (let e of this.freeDraw.all()) {
        if (e.options.hasDeleteEvent) continue;
        e.options.hasDeleteEvent = true;
        e.addEventListener('click', e => {
          if (this.removeModeToggled) {
            this.requestHelper.deleteQuery(`sketches?sketchId=${e.target.options.sketchId}&projectId=${e.target.options.projectId}`, () => {
              this.freeDraw.remove(e.target);
            });
          }
        });
      }
    },
    listenForEditSketch () {
      Object.values(this.map._layers).forEach(l => {
        if (!l._events || !l._events['pm:update'] || l._events['pm:update'].length < 1) {
          l.addEventListener("click", async () => {
            if (this.showColor) {
              if (l.options.type == "freeDraw") {
                document.getElementById(l._path.id).style.fill = this.colorPickerColors.hex;
                document.getElementById(l._path.id).style.stroke = this.colorPickerColors.hex;
                document.getElementById(l._path.id).style['fill-opacity'] = 0.5;
              } else if(l.options.type && l.options.type != "marker") {
                if (l.options.type == "arrow") {
                  l.options.color = this.colorPickerColors.hex;
                  this.map.removeLayer(l);

                  let arrow = L.polyline(l._latlngs, l.options);
                  this.map.addLayer(arrow.arrowheads());
                  arrow.addTo(this.map);
                  arrow._events = l._events;

                  this.bindMarkerPopup(arrow);
                  this.toggleTooltipForMarker(arrow, l.options.title, l.options.text);

                } else {
                  l.setStyle({
                    color: this.colorPickerColors.hex
                  });
                }
              }

              if (l.options.type && l.options.type != "marker") {
                await this.updateSketchShape(l.options.sketchId, l.options.projectId, null, null)
              }
            }
          })
          l.on("pm:enable", () => {
            this.editModeToggled = true;
          });

          //todo this could be added to the other pm:update function
          l.addEventListener('pm:update', async (e) => {
            if (e.shape.toLowerCase() != 'polygon') {
              if (e.shape.toLowerCase() == 'circle') e.target.options.radius = e.target._mRadius;
              await this.updateSketchShape(e.layer.options.sketchId, e.layer.options.projectId, e.target._latlng, e.target.options,  e.target._tooltip ? e.target._tooltip._content : undefined);
              this.editModeToggled = false;
            }
          });
        }
      });
    },
    getAndRenderPolygons () {
      if (this.mapName === 'map' && localStorage.token) {
        this.requestHelper.getQuery('sitesQuery', response => {
          const sites = response.data;

          this.$store.subscribe((mutation) => {
            if (!this.map) return;
            switch (mutation.type) {
              case 'inputs/finishUpdateParent':
                this.setupSites(sites);
                this.renderSketches();
                break;
            }
          });

          if (this.finishedUpdate) {
            this.setupSites(sites);
            this.renderSketches();
          }
        });
      }
    },

    setupSites (sites) {
      let lastSite;
      let polygons = [];

      for (let site of sites) {
        if (site.type === 'polygon') {
          lastSite = L.polygon(site.coordinates, {
            color: site.color || '#3388ff',
            //removes 'magnet' to the sides of the sites
            snapIgnore: true
          });

          lastSite.options.currentStepId = site.currentStepId || 'qgaid1';
          lastSite.options.id = site._id;
          this.attachEventListenersToPolygons(lastSite);
          lastSite.on('click', (e) => {
            this.changeSiteColor(e);


            this.popupMenu(e, e.target.options.id);
            this.$store.commit(
              'siteOwner/setSiteSelected', 
              e.target.options.id
            );
          });
          lastSite.options.projectId = site.projectId;
          polygons.push(lastSite);

          if (!this.projectDevId || this.parents[site.projectId]?.connected
            .find((value) => value._id === this.projectDevId).status === 4) {
            if (this.map){
              lastSite.addTo(this.map);
            }
          }

          // Add the site's answers to the global store if they exist.
          const parents = {
            [site._id]: {
              answers: site.answers,
              users: site.users,
              objType: 'site'
            }
          };

          this.$store.commit('inputs/addParents', parents);
          this.showMeasurements(lastSite);
        }
      }
      
      if (!lastSite) return;

      this.$emit('onPolygonsRendered', polygons);
    },
    

    enableMeasurements () {
      let measurementsIcon = document.getElementsByClassName("pm-measurements-icon");
      measurementsIcon.length != 0 ? measurementsIcon[0].style["background-image"] = 'url(/assets/measurements-icon-inactive.svg)' : undefined;
      this.measurementsEnabled = true;
      this.showAllMeasurements(); 
    },

    toggleMeasurements () {
      this.measurementsEnabled ? this.disableMeasurements() : this.enableMeasurements();
    },

    enterFreeFormDrawMode () {
      if (!this.freeDraw._events || !this.freeDraw._events["markers"]) {
        this.addFreeDrawEvents();
      } 
      
      this.freeFormDrawEnabled = !this.freeFormDrawEnabled;
      this.freeDraw.mode(!this.freeFormDrawEnabled ? NONE : CREATE);
    },

    addFreeDrawEvents() {
      this.freeDraw.on('markers', async (eventData) => {
        if (this.freeFormDrawEnabled && eventData.eventType === 'create') {
          if (this.selectedProjectId && !!localStorage.token) {
            this.requestHelper.postQuery(
              'sketches',
              {
                projectId: this.selectedProjectId,
                color: this.colorPickerColors.hex,
                type: 'freeDraw',
                coordinates: eventData.latLngs[eventData.latLngs.length - 1]
              },
              (response) => {
                const index = this.freeDraw.all().length - 1;
                this.freeDraw.all()[index].options['projectId'] = this.selectedProjectId;
                this.freeDraw.all()[index].options['sketchId'] = response.data.sketchId;
                this.freeDraw.all()[index].options['type'] = response.data.type;
                this.sketches[JSON.stringify(eventData.latLngs[eventData.latLngs.length - 1])] = response.data;
                const id = `freeDraw${index + 1}`;
                this.freeDraw.all()[index]._path.id = id

                document.getElementById(id).style.fill = this.colorPickerColors.hex;
                document.getElementById(id).style.stroke = this.colorPickerColors.hex;
                document.getElementById(id).style['fill-opacity'] = 0.5;
                this.listenForDeleteSketch();
                this.listenForEditSketch();

                const layer = Object.values(this.map._layers).find(l => l.options.sketchId == response.data.sketchId);

                this.$store.commit('inputs/addSketchToParent', response.data);

                this.bindMarkerPopup(layer);
                this.toggleTooltipForMarker(layer);
                this.enterFreeFormDrawMode();
              }
            );
          } else {
            this.freeDraw.remove(this.freeDraw.all()[this.freeDraw.all().length - 1]);
          }
        }
      });
    },

    toggleRulerMeasurements () {
      this.toggleRulerMeasurementEnabled = !this.toggleRulerMeasurementEnabled;
      this.ruler.toggle(this.toggleRulerMeasurementEnabled);
    },
    
    disableMeasurements () {
       document.getElementsByClassName("pm-measurements-icon").length != 0 ? document.getElementsByClassName("pm-measurements-icon")[0].style["background-image"] = 'url(/assets/measurements-icon-active.svg)' : undefined;
      this.measurementsEnabled = false; 
      this.hideAllMeasurements();
    },

    showMeasurements (site) {
      // Show the measurements, hide the area size from the center
      site.showMeasurements({ minDistance: 0, formatArea: () => '' });
      
      if (site._icon || !this.measurementsEnabled) this.disableMeasurements();
      else this.enableMeasurements();
    },

    async popupMenu (e, id) {
      if (this.mapName === 'map' && this.isSiteModalEnabled) {
        const site = this.userSites.find((site) => site.options.id === id);
        if (!site) return;
        this.isPolygonClicked = true;
        this.$emit('updateInputBox', this.isPolygonClicked);

        try {

          this.popup.setLatLng(
            e ? e.latlng : { lat: site.getCenter().lat, lng: site.getCenter().lng }
          );
          // if the site is deleted
        } catch (e) {
          return;
        }
        const siteSize = this.parents[this.selectedSiteId]?.answers?.gaid39;
        if (siteSize) this.siteSize = siteSize;

        else if(!this.siteSize)
          this.siteSize = this.parents[this.selectedSiteId].answers.gaid291 = turf
            .area(site.toGeoJSON())
            .toFixed(2);
        
       this.$store.commit('inputs/updateParentAnswers', {
          parentId: this.selectedSiteId,
          newAnswers:  { 'gaid291': this.siteSize }
       });


        this.projectName =
          this.parents[this.selectedSiteId]?.answers?.gaid230 ||
          this.parents[site.options.projectId]?.answers?.gaid230 ||
          'New Project';
        this.siteName =
          this.parents[id]?.answers?.gaid185 ||
          `Site ${this.userSites.indexOf(site) + 1}`;

        this.setSitePopupContent();
        this.popup.openOn(this.map);

        this.$emit('changedBoxColor', site.options.color);

        let submitButtons = document.querySelectorAll('#submit-color-change');
        submitButtons.forEach((button) =>
          button.addEventListener('click', (ev) => {
            let color;
            if (ev.target.className.includes('red-button')) color = '#F00';
            else if (ev.target.className.includes('green-button'))
              color = '#0F0';
            else if (ev.target.className.includes('blue-button'))
              color = '#3388ff';
            site.options.color = color;

            this.map.removeLayer(site);
            this.map.addLayer(site);
            this.map.closePopup();
            this.updateSite({ layer: site, shape: 'polygon' });
            this.cutPolygon({ layer: site });
          })
        );
      }
    },
    getSiteBorders (site) {
      const jsonCoordinates = JSON.stringify(site.toGeoJSON().geometry.coordinates);

      this.requestHelper.getQuery(
        `congestionAndRestrictedGeo?coordinates=${jsonCoordinates}`, (response) => {

          if (this.parents[site.options.id]) {
            this.$store.commit('inputs/updateParentAnswers', {
              parentId: site.options.id,
              objType: 'site',
              newAnswers: {
                gaid526: response.data.gridCongestions,
                gaid527: response.data.restrictedAreas
              }
            });
          }
        });
      this.requestHelper.getQuery(
        `provincesGeoIntersects?coordinates=${jsonCoordinates}`,
        (response) => {
          const provincesList = new Set();
          const municipalitiesList = new Set();
          const resRegionsList = new Set();

          const provinceIds = new Set();
          const municipalityIds = new Set();
          const resRegionIds = new Set();

          if (response.data)
            response.data.forEach((segment) => {
              provinceIds.add(segment.Province._id);
              municipalityIds.add(segment.Municipality._id);
              resRegionIds.add(segment.ResRegion._id);

              provincesList.add(segment.Province.name);
              municipalitiesList.add(segment.Municipality.name);
              resRegionsList.add(segment.ResRegion.name);
            });

          if (this.parents[site.options.id]) {
            this.$store.commit('inputs/updateParentAnswers', {
              parentId: site.options.id,
              objType: 'site',
              newAnswers: {
                gaid440: [...provincesList],
                gaid441: [...municipalitiesList],
                gaid442: [...resRegionsList],
                gaid443: [...provinceIds],
                gaid444: [...municipalityIds],
                gaid445: [...resRegionIds]
              }
            });
          }
        }
      );
    },
    updateSite (updatedSite) {

      this.siteSize = this.parents[this.selectedSiteId].answers.gaid291 = turf
            .area(updatedSite.layer.toGeoJSON())
            .toFixed(2);

      this.$store.commit('inputs/updateParentAnswers', {
        parentId: this.selectedSiteId,
        newAnswers: {
          'gaid291': this.siteSize, 
          'gaid548': turf.area(updatedSite.layer.toGeoJSON()).toFixed(2),
          'gaid39': this.siteSize}
      });
      if (updatedSite.shape.toLowerCase() === 'polygon' &&
        this.user.userType === 0) {
        if (localStorage.token)
          this.requestHelper.putQuery('sitesQuery', {
            color: updatedSite.layer.options.color || '#3388ff',
            id: updatedSite.layer.options.id,
            coordinates: updatedSite.layer._latlngs.map((cords) =>
              cords.map((site) => [site.lat, site.lng])
            )
          });
        this.showMeasurements(updatedSite.layer);
        this.getSiteBorders(updatedSite.layer);
      }
    },

    async cutPolygon (cutSite) {
      if (this.user.userType === 0) {
        this.attachEventListenersToPolygons(cutSite.layer);
        const site = this.userSites.find(s => s.options.id === cutSite.layer.options.id);
        try {
          let cuttedSite = cutSite.layer._latlngs.map((cords) =>
            cords.map((cord) => [cord.lat, cord.lng])
          );

          cutSite.layer.on('click', (e) => {
            this.changeSiteColor(e);
            this.popupMenu(e, cutSite.layer.options.id);
            this.$store.commit(
              'siteOwner/setSiteSelected',
              cutSite.layer.options.id
            );
          });

          this.siteSize = this.parents[this.selectedSiteId].answers.gaid291 = turf
            .area(cutSite.layer.toGeoJSON())
            .toFixed(2);

          if (localStorage.token)
            this.requestHelper.putQuery('sitesQuery', {
              color: cutSite.layer.options.color || '#3388ff',
              id: cutSite.layer.options.id,
              coordinates: cuttedSite
            });
        
          this.$store.commit('inputs/updateParentAnswers', {
              parentId: this.selectedSiteId,
              newAnswers: { 'gaid291': this.siteSize }
          });

          this.getSiteBorders(cutSite.layer);
          this.showMeasurements(cutSite.layer);
        } catch (e) {
          if(e=="TypeError: Cannot read properties of undefined (reading 'map')");
            await this.deleteSite({ layer: site }, cutSite.originalLayer._latlngs);
        }
      }
    },
    async deleteSite (e, coordinates) {
      const remove = confirm('Do you really want to delete that polygon ?');
      if (remove) {
        if (localStorage.token && this.user.userType === 0)
          await this.requestHelper.deleteQuery(`sitesQuery?id=${e.layer.options.id}`);
        this.$store.commit('siteOwner/removeUserSite', e.layer.options.id);
        this.$store.commit('siteOwner/setSiteSelected');
      } else {
        let layer = L.polygon(coordinates || e.layer._latlngs, e.layer.options);
        this.attachEventListenersToPolygons(layer)
        layer.addTo(this.map)
        this.showMeasurements(layer);
        layer.on('click', (e) => {
            this.changeSiteColor(e);
            this.popupMenu(e, e.target.options.id);
            this.$store.commit(
              'siteOwner/setSiteSelected', 
              e.target.options.id
            );
        });
      }
      this.map.closePopup();
      this.map.pm.disableGlobalRemovalMode();
    },

    generatePatterns () {
      this.fillPatterns = {};
      for (const pattern of mapPatterns.patterns) {
        let patternOptions = {};
        for (const property of pattern.patternProperties) {
          patternOptions[property.property] = property.value;
        }

        switch (pattern.type) {
          case 'stripe':
            this.fillPatterns[pattern.pattern] = new L.StripePattern({
              ...patternOptions
            }).addTo(this.map);

            break;

          case 'custom':
            let shapeOptions = {};

            for (const property of pattern.shapeProperties)
              shapeOptions[property.property] = property.value;

            const shape = new L.PatternPath({
              ...shapeOptions
            });

            this.fillPatterns[pattern.pattern] = new L.Pattern({
              ...patternOptions
            });

            this.fillPatterns[pattern.pattern].addShape(shape);
            this.fillPatterns[pattern.pattern].addTo(this.map);
            break;
        }
      }
    },

    /**
     * An abstract function for displaying similar map layers.
     *
     * @param layerName - Based on the MapLayer enum (common\data\enums\MapLayer.js)
     * @param color - The default color of the layer polygons
     */
    async displayMapLayer (
      layerName,
      color,
      patternName
    ) {
      if (this.showLayers[layerName]) {
        this.mapLayers[layerName].displayedLayers.forEach(iteratedLayer => {
          if (iteratedLayer.rendered) return;
          const source = this.mapLayers[layerName].sources[0]
          

          const layer = L.geoJSON(iteratedLayer, {
            pmIgnore: true,
            style: {
              fillPattern: this.fillPatterns[patternName],
              color: source.color || color,
              fillColor: source.color || color,
              //todo make opacity configurable
              fillOpacity: source.opacity || 0.5
            },
            layerData: this.getLayerData(layerName, iteratedLayer)
          });

          layer.addTo(this.map);
          layer.bringToBack();
          layer.options[`isOf${layerName}`] = true;

          layer.on('click', (e) => {
            this.$emit(`${layerName}Data`, e.target.options.layerData);
          });

          iteratedLayer.rendered = true;
        });
      }
    },

    getLayerData (layerName, iteratedLayer) {
      switch (layerName) {
        case MapLayer.Provinces:
          return {
            provinceCode:
              iteratedLayer.properties.Code || iteratedLayer.properties.PID,
            provinceName:
              iteratedLayer.properties.Province ||
              iteratedLayer.properties.PName
          };

        case MapLayer.Municipalities:
          return {
            municipalityCode: iteratedLayer.properties.Code,
            municipalityName: iteratedLayer.properties.Name
          };

        case MapLayer.ResRegions:
          return {
            resRegionName: iteratedLayer.properties.Name
          };

        default:
          return {};
      }
    },

    parseEnergyProjectText (l) {
      let projectType = 'Unknown';
      let city = 'Unknown';
      let power = 'Unknown';
      let powerUnit = 'Unknown';
      let powerPerYear = 'Unknown';
      let powerPerYearUnit = 'Unknown';
      let duration = 'Unknown';
      let release = 'Unknown';

      if (l.allTerms.includes('Zon;')) projectType = 'Solar';
      else if (l.allTerms.includes('Wind op ')) projectType = 'Wind';

      const words = l.messageText.split(' ');

      let inIndex = words.findIndex((i) => i === 'in');
      if (inIndex !== -1) {
        if (words[inIndex + 1] === 'de') inIndex++;
        city = words[inIndex + 1];
      }

      let powerIndex = words.findIndex((i) => i.toLowerCase() === 'vermogen');
      if (
        words[powerIndex + 1].toLowerCase().includes('max.') ||
        words[powerIndex + 1].toLowerCase().includes('min.')
      ) powerIndex++;

      const powerConv = +words[powerIndex + 1]
        .replace('.', '')
        .replace(',', '.');
      if (!isNaN(powerConv)) power = `${powerConv}`;
      powerUnit = words[powerIndex + 2].replace(',', '');

      let ppyIndex = words.findIndex((i) => i.toLowerCase() === 'jaar');
      const ppyConv = +words[ppyIndex + 1].replace('.', '').replace(',', '.');
      if (!isNaN(powerConv)) powerPerYear = `${ppyConv}`;
      powerPerYearUnit = words[ppyIndex + 2].replace(',', '');

      let durationIndex = words.findIndex(
        (i) => i.toLowerCase() === 'looptijd'
      );
      if (durationIndex !== -1) duration = words[durationIndex + 1];

      //is released
      if (!l.messageText.toLowerCase().includes('nog niet gerealiseerd')) {
        let releasedIndex = words.findIndex(
          (i) => i.toLowerCase() === 'gerealiseerd'
        );
        if (releasedIndex !== -1 && words[releasedIndex + 1] === 'in')
          release = words[releasedIndex + 2].split('.')[0];
      } else {
        let yearIndex = words.findIndex((i) => i.match(/[0-9]+\)/g) !== null);
        if (yearIndex !== -1)
          release = words[yearIndex].match(/[0-9]+/g)[0].replace('.', '');
      }

      let tableObj = {
        'Project Type': projectType,
        'Location': city,
        'Capacity': power + ' ' + powerUnit,
        'Annual production': powerPerYear + ' ' + powerPerYearUnit,
        'Project status': l.status,
        'Duration': duration,
        'Start Date': release
      };

      return { popupContent: this.createTable(tableObj), projType: projectType };
    },
    createTable (infoObj) {
      let tbody = ``;

      for (let element in infoObj) {
        if (!infoObj[element]) continue;
        const result = element.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        tbody = tbody + `<tr><td>${finalResult}</td><td>${infoObj[element]}</td></tr>`;
      }

      return `<table class='table-pop-up'>${tbody}</table>`;
    },

    displayReducedRadiusMapLayer (layerName) {
      let lastLayer;

      const coordObjKey = layerName === MapLayer.Cadasters ? 'polygon' : 'geometry';
      this.mapLayers[layerName].displayedLayers.forEach(layer => {
        if (layer.rendered) return;

        let { sources, color, weight, opacity, titleColor, title, lastEditDate } = this.getProperties(layerName);
        let { popupContent, type } = this.setupPopupContent(layer, layerName, this.mapLayers[layerName]);
        const source = sources.find(s => s.type == type.toLowerCase() || (s.type.split(",").includes(type.toLowerCase())) || (layer.properties ? (layer.properties.color_type == s.type || layer.properties.type == s.type) : false) || s.type == layer.type) || sources[0];
        if (layer[coordObjKey].type === 'Point') {
          lastLayer = L.geoJson(layer[coordObjKey], {
            pointToLayer: function(feature, latlng) {
              return L.circleMarker(latlng, {
                color: source.color || color,
                weight: weight || 4,
                radius: ((l) => {
                  const words = l.messageText.split(' ');
                  let index = words.findIndex((i) => i.toLowerCase() === 'vermogen');
                  if (index === -1) return 10;

                  if (words[index + 1].toLowerCase().includes('max.') ||
                    words[index + 1].toLowerCase().includes('min.')
                  ) index++;

                  if (!words[index + 2].toLowerCase().includes('mw')) return 10;

                  const mW = +words[index + 1].replace(',', '.');
                  if (isNaN(mW)) return 10;

                  return (mW / (0.4 + mW)) * 15 + 3;
                })(layer) || 10,
                pmIgnore: true
              });
            }
          });
        } else {
          lastLayer = L.geoJSON(layer[coordObjKey], {
            pmIgnore: true,
            style: {
              color: source.color || subColor(layer),
              weight: weight || 4,
              fillOpacity: opacity || 0.2
            }
          });
        }

        lastLayer.addTo(this.map);
        lastLayer.bringToBack();

        lastLayer.options[`isOf${layerName}`] = true;
        layer.rendered = true;

        lastLayer.on('click', async (e) => {
          let popup = L.popup({
            closeButton: true
          });
          popup.setLatLng(e.latlng);
          //todo move style to class

          const popupContentTop = `<div class='layer-popup'>
            <span style='color: ${color || titleColor}; font-size:14px; display: flex;'>${title || layerName}</span>`
              
          const popupContentBottom = `</div><a target='_blank' href = '${source.url}'><p style='font-size: 14px;color: #2783FF;font-weight: normal; position: relative; margin:0;'>
              View source <img class='external-link' src='/assets/external-link.svg'/>
            </p></a><span style='font-size:12px;  color:#505158; font-weight: 300; display: flex;
            justify-content: center;  padding-bottom: 4px;'>Last update: ${new Date(lastEditDate).toLocaleDateString("en-NL")}</span>`;

          if (layerName === MapLayer.Cadasters) {
            const index = this.mapLayers[layerName].displayedLayers.findIndex(l => l.id == layer.id);
            let cadastreObject = this.mapLayers[layerName].displayedLayers[index];
            
            if (cadastreObject.info && cadastreObject.info.size && !`${cadastreObject.info.size}`.includes("sq. m")) {
              cadastreObject.info.size += " sq. m"; 
            }
            if (cadastreObject.info && cadastreObject.info.lastUpdateDate && !cadastreObject.info.lastUpdateDate.match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/g)) { 
              cadastreObject.info.lastUpdateDate = new Date(cadastreObject.info.lastUpdateDate).toLocaleDateString("en-NL"); 
            }

            popup.setContent(`${popupContentTop}${(cadastreObject.info && cadastreObject.info.address && cadastreObject.info.use) ? this.createTable(cadastreObject.info) : '<div style="margin-left: 40%; margin-top: 27px;" class="loader"></div>' }${popupContentBottom}`);

              this.getCadastrePolygonInfo(layer.id, true).then((response) => {
                const result = response.data;
                let resultCopy = {};
                Object.assign(resultCopy, result);
                this.mapLayers[layerName].displayedLayers[index] = { ...this.mapLayers[layerName].displayedLayers[index], info: resultCopy };
                result.size += " sq. m";
                result.lastUpdateDate = new Date(result.lastUpdateDate).toLocaleDateString("en-NL")
                popup.setContent(`${popupContentTop}${this.createTable(result)}${popupContentBottom}`);
            });

            popup.openOn(this.map);
           
          } else {
            popup.setContent(`${popupContentTop}${layerName == MapLayer.RestrictedAreas ? source.title : popupContent}${popupContentBottom}`);
            popup.openOn(this.map);
          }
        });
      });
    },

    getProperties (layer) {
      let l = this.mapLayers[layer];
      if (layer.properties?.type && l.subLayers && l.subLayers[layer.properties?.type]) l = l.subLayers[layer.properties?.type];

      return { sources: l.sources, color: l.color, opacity: l.opacity, title: l.title, lastEditDate: l.lastEditDate };
    },

    async getCadastrePolygonInfo(cadastreCode, update = false) {
      let response = undefined;

      while (true) {
        response = await this.requestHelper.getQuery(
        `cadastreInfoQuery?rows=1&cadastreCode=${cadastreCode}${update ? "&type=update" : ""}`);
        if (response && response.data) return response;
      }
    },

    setupPopupContent (layer, layerName, mapLayer) {
      let popUpContent = `Type: ${layerName}<br>`;
      let type = "";
      if (layerName === MapLayer.Provinces) {
        popUpContent = `${layer.properties?.Province || ''}${layer.properties?.PName || ''}`;
      } else if (layerName === MapLayer.Municipalities) {
        popUpContent = `${layer.properties.Name}`;
      } else if (layerName === MapLayer.ResRegions) {
        popUpContent = `${layer.properties.Name}`;
      } else if (layerName === MapLayer.EnergyProjects) {
        const {popupContent, projType} = this.parseEnergyProjectText(layer);
        popUpContent = popupContent;
        type = projType;
      } else if (layerName === MapLayer.RestrictedAreas) {
        popUpContent = JSON.stringify(layer.properties.type);
        type = popUpContent;
      } else if (layerName === MapLayer.SearchAreas) {
        const region = layer.properties.regio || layer.properties.REGIO;
        let ltype = layer.properties.type || layer.properties.TYPE;
        switch (layer.properties.type) {
          case 'zon':
          case 'zon tijdelijk':
            ltype = 'solar';
            break;
          case 'wind':
          case 'wind voorkeursgebied':
            ltype = 'wind';
            break;
          case 'zon en wind':
            ltype = 'solar plus wind';
            break;
          case 'wind extra reservegebied':
            ltype = 'wind extra reserve area';
            break;
          case 'wind reservegebied':
            ltype = 'wind reserve area';
            break;
          case 'zon reservegebied op water':
            ltype = 'solar reserve area on water';
            break;
          case 'zon reservegebied veld':
            ltype = 'solar reserve area field';
            break;
        }
        popUpContent = this.createTable({
          'RES areas': region,
          'Search area type': ltype
        });
        type = ltype;
      } else if (layerName === MapLayer.GridCongestion) {
        popUpContent = '';

        switch (layer.properties.color_type) {
          case 'yellow':
            popUpContent += `(Yellow)<br/><i>there is a threat of transport scarcity, an adjusted quotation regime applies</i>`;
            break;
          case 'orange':
            popUpContent += `(Orange)<br/><i>pre-announcement of structural congestion at ACM</i>`;
            break;
          case 'red':
            popUpContent += `(Red)<br/><i>structural congestion, new requests for transport are not honored</i>`;
            break;
          default:
            popUpContent += `Unknown color type`;
        }
      } else {
        popUpContent = `<p>${mapLayer.title}</p>`
      }

      return { popupContent: popUpContent, type };

    },

    async toggleMapLayer(layer, showLayer) {
      if (showLayer) {
        if(!this.renderedMapLayers[layer]) {
          this.renderedMapLayers[layer] = true;
          try {
            await this[`${this.mapLayers[layer].function}`](layer);
            this.displayReducedRadiusMapLayer(layer); 
            return;
          } catch(e) {
            return
          }
        }
      } else {
        this.renderedMapLayers[layer] = false;
        this.removeMapLayer(layer);
      }
    },

    triggerZoomAndCoordinates () {
      const userInfo = {
        mapCoordinates: [this.map.getCenter().lat, this.map.getCenter().lng],
        zoomLevel: this.map._zoom
      };

      this.$emit('updateUserStatus', userInfo);
    },

    attachEventListenersToPolygons (layer) {

      layer.on('pm:markerdrag', () => {
        this.showMeasurements(layer)
      });
      layer.on('pm:update', this.updateSite);
      layer.on('pm:cut', this.cutPolygon);
      layer.on('pm:remove', this.deleteSite);
    },

    async tryRerenderActiveLayers () {
      for(let layerName of Object.keys(this.showLayers)) {
        const showLayer = this.showLayers[layerName.replace(/\s/g, '')];
        if (showLayer && layerName != MapLayer.Grid) {
          try {
            this.getReducedRadiusMapLayer(layerName);
            this.removeMapLayer(layerName);
            this.displayReducedRadiusMapLayer(layerName); 
            return;
          } catch(e) {}
          try {
            await this.getSegmentedMapLayer(layerName);
            this.removeMapLayer(layerName);
            this.displayReducedRadiusMapLayer(layerName);
            return
          } catch(e) {}
        }
      }
    },

    removeMapLayer (layerName) {
      Object.values(this.map?._layers).forEach((e) => {
        if (e.options[`isOf${layerName}`]) {
          this.map.removeLayer(e);

          if (this.mapLayers[layerName].displayedLayers.length)
            this.mapLayers[layerName].displayedLayers.forEach(
              (layer) => (layer.rendered = false)
            );
        }
      });
    },

    // TODO: (ii) This function restarts all activated layers when only one is changed.
    // It should bother only with the one selected/deselected by the user.
    async handleLayers (showLayers) {
      if (!showLayers) return;
      for (let layerName of Object.keys(MapLayer)) {
        switch (layerName) {
          case MapLayer.Grid:
            if (showLayers[layerName]) this.getGridAndDisplay();
            else {
              this.removeMapLayer(layerName);
              // Cancel all requests for grid data
              this.gridCancelTokenSource?.cancel();
            }
            break;
          default:
            await this.toggleMapLayer(layerName, showLayers[layerName]);
            break;
        }
      }
    },
    hideAllMeasurements () {
      if (this.measurementsEnabled) return;
      for (const el of document.querySelectorAll('.leaflet-measure-path-measurement'))
        el.classList.add('d-none');
    },
    showAllMeasurements () {
      if (!this.measurementsEnabled) return;
      for (const el of document.querySelectorAll('.leaflet-measure-path-measurement'))
        el.classList.remove('d-none');
    }
  },
  watch: {
    selectedSiteId () {
      if (this.projectDevId &&
        this.parents[this.selectedProjectId].connected
          .find((value) => value._id === this.projectDevId).status !== 4)
        return;

      this.popupMenu(undefined, this.selectedSiteId);
    },
    selectedProjectId () {
      if (this.projectDevId &&
        this.parents[this.selectedProjectId].connected
          .find((value) => value._id === this.projectDevId).status !== 4)
        return;

      const siteSize = this.parents[this.selectedSiteId]?.answers?.gaid39;
      if (siteSize) this.siteSize = siteSize;
      this.projectName =
        this.parents[this.selectedProjectId]?.answers?.gaid230 || 'New Project';
      this.setSitePopupContent();
    },
    isSatelliteView (isSatellite) {
      this.map.removeLayer(isSatellite ? this.grayView : this.satelliteView);
      this.map.addLayer(isSatellite ? this.satelliteView : this.grayView);
    },
    mapName (newValue) {
      if (!this.$store.state.map.instances[newValue])
        this.$nextTick(() => this.createMap());
    },

    showLayers: {
      deep: true,

      async handler (showLayers) {
        await this.handleLayers(showLayers);
      }
    },

    renderToolsPopup (newValue) {
      if(!newValue){this.showColor = false; this.$emit('showColorPicker', false);}
      this.map.pm.toggleControls();
      if(newValue) document.getElementsByClassName("pm-measurements-icon").length != 0 ? document.getElementsByClassName("pm-measurements-icon")[0].style["background-image"] = this.measurementsEnabled ? 'url(/assets/measurements-icon-inactive.svg)' : 'url(/assets/measurements-icon-active.svg)' : undefined;
    },

    selectedAddress (address) {
      this.map.fitBounds(address.bounds);
    }
  }
};
</script>

<style lang='scss'>
@import '@styles/colors.scss';

.leaflet-gac-control-custom {
  margin: 0 auto;
  width: 345px;
}

.pm-measurements-icon {
  background-image: url('/assets/measurements-icon-inactive.svg');
}

.measurements-ruler-icon {
  background-image: url('/assets/ruler-control-icon.png');
}

.pm-draw-icon {
  background-image: url('/assets/free-draw-icon.jpg');
}

//Override default polyline image
.pm-draw-arrow-icon {
  background-image: url('/assets/pm-draw-arrow.png');
}

.pm-color-picker-icon {
  background-image: url('/assets/color-picker-icon.svg');
}

.leaflet-polyline-arrow {
}

// .leaflet-polygon {
//   fill: #ff0000;
//   fill-opacity: 0.5;
//   stroke: #5f021f;
// }

.leafmap {
  position: relative;
  width: 100%;
  height: calc(100vh - 96px);
  margin: 0;
  padding: 0;
  overflow: hidden;

  .leaflet-left .leaflet-control {
    margin-left: 0;
  }

  .leaflet-top .leaflet-control {
    margin-top: 0;
  }

  .leaflet-control-zoom.leaflet-bar.leaflet-control,
  .leaflet-top .leaflet-control {
    border-color: white;
    background-color: white;
    z-index: 400;
  }

  .leaflet-top,
  .leaflet-bottom {
    z-index: 400;
  }

  .leaflet-top {
    &.leaflet-left {
      height: auto;
      position: absolute;
      background-color: white;
      top: 45px;
      border-radius: 0 0px 10px 0;
      border-top: 10px solid white;
      border: 3px solid white;
      width: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 400;
    }
  }

  .leaflet-bar {
    a {
      margin-bottom: 10px;
    }
  }

  .leaflet-bar a,
  .leaflet-bar {
    border-bottom: 0;
  }

  .leaflet-pm-edit {
    border-radius: 0 10px 10px 0 !important;
  }

  .leaflet-map-pane {
    z-index: 1;

    .leaflet-popup-pane {
      .leaflet-popup {
        .leaflet-popup-content-wrapper {
          .layer-popup {
            padding: 10px 20px;
            margin: 0;
            width: 320px;
            min-height: 154px;
            height: auto;
          }

          .leaflet-container a.leaflet-popup-close-button {
            display: none;
          }


          .leaflet-popup-content {
            width: auto !important;

            button {
              &.red-button {
                background-color: red;
              }

              &.green-button {
                background-color: green;
              }

              &.blue-button {
                background-color: #2783ff;
              }
            }
          }
        }
      }
    }
  }

  .leaflet-control-zoom {
    &.leaflet-bar {
      &.leaflet-control {
        .leaflet-control-zoom-in {
          margin-top: 10px;
        }

        border: 2px solid white;
        margin-left: 10px;
      }
    }
  }
}

.leaflet-measure-path-measurement {
  position: absolute;
  font-size: 16px;
  color: $black;
  text-shadow: -1px 0 0 white, -1px -1px 0 white, 0 -1px 0 white,
  1px -1px 0 white, 1px 0 0 white, 1px 1px 0 white, 0 1px 0 white,
  -1px 1px 0 white;
  white-space: nowrap;
  transform-origin: 0;
  pointer-events: none;
}

.leaflet-measure-path-measurement > div {
  position: relative;
  margin-top: -25%;
  left: -50%;
}

.table-pop-up {
  width: 100%;
  height: 100%;

  table {
    border-collapse: collapse;
  }

  td,
  th {
    border: 1px solid #dddddd;
    word-wrap: break-word;
    max-width: 200px;
  }

  tr:nth-child(even) {
    background-color: #b4d3ac;
  }
}

.external-link {
  width: 12px;
  height: 12px;

}

.leaflet-tile {
  width: 256.5px !important;
  height: 256.5px !important;
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #6fb16c; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
