<template>
  <div class='wrap-chart'>
    <div class='section site-type'>
      <img src='/assets/roofChart.svg' alt=''>
      <p><span class='text'>Roof - 90% of all sites</span></p>
    </div>
    <p><span class='chart-text'>Groningen</span></p>
    <div class='p-3 flex flex-col' id='pie-chart' />
  </div>
</template>
<script>
// import { pie as d3pie, arc as d3arc, select as d3select} from 'd3';
import * as d3 from 'd3';

export default {
  name: 'MetricsPieChart',
  props: {
    data: [Boolean, Array]
  },
  data () {
    return {
      running: false,
      redraw: false
    };
  },
  mounted () {
    if (this.data)
      this.drawChart();
  },
  watch: {
    data () {
      this.drawChart();
    }
  },
  methods: {
    removeOld () {
      const el = document.querySelector(`#pie-chart`);
      if (el.firstChild) el.removeChild(el.firstChild);
    },
    drawChart () {
      if (this.running) {
        this.redraw = true;
        return;
      }
      this.removeOld();
      this.running = true;
      let radius = 80;
      let innerRadius = 0;

      // append the svg object to the div called 'data'
      let svg =
        d3.select('#pie-chart')
          .append('svg')
          .attr('width', radius * 3.5)
          .attr('height', radius * 3)
          .append('g')
          .attr('transform', 'translate(' + radius * 2 + ',' + radius * 1.2 + ')');

      // Compute the position of each group on the pie:
      // var pie = d3pie().value(function(d) {
      //   return d.value;
      // });
      const pie = d3.pie()
        .startAngle(8 * (Math.PI / 8))
        .endAngle(Math.PI * 2 + 10 * (Math.PI / 8))
        .value(function(d) {return d[1]; });

      svg
        .append('svg:text')
        .attr('dy', '.35em')
        .attr('text-anchor', 'middle')
        .attr('font-size', '20')
        .attr('fill', '#5CB85C');

      svg.selectAll('w')
        .data(pie(this.data))
        .join('path')
        .attr('d', d3.arc()
          .innerRadius(innerRadius)
          .outerRadius(radius - 10)
        )
        .attr('stroke', 'white')
        .style('stroke-width', '5.5px')
        .attr('fill', function(d) {
          return d.data[2];
        })
        .style('transform', function(d) {
          if (d.data[3])
            return 'scale(1.1)';
        })
        .style('opacity', 1);
      svg
        .selectAll('w')
        .on('mouseover', function() {
          svg.d3.arc.selectAll('w').attr('fill', 'blue');
        });

      let labels = [];
      for (let i = 0; i < this.data.length; i++) {
        labels.push(this.data[i][0]);
      }
      let labelArc = d3.arc().innerRadius((radius * 3) / 8).outerRadius((radius * 2.5));
      svg
        .selectAll('w')
        .data(pie(this.data))
        .join('svg:image')
        .attr('xlink:href', function(d) {
          return d.data[4];
        })
        .attr('transform', function(d) {
          let a = labelArc.centroid(d);
          a[1] -= 10;
          return 'translate(' + a + ')';
        })
        .attr('width', 25)
        .attr('height', 25);

      svg
        .selectAll('w')
        .data(pie(this.data))
        .join('text')
        .text(function(d) {return d.data[0];})
        .attr('transform', function(d) {
          let a = labelArc.centroid(d);
          a[1] += 40;
          return 'translate(' + a + ')';
        })
        .style('font-weight', 400)
        .attr('fill', function(d) {
          return d.data[2];
        })
        .attr('font-size', '12');

      this.running = false;
      if (this.redraw) this.drawChart();
    }
  }

};
</script>

<style>
.wrap-chart {
  margin-bottom: -40px;
  margin-top: 70px;
  margin-left: -40px;
}

#pie-chart {
  margin-left: -35px;
}

.chart-text {
  position: relative;
  font-size: 12px;
  color: #1D226F;
  font-weight: 500;
  left: -10px
}

.site-type {
  margin-left: 70px !important;
}
</style>
