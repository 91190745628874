<template>
<div class='reset-password'>
    <div class='components col-12 freeArea'>
        <div class='above-texts'>
            <img :class="hasError == null ? 'logo' : 'logo-middle'" src='/assets/logo.png' />
            <p v-if='hasError == null' class='header-text'> {{ translation.header }} </p>
            <!-- <div class='image'>
        <div class='text'> Connecting renewable energy!</div>
        </div>  -->
            <p v-if='hasError' class='header-text'>Invalid or expired link</p>
            <div v-if='hasError'>
                <p @click="$emit('startNow')" class='start-now-link clickable'>
            Don't have an account
            <span class='yellow-line'> Start now</span>
          </p>
            </div>

            <p v-if='hasError === false' class='header-text'>Successfully reset password</p>
            <p v-if='hasError === false' class='back-to-login clickable' @click="goLogin()">Back to Login</p>

            <div v-if='hasError === undefined' class='new-password'>
                <ShowHidePassword field="newPass" />
                <input v-model='newPassword' id='pass' class='pass' type="password" placeholder='New Password' ref="newPass" />

                <div class='texts'>
                    <PasswordValidator :password='newPassword' :confirmPassword='confirmPassword' ref='passRule' />
                </div>

            </div>
            <div v-if='hasError === undefined' class='confirm-new-password'>
                <input v-model='confirmPassword' id='confirmPass' class='pass' type="password" placeholder='Confirm New Password' />
            </div>
            <p style="display: none; color: orange" class="errorText" id="errorText">Your new password must be different from the previous!</p>
            <div v-if='hasError === undefined' class='bottom-buttons'>
                <button class='confirm' @click='changePassword()'>
                    <label>{{ translation.confirmButton }}</label>
                </button>
            </div>

            <!-- <button class='back-to-sign-up'>{{ translation.backButton }}</button> -->
        </div>
    </div>
</div>
</template>

<script>
import ResetPasswordEn from '@content/en/general/reset-password.yaml';
import ResetPasswordNl from '@content/nl/general/reset-password.yaml';
import PasswordValidator from '@components/inputs/types/dynamic/password-validator';
import axios from '@axios';
import ShowHidePassword from './ShowHidePassword.vue';

export default {
    components: {
        PasswordValidator,
        ShowHidePassword,
    },
    data() {
        return {
            translation: {},
            newPassword: '',
            confirmPassword: '',
            hasError: undefined,
            lang: "",
            samePassword: false
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            localStorage.lang = this.lang = this.$router.history.current.params.lang;
            this.translation = this.lang === 'en' ? ResetPasswordEn : ResetPasswordNl;
            
            if(localStorage.loggedUser){
                this.delete()
            }else{
                this.get();
            }
            
        },
        changePassword() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const token = urlParams.get('token');
            let errorText = document.getElementById("errorText")
            if (this.newPassword === this.confirmPassword && this.$refs.passRule.isValid()) {
                axios
                    .put('/.netlify/functions/forgottenPassword', {
                        newPassword: this.newPassword,
                        confirmPassword: this.confirmPassword,
                        token
                    }).catch((e) => {
                        if(e.response.status == 400){
                            this.samePassword = true;
                            errorText.style.display = 'block';
                        }
                    })
                    .then(() => {
                    if(!this.samePassword){
                        errorText.style.display = 'none';
                        this.hasError = false;
                    }
                    this.samePassword = false;
                });
            }
        },

        get() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const token = urlParams.get('token');
            axios
                .get(`/.netlify/functions/forgottenPassword?token=${token}`)
                .then((response) => this.email = response.data.email)
                .catch(() => this.hasError = true);
        },

        delete(){
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const token = urlParams.get('token');
            axios
                .delete(`/.netlify/functions/forgottenPassword?token=${token}`)
                .catch((e) => {
                    if(e.response.status == 400){
                        this.hasError = true;
                    }
                })
                .then(() => {
                    if(!this.hasError){
                        this.$router.push(`/${this.lang}/home`).catch(()=> {});
                    }
                });
        },

        goLogin(){
            this.$router.push(`/${this.lang}/home/site-owner/login`).catch(() => {});
        }
    }
};
</script>
