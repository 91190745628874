<template>
  <div class='container' id='our-platform'>
    <div class='our-platform'>
      <div class='col-12'>
        <h1>{{ translations.header }}</h1>
      </div>
      <div class='image' />
      <div class='row'>

        <div class='card-images col-12 col-sm-12 col-md-6 col-xl-6' v-for='(card, i) of cards ' :key='i'>
          <img :src='card.image' :class='i !== 2 ? "" : "last-image" ' alt=''>
          <div :class='i !== 2 ? "style-card" : "last-card"'>
            <h2>{{ card.header }}</h2>
            <div class="content">
            <p>{{ card.paragraph }}</p>
            <p class='button-text' @click='contactFormOpened = true'> {{ card.buttonText }}
            <img v-if="i == 2" src="/assets/arrow.png" class='blue-arrow' alt=''>
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
    <ContactForm  v-if="contactFormOpened" gaid='gaid520'
                  @closeContactForm='contactFormOpened=false'/>
  </div>
  
</template>

<script>
import platformNL from '@content/nl/general/navbar/platform.yaml';
import platformEN from '@content/en/general/navbar/platform.yaml';
import ContactForm from '@layouts/matching/ContactForm';

export default {
  name: 'OurPlatform',

  components: {
    ContactForm
  },

  data () {
    return {
      translations: this.lang === 'en' ? platformEN : platformNL,
      cards: [],
      contactFormOpened: false
    };
  },

  props: {
    lang: String
  },

  computed: {
    userType () {
      return this.$store.state.user.userType;
    }
  },

  mounted () {
    this.cards = this.translations[this.userType ? "pd" : "so"].cards
  }
};
</script>

<style lang='scss' scoped>
@import "@styles/mobile-mode.scss";
//  @import "@styles/main.scss";
@import "@styles/home-page.scss";

.row {
  display: inline-flex;
  align-items:baseline;
  position: relative;
  // left: 3vw;
  justify-content: center;
}

.style-card {
  width: 330px;
  height: 255px;
  border-radius: 0 0 20px 0;
}

.card-images {
  display: grid;
  margin-top: 20px;
  width:250px;
  // left:-6vw;
  justify-content: center
}

h2 {
  padding-top: 20px;
}

.button-text {
  padding-left: 15px;
  color: #5694E8;
  text-align: left;
  position: absolute;
  bottom: 0;
  cursor: pointer;
}

p {
  text-align: left;
  margin-left: 10px;
  padding: 0 15px;
}

.blue-arrow {
 width: 9px;
  display: initial;
  height: 9px;
}

  .last-card{
  h2{
    position: relative;
    top: -190px;
    font-size: 30px;
    font-weight: 500;
    color:#393A4D;
    width: fit-content;
  }
  p{
    position: relative;
    left: -10px;
    width: 356px;
    top: -170px;
    margin-bottom:-5px;
    padding: 0;
  }
  .button-text{
    padding-left: 0;
    font-weight: 500;
    margin-top: 10px;
    margin-left:10px;
  }
  .blue-arrow {
    position: relative;
  }
  }

.last-image{
  margin-top:50px;
  margin-left: 372px;
  border-radius: 0 0 20px 0;
}
</style>
