<template>
  <div class="container" id="partners">
    <div class="partners">
      <div class="col-12">
        <h1>{{ title }}</h1>
      </div>
      <div class="row">
        <div
          class="col-12 info-partners"
          v-for="(partner, index) of partners"
          :key="index">
          <div class="odd-partner style-card" v-if="index % 2 === 1">
            <div class="row">
              
              <p class=" text col-xl-8 col-sm-8 col-12">{{ partner.description }}</p>
               <img class="col-xl-4 col-sm-4 col-12" :src="partner.image" alt='' />
             
            </div>
          </div>

          <div class="even-partner style-card" v-if="index % 2 === 0">
            <div class="row">
              <img class="col-xl-4 col-sm-4 col-12" :src="partner.image" alt='' />
              <p class="col-xl-8 col-sm-8 col-12">{{ partner.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import partners from "@content/nl/general/partners.yaml";
import partnersEn from "@content/en/general/partners.yaml";
export default {
  name: "Partners",
  data() {
    return {
      partners: {},
      title: "",
    };
  },
  props: {
    lang: String,
  },
  mounted() {
    this.init();
  },
  watch: {
    lang() {
      this.init();
    },
  },
  methods: {
    init() {
      let data = this.lang === "en" ? partnersEn : partners;
      this.partners = data.partners;
      this.title = data.title;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@styles/main.scss";
@import "@styles/home-page.scss";
@import '@styles/mobile-mode.scss';
</style>
