<template>
  <div class='definition-popup' style='display: none'>
    <p/>
  </div>
</template>

<script>

export default {
  name: 'info-popup',
};
</script>

<style lang='scss'>
.definition-popup {
  position: absolute;
  border: 2px solid;
  border-radius: 0 16px;
  padding: 10px 10px 0 5px;
  margin-top: 5px;
  font-weight: 300;
  font-size: 12px;
  z-index: 50000;
  background: white;
  pointer-events: none;

  p {
    font-size: 12px;
    font-weight: 300;
    color: black;
  }
}

</style>
