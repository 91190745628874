<template>
  <lazy-background
    class='background-image'
    :src='userType === 0 ? translation[`background-image`] : translation[`background-image-dev`]'
    placeholder='https://placeholder.pics/svg/1300x800'
    :blur='30'

  >
    <div slot='content' class='header' id='header'>
      <div class='header-overlay' id='header-overlay'></div>


      <div class='header-content'>
        <h1 class='col-sm-12 col-12'>{{ translation.title[userType ? 'pd' : 'so'] }}</h1>
        <!-- <Calendly
          v-if='env.VUE_APP_CONTEXT === "live"'
          :lang='lang'
        /> -->
        <button class='start-buttons' @click="$emit('startNow')">
          {{ userType ? 'Register' : 'Start Now' }}
          <img src='/assets/arrow-right-white.png' />
        </button>
        <div @click='setUserType(1)' v-if='userType === 0'>
          <button class='developer-button'>
            <img src='/assets/forward.png' />
            {{ translation['developer-page'] }}
          </button>
        </div>
        <div @click='setUserType(0)' v-else-if='userType === 1'>
          <button class='developer-button'>
            <img src='/assets/forward.png' />
            {{ translation['owner-page'] }}
          </button>
        </div>
      </div>
    </div>
  </lazy-background>
</template>

<script>

// Translations
import headerTranslationsNl from '@content/nl/general/header.yaml';
import headerTranslationsEn from '@content/en/general/header.yaml';
import Calendly from '@layouts/home/components/Calendly';

export default {
  components: {
    Calendly
  },

  data () {
    return {
      hidden: false,
      env: {},
      isClicked: true,
      translation: {}
    };
  },
  props: {
    lang: String
  },

  computed: {
    userType () {
      return this.$store.state.user.userType;
    }
  },

  created () {
    this.init();
    this.env = process.env;
  },
  watch: {
    lang () {
      this.init();
    }
  },
  methods: {
    init () {
      this.translation = this.lang === 'en' ? headerTranslationsEn : headerTranslationsNl;
    },

    setUserType (userType) {
      this.$store.commit('user/setType', userType);
      this.$forceUpdate();
    }
  }
};
</script>

<style scoped lang='scss'>
@import "@styles/main.scss";
@import "@styles/header.scss";
@import "@styles/home-page.scss";
</style>
