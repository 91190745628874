<template>
  <div>
    <div class='font'>
      <div class='profile-c' id='charts'>
        <div class='profile'>
          <div class='output-box'>
            <DraggableBox 
              v-if='showCharts'
              class='freeArea output-box box-style'
              :isResizable='false'
              :w='outputWidth'
              :x='outputLeft'
              :y='outputTop'
              :h='inputOutputBoxHeight'
              :disableDragging="true"
              :style="{
              position: 'initial',
            }"
            >
              <template slot='header'>
                <Model id='model-stats' :selectedSiteId='currentPdfSiteId' />
                <Feasibility :showLegend='true' :hideTitle='true' id='feasibility-stats' v-if='showCharts' />
                <!-- <Metrics id="metrics-stats" :parents="currentPdfParents" :siteId="currentPdfSiteId" :stepId="currentPdfStepId"/> -->
              </template>
            </DraggableBox>
          </div>
        </div>
      </div>
      <div class='page-alignment'>
        <div class='card-alignment'>
          <!--          Project Developer Profile-->
          <div v-if='projectDevId'>
            <InfoCard
              v-show='confirmed === false'
              text='You need to confirm your account and enter the 6 digit code. Matchmaking only possible if profile is complete'
              buttonText='Go to confirmation'
              buttonIcon='/assets/arrow-right-white.png'
              color='red'
              @buttonClick='
              $router.push(`/${lang}/project-developer/confirmation`)
            '
            />

            <InfoCard
              v-show='confirmed === true'
              text='Your profile is ready and you can proceed to matchmaking. You can revisit this page to edit and reduce or increase the scope of your matches'
              buttonText='Go to matchmaking'
              buttonIcon='/assets/arrow-right-white.png'
              color='green'
              @buttonClick='
              $router.push(`/${lang}/dev/matching`)
            '
            />

            <div class='site-wrapper mt-5 mb-5'>
              <div class='header'>
                <p class='site-text'>My Profile</p>

                <div class='header-right'>
                  <button @click='minimizeInputBox = !minimizeInputBox'>
                    <img class='icon' :class="minimizeInputBox === false ? 'rotated-arrow':'' "
                         src='/assets/arrow-down-boring.svg' />
                  </button>
                </div>
              </div>

              <div class='header'>
                <div class='site-line' />
              </div>
              <!--            todo titles to configuration-->
              <div
                :style="minimizeInputBox ? 'display: none' : ''">
                <BoringSection
                  v-for='(q, i) of questionnaires'
                  :key='i'
                  :projectId='projectDevId'
                  :questionnaire='q'
                  :project='true'
                  :description='true'
                  :editButton='editButton'
                  @editAnswer='editDevDetails($event, q, i)'
                  :questionnaireActive='activeQuestionnaire === i'
                  :title='questionnaireTitles[i]'
                />
              </div>
            </div>
          </div>

          <!--          Site Owner Project page-->
          <div v-else>
            <div class='transparent-card'>
              <div class='text-alignment'>
                {{ translations.title }}
              </div>

              <button :style="{ cursor: downloadingPdf ? 'not-allowed' : 'pointer' }"
                      class='button download-button'
                      @click='exportPlainPDF'>
                <img src='/assets/download-icon.svg' />{{ translations['pdf-button'] }}
              </button>
            </div>

            <div id='project-info'>
              <div class='header' v-if="projectSites.length > 1">
                <div class='header-left'>
                  <div class='project'>
                    <img src='/assets/files.svg'>
                    <p>{{ translations['project-text'] }}</p>
                    <p class='project-name'>{{ projectName }}</p>
                    <button class="editProjectName" @click='changeProjectName()'>
                      <img src="../../../public/assets/edit-pen.svg" alt="" class="editPen">
                    </button>
                    <div class='changeName' :class='{projectNamePopUp: projectNamePopUp}'>
                      <div class='inputs'>
                        <div>
                          <img class="close-popup" src='/assets/close-blue.svg' @click='changeProjectName()'/>
                        </div>
                        <p class='title'>Change project: {{projectName}}'s name.</p>
                        <input type='text' class='newName' placeholder='New project name' id='newName'/>
                        <input type='text' class='newNameConfirm' placeholder='Confirm new project name' id='newNameConfirm'/>
                        <button class="change" @click="changeName()">Change</button>
                        <p style="display: none" id="createdText">The project name has been changed.</p>
                        <p style="display: none" id="errorText">There is empty field or the names don't match.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='header-right'>
                  <div class='project'>
                    <button @click='minimizeInputBox = !minimizeInputBox'>
                      <img class='icon' :class="minimizeInputBox === false ? 'rotated-arrow':'' "
                           src='/assets/arrow-down-boring.svg' />
                    </button>
                  </div>
                </div>
              </div>
              <div class='line' v-if="projectSites.length > 1" style='width: 100%; margin-bottom: 20px' />
              <BoringSection
                v-show='!minimizeInputBox'
                v-if='selectedProjectId && projectSites.length > 1'
                :projectId='selectedProjectId'
                :questionnaire='boringPageOwnerQuestions'
                :questionnaireActive='false'
                :boring='true'
                :project='true'
                title='Project Representative'
                />
              <BoringSection
                v-show='!minimizeInputBox'
                v-if='selectedProjectId && projectSites.length > 1'
                :projectId='selectedProjectId'
                :questionnaire='descriptionQuestionnaire'
                :questionnaireActive='editingStepType === 2'
                @editAnswer='editProjectDescription'
                :editButton='editButton'
                :boring='true'
                :project='true'
                :description='true'
                title='Description'
                />
              <div
                v-show='!minimizeInputBox && site.options.projectId === selectedProjectId'
                v-for='(site, index) of userSites'
                :key='index'
                class='big-row'>

                <Site
                  ref='site'
                  @editAnswer='editAnswer($event)'
                  @editPreferencesAnswer='editPreferencesAnswer($event)'
                  @editOwnerDetails='editOwnerDetails($event)'
                  :site='site'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Questionnaire
        ref='questionnaire'
        v-if='questionnaire'
        :questionnaire='questionnaire'
        page='project'
        :activatedInputBox='true'
        :inputOutputBoxHeight='690'
        :siteId='editingSiteId'
        :projectDevId='projectDevId'
        :startingStep='editingStep'
        @onStepFinish='onStepFinish'
      />
      <Footer :lang='lang' />
    </div>
  </div>
</template>

<script>
import BoringSection from '@layouts/boring/components/BoringSection.vue';
import Site from '@layouts/boring/components/Site.vue';
import Footer from '@components/Footer.vue';
import L from 'leaflet';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ids from '@common/ids.yaml';
import Questionnaire from '../questionnaire/Questionnaire';
import questionnaire_site from '@common/questionnaire/boring_page_site_owner_site.yaml';
import questionnaire_site_preferences from '@common/questionnaire/boring_page_site_owner_site_preferences.yaml';
import questionnaire_boring_page_site_owner from '@common/questionnaire/boring_page_site_owner.yaml';
import questionnaire_project_dev_personal from '@common/questionnaire/project_dev_personal.yaml';
import questionnaire_project_dev_company from '@common/questionnaire/project_dev_company.yaml';
import questionnaire_project_dev_active_locations from '@common/questionnaire/project_dev_active_locations.yaml';
import questionnaire_project_dev_relationship from '@common/questionnaire/project_dev_relationship.yaml';
import questionnaire_project_dev_energy from '@common/questionnaire/project_dev_energy.yaml';
import questionnaire_project_dev_exclusivity from '@common/questionnaire/questionnaire_project_dev_exclusivity.yaml';
import questionnaire_project_dev_social from '@common/questionnaire/questionnaire_project_dev_social.yaml';
import questionnaire_project_dev_project from '@common/questionnaire/questionnaire_project_dev_project.yaml';
import RequestHelper from '@common/helpers/request.helper';
import translationsEn from '@content/en/owner/boring-page.yaml';
import translationsNl from '@content/nl/owner/boring-page.yaml';
import description from '@common/questionnaire/description.yaml';
import InfoCard from '@components/InfoCard';
import 'leaflet-simple-map-screenshoter';
import Model from '@layouts/civilization/Model';
import Feasibility from '@layouts/civilization/Feasibility';
import DraggableBox from '@components/DraggableBox';
// import Metrics from '@layouts/civilization/Metrics';
import html2canvas from 'html2canvas';

export default {
  components: {
    InfoCard,
    BoringSection,
    Site,
    Footer,
    Questionnaire,
    Model,
    DraggableBox,
    Feasibility
    // Metrics
  },
  props: {
    projectDevId: String,
    site: Object,
    startingStep: String,
  },
  data () {
    return {
      minimizeInputBox: false,
      downloadingPdf: false,
      ids: ids,
      lang: '',
      name: '',
      questionnaire: undefined,
      siteOwnerQuestions: { ...questionnaire_site },
      siteOwnerPreferencesQuestions: { ...questionnaire_site_preferences },
      boringPageOwnerQuestions: { ...questionnaire_boring_page_site_owner },
      outputTop: (window.innerHeight - Number(690)) / 2,
      outputLeft: window.innerWidth - Number(450),
      outputWidth: Number(360),
      inputOutputBoxHeight: Number(690),
      currentPdfSiteId: '',
      currentPdfStepId: '',
      showCharts: false,
      descriptionQuestionnaire: {...description},
      currentPdfParents: {},
      editingStepType: 0,
      questionnaires: [
        { ...questionnaire_project_dev_company },
        { ...questionnaire_project_dev_personal },
        { ...questionnaire_project_dev_active_locations },
        { ...questionnaire_project_dev_relationship },
        { ...questionnaire_project_dev_energy },
        { ...questionnaire_project_dev_project },
        // { ...questionnaire_project_dev_social },
        // { ...questionnaire_project_dev_exclusivity }
      ],
      questionnaireTitles: [
        'Company Information',
        'User Information',
        'Active Locations',
        'Site owner relationship',
        'Site ownership',
        'Services offered',
        'Project financing and participation',
        'Exclusivity'
      ],
      activeQuestionnaire: undefined,
      role: localStorage.loggedUser ? JSON.parse(localStorage.loggedUser).userType : 0,
      projectName: '',
      projectNamePopUp: false,
      editingSiteId: undefined,
      editingStep: undefined,
      projectSites: [],
      selectedProjectSites: [],
      editButton: false,
      requestHelper: new RequestHelper(this.$router),
      translations: localStorage.lang === 'en' ? translationsEn : translationsNl,
      confirmed: JSON.parse(localStorage.loggedUser).confirmed
    };
  },

  computed: {
    userSites () {
      return this.$store.state.siteOwner.userSites;
    },
    selectedProjectId () {
      return this.$store.state.siteOwner.selectedProjectId;
    },
    parents () {
      return this.$store.state.inputs.parents;
    },
    userProjects () {
      return this.$store.state.siteOwner.userProjects;
    },
    inputParents () {
      return this.$store.state.inputs.parents;
    },
  },

  watch: {
    $route () {
      this.init();
    },
    selectedProjectId () {
      this.onStepFinish();
    },
    municipalitiesNames (municipality) {
      this.municipalityName = municipality;
    }
  },

  mounted () {
    this.init();
    window.addEventListener('resize', this.setQuestionnairePos);
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.setQuestionnairePos);
  },

  methods: {
    init () {
      localStorage.lang = this.lang = this.$router.history.current.params.lang;
      this.$store.subscribe(this.onStoreChange);

      if (this.selectedProjectId === undefined && this.userProjects[0])
        this.$store.commit('siteOwner/setSelectedProjectId', this.userProjects[0]._id);

      this.updateProjectName();
      this.translations = this.lang === 'en' ? translationsEn : translationsNl;
      this.allProjectSites();
    },

    editProjectDescription (step) {
      if (!step) {
        this.editAnswer();
        return;
      }
      this.editButton = true;
      this.editDescription(step);
      this.editingStepType = 2;
    },

    editStep (step) {
      this.$emit('editAnswer', { step, siteId: this.selectedProjectId });
      this.editingStepType = 1;
    },
  
    allProjectSites(){
      this.updateProjectName();
      this.projectSites = this.userSites.filter(
        (site) => site.options.projectId === this.selectedProjectId
      );
    },

    updateProjectName(){
      this.projectName = this.parents[this.selectedProjectId]?.answers['gaid230'] ||
        'New Project';
    },

    getScreenshotOfElement: async function(site) {
      let map = this.$store.state.map.instances[`project${site.options.id}`];

      // map.addLayer(L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      //   attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
      //   maxNativeZoom: 19,
      //   maxZoom: 22
      // }));

      let simpleMapScreenshoter = L.simpleMapScreenshoter().addTo(map);
      let format = 'image'; // 'image' - return base64, 'canvas' - return canvas
      let overridedPluginOptions = {
        mimeType: 'image/png'
      };

      return new Promise((resolve, reject) => {
        simpleMapScreenshoter.takeScreen(format, overridedPluginOptions)
          .then(base => {
            resolve(base);
          })
          .catch(() => {
            reject();
          });
      });

    },

    // exportPDF: async function() {
    //   if (this.downloadingPdf) return;
    //   this.downloadingPdf = true;
    //   // This is function to export frontend info to PDF file with "jspdf" and "jspdf-autotable" libraries
    //
    //   const doc = new jsPDF({ format: 'a4', unit: 'px' });
    //   let pdf = pdfTemplate;
    //   let sites = '';
    //   for (const site of this.userSites) {
    //     if (site.options.projectId !== this.selectedProjectId) continue;
    //     let sitePdf = pdfSiteTemplate;
    //
    //     sitePdf = sitePdf.replace('{imageUrl}', 'data:image/png;base64,' +
    //       await this.getScreenshotOfElement(document.getElementById(`project${site.options.id}`),
    //         0, 0, 536, 253));
    //
    //     const answers = this.parents[site.options.id].answers;
    //
    //     sitePdf = sitePdf.replace('{resRegion}', answers['gaid442'] ? answers['gaid442'].join(', ') : 'No Information added')
    //       .replace('{siteName}', answers['gaid185'] || 'Unnamed')
    //       .replace('{province}', answers['gaid440'] ? answers['gaid440'].join(', ') : 'No Information added')
    //       .replace('{municipality}', answers['gaid441'] ? answers['gaid441'].join(', ') : 'No Information added');
    //
    //     const sections = `${this.generateSection(this.siteOwnerQuestions, answers)}
    //     ${this.generateSection(this.siteOwnerPreferencesQuestions, answers)}`;
    //
    //     sitePdf = sitePdf.replace('{sections}', sections);
    //     sites += sitePdf + '\n';
    //   }
    //
    //   pdf = pdf.replace('{sites}', sites)
    //     .replace('{projectName}', this.parents[this.selectedProjectId].answers['gaid230']);
    //
    //   await doc.html(pdf, {
    //     margin: 200,
    //     callback: d => {
    //       d.save('project.pdf');
    //       this.downloadingPdf = false;
    //     }
    //   });
    // },

    exportPlainPDF: async function() {
      if (this.downloadingPdf) return;
      this.downloadingPdf = true;
      this.showCharts = true;
      let lastSelectedSiteId = this.$store.state.siteOwner.selectedSiteId;
      // This is function to export frontend info to PDF file with "jspdf" and "jspdf-autotable" libraries

      const doc = new jsPDF();
      doc.page = 1;
      doc.setFontSize(14);
      doc.setFont('helvetica', 'normal');
      doc.text(`-${doc.page++}-`, doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 7);
      doc.setFont('helvetica', 'bold');

      var img = new Image();
      img.src = `${process.env.VUE_APP_API_URL}/assets/logo.png`;
      doc.addImage(img, 'png', 15, 15, 37, 30); //width = height * 1.24

      doc.setFont('helvetica', 'normal');
      doc.setFontSize(18);
      doc.text('Contact us:', doc.internal.pageSize.getWidth() - 90, 25);
      doc.setFontSize(15);
      doc.text('Email: team@AuxGeo.green', doc.internal.pageSize.getWidth() - 88, 33);
      doc.text('LinkedIn: /AuxGeo.green', doc.internal.pageSize.getWidth() - 88, 39);
      doc.line(15, 45, doc.internal.pageSize.getWidth() - 15, 45);
      doc.setFont('helvetica', 'bold');

      doc.setFontSize(23);
      doc.text(
        `Project Name: ${
          this.parents[this.selectedProjectId]?.answers['gaid230'] ||
          'New Project'
        }`,
        (doc.internal.pageSize.getWidth() / 4),
        57
      );
      // doc.line(20, 62, doc.internal.pageSize.getWidth() - 20, 62)

      let y = 60;
      let c = 0;
      for (const site of this.selectedProjectSites) {
        if (c > 0) {
          doc.addPage();
          doc.setFontSize(14);
          doc.setFont('helvetica', 'normal');
          doc.text(`-${doc.page++}-`, doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 7);
          doc.setFont('helvetica', 'bold');
          y = 10;
        }
        const answers = this.parents[site.options.id]?.answers;
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(16);
        doc.line(20, y + 10, doc.internal.pageSize.getWidth() - 20, y + 10);
        doc.text(doc.splitTextToSize(`Site Name: ${answers['gaid185']}`, 180), 20, (y += 20));
        doc.line(20, y += 5, doc.internal.pageSize.getWidth() - 20, y);

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(16);

        doc.text(doc.splitTextToSize(`Site information`, 180), 25, (y += 20));
        doc.line(25, y += 5, doc.internal.pageSize.getWidth() - 25, y);

        doc.setFontSize(14);
        doc.setFont('helvetica', 'normal');
        y += 5;
        let image = new Image();
        image.src = await this.getScreenshotOfElement(site);
        let imageWidth = (doc.internal.pageSize.getWidth() - 30);
        let imageHeight = imageWidth / 1.7;
        doc.addImage(image, 'pdf', 15, y, imageWidth, imageHeight);
        y += imageHeight;

        this.currentPdfSiteId = site.options.id;
        this.$store.commit('siteOwner/setSiteSelected', this.currentPdfSiteId);
        this.currentPdfStepId = site.options.currentStepId;
        this.currentPdfParents = this.parents;

        // imageWidth = (doc.internal.pageSize.getWidth() / 2) - 10;
        // imageHeight = imageWidth * 1.4;

        // doc.addImage(await this.captureComponentById("model-stats"), 'pdf', 2, y, imageWidth, imageHeight);
        // // let xPossition = (doc.internal.pageSize.getWidth() - imageWidth) + 2
        // // imageWidth = imageWidth - 30
        // // doc.addImage(await this.captureComponentById("metrics-stats"), 'pdf',  xPossition, y, imageWidth + 10, (imageHeight += 40) + 30);

        // y += imageHeight;

        // doc.line(15, y += 5, doc.internal.pageSize.getWidth() - 15, y)

        doc.text(doc.splitTextToSize(`Country: Netherlands`, 180), 27, (y += 15));
        doc.text(doc.splitTextToSize(`Province: ${answers['gaid440'] ? answers['gaid440'].join(', ') : 'No Information added'}`, 180), 27, (y += 10));
        doc.text(doc.splitTextToSize(`Municipality: ${answers['gaid441'] ? answers['gaid441'].join(', ') : 'No Information added'}`, 180), 27, (y += 10));
        doc.text(doc.splitTextToSize(`RES Region: ${answers['gaid442'] ? answers['gaid442'].join(', ') : 'No Information added'}`, 180), 27, (y += 10));

        doc.addPage();
        doc.text(`-${doc.page++}-`, doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 7);
        y = 10;

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(16);

        doc.text(doc.splitTextToSize(`Representative: `, 180), 25, (y += 20));
        doc.setFontSize(14);
        doc.setFont('helvetica', 'normal');
        doc.line(25, y += 5, doc.internal.pageSize.getWidth() - 25, y);
        for (const q of [this.boringPageOwnerQuestions]) {
          for (let stepKey of Object.keys(q)) {
            const step = q[stepKey];
            if (y >= 270) {
              doc.addPage();
              doc.text(`-${doc.page++}-`, doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 7);
              y = 10;
            }

            if (step.inputs) {
              let answer = `${this.getSelectedAnswer(step, answers).join(' ')}`;
              doc.text(doc.splitTextToSize(`${this.ids[step.title][this.lang].boringName
              || this.ids[step.title][this.lang].name}: ${answer}`, 180), 30, (y += 10));
            }
          }
        }

        for (const q of [this.siteOwnerQuestions, this.siteOwnerPreferencesQuestions]) {
          for (let stepKey of Object.keys(q)) {
            const step = q[stepKey];

            if (step.section) {
              if (y + 25 >= 250) {
                doc.addPage();
                doc.text(`-${doc.page++}-`, doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 7);
                y = 10;
              }
              doc.setFont('helvetica', 'bold');
              doc.setFontSize(16);
              doc.text(doc.splitTextToSize(`${step.section}`, 180), 25, (y += 20));
              doc.line(25, y += 5, doc.internal.pageSize.getWidth() - 25, y);
              doc.setFont('helvetica', 'normal');
              doc.setFontSize(14);
            }

            if (step.inputs && (!step.if || this.isIfAnswered(step.if, answers))) {
              if (y + 10 >= 270) {
                doc.addPage();
                doc.text(`-${doc.page++}-`, doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 7);
                y = 10;
              }
              let answer = `${this.ids[step.title].sizeLabel ?
                this.getAnswer(answers, { gaid: step.title }, { gaid: 'gaid291' }) + ' sq.m.' :
                this.getSelectedAnswer(step, answers).join(' ')}`;

              if (step.subsection) {
                doc.text(doc.splitTextToSize(`${this.ids[step.title][this.lang].boringName
                || this.ids[step.title][this.lang].name}: ${answer}`, 180), 35, (y += 10));
              } else {
                doc.text(doc.splitTextToSize(`${this.ids[step.title][this.lang].boringName
                || this.ids[step.title][this.lang].name}: ${answer}`, 180), 30, (y += 10));
              }
            }
          }
        }

        doc.addPage();
        doc.setFontSize(14);
        doc.setFont('helvetica', 'normal');
        doc.text(`-${doc.page++}-`, doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 7);
        y = 10;
        imageWidth = doc.internal.pageSize.getWidth() * 0.8;
        imageHeight = doc.internal.pageSize.getHeight() * 0.5;

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(16);
        doc.text(doc.splitTextToSize('Feasibility', 180), 10, y);
        doc.line(10, y += 5, doc.internal.pageSize.getWidth() - 10, y);
        y += 5;

        doc.addImage(await this.captureComponentById('feasibility-stats'), 'pdf', 45, y, doc.internal.pageSize.getWidth() * 0.57, doc.internal.pageSize.getHeight() * 0.8);
        c++;

        doc.addPage();
        doc.setFontSize(14);
        doc.setFont('helvetica', 'normal');
        doc.text(`-${doc.page++}-`, doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 7);

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(16);
        doc.text(doc.splitTextToSize('Project economics', 180), 10, y);
        doc.line(10, y += 5, doc.internal.pageSize.getWidth() - 10, y);
        y += 5;

        doc.addImage(await this.captureComponentById('model-stats'), 'pdf', 21, y, imageWidth, imageHeight);
        c++;
      }
      this.$store.commit('siteOwner/setSiteSelected', lastSelectedSiteId);
      doc.save(`${this.parents[this.selectedProjectId]?.answers['gaid230'] || 'new-project'}.pdf`);
      this.showCharts = false;
      this.downloadingPdf = false;

    },

    async captureComponentById (id) {
      let element = document.getElementById(id);
      if (element) {
        let canvas = await html2canvas(element);
        let image = new Image();
        image.src = canvas.toDataURL();
        return image;
      }
    },

    // generateSection: function(questionnaire, answers) {
    //   let sections = '';
    //   for (let stepKey of Object.keys(questionnaire)) {
    //     const step = questionnaire[stepKey];
    //     if (step.section) {
    //       if (sections.length !== 0)
    //         sections = `${sections}</div>`;
    //       sections = `${sections}<div class='section-name-row'>`;
    //       sections = `${sections}<p class='section-name'>${step.section}</p>`;
    //       sections = `${sections}<div class='line'></div>`;
    //     }
    //
    //     if (step.inputs && (!step.if || this.isIfAnswered(step.if, answers))) {
    //       const answer = `${this.ids[step.title].sizeLabel ?
    //         this.getAnswer(answers, { gaid: step.title }, { gaid: 'gaid291' }) + ' sq.m.' :
    //         this.getSelectedAnswer(step, answers).join(' ')}`;
    //
    //       if (!answer || answer === '') continue;
    //
    //       // `${this.ids[step.title][this.lang].boringName || this.ids[step.title][this.lang].name}: ${answer}`
    //       sections = `${sections}
    //         <div class='details-row'>
    //           <p class='${step.subsection ? 'question-sub' : 'question'}'>
    //             ${this.ids[step.title][this.lang].boringName || this.ids[step.title][this.lang].name}
    //           </p>
    //           <p class='answer'>${answer}</p>
    //         </div>`;
    //     }
    //   }
    //
    //   return `${sections}</div>`;
    // },

    isIfAnswered: function(gaids, answers) {
      if (!answers) return false;
      if (typeof gaids === 'string')
        gaids = [gaids];

      // Returns the first answered
      for (const gaid of gaids) {
        const answer = answers[gaid];
        if (answer && answer !== '') return answer;
      }

      return false;
    },

    getSelectedAnswer: function(step, answers) {
      if (!step) return [this.translations.unanswered];

      if (step.group) return [this.getGroupAnswer(answers, step.inputs)];

      return this.getAnswers(answers, ...step.inputs);
    },

    getAnswer: function(answers, ...gaids) {

      if (!answers) return this.translations.unanswered;

      // Returns the first answered
      for (let gaid of gaids) {
        const answer = answers[gaid.gaid];
        if (answer && answer !== '') return answer;
      }

      return this.translations.unanswered;
    },

    clearSelectedAnswer () {
      this.editingStep = undefined;
      if (this.$refs.section)
        this.$refs.section.clearSelectedAnswer();
    },

    getAnswers: function(answers, ...gaids) {
      if (!answers) return [this.translations.unanswered];

      // Collects all answered
      let output = [];
      for (let gaid of gaids) {
        const answer = answers[gaid.gaid];
        if (answer && answer !== '')
          output.push(
            answer === true ? this.ids[gaid.gaid][this.lang].name : answer
          );
      }

      return output.length > 0 ? output : [this.translations.unanswered];
    },

    onStoreChange (mutation) {
      switch (mutation.type) {
        case 'siteOwner/setSiteSelected':
        case 'siteOwner/setSiteProjectId':
        case 'siteOwner/setSelectedProjectId':
          this.initSelectedProjectSites();
          this.allProjectSites();
          break;
        case 'siteOwner/removeUserSite':
          this.initSelectedProjectSites();
          break;
      }
    },

    initSelectedProjectSites () {
      this.selectedProjectSites = this.userSites.filter(
        (site) => site.options.projectId === this.selectedProjectId
      );
    },
    getGroupAnswer (answers, gaids) {

      if (!answers) return this.translations.unanswered;

      for (const gaid of gaids) {
        const answer = answers[gaid.gaid];
        if (!answer || answer === '') continue;

        return typeof answer == 'boolean' ? this.ids[gaid.gaid][this.lang].name : answer;
      }

      return this.translations.unanswered;
    },

    emitUserName (userName) {
      this.name = userName;
    },

    onStepFinish () {
      this.editingSiteId = undefined;
      this.editingStep = undefined;
      this.questionnaire = undefined;
      this.activeQuestionnaire = undefined;
      this.editingStepType = 0;
      this.editButton = false;
      for (const s in this.$refs.site)
        this.$refs.site[s].clearSelectedAnswer();
    },
    editAnswer (site) {
      if (site === undefined) {
        this.onStepFinish();
        return;
      }
      this.editingSiteId = site.siteId;
      this.editingStep = site.step;
      this.questionnaire = this.siteOwnerQuestions;

      this.setQuestionnairePos();
    },
    editDescription (site) {
      this.editingSiteId = this.selectedProjectId;
      this.editingStep = site.step;
      this.questionnaire = this.descriptionQuestionnaire;
      this.setQuestionnairePos();
    },
    setQuestionnairePos () {
      this.$nextTick(() => {
        let currentEditableElement = document.getElementsByClassName('editable')[0];
        if (!currentEditableElement)return;
        let rectPos = currentEditableElement.getBoundingClientRect();
        let bodyPos = document.body.getBoundingClientRect();
        let k;
        if (!this.$refs.questionnaire) return;
        if(innerWidth >= 2550)k = 1.2;
        else if(innerWidth >= 1200)k = 0.8;
        else if(innerWidth >= 768)k = 0.65;
        else if(innerWidth >= 600)k = 0.56;
        else k = 0.5;
        this.$refs.questionnaire.top = (rectPos.top - bodyPos.top) / k;    
        this.$refs.questionnaire.left = (rectPos.left + rectPos.width - 20) / k; 
        // const firstField = this.questionnaire[this.editingStep].inputs[0];
        // if (this.ids[firstField.gaid].type === 'text') {
        //   const lastChar = parseInt(this.editingStep.substr(this.editingStep.length - 1));
        //   document.getElementsByClassName('text')[lastChar - 1].focus();
        // }
      });
    },
    editPreferencesAnswer (site) {
      this.editAnswer(site);
      this.questionnaire = this.siteOwnerPreferencesQuestions;
    },
    editOwnerDetails (e) {
      this.editingSiteId = this.selectedProjectId;
      this.editingStep = e.step;
      this.questionnaire = this.boringPageOwnerQuestions;
      this.setQuestionnairePos();
    },
    editDevDetails (e, questionnaire, questionnaireId) {
      if (e === undefined) {
        this.onStepFinish();
        return;
      }
      this.editingSiteId = this.projectDevId;
      this.editingStep = e.step;
      this.activeQuestionnaire = questionnaireId;
      this.questionnaire = questionnaire;
      this.editButton = true;
      this.setQuestionnairePos();
    },
    changeProjectName(){
      this.projectNamePopUp = !this.projectNamePopUp;
    },
    async changeName(){
      this.newNameField = document.getElementById("newName").value;
      this.newNameConfirmField = document.getElementById("newNameConfirm").value;
      let text = document.getElementById("createdText");
      let errorText = document.getElementById("errorText");
      if((this.newNameField === this.newNameConfirmField) && (this.newNameField != '')){
        await this.requestHelper.postQuery('changeProjectName',{ 
          projectId: this.selectedProjectId,
          newName: this.newNameField
        });
          text.style.display = 'block' 
          text.style.color = '#82c37f'
          errorText.style.display = 'none';
          setTimeout(() => {
            location.reload();
          }, 500);
      }else {
        errorText.style.display = 'block';
        errorText.style.color = 'orange';
        text.style.display = 'none';
      }
    }
  }
};
</script>

<style scoped lang='scss'>
@import "@styles/main.scss";
@import "@styles/boring-page.scss";
@import "@styles/mobile-mode.scss";
@import "@styles/colors.scss";
@import '@styles/profile.scss';

#charts {
  position: absolute;
  z-index: -1;

  .wrap {
    height: 1000px !important;
  }
}
@media (orientation:portrait) and (min-width: 768px)   {
    .site {
     margin-right: 64px;
    }

   
}


@media (orientation: landscape) and (max-width: 1024px) {
   .site {
     margin-right: 64px;
    }

   

}
</style>
      