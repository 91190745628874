import { render, staticRenderFns } from "./SuccessfulPayment.vue?vue&type=template&id=affc140a&scoped=true&"
import script from "./SuccessfulPayment.vue?vue&type=script&lang=js&"
export * from "./SuccessfulPayment.vue?vue&type=script&lang=js&"
import style0 from "./SuccessfulPayment.vue?vue&type=style&index=0&id=affc140a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "affc140a",
  null
  
)

export default component.exports