<template>
  <div
    class='site-owner-container'
    :class="{ 'overflow-hidden': currentTab === 'map' }">
    <Nav
      :currentTab='currentTab'
      :key='`nav${rerender}`'
      @change-tab='changeCurrentTab($event)'
      @openGuideBox='guideBox($event)'
      @openLogin='openLogin()' />

    <DevMatching
      v-if="currentTab === 'matching'" />

    <BoringPage
      v-show="currentTab === 'project'" />

    <!-- <RegTech v-show="currentTab === 'regTech'" :readOnly="true" /> -->

    <Profile
      ref='profile'
      :key='`profile${rerender}`'
      v-show="currentTab === 'map'"
      @redraw='redraw()' />

    <Guide v-show='guideBoxOpened' @closeGuideBox='closeGuideBox()' />
  </div>
</template>

<script>
import DevMatching from '@layouts/matching/Matching';
import Profile from '@layouts/civilization/Profile';
import Nav from '@components/Nav.vue';
import BoringPage from '@layouts/boring/BoringPage';
// import RegTech from '@layouts/RegTech/RegTech.vue';
import Guide from '@layouts/home/Guide';
import chevronTabNl from '@content/nl/i/1.yaml';
import chevronTabEn from '@content/en/i/1.yaml';

export default {
  components: { Profile, DevMatching, Nav, BoringPage, Guide },
  data () {
    return {
      currentTab: 'map',
      previousTab: '',
      section: localStorage.lang === 'en' ? chevronTabEn.navigation : chevronTabNl.navigation,
      subSection: localStorage.lang === 'en' ? chevronTabEn.sections[0].section : chevronTabNl.sections[0].section,
      lang: localStorage.lang,
      sites: 0,
      rerender: 0,
      toggleId: '',
      hasRendered: undefined,
      guideBoxOpened: false,
      pages: {
        map: '',
        matching: '/matching',
        'project': '/project',
        i: '/i'
      }
    };
  },
  computed: {
    userSites () {
      return this.$store.state.siteOwner.userSites;
    }
  },
  beforeDestroy () {
    const call = this.showCloseWarning;
    window.removeEventListener('beforeunload', function(e) {
      call(e);
    });
  },
  created () {
    if (!localStorage.loggedUser)
      localStorage.loggedUser = JSON.stringify({ userType: 0 });

    const call = this.showCloseWarning;
    window.addEventListener('beforeunload', function(e) {
      call(e);
    });

    if (this.$route.path.endsWith('/i')) {
      this.guideBoxOpened = true;
    }

    this.urlWatcher();
  },
  watch: {
    $route () {
      if (this.previousTab !== this.currentTab)
        this.$store.commit('map/updateMapName', this.currentTab);
      this.urlWatcher();
      if (this.$route.query.section)
        this.guideBoxOpened = true;
    },
    userSites () {
      this.sites = this.userSites.length;
    },
    currentTab (e) {
      let lang = this.$router.history.current.params.lang;
      if (this.$router.history.current.path == `/${lang}/site-owner${this.pages[e]}` ||
        this.$router.history.current.path == `/${lang}/site-owner${this.pages[e]}/i`) return;
      this.$router.push(`/${lang}/site-owner${this.pages[e]}`);
    }
  },
  methods: {
    closeGuideBox () {
      this.guideBoxOpened = false;
      const path = this.currentTab === 'map' ? `/${this.lang}/site-owner` : `/${this.lang}/site-owner/${this.currentTab}`;
      this.$router.push({ path, query: '' });
    },
    urlWatcher () {
      this.previousTab = this.currentTab;
      if (this.$route.path.includes('site-owner/project')) {
        this.currentTab = 'project';
        // } else if (
        //   this.$route.path.endsWith('site-owner/RegTech')
        // ) {
        //   this.currentTab = 'regTech';
      } else if (this.$route.path.includes('site-owner/matching')) {
        this.currentTab = 'matching';
      } else if (this.$route.path.includes('site-owner')) {
        this.currentTab = 'map';
      }
    },
    guideBox () {
      this.guideBoxOpened = true;
      const path = this.currentTab === 'map' ? `/${this.lang}/site-owner/i` : `/${this.lang}/site-owner/${this.currentTab}/i`;
      this.$router.push({ path, query: { section: this.section, subsection: this.subSection } });
    },
    redraw () {
      this.rerender += 1;
    },
    showCloseWarning (e) {
      if (localStorage.token || this.$store.state.siteOwner.userSites.length === 0)
        return undefined;

      const confirmationMessage = 'If you leave now, your progress will be lost!';

      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    },
    openLogin () {
      this.currentTab = 'map';
    },
    changeCurrentTab (tab) {
      this.previousTab = this.currentTab;
      this.currentTab = tab;
    },
  }
};
</script>
<style>
.pac-container {
  z-index: 1100 !important;
}
</style>
