<template>
  <div class='notExpanded' v-if="(sites.length > 0) || !haveSites">
    <p class='nameEmail'>{{ user.name }} {{ sites.length ? '| ' + sites.length + ' sites' : '' }}<br />{{ user.email }}
    </p>
    <button class='match-arrow' :class='{expanded: expanded}' @click='expand()'></button>
    <button class='impersonate' @click='impersonate()'>Log in</button>
    <p class='role'>{{ userRole }}</p>
    <button class='resetPassword' @click='resetPassword()'>Send reset email</button>
    <button class='changePassword' @click='changePassword()'>Change</button>
    <div class="passDiv" :class="{passPopUp: passPopUp}">
      <p class="text">Change password of: <br>{{user.email}}</p>
      <input type='text' class='pass' placeholder='Enter New Password' id='pass' autocomplete='new-password' v-model="pass" />
      <input type='text' class='confirmPass' placeholder='Confrim New Password' id='confirmPass' autocomplete='new-password' v-model="confirmPass" />
      <p style="display: none" id="matching">Passwords match.</p>
      <p style="display: none" id="notMatching">Passwords do not match.</p>
      <button class="change" @click="change()">Change</button>
    </div>
    <div class='expanded-div' :class='{expanded: expanded}'>
      <table class='expandedTable'>
        <thead class='tableHead'>
          <th class='siteName'>Site Name</th>
          <th class='siteAddress'>Site Address</th>
        </thead>
        <tbody class='tableData'>
          <tr class='tableRows' v-for='site in sites' :key='site.id'>
            <td class='siteName'>{{ site.answers.gaid185 }}</td>
            <td class='address'>{{ (site.answers.gaid440 || [])[0] || '' + ', ' + site.answers.gaid441 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
 
 
</div>
</template>

<script>
import RequestHelper from '@common/helpers/request.helper';
import axios from '@axios';

export default {
  data () {
    return {
      requestHelper: new RequestHelper(this.$router),
      expanded: false,
      sites: [],
      lang: 'en',
      userRole: 0,
      sent: false,
      passPopUp: false,
      pass: '',
      confirmPass: ''
    };
  },
  props: {
    user: Object,
    haveSites: Boolean
  },
  created () {
    localStorage.lang = this.lang = this.$router.history.current.params.lang;
    this.$store.subscribe(this.onStoreChange);
    this.init();

    if (this.user.userType === 0) this.userRole = 'Site owner';
    else if (this.user.userType === 1) this.userRole = 'Project dev';
    else if (this.user.userType === 2) this.userRole = 'Admin';
  },
  computed: {
    parents () {
      return this.$store.state.inputs.parents;
    }
  },
  methods: {
    init () {
      this.sites = [];
      for (const parent in this.parents) {
        let p = this.parents[parent];
        if (p.objType !== 'site') continue;
        for (const user of p.users)
          if (user._id === this.user.id) {
            this.sites.push({ ...p, id: parent });
            break;
          }
      }
    },

    onStoreChange (mutation) {
      switch (mutation.type) {
        //this is ran last, so it waits for the sites to load
        case 'map/initInstance':
          this.init();
      }
    },

    expand () {
      if (this.sites.length === 0) this.init();
      this.expanded = !this.expanded;
    },

    impersonate () {
      this.requestHelper.postQuery('impersonateUser', { id: this.user.id }, (response) => {
        let userData = response.data;
        if (this.user.id === userData._id) {
          localStorage.token = userData.token;
          localStorage.loggedUser = JSON.stringify(userData);
          this.$store.commit('user/setType', userData.userType);
          this.$router.push(`/${this.lang}/${this.user.userType ? 'dev/profile' : 'site-owner'}`).catch(()=> {});
          this.$router.go(0);
        }
      });
    },

    resetPassword(){
      let email = this.user.email;
      let lang = this.lang;
      if(email){
        axios
        .post('/.netlify/functions/forgottenPassword',{
          email,
          lang
        });
        this.sent = true;
      }
      if(this.sent) alert('Email to change the password was sent to the user.');
    },

    changePassword(){
      this.passPopUp = !this.passPopUp;
    },

    change(){
      let text = document.getElementById("matching");
      let errorText = document.getElementById("notMatching");
      if(this.pass === this.confirmPass && this.pass.length > 0){
        text.style.display = 'block';
        text.style.color = '#82c37f';
        errorText.style.display = 'none';
        this.requestHelper.putQuery('/.netlify/functions/changePassword', {
          newPassword: this.pass, 
          confirmPassoword: this.confirmPass, 
          email: this.user.email
        })
      }else{
        errorText.style.display = 'block';
        text.style.display = 'none';
        errorText.style.color = 'orange';
      }
    }
  }
};
</script>

<style lang='scss' scoped>
@import '@styles/all-users.scss';

</style>
