<template>
  <div class="process" id="process">
    <div class="processDev">
      <div class="col-12 macbook-image">
        <h1>{{ title }}</h1>
      </div>
      <div class="row container">
        <div class="column">
          <div
            class="col-12 col-sm-12 col-md-6 col-xl-4 info-steps margin-fixing"
            v-for="step of firstStep.firstStep"
            :key="step.label"
          >
            <p class="background-numbers">{{ step.number }}</p>

            <div class="step-info">
              <h2>{{ step.label }}</h2>
              <div class="line">
                <div class="black-line"></div>
                <p class="information">{{ step.information }}</p>
              </div>
            </div>
          </div>

          <div
            class="col-12 col-sm-12 col-md-6 col-xl-4 info-steps margin-fixing"
            v-for="step of secondStep.secondStep"
            :key="step.label"
            @mouseover="(hover2 = true) && (hover = false)"
            @mouseleave="(hover = true) && (hover2 = false)"
          >
            <p class="background-numbers">{{ step.number }}</p>
            <div class="step-info">
              <h2>{{ step.label }}</h2>
              <div class="line">
                <div class="black-line"></div>
                <p class="information">{{ step.information }}</p>
              </div>
            </div>
          </div>

          <div
            class="col-12 col-sm-12 col-md-6 col-xl-4 info-steps margin-fixing"
            v-for="step of thirdStep.thirdStep"
            :key="step.label"
            @mouseover="(hover3 = true) && (hover = false)"
            @mouseleave="(hover = true) && (hover3 = false)"
          >
            <p class="background-numbers">{{ step.number }}</p>
            <div class="step-info">
              <h2>{{ step.label }}</h2>
              <div class="line">
                <div class="black-line"></div>
                <p class="information">{{ step.information }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="macbook-image container">
          <lazy-img class="img" :blur="30" v-show="hover" :src="firstImage" />
          <lazy-img class="img" :blur="30" v-show="hover2" :src="secondImage" />
          <lazy-img class="img" :blur="30" v-show="hover3" :src="thirdImage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import process from "@content/nl/developer/processForDev.yaml";
import processEn from "@content/en/developer/processForDev.yaml";
export default {
  name: "Process",
  data() {
    return {
      firstStep: {},
      secondStep: {},
      thirdStep: {},
      firstImage: "",
      secondImage: "",
      thirdImage: "",
      title: "",
      label: "",
      description: "",
      link: "",
      contactText: "",
      hover: true,
      hover2: false,
      hover3: false,
    };
  },
  props: {
    lang: String,
  },
  mounted() {
    this.init();
  },
  watch: {
    lang() {
      this.init();
    },
  },
  methods: {
    init() {
      let data = this.lang === "en" ? processEn : process;
      this.firstStep = data;
      this.secondStep = data;
      this.thirdStep = data;
      this.title = data.title;
      this.firstImage = data["first-step-image"];
      this.secondImage = data["second-step-image"];
      this.thirdImage = data["third-step-image"];
      this.label = data["introductory-title"];
      this.description = data["introductory-description"];
      this.link = data["contact-link"];
      this.contactText = data["contact-text"];
    },
  },
};
</script>

<style scoped lang="scss">
@import "@styles/main.scss";
@import "@styles/home-page.scss";
</style>