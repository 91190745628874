 <template>
   <div class="get-started">
    <!-- <h1>{{ translation.title }}</h1>
    <button  class="start-buttons"
        @click="$emit('startNow')">
          {{ translation.button }}
        <img src="/assets/arrow-right-white.png" />
      </button>
    <p>{{ translation.introduction }}</p> -->
  </div> 
 </template>

<script>
import getStarted from "@content/nl/general/get-started.yaml";
import getStartedEn from "@content/en/general/get-started.yaml";

export default {
  data() {
    return {
      translation: {}
    };
  },
  props: {
    lang: String,
  },

  computed: {
    userType() {
      return this.$store.state.user.userType;
    },
  },

  mounted() {
    this.init();
  },
  watch: {
    lang() {
      this.init();
    },
  },
  methods: {
    init() {
      this.translation = this.lang === "en" ? getStartedEn : getStarted;
    },
  },
};
</script>

<style scoped lang="scss">  
@import "@styles/main.scss";
@import "@styles/home-page.scss";
</style>
