<template>
  <div class="regTech-content">
    <h1>RegTech</h1>
    <div class="options" v-show="!currentTab">
      <h3>List of Map Layers</h3>
      <div
        class="button-options"
        @click="onTabChange('provinces')"
        :class="{ 'router-link-active': currentTab === 'provinces' }"
      >
        <h4>Provinces</h4>
      </div>
      <div
        class="button-options"
        @click="onTabChange('municipalities')"
        :class="{ 'router-link-active': currentTab === 'municipalities' }"
      >
        <h4>Municipalities</h4>
      </div>

      <div
        class="button-options"
        @click="onTabChange('res-region')"
        :class="{ 'router-link-active': currentTab === 'res-region' }"
      >
        <h4>RES Regions</h4>
      </div>
    </div>

    <div v-show="!!currentTab && currentTab.length > 0" class="segments">
      <div class="back-button" @click="goBack()">
        <h4>Back</h4>
      </div>

      <div class="content" v-show="currentTab === 'res-region'">
        <h1>RES Regions</h1>
        <div class="section">
          <div class="options">
            <div class="row">
              <div
                class="button-options"
                @click="onTabChange('segmentInfo', region, 'ResRegion')"
                :class="{ 'router-link-active': currentTab === 'segmentInfo' }"
                v-for="(region, index) of regions"
                :key="index"
              >
                <span>{{ region.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content" v-show="currentTab === 'provinces'">
        <h1>Provinces</h1>
        <div class="section">
          <div class="options">
            <div class="row">
              <div
                class="button-options"
                @click="onTabChange('segmentInfo', province, 'Province')"
                :class="{ 'router-link-active': currentTab === 'segmentInfo' }"
                v-for="(province, index) of provinces"
                :key="index"
              >
                <span>{{ province.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content" v-show="currentTab === 'municipalities'">
        <h1>Municipalities</h1>
        <div class="section">
          <div class="options">
            <div>
              <div
                v-for="(group, index) of municipalitiesByProvinces"
                :key="index"
              >
                <p class="province-group">{{ group.province }}</p>
                <div class="row municipalities">
                  <div
                    class="button-options"
                    @click="
                      onTabChange('segmentInfo', municipality, 'Municipality')
                    "
                    :class="{
                      'router-link-active': currentTab === 'segmentInfo',
                    }"
                    v-for="(municipality, index) of group.municipalities"
                    :key="index"
                  >
                    <span>{{ municipality.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SegmentInfo
      v-show="currentTab === 'segmentInfo'"
      :segment="segment"
      :readOnly="readOnly"
    />
  </div>
</template>

<script>
import SegmentInfo from "@layouts/RegTech/SegmentInfo.vue";
import RequestHelper from "../../../common/helpers/request.helper";

export default {
  name: "RegTech",
  components: { SegmentInfo },

  props: {
    readOnly: Boolean,
  },

  data() {
    return {
      lang: "",
      municipalities: [],
      provinces: [],
      provinceOfMunicipality: {},
      municipalitiesByProvinces: [],
      regions: [],
      currentTab: "",
      previousTab: "",
      segment: {},
      requestHelper: new RequestHelper(this.$router),
    };
  },
  created() {
    this.getMunicipalitiesNames();
    this.getProvincesNames();
    this.getRegionsNames();
    this.onTabChange();
  },
  methods: {
    getMunicipalitiesNames() {
      this.requestHelper.getQuery("municipalityQuery", (response) => {
        this.municipalities = response.data.map((municipality) => ({
          _id: municipality._id,
          name: municipality.properties.Name,
        }));

        this.sortMunicipalities();
      });
    },
    getProvincesNames() {
      this.requestHelper.getQuery("provinceQuery", (response) => {
        response.data
          .map((province) => ({
            _id: province.provinceId,
            name: province.ProvinceName,
            municipalityName: province.MunicipalityName,
          }))
          .sort(this.sortByName)
          .forEach((province) => {
            this.provinceOfMunicipality[province.municipalityName] =
              province.name;
            if (!this.provinces.find((p) => p.name === province.name))
              this.provinces.push(province);
          });
        this.sortMunicipalities();
      });
    },
    getRegionsNames() {
      this.requestHelper.getQuery("resRegionQuery", (response) => {
        response.data
          .map((region) => ({
            _id: region._id,
            name: region.properties.Name,
          }))
          .sort(this.sortByName)
          .forEach((region) => {
            if (!this.regions.find((reg) => reg.name === region.name))
              this.regions.push(region);
          });
      });
    },
    sortMunicipalities() {
      if (this.provinces.length === 0 || this.municipalities.length === 0)
        return;
      this.provinces.forEach((province) => {
        const municipalitiesForProvince = this.municipalities
          .filter(
            (municipality) =>
              this.provinceOfMunicipality[municipality.name] === province.name
          )
          .sort(this.sortByName);

        this.municipalitiesByProvinces.push({
          province: province.name,
          municipalities: municipalitiesForProvince,
        });
      });
    },
    sortByName(a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    },
    goBack() {
      const tab = this.previousTab === "segmentInfo" ? "" : this.previousTab;

      this.onTabChange(tab);
    },
    onTabChange(tab, segment, segmentType) {
      this.$emit("change-tab", tab);
      this.previousTab = this.currentTab;
      this.currentTab = tab;
      if (segment)
        this.segment = {
          segment: segment.name,
          parentId: segment._id,
          segmentType,
        };
    },
  },
};
</script>

<style lang="scss">
@import "@styles/regtech.scss";
</style>