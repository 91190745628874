<template>
  <div class='size-wrap'>
    <div class='section size'>
      <img src='/assets/sizeChart.svg' alt=''>
      <p><span class='text'>{{ size }} sq.m</span></p>
    </div>
    <div class='line-chart'>
      <div class='arrow' :style='styleMove'></div>
      <p class='arrow-text' :style='styleMove'>
        <span class='line'>{{ size }}  sq.m </span>
        <span> Your site</span>
      </p>
      <p class='max-size-p'><span class='max-size'> {{ maxSize }} sq.m </span>
        <span>largest in the ND </span>
      </p>
    </div>
  </div>
</template>

<script>


export default {
  props: {
    size: Number,
    maxSize: Number
  },
  updated () {
    this.move();
  },
  created () {
    this.move();
  },
  data () {
    return {
      styleMove: ''
    };
  },

  methods: {
    move () {
      const maxMoveRight = 230;
      let percentage = this.size / this.maxSize;
      //todo move 200px as style, because its hardcoded
      if (percentage > 1) percentage = 1;
      this.styleMove = 'margin-left: ' + percentage * maxMoveRight + 'px';

    }
  }
};
</script>

<style lang='scss'>
.size-wrap {
  width: 350px;
}

.line-chart {
  width: 240px;
  height: 8px;
  background: rgb(194, 237, 175);
  background: linear-gradient(90deg, rgba(194, 237, 175, 1) 0%, rgba(147, 196, 125, 1) 67%, rgba(84, 165, 79, 1) 100%);
  margin-left: 30px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.arrow {
  position: relative;
  top: 5px;
  z-index: 10;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
}

.arrow-text {
  color: #93C47D;
  width: 55px;
  font-size: 12px;
  position: relative;
  left: -20px;
  top: 5px;

  span {
    font-weight: 300;
  }

  .line {
    text-decoration: underline;
    white-space: nowrap;
    font-weight: 300;
  }
}


.max-size-p {
  width: 75px;
  position: relative;
  left: 240px;
  top: -50px;

  span {
    color: #9597AC;
    font-weight: 200;
    font-size: 10px;
  }

}

.max-size {
  color: #9597AC;
  font-size: 10px;
  font-weight: 200;
  text-decoration: underline;

}

</style>
