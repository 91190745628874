<template>
  <div class='site-owner-container'
       :class="{ 'overflow-hidden': currentTab === 'map' }">

    <!-- Profile needs to be before the Nav in order to work -->

    <Nav
      :currentTab='currentTab'
      @change-tab='changeTab($event)'
    />

    <DevMatching v-if="currentTab === 'matching'" />

    <BoringPage
      v-show="currentTab === 'project'"
    />
    <AllUsers v-show="currentTab === 'users'"/>

    <RegTech v-show="currentTab === 'regTech'" />

    <Profile v-show="currentTab === 'map'" />

    <Footer class='footer-alignment' :lang='lang' />
  </div>
</template>

<script>
import Nav from '@components/Nav.vue';
import BoringPage from '../boring/BoringPage';
import RegTech from '@layouts/RegTech/RegTech';
import Profile from '@layouts/civilization/Profile';
import DevMatching from '@layouts/matching/Matching';
import AllUsers from '@components/cms/AllUsers.vue'

export default {
  components: {
    Nav,
    Profile,
    BoringPage,
    DevMatching,
    RegTech,
    AllUsers
  },
  data () {
    return {
      lang: 'en',
      currentTab: 'map',
      previousTab: '',
      pages: {
        regTech: '/regTech',
        map: '',
        matching: '/matching',
        'project': '/project',
        'users': '/cms/users'
      }
    };
  },
  created () {
    localStorage.lang = this.lang = this.$router.history.current.params.lang;

    this.urlWatcher();
  },
  watch: {
    $route () {
      if (this.previousTab !== this.currentTab)
        this.$store.commit('map/updateMapName', this.currentTab);
      this.urlWatcher();
    },
    lang () {
      localStorage.lang = this.lang = this.$router.history.current.params.lang;
    },
    currentTab (e) {
      let lang = this.$router.history.current.params.lang;
      this.$router.push(`/${lang}/admin${this.pages[e]}`).catch(()=>{});
    }
  },
  methods: {
    changeTab (e) {
      this.previousTab = this.currentTab;
      this.currentTab = e;
    },
    urlWatcher () {
      this.previousTab = this.currentTab;
      if (this.$route.path.includes('admin/project')) this.currentTab = 'project';
      else if (this.$route.path.endsWith('admin/RegTech')) this.currentTab = 'regTech';
      else if (this.$route.path.includes('admin/matching')) this.currentTab = 'matching';
      else if (this.$route.path.includes('admin/cms/users')) this.changeTab('users');
      else if (this.$route.path.includes('admin')) this.currentTab = 'map';
    }
  }
};
</script>

<style lang='scss'>
.developer-onboarding > .questionnaires {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
