<template>
  <!-- <div class="dropdown-container"> -->
  <!-- <div class="dropdown-component"> -->
  <!-- <div class="dropdown-header d-flex" @click="toggleExpanded()">
        <div v-if="matrix" class="selected-texts">
          <p class="selected-title">{{ selectedProject }}</p>
          <p class="selected-item">{{ selectedSite || placeHolder }}</p>
        </div>
        <div class="selected-texts" v-else>
          <p class="selected-title">{{ selectedSite || placeHolder }}</p>
          <p class="selected-item">{{ selectedStatus || '' }}</p>
        </div>

        <img
          class="dd-arrow"
          src="/assets/caret-down.svg"
          :class="{ expanded: expanded }"
          alt=""
        />
      </div> -->
  <div class="dropdown" v-show="expanded" :style="user === 1 ? 'left: 330px' : '140px'">
    <div v-if="items && items.length > 0">
      <div v-for="(item, index) of items" :key="index">
        <div v-if="!matrix">
          <p
            class="item-title item"
            :class="{ active: selectedSite === item.text }"
            @click="activateItem(item.id)"
          >
            {{ item.text }}
          </p>
        </div>

        <div v-else>
          <p
            class="item-title"
            :class="{ active: selectedProject === item.text }"
          >
            {{ item.text }} 
            <span v-if="matched" class="status matched">Matched</span>
            <span v-if="!matched" class="status in-progress">In progress</span>
          </p>
          <div
            class="row item"
            v-for="(child, childIndex) of item.children"
            :key="childIndex"
            @click="matrixActivateItem(child.id, true)"
          >
            <p
              class="item-text"
              :class="{ active: selectedSite === child.text }"
            >
              {{ child.text }}
            </p>

            <p v-if="child.status" class="item-type">{{ child.status }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="item-title">
        {{ emptyMessage }}
      </p>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
export default {
  props: {
    /**
     * Specifies whether the dropdown will be 2-dimentional or
     * not.
     *
     * matrix Dropdown:
     * Each item in this dropdown has its own array of sub-
     * items, or "children", making the "items" array a
     * matrix.
     */
    matrix: Boolean,
    emptyMessage: String,

    items: {
      type: Array,
      required: true,

      // TODO: Each array object should be of a certain type
      // and has to be validated.
      // Paused: Awaiting typescript refactor
    },

    placeHolder: String,

    /**
     * If any item needs to be pre-selected, pass its ID
     */
    preSelectedId: String,
    dropdown: Boolean,
  },

  data() {
    return {
      selectedItem: undefined,
      expanded: false,
      selectedProject: undefined,
      selectedStatus: undefined,
      selectedSite: undefined,
      matched: false,
      user: JSON.parse(localStorage.loggedUser || '{}').userType || 0
    };
  },

  watch: {
    items() {
      this.preSelect();
    },

    preSelectedId() {
      this.preSelect();
    },
    selectedProject() {
      this.$forceUpdate();
    },
    dropdown(expanded) {
      this.expanded = expanded;
    },
  },

  mounted() {
    this.preSelect();
  },

  methods: {
    preSelect() {
      if (this.preSelectedId) {
        if (this.matrix) this.matrixActivateItem(this.preSelectedId);
        else
          this.selectedItem = this.items.find(
            (item) => item.id === this.preSelectedId
          );
      }
      this.setupSelected();
    },

    activateItem(preSelectedId) {
      this.selectedItem = this.items.find((item) => item.id === preSelectedId);
      this.expanded = false;

      if (this.selectedItem) {
        this.$emit('data', this.selectedItem);
        this.setupSelected();
      }
      this.$emit("toggleDropdown", false);
    },

    matrixActivateItem(childId, emit) {
      let parentId, foundChild;

      for (let item of this.items) {
        foundChild = item.children?.find((child) => child.id === childId);

        if (foundChild) {
          parentId = item.id;
          break;
        }
      }

      this.selectedItem = foundChild;
      this.$emit("toggleDropdown", false);
      if (emit) this.$emit('data', { parentId, childId });
    },

    toggleExpanded() {
      this.expanded = !this.expanded;
    },

    setupSelected() {
      if (!this.preSelectedId) this.selectedItem = undefined;
      if (!this.selectedItem) {
        this.selectedProject = undefined;
        this.selectedSite = undefined;
        this.selectedStatus = undefined;
        return;
      }
      this.selectedStatus = this.selectedItem.status;

      this.selectedSite = this.selectedItem.text;
      for (let item of this.items)
        if (item.children?.find((child) => child.id === this.selectedItem.id)) {
          this.selectedProject = item.text;
          this.selectedStatus = item.status;
          return;
        }
    },
  },
};
</script>

<style scoped lang='scss'>
@import '@styles/dropdown.scss';
</style>
