<!--
  Static buttons use the generic ga-button component.
  
  What makes them static is that they take the texts for their
  value/sub-value from the ids.yaml, based on their assigned
  gaid.
-->

<template>
  <div>
    <gaButton
      :type='type'
      :gaid='gaid'
      :group='group'
      :readOnly='readOnly || disable'
      :multiSelectGroup='multiSelectGroup'
      :active='isActive'
      :value='getGaidName(gaid)'
      :definition='getGaidDefinition(gaid)'
      :image='image'
      :error='errorMessage'
      :warning='warningMessage'
      :page='page'
      @click='onClick'
    />
    <div class='mt-auto' v-if='warningMessage'>
      <p class='error'>
        <img
          src='/assets/exclamation-small.svg'
        />
        {{ warningMessage }}
      </p>
    </div>   
  </div>
</template>

<script>
import ids from '@common/ids.yaml';
import gaButton from '@components/inputs/generic/ga-button';

export default {
  name: 'Button',

  components: {
    gaButton
  },

  props: {
    page: String,
    type: String,
    gaid: String,
    group: String,
    active: Boolean,
    multiSelectGroup: String,
    readOnly: Boolean,
    warning: String,
    image: String
  },

  watch: {
    active () {
      this.isActive = this.active;
    },
    siteId () {
      if (this.siteId !== undefined) {
        this.disabledText();
      }
    }
  },

  data () {
    return {
      ids: ids,
      lang: 'en',
      isActive: this.active,
      disable: false,
      errorMessage: undefined,
      warningMessage: undefined
    };
  },

  mounted () {
    localStorage.lang = this.lang = this.$router.history.current.params.lang;
    this.disabledText();
    this.$store.subscribe(this.onStoreChange);
  },
  computed: {
    siteId () {
      return this.$store.state.siteOwner.selectedSiteId;
    },
    inputParents () {
      return this.$store.state.inputs.parents;
    }
  },
  methods: {
    onClick (active) {
      this.isActive = active;
      this.$emit('click', { [this.gaid]: this.isActive });
    },

    getGaidName (gaid) {
      if (gaid && this.lang) return ids[gaid][this.lang].name;
    },

    getGaidDefinition (gaid) {
      if (gaid && this.lang) return ids[gaid][this.lang].definition;
    },
    disabledText () {
      this.clearConstraints();
      if (!ids[this.gaid]?.constraints) return;

      let answers = {...this.inputParents[this.siteId]?.answers};
      if (!answers) return;
      if (!answers.gaid39) answers.gaid39 = answers.gaid291;

      for (const constraint of ids[this.gaid].constraints) {
        let executeAction = true;
        for (const condition of constraint.conditions) {
          switch (condition.type) {
            case 'min':
              if (answers[condition.gaid] >= condition.value)
                executeAction = false;
              break;
            case 'max':
              if (answers[condition.gaid] <= condition.value)
                executeAction = false;
              break;
            case 'answered':
              if (!answers[condition.gaid])
                executeAction = false;
              break;
          }
          if (!executeAction) break;
        }
        if (executeAction) this.action(constraint);
      }
    },
    clearConstraints(){
      this.disable = false;
      this.errorMessage = undefined;
      this.warningMessage = undefined;
    },
    action (constraint) {
      if (constraint.action === 'disable') {
        this.disable = true;
        this.errorMessage = constraint[this.lang].message;
      } else if (constraint.action === 'warning') {
        this.warningMessage = constraint[this.lang].message;
      }
    },
    onStoreChange (mutation) {
      switch (mutation.type) {
        case 'inputs/emitValueChange':
          this.disabledText();
          break;
      }
    }
  }
};
</script>
