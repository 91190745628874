<template>
  <div class="calendly" id="Calendly">
    <p class="title">
      {{ calendly.title }}
    </p>
    <div class="calendly-contact">
      <a :href="calendly.url" target="_blank">
        <img class="icon" src="/assets/calendly.svg" />
        <p>{{ calendly.contact }}</p>
        <div class="green-button-arrow">
          <img src="/assets/arrow-right-white.png" />
        </div>
      </a>
    </div>
    <p class="description">
      {{ calendly.description }}
    </p>
  </div>
</template>

<script>
import calendly from "@content/nl/general/header.yaml";
import calendlyEn from "@content/en/general/header.yaml";
export default {
  name: "Calendly",
  data() {
    return {
      calendly: {},
    };
  },
  props: {
    lang: String,
  },
  mounted() {
    this.init();
  },
  watch: {
    lang() {
      this.init();
    },
  },
  methods: {
    init() {
      localStorage.lang = this.lang = this.$router.history.current.params.lang;
      let data = this.lang === "en" ? calendlyEn : calendly;
      this.calendly = data.calendly;
    },
  },
};
</script>

<style lang="scss">
@import "@styles/home-page.scss";
@import "@styles/mobile-mode.scss";
</style>