<template>
  <div class='site-owner-container'
       :class="{ 'overflow-hidden': currentTab === 'map' }">

    <!-- Profile needs to be before the Nav in order to work -->

    <Nav
      :currentTab='currentTab'
      @change-tab='changeTab($event)'
      @openGuideBox='guideBox($event)'
      :projectDevId='projectDevId'
      
    />

    <DevMatching v-if="currentTab === 'matching'" />

    <BoringPage
      v-show="currentTab === 'project'"
    />

    <BoringPage
      v-show="currentTab === 'profile'"
      :projectDevId='projectDevId'
    />

    <!-- <RegTech v-show="currentTab === 'regTech'" :readOnly="true"/> -->

    <Profile
      v-show="currentTab === 'map'"
      :projectDevId='projectDevId'
    />
    <Guide v-show='guideBoxOpened' @closeGuideBox="closeGuideBox()"/>

    <Footer :lang='lang' />
  </div>
</template>

<script>
import Nav from '@components/Nav.vue';
import RequestHelper from '../../../common/helpers/request.helper';
import BoringPage from '../boring/BoringPage';
// import RegTech from '@layouts/RegTech/RegTech'
import Profile from '@layouts/civilization/Profile';
import DevMatching from '@layouts/matching/Matching';
import Guide from '@layouts/home/Guide.vue';
import chevronTabNl from '@content/nl/i/1.yaml';
import chevronTabEn from '@content/en/i/1.yaml';

export default {
  components: {
    Nav,
    Profile,
    DevMatching,
    // RegTech,
    BoringPage,
    Guide
  },
  data () {
    return {
      lang: '',
      answers: {},
      currentTab: 'profile',
      previousTab: '',
      projectDevId: '',
      requestHelper: new RequestHelper(this.$router),
      pages: {
        // regTech: '/RegTech',
        map: '',
        matching: '/matching',
        'project': '/project',
        profile: '/profile'
      },
      section: localStorage.lang === 'en' ? chevronTabEn.navigation : chevronTabNl.navigation,
      subSection: localStorage.lang === 'en' ? chevronTabEn.sections[0].section : chevronTabNl.sections[0].section,
      guideBoxOpened: false,
    };
  },
  created () {
    localStorage.lang = this.lang = this.$router.history.current.params.lang;
    this.requestHelper.getQuery(
      'projectDevQuery',
      (response) => {
        this.projectDevId = response.data[0]._id;
        this.answers = response.data[0].answers;
        let commitData = {
          parentId: this.projectDevId,
          newAnswers: this.answers
        };
        this.$store.commit('inputs/updateParentAnswers', commitData);
      }
    );
    this.urlWatcher();

    if (this.$route.path.endsWith('/i')) {
      this.guideBoxOpened = true;
    }
  },
  watch: {
    $route () {
      if (this.previousTab !== this.currentTab)
        this.$store.commit('map/updateMapName', this.currentTab);
      this.urlWatcher();
    },
    lang () {
      localStorage.lang = this.lang = this.$router.history.current.params.lang;
    },
    currentTab (e) {
      let lang = this.$router.history.current.params.lang;
      this.$router.push(`/${lang}/dev${this.pages[e]}`).catch(()=>{});
    }
  },
  methods: {
    guideBox(){
      this.guideBoxOpened = true;
      const path = this.currentTab === "map" ? `/${this.lang}/dev/i` :  `/${this.lang}/dev/${this.currentTab}/i`
      this.$router.push({path, query: {section: this.section, subsection: this.subSection}})
    },
    closeGuideBox(){
      this.guideBoxOpened = false;
      const path = this.currentTab === "map" ? `/${this.lang}/dev` :  `/${this.lang}/dev/${this.currentTab}`
      this.$router.push({path, query: ''})
    },
    changeTab (e) {
      this.previousTab = this.currentTab;
      this.currentTab = e;
    },
    urlWatcher() {
      this.previousTab = this.currentTab;
      if (this.$route.path.includes('dev/project')) this.currentTab = 'project';
      else if (this.$route.path.includes('dev/matching')) this.currentTab = 'matching';
      else if (this.$route.path.includes('dev/profile')) this.currentTab = 'profile';
      else if (this.$route.path.includes('dev')) this.currentTab = 'map';
    }
  }
};
</script>

<style lang='scss'>
.developer-onboarding > .questionnaires {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
