<template>
  <div class='all-users'>
    <div class='newUser'>
      <p>Users Count: {{users.length}}</p>
      <button class='createUser' @click='createUserPopUp()'>Create new user</button>
      <div class='createUserPopUp' :class='{userPopUp: userPopUp}'>
        <div class='inputs'>
          <p class='regTitle'>Enter email, password and select the user's role.</p>
          <select class="roles" id="roles" @change="getDropDownValues($event)">
            <option value="none" selected disabled hidden>Select a role: </option>
            <option value="0">Site owner</option>
            <option value="1">Project dev</option>
            <option value="2">Admin</option>
          </select>
          <input type='email' class='email' placeholder='Email address' id='email' autocomplete='email' />
          <input v-if="userRole !== 2" type='text' class='pass' placeholder='Password' id='pass' autocomplete='new-password' /> 
          <label class="sendEmail-label" for="scales">
            <input type="checkbox" class="sendEmail" name="scales" v-model="sendEmail" />Send Email to user.
          </label>
          <p style="display: none" id="createdText">The user has been created.</p>
          <p style="display: none" id="errorText">There is empty field.</p>
          <button class="register" @click="register()">Register</button>
        </div>
      </div>
    </div>

    <button class="btn active" @click="setType(undefined)">Show all</button>
    <button class="btn" @click="setType(0)">Site owners</button>
    <button class="btn" @click="setType(1)">Project devs</button>
    <button class="btn" @click="setType(2)">Admins</button>
    <button class="btn" @click="hideWithoutSites">Toggle users without sites</button>
    <input type="text" placeholder="Search email or name" v-model="search" autocomplete="off" class="search" />
    
    <table class='table'>
      <thead>
        <th class="nameEmail">Name and Email</th>
        <th class="password">Password</th>
        <th class="role">Role</th>
        <th class="impersonate">Impersonate</th>
        <th class="expanded">Expand</th>
      </thead>
    </table>
    <div v-for='user in users' :key='user.id' >
      <UserComponent
        :user='user'
        :haveSites="haveSites"
        v-if="
        (filterType === undefined && search === '') || 
        (filterType === user.userType && (user.name.includes(search, 0) || user.email.includes(search, 0))) || 
        (filterType === undefined && (user.name.includes(search, 0) || user.email.includes(search, 0)))" 
      />
    </div>
  </div>
</template>

<script>
import languageNl from '@content/nl/general/navigation.yaml';
import languageEn from '@content/en/general/navigation.yaml';
import RequestHelper from '@common/helpers/request.helper';
import UserComponent from './UserComponent.vue';

export default {
  name: 'AllUsers',
  components: { UserComponent },
  data () {
    return {
      langTranslation: {},
      users: [],
      userPopUp: false,
      requestHelper: new RequestHelper(this.$router),
      userRole: undefined,
      email: '',
      password: '',
      filterType: undefined,
      search: '',
      haveSites: false,
      sendEmail: false
    };
  },

  created () {
    this.init();
  },

  methods: {
    init () {
      this.langTranslation = localStorage.lang === 'en' ? languageEn : languageNl;
      this.getUserData();
    },

    async getUserData () {
      if (this.users.length !== 0) return;
      let { data } = await this.requestHelper.getQuery(`allUsersQuery`);
      for (const user of data) {
        let name = "undefined";
        if (user.answers) {
          if (user.answers.gaid273) {
            name = user.answers.gaid273 + ' ' + user.answers.gaid274;
            }else if (user.answers.firstName) {
              name = user.answers.firstName + ' ' + user.answers.lastName;
            }
          }
        this.users.push({ name, email: user.email, id: user._id, userType: user.userType });
      }
    },

    createUserPopUp () {
      this.userPopUp = !this.userPopUp;
    },

    getDropDownValues(event){
      this.userRole = parseInt(event.target.value);
    },

    async register(){
      this.email = document.getElementById("email").value
      if(this.userRole !== 2)
        this.password = document.getElementById("pass").value
      let text = document.getElementById("createdText")
      let errorText = document.getElementById("errorText")
      if(this.email !== '' && (this.password !== '' || this.userRole === 2) && this.userRole != null || ''){
        await this.requestHelper.postQuery('createNewAccount',{ 
          email: this.email,
          password: this.password, 
          userType: this.userRole,
          sendEmail: this.sendEmail
        },(res) => {
          this.users.unshift(res.data)
        });
          text.style.display = 'block' 
          text.style.color = '#82c37f'
          errorText.style.display = 'none';
          setTimeout(() => {
            location.reload();
          }, 500);
      } else {
        errorText.style.display = 'block';
        errorText.style.color = 'orange';
      }
    },
    
    setType(type){
      this.filterType = type;
    },
    
    hideWithoutSites(){
      this.haveSites = !this.haveSites;
    }

  }
};
</script>
<style lang='scss' scoped>
@import '@styles/all-users.scss';

</style>
