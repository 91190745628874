<template>
  <div class='p-3 flex flex-col'>
    <div class='pieChart' :id='chartId' />
  </div>
</template>
<script>
import { pie as d3pie, arc as d3arc, select as d3select } from 'd3';

export default {
  name: 'DoughnutChartItem',
  props:{
    data: Array,
    chartId: { type: String, default: 'data' }
  },
  data () {
    return {
      currentI: -1,
      isChanged: false,
      redrawn: false,
      isClicked: false,
      text: 'Great project few challenges likely',
      labels: []
    };
  },
  watch:{
    data() {
      this.init();
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      this.draw(this.data);
      this.labels = [];
      for (let i = 0; i < this.data.length; i++) {
        this.labels.push(this.data[i][0]);
      }
    },
    draw (data) {
      this.removeOld();
      let radius = 90;
      let innerRadius = 67.5;
      let image_width = 20;
      let image_height = 20;

      // Append the svg object to the div called 'data'.
      let svg = d3select(`#${this.chartId}`)
        .append('svg')
        .attr('width', radius * 3.8)
        .attr('height', radius * 4)
        .append('g')
        .attr('transform', 'translate(' + (radius + 70) + ',' + (radius * 2) + ')');

      // Creating the pie.
      const pie = d3pie()
        .startAngle(0)
        .endAngle(Math.PI * 2 + 3 * (Math.PI / 4))
        .value(function(d) {
          return d[1];
        });

      const t = this;

      // Texts in the center.
      svg
        .append('svg:text')
        .data(pie(data))
        .attr('dy', '.35em')
        .attr('text-anchor', 'middle')
        .attr('font-size', '12')
        .text(() => t.currentI === -1 ? 'Overview' : data[t.currentI][3]).call(this.wrap, 120)
        .attr('fill', function() {
          return t.currentI === -1 ? '#54A54F' : data[t.currentI][2];
        });

      // Creating the arcs.
      let labelArc = d3arc().innerRadius((radius * 2) / 1.5).outerRadius((radius * 1.5));

      svg
        .selectAll('w')
        .data(pie(data))
        .join('path')
        .attr('d', d3arc().innerRadius(innerRadius).outerRadius(radius))
        .attr('stroke', t.currentI === -1 ? 'white' : data[t.currentI][2])
        .style('stroke-width', t.currentI === -1 ? '1.5px' : '0.7px')
        .attr('fill', function(d) {
          return t.currentI === -1 ? d.data[2] : data[t.currentI][2];
        })
        .style('opacity', 1)
        .attr('cursor', 'pointer')
        // Hover and click effects for the arcs.
        .on('mouseover', function(event, element) {
          if (!t.isClicked) {
            t.hoverRedraw(data, element);
          }
        })
        .on('mouseout', function() {
          setTimeout(() => {
            if (t.isClicked) return;
            if (t.isChanged) {
              t.isChanged = false;
              return;
            }
            t.currentI = -1;
            t.isChanged = false;
            t.init();
          }, 100);
        })
        .on('click', function() {
          setTimeout(() => {t.isClicked = true;}, 10);
        });
      d3select(`#${this.chartId}`)
        .on('click', function() {
          if (t.isClicked) {
            t.isClicked = false;
            t.currentI = -1;
            t.init();
          }
        });

      // Adding the Labels.
      svg
        .selectAll('w')
        .data(pie(data))
        .join('text')
        .text((d) => d.data[0])
        .attr('dy', '.35em')
        .call(this.wrap, 100)
        .attr('cursor', 'pointer')
        .attr('font-weight', function(d) {
          return d.index !== t.currentI ? 'normal' : '';
        })
        .attr('font-size', '12')
        .attr('text-anchor', 'middle')
        .attr('fill', function(d) {
          return d.index !== t.currentI ? '' : data[t.currentI][2];
        })
        .attr('transform', function(d) {
          let a = labelArc.centroid(d);
          return 'translate(' + a + ')';
        })
        // Hover and click effects for the labels.
        .on('mouseover', function(event, element) {
          if (!t.isClicked) {
            t.hoverRedraw(data, element);
          }
        })
        .on('mouseout', function() {
          if (t.isClicked) return;
          t.currentI = -1;
          t.init();
        })
        .on('click', function() {
          setTimeout(() => {t.isClicked = true;}, 10);
        });
      d3select(`#${this.chartId}`)
        .on('click', function() {
          if (t.isClicked) {
            t.isClicked = false;
            t.currentI = -1;
            t.init();
          }
        });

      // Adding the icons.
      svg
        .selectAll('w')
        .data(pie(data))
        .join('svg:image')
        .attr('xlink:href', function(d) {
          return d.data[4];
        })
        .attr('transform', function(d) {
          let a = labelArc.centroid(d);
          a[0] -= 10;
          a[1] -= 30;
          return 'translate(' + a + ')';
        })
        .attr('width', image_width)
        .attr('height', image_height)
        .attr('filter', function(d) {
          return d.index !== t.currentI ? '' : data[t.currentI][5];
        })
    },
    // Hover function.
    hoverRedraw (data, element) {
      if (this.redrawn){
        this.redrawn = false;
        return;
      }
      if (this.currentI !== -1){
        this.isChanged = true;
        return;
      }
      if (this.currentI === element.index) return;
      this.currentI = element.index;
      this.redrawn = true;
      this.draw(data);
    },
    // Wrap function for the texts.
    wrap (text, width) {
      text.each(function() {
        let text = d3select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1,
          y = text.attr('y'),
          dy = parseFloat(text.attr('dy')),
          tspan = text.text(null).append('tspan').attr('x', 0).attr('y', y)
            .attr('dy', dy + 'em');
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(' '));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            tspan = text.append('tspan').attr('x', 0).attr('y', y).attr('dy', ++lineNumber * lineHeight + dy + 'em').text(word);
          }
        }
      });
    },
    // Remove old child function.
    removeOld () {
      const el = document.querySelector(`#${this.chartId}`);
      if (el?.firstChild) el?.removeChild(el?.firstChild);
    }
  }
};
</script>
<style>
.pieChart > * {
  overflow: initial !important;
}

.pieChart {
  width: 270px;
  height: 480px;
}
</style>
