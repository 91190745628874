<template>
  <!-- type checkbox -->
  <div v-if="type === 'checkbox'" class='checkbox custom-control custom-checkbox'>
    <input
      class='custom-control-input ga-input'
      type='checkbox'
      :gaid='gaid'
      :id='id'
      @click='checkbox($event)'
      :checked="inputParents['user'] ? inputParents['user'].answers.gaid453 : false"
    />
    <label class='custom-control-label' :for='id' v-if="text">{{ text }}</label>
    <label class='custom-control-label' :for='id' v-else>
      <template v-for='(t, i) of translation.checkbox'>
        <span v-if='!t.url' :key='i'> {{ t.text }} </span>
        <router-link v-else :to='`/${lang}/${t.url}`' target="_blank" :key='i'> {{ t.text }}</router-link>
      </template>
    </label>
  </div>
</template>

<script>
import translationsNl from '@content/nl/auth/translations.yaml';
import translationsEn from '@content/en/auth/translations.yaml';

export default {
  data () {
    return {
      lang: localStorage.lang,
      id: undefined
    };
  },
  props: {
    type: String,
    gaid: String,
    value: undefined,
    text: String
  },
  computed: {
    inputParents () {
      return this.$store.state.inputs.parents;
    },
  },
  watch: {
    $route () {
      this.init();
    },
    value () {
      this.$store.commit('inputs/emitValueChange');
    }
  },
  beforeMount () {
    // generate a random id to remove an error from the console
    this.id = Math.floor(Math.random() * 899999) + 100000 + "";
  },
  created () {
    this.init();
  },

  methods: {
    init () {
      this.translation = this.lang === 'en' ? translationsEn : translationsNl;
    },
    checkbox (event) {
      const checked = event.target.checked;
      const compiled = { [this.gaid]: checked };

      this.$emit('change', compiled);
    }
  }
};
</script>

<style lang='scss'>
@import '@styles/boring-page.scss';
@import '@styles/reject-site.scss';

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary !important;
}

.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  background-color: $checkbox;
}

.popup-checkbox-input {
  .checkbox {
    width: 340px;
    margin: 0;
  }
}

.checkbox {
  width: 255px;
  margin: 15px auto 0;
  font-size: 12px;
  font-family: $font__IBM;
  color: $text;
  text-align: left;
  font-weight: 400;

  label {
    line-height: 24px;
    align-items: baseline;

    a {
      text-decoration: underline;
    }
  }
}

</style>
