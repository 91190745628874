<template>
  <div class="container" id="helper">
    <div class="helper">
      <div class="col-12">
        <h1>{{ title }}</h1>
        <h3>{{ description }}</h3>
      </div>
      <div
        class="col-12 info-helper"
        v-for="help of explanations.explanations"
        :key="help.image"
      >
        <div class="row">
          <img class="col-2" :src="help.image" />
          <h2 class="col-10">{{ help.information }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helperTranslationsNl from "@content/nl/general/helper.yaml";
import helperTranslationsEn from "@content/en/general/helper.yaml";

export default {
  name: "Helper",
  data() {
    return {
      explanations: {},
      title: "",
      description: "",

      translations: {
        en: helperTranslationsEn,
        nl: helperTranslationsNl,
      },
    };
  },
  props: {
    lang: String,
  },

  computed: {
    userType() {
      return this.$store.state.user.userType;
    },
  },

  mounted() {
    this.init();
  },
  watch: {
    lang() {
      this.init();
    },
  },
  methods: {
    init() {
      const data = this.translations[this.lang];
      this.explanations = data;
      this.title = data.title;
      this.description = data.description;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@styles/main.scss";
@import "@styles/home-page.scss";
</style>