var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[(_vm.showSavedState() === true && _vm.showActionLabels)?_c('label',[_vm._v(" "+_vm._s(_vm.translations.saving))]):(_vm.showSavedState() === false && _vm.showActionLabels)?_c('label',[_vm._v(" "+_vm._s(_vm.translations.saved))]):_vm._e(),(_vm.type === "password-showable")?_c('ShowHidePassword',{staticStyle:{"right":"35px","top":"20px"},attrs:{"field":`input-${_vm.gaid}`}}):_vm._e(),(
      _vm.type === 'text' ||
        _vm.type === 'password' ||
        _vm.type === 'password-showable' ||
        _vm.type === 'project-selector' ||
        _vm.type === 'number' ||
        _vm.type === 'email'
    )?_c('input',{ref:`input-${_vm.gaid}`,staticClass:"ga-input",class:_vm.page === 'project' && _vm.type === `text` ? 'input-box text' : '',attrs:{"disabled":_vm.readOnly,"type":_vm.type === 'number' ? 'text' : _vm.type === 'password-showable' ? 'password' : _vm.type,"pattern":_vm.type === 'number' ? '[\\d.]+' : '.*',"autocomplete":_vm.ids[_vm.gaid].autocomplete ||  "off","gaid":_vm.gaid,"group":_vm.group,"multiSelectGroup":_vm.multiSelectGroup,"placeholder":_vm.placeholder,"onclick":"this.select()"},on:{"blur":_vm.onBlur,"keyup":_vm.onKeyUp,"keydown":function($event){$event.keyCode === 13 ? $event.preventDefault() : false},"keypress":function($event){return _vm.onNumberKeypress($event)}}}):_vm._e(),(_vm.type === 'textarea')?_c('textarea',{staticClass:"ga-textarea",attrs:{"disabled":_vm.readOnly,"type":_vm.type,"gaid":_vm.gaid,"group":_vm.group,"multiSelectGroup":_vm.multiSelectGroup,"placeholder":_vm.placeholder,"rows":_vm.rows},on:{"blur":_vm.onBlur,"keyup":_vm.onKeyUp}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }