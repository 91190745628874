import axios from 'axios';

const API_URL = window.location.origin;

export default axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },

});
