const axios = require('axios');
const { logout } = require('./logout.helper');
export default class RequestHelper {
  #router;
  #FUNCTIONS_URL = '/.netlify/functions/';

  getConfig () {
    const config = { headers: { Auth: `Bearer ${localStorage.token}` }};

    if(process.env.VUE_APP_CONTEXT == "acceptance") 
      config.headers["Authorization"] = `Basic ${process.env.VUE_APP_BASIC_AUTH}`;
    
    return config;
  }

  constructor (router) {
    this.#router = router;
  }

  getAnswers (store, parentId, segmentType) {
    this.getQuery(
      `segmentAnswers?parentId=${parentId}&segmentType=${segmentType}`,
      (response) => {
        const answers = !response.data ? {} : response.data.regtech.answers;

        store.commit('inputs/updateParentAnswers', {
          parentId: parentId,
          newAnswers: answers
        });
      }
    );
  }

  // The different request types
  getQuery (url, callback, errorCallback, cancelToken) {
    const config = { ...this.getConfig() };
    if (cancelToken) config.cancelToken = cancelToken;
    return this.handleResponse(
      axios.get(this.#FUNCTIONS_URL + url, config),
      callback,
      errorCallback
    );
  }

  postQuery (url, data, callback, errorCallback) {
    return this.handleResponse(
      axios.post(this.#FUNCTIONS_URL + url, data, this.getConfig()),
      callback,
      errorCallback
    );
  }

  putQuery (url, data, callback, errorCallback) {
    return this.handleResponse(
      axios.put(this.#FUNCTIONS_URL + url, data, this.getConfig()),
      callback, errorCallback
    );
  }

  deleteQuery (url, callback, errorCallback) {
    return this.handleResponse(
      axios.delete(this.#FUNCTIONS_URL + url, this.getConfig()),
      callback, errorCallback
    );
  }

  // Handles the response of the request
  handleResponse (request, callback, errorCallback) {
    return request
      .then((response) => {
        // If we have a callback we execute it, otherwise we return the response
        if (callback) callback(response);
        else return response;
      })
      .catch((reason) => this.handleError(reason, errorCallback));
  }

  // We catch the error and if it's 401 (Unauthorised), we logout the user
  handleError (reason, errorCallback) {
    if (reason?.response?.status === 401) logout(this);
    if (errorCallback) errorCallback(reason?.response);
  }
}
