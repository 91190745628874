<template>
<div class="footer">
    <div class="container">
        <div class="row">
            <div class="footer-logo">
                <img src="/assets/logo.png" class="logo-image" />
            </div>
            <div class="links-positioning">
                <h3 class="footer-title">Links</h3>
<!--              todo this should be a for cycle-->
                <router-link :to="`/${lang}/website-tos`">
                    <p @click="execute()" class="footer-title">
                        {{ footer.titles['first-link'] }}
                    </p>
                </router-link>
                <router-link :to="`/${lang}/platform-tos`">
                    <p @click="execute()" class="footer-title">
                        {{ footer.titles['second-link'] }}
                    </p>
                </router-link>
                <router-link :to="`/${lang}/privacy-policy`">
                    <p @click="execute()" class="footer-title">
                        {{ footer.titles['third-link'] }}
                    </p>
                </router-link>
<!--                <router-link :to="`/${lang}/project-developer`">-->
<!--                    <p @click="execute()" class="footer-title">-->
<!--                        {{ footer.titles['fourth-link'] }}-->
<!--                    </p>-->
<!--                </router-link>-->
<!--                <router-link :to="`/${lang}/specialists`">-->
<!--                    <p @click="execute()" class="footer-title">-->
<!--                        {{ footer.titles['fifth-link'] }}-->
<!--                    </p>-->
<!--                </router-link>-->
            </div>
            <div class="row-info">
                <h3 class="footer-title">{{ footer.titles.contact }}</h3>
                <a :href="`mailto:${contact}`" v-for="contact of footer.contacts" :key="contact">
                    <p class="font">
                        <img class="icon" src="/assets/envelope-footer.svg" />
                        {{ contact }}
                    </p>
                </a>
            </div>
            <div class="row-info">
                <h3 class="footer-title">{{ footer.titles.social }}</h3>
                <a :href="footer.linkedin.link" target="_blank">
                    <p class="font-linkedin">
                        <img class="icon" src="/assets/linkedin-footer.svg" />
                        {{ footer.linkedin.name }}
                    </p>
                </a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import footerNl from '@content/nl/footer.yaml';
import footerEn from '@content/en/footer.yaml';

export default {
    name: 'Footer',
    data() {
        return {
            footer: {},
            lang: ''
        };
    },
    watch: {
        $route() {
            this.init();
        }
    },
    created() {
        this.init();
    },
    methods: {
        execute(){
            window.scrollTo(0,0);
        },
        init() {
            localStorage.lang = this.lang = this.$router.history.current.params.lang;
            this.footer = this.lang === 'en' ? footerEn : footerNl;
        }
    }
};
</script>

<style lang="scss">
@import '@styles/main.scss';
@import '@styles/home-page.scss';
</style>
