<template>
  <div class='process' id='process'>
    <div class='process'>
      <div class='col-12 macbook-image'>
        <h1>{{ title }}</h1>
        <div class='macbook-image container'>
          <div v-for="(step, i) of data['step-information']" :key='`${i}${hover[i]}`'>
            <lazy-img class='img' :blur='30' v-show='hover[i]' :src='step.image' :key='hover[i]' />
          </div>
        </div>
      </div>
      <div class='row container'>
        <div class='col-12 col-sm-12 col-md-6 col-xl-4 info-steps margin-fixing'
             v-for="(step, i) of data['step-information']"
             :key='i'
             @mouseover="hover = [false, false, false]; hover[i] = true"
             @mouseleave="hover = [true, false, false]">
          <p class='background-numbers'>{{ i + 1 }}</p>
          <h2>{{ step.label }}</h2>
          <p class='information'>{{ step.information }}</p>
        </div>
    </div>
</div>
  </div>
</template>

<script>
import process from '@content/nl/general/process.yaml';
import processEn from '@content/en/general/process.yaml';

export default {
  name: 'Process',
  data () {
    return {
      data: {},
      title: '',
      label: '',
      description: '',
      link: '',
      contactText: '',
      hover: [true, false, false]
    };
  },
  props: {
    lang: String
  },
  mounted () {
    this.init();
  },
  watch: {
    lang () {
      this.init();
    }
  },
  methods: {
    init () {
      this.data = this.lang === 'en' ? processEn : process;
      this.title = this.data.title;
      this.label = this.data['introductory-title'];
      this.description = this.data['introductory-description'];
      this.link = this.data['contact-link'];
      this.contactText = this.data['contact-text'];
    }
  }
};
</script>

<style scoped lang='scss'>
@import "@styles/main.scss";
@import "@styles/home-page.scss";
@import '@styles/mobile-mode.scss';
</style>
