<template>
  <div :key='`${gaid}${value}`'>
    <p v-if='(!token || (value || roiValue) === undefined)' class='values'>
      <img src='/assets/model.energy.icons/eye-slash.svg' alt='' style="margin-bottom: -12.5%"/>
    </p>
    <p v-if='!token' class='requiringText'>{{ modelTranslation.withoutRegistration }}</p>
    <p v-if='(token && ((value || roiValue) === undefined))' class='requiringText'>{{ modelTranslation.withRegistration }}</p>
    <div class='row-and-text'>
      <div class='row' v-if='(token && value !== undefined)'>
        <p class='values'>{{ prefix }} {{ value || 0 }} {{ suffix }}</p>
      </div>
      <div class="row" v-if='(token && roiValue !== undefined)'>
        <p class="values">{{ prefix }} {{ roiValue.toFixed(2) }} {{suffix}}</p>
      </div>
<!--      todo this is bad, but it's a quick fix-->
      <p v-if='subtext.includes("{2}")' class='subtext'>
        {{ subtext.split("{2}")[0] }}<sub>2</sub>{{ subtext.split("{2}")[1] }}
      </p>
      <p v-else-if='(token && ((value || roiValue) !== undefined))' class='subtext'>{{ subtext }}</p>
    </div>
  </div>
</template>

<script>
import modelTranslationEn from '@content/en/model/translations.yaml';
import modelTranslationNl from '@content/nl/model/translations.yaml';

export default {
  name: 'ModelField',
  props: {
    gaid: String,
    subtext: String,
    prefix: String,
    suffix: String,
    roiValue: Number 
  },
  data () {
    return {
      modelTranslation: undefined,
      answers: {},
      value: undefined
    };
  },

  created () {
    this.init();
  },

  methods: {
    init () {
      localStorage.lang = this.lang = this.$router.history.current.params.lang;

      this.modelTranslation =
        this.lang === 'en' ? modelTranslationEn : modelTranslationNl;
      this.modelOutputs();
      this.$store.subscribe(this.onStoreChange);
    },

    onStoreChange (mutation) {
      switch (mutation.type) {
        case 'siteOwner/setSiteSelected':
        case 'inputs/updateParentAnswers':
          this.modelOutputs();
      }
    },
    modelOutputs () {
      this.value = (this.$store.state.inputs.parents[this.$store.state.siteOwner.selectedSiteId]?.answers || {})[this.gaid];
    }
  },

  computed: {
    token () {
      return localStorage.token;
    }
  }
};
</script>

<style scoped>

</style>
