<template>
  <div class='wrap'>
    <SizeChart :size='parseInt(siteSize)' :maxSize='dataObject["size"]' :key='siteSize' />
    <MetricsPieChart v-if='siteType !== undefined  && dataObject["siteType"]' :data='dataObject["siteType"]' />
    <StackedChart v-if='energyType !== undefined && dataObject["stackedChart"]'
                  :data='dataObject["stackedChart"]' />
    <YearlyAvg v-if='energyType && dataObject["yearlyAvg"]' title='Bar Chart' xKey='name'
               yKey='amount'
               :data='dataObject["yearlyAvg"]' />
    <EnergyUse v-if="energyUse && dataObject['energyUse']" :data='dataObject["energyUse"]' />

  </div>
</template>

<script>
import MetricsPieChart from '@layouts/civilization/Charts/MetricsChartsPie';
import EnergyUse from '@layouts/civilization/Charts/EnergyUse';
import SizeChart from '@layouts/civilization/Charts/SizeChart';
import YearlyAvg from '@layouts/civilization/Charts/YearlyAvg';
import StackedChart from '@layouts/civilization/Charts/StackedChart';
import RequestHelper from '@common/helpers/request.helper.js';

export default {
  components: {
    MetricsPieChart,
    SizeChart,
    EnergyUse,
    YearlyAvg,
    StackedChart
  },

  props: {
    parents: {},
    siteId: String,
    stepId: String
  },
  data () {
    return {
      siteSize: 0,
      siteTypeGaids: ['gaid1', 'gaid2', 'gaid3'],
      energyUseGaids: ['gaid167', 'gaid168'],
      siteType: undefined,
      energyType: undefined,
      energyUse: undefined,
      lang: localStorage.lang,
      requestHelper: new RequestHelper(this.$router),
      currentStepId: undefined,
      dataObject: {
        'size': 0,
        'siteType': undefined,
        'stackedData': undefined,
        'yearlyAvg': undefined,
        'energyUse': undefined
      }
    };
  },
  computed: {
    answers: {
      get: function() {
        return this.inputParents[this.selectedSiteId]?.answers || {};
      },
      set: function() {
      }
    },
    inputParents () {
      return this.parents || this.$store.state.inputs.parents;
    },
    selectedSiteId () {
      return this.siteId || this.$store.state.siteOwner.selectedSiteId;
    },
    currentSavedStep() {
      return this.stepId || this.$store.state.siteOwner.currentStepId;
    },
  },
  watch: {
    siteId: {
      immediate: true, 
      handler () {
        this.siteSelectedRefresh()
      },
    parents: {
      immediate: true,
      handler() {
        this.updateParentAnswersRefresh()
      }
    }
    }, 
    siteType () {
      this.getChartsData('siteType');
    },
    energyType () {
      this.getChartsData('stackedChart');
      this.getChartsData('yearlyAvg');
    },
    energyUse () {
      this.getChartsData('energyUse');
    },
    siteSize () {
      this.getSiteSize();
    }

  },
  created () {
    this.$store.subscribe(this.onStoreChange);
    this.getChartsData('size');
  },
  methods: {
    onStoreChange (mutation) {
      switch (mutation.type) {
        case 'inputs/updateParentAnswers':
          this.updateParentAnswersRefresh()
          break;
        case 'siteOwner/removeUserSite':
          this.dataObject['siteType'] = undefined;
          this.dataObject['stackedChart'] = undefined;
          this.dataObject['yearlyAvg'] = undefined;
          this.dataObject['energyUse'] = undefined;
          break;
        case 'siteOwner/setSiteSelected':
          this.siteSelectedRefresh();
          break;
        case 'map/initInstance':
          this.getSiteSize();
          break;
      }
    },
    siteSelectedRefresh() {
      this.siteType = undefined;
      this.energyType = undefined;
      this.energyUse = undefined;
      this.getSiteSize();
      this.getSiteType();
      this.getEnergyType();
      this.getEnergyUse();
    },
    updateParentAnswersRefresh() {
      if (this.inputParents[this.selectedSiteId] && this.inputParents[this.selectedSiteId].answers)
          this.answers = this.inputParents[this.selectedSiteId].answers;
      this.getSiteSize();
      this.checkAnswers();
    },
    checkAnswers(){
      if(this.currentSavedStep === 'qgaid5')
        this.getSiteType();
      if(this.currentSavedStep === 'qgaid20')
        this.getEnergyType();
      if(this.currentSavedStep === 'qgaid21')
        this.getEnergyUse();
    },
    getSiteSize () {
      this.siteSize = this.inputParents[this.selectedSiteId]?.answers['gaid39'] || this.inputParents[this.selectedSiteId]?.answers['gaid291'];
    },
    getSiteType () {
      if (this.answers['gaid1']) {
        this.siteType = 'gaid1';
      }
      if (this.answers['gaid2']) {
        this.siteType = 'gaid2';
      }
      if (this.answers['gaid3']) {
        this.siteType = 'gaid3';
      }
    },

    getEnergyType () {
      if (this.answers['gaid32']) {
        this.energyType += 'gaid32';
      }
      if (this.answers['gaid33']) {
        this.energyType += 'gaid33';
      }
    },

    getEnergyUse () {
      if (this.answers['gaid167']) {
        this.energyUse = 'gaid167';
      }
      if (this.answers['gaid168']) {
        this.energyUse = 'gaid168';
      }
    },

    pieChartsAnswers (gaids, chartName, answer) {
      try {
        for (let i = 0; i < gaids.length; i++) {
          if (gaids[i] === answer) {
            this.dataObject[`${chartName}`][i][3] = true;
          }
        }
      } catch (e) {this.dataObject[`${chartName}`] = false;}
    },
    async getChartsData (type) {
      const { data } = await this.requestHelper.getQuery(
        //todo get the current municipality
        `metricsQuery?type=${type}&municipality=Amsterdam`
      );
      this.dataObject[type] = data;
      this.pieChartsAnswers(this.siteTypeGaids, 'siteType', this.siteType);
      this.pieChartsAnswers(this.energyUseGaids, 'energyUse', this.energyUse);
    }

  }
};
</script>

<style>
::-webkit-scrollbar {
  display: none;
}

.wrap {
  position: absolute;
  top: 130px;
  height: 520px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.section {
  display: flex;
  margin-left: 30px;
  margin-bottom: 20px;
  color: black;
  font-size: 18px;
}


.text {
  margin-left: 10px;
  color: black;
  font-size: 18px;
}


</style>
