<template>
  <div>
    <div class='group-row'>
      <div class='images-and-texts'>
        <img class='type-img' :src='imgSrc' v-if='imgSrc' />
        <div class='text'>
          <div class='description-unknown' v-if='!answers.gaid32 && !answers.gaid33 && !isProjectPage'>
            <span> {{ modelTranslation.unknownSite }}</span>
            <span> {{ modelTranslation.unknownEnergy }} </span>
          </div>
          <div class='description-unknown-project' v-else-if='!answers.gaid32 && !answers.gaid33 && isProjectPage'>
            <span> {{ modelTranslation.unknownEnergyProject }}<br></span>
            <span><br></span>
            <span> {{ modelTranslation.unknownSiteProject }}</span>
          </div>

          <p class='description' v-if='answers.gaid33 && !answers.gaid32 && answers.gaid167'>
            {{ modelTranslation.wind.prosumer }} </p>
          <p class='description' v-if='answers.gaid33 && !answers.gaid32 && answers.gaid168'>
            {{ modelTranslation.wind.producer }} </p>
          <p class='solar-description' v-if='answers.gaid32 && answers.gaid33'> {{ modelTranslation.solar.name
            }} and {{ modelTranslation.wind.windAndSolar }} </p>
          <!-- <p class='sub-description' v-if='modelSubDescription'> {{ modelSubDescription }} </p> -->
          <!-- <div class='solar'> -->
          <p class='solar-description' v-if='answers.gaid33'> {{ modelTranslation.solar.small }} </p>

          <p class='sub-description' v-if='answers.gaid32 && !answers.gaid33'>
            {{ modelTranslation.solar.roof }} </p>
          <!-- </div> -->
        </div>
      </div>
      <!--      todo numbers-two and numbers-three are weird names-->
      <div class='numbers' :class="answers.gaid32 || 100 < 100 ? 'numbers-two' : 'numbers-three'" v-if="!isProjectPage">
        <p :key='`gaid448${panelsValue}`'  class='number' v-if='(!answers.gaid32 && !answers.gaid33 || answers.gaid32)'>
          {{ panelsValue || 'N/A' }}
          <span class='description'>{{ modelTranslation.solar.valueText }}</span>
        </p>
        <p :key='`gaid451${turbinesValue}`' class='number' v-if='(!answers.gaid32 && !answers.gaid33 || answers.gaid33)'>
          {{ turbinesValue || 'N/A' }}
          <span class='description'>{{ modelTranslation.wind.valueText }}</span>
        </p>
      </div>
      <div class='numbers' :class="answers.gaid32 || 100 < 100 ? 'numbers-two' : 'numbers-three'" v-else-if="isProjectPage">
        <p :key='`gaid448${panelsValue}`'  class='number' v-if='(!answers.gaid32 && !answers.gaid33 || answers.gaid32)'>
          {{ panelsValue || 'Unanswered' }}
        </p>
        <p class='dash' v-if="isProjectPage && (answers.gaid32 && answers.gaid33)">/</p>
        <p class='dash' v-if="isProjectPage && (!answers.gaid32 && !answers.gaid33)">/</p>
        <p :key='`gaid451${turbinesValue}`' class='number' v-if='(!answers.gaid32 && !answers.gaid33 || answers.gaid33)'>
          {{ turbinesValue || 'Unanswered' }}
        </p>
      </div>
    </div>

    <div class='group-row'>
      <p class='description'>{{ modelTranslation.generate }}</p>
      <div class='group' v-if="!isProjectPage">
        <ModelField gaid='gaid450' subtext='kWh/m' />
        <p class='dash'>/</p>
        <ModelField gaid='gaid402' subtext='kWh/year' />
      </div>
      <div class='group' v-else>
        <ModelFieldProjectPage :selectedSiteId='selectedSiteId' gaid='gaid450' suffix='kWh /m' />
        <p class='dash'>/</p>
        <ModelFieldProjectPage :selectedSiteId='selectedSiteId' gaid='gaid402' suffix='kWh /year' />
      </div>
    </div>

    <div class='group-row'>
      <p class='description'>{{ modelTranslation.willSave }}</p>
      <div class='group' v-if="!isProjectPage">
      <ModelField gaid='gaid401' subtext='kgCO{2} / a year' />
      </div>
      <div class='group' v-else>
      <ModelFieldProjectPage style="margin-top: -4px" :selectedSiteId='selectedSiteId' gaid='gaid401' suffix='kgCO² /year' />
      </div>
    </div>


    <div v-if="env.VUE_APP_CONTEXT === 'acceptance'" class='group-row'>
      <p class='description' v-if="answers.gaid168">{{ modelTranslation.earn }}</p>
      <p class='description' v-else>{{ modelTranslation.save }}</p>
      <div class='group' v-if="!isProjectPage">
        <ModelField gaid='gaid449' :subtext='modelTranslation.month' prefix='€' />
        <p class='dash'>/</p>
        <ModelField gaid='gaid407' :subtext='modelTranslation.year' prefix='€' />
      </div>
      <div class='group' v-else>
        <ModelFieldProjectPage gaid='gaid449' :selectedSiteId='selectedSiteId' suffix='/month' prefix='€' />
        <p class='dash'>/</p>
        <ModelFieldProjectPage gaid='gaid407' :selectedSiteId='selectedSiteId' suffix='/year' prefix='€' />
      </div>
    </div>

    <div v-if="env.VUE_APP_CONTEXT === 'acceptance'" class='group-row'>
      <p class='description'>{{ modelTranslation.estimate }}</p>
      <div class='group' v-if="!isProjectPage">
        <ModelField gaid='gaid405' subtext='initial cost' prefix='€' />
        <p class='dash'>/</p>
        <ModelField gaid='gaid406' subtext='payback time' suffix='years' />
      </div>
      <div class='group' v-else>
        <ModelFieldProjectPage gaid='gaid405' :selectedSiteId='selectedSiteId' suffix='initial cost' prefix='€' />
        <p class='dash'>/</p>
        <ModelFieldProjectPage gaid='gaid406' :selectedSiteId='selectedSiteId' suffix='years payback' />
      </div>
    </div>

    <!--    todo calculate-->
    <div v-if="env.VUE_APP_CONTEXT === 'acceptance'" class='group-row'>
      <p class='description'>ROI</p>
      <div class='group' v-if="!isProjectPage">
        <ModelField :roiValue='roiValue' subtext='return of investment' suffix='%/year'/>
      </div>
      <div class='group' v-else>
        <ModelFieldProjectPage style="margin-top: -14px" :selectedSiteId='selectedSiteId' :roiValue='roiValue' suffix='%/ year'/>
      </div>
    </div>

  </div>
</template>

<script>
import modelTranslationNl from '@content/nl/model/translations.yaml';
import modelTranslationEn from '@content/en/model/translations.yaml';
import ModelField from '@layouts/civilization/ModelField';
import ModelFieldProjectPage from '@layouts/civilization/ModelFieldProjectPage';
import ids from '@common/ids.yaml';

export default {
  components: { ModelField, ModelFieldProjectPage },
  props: {
    selectedSiteId: String,
    isProjectPage: Boolean
  },
  data () {
    return {
      modelTranslation: undefined,
      modelSubDescription: undefined,
      ids: ids,
      imgSrc: undefined,
      env: {},
      answers: {},
      panelsValue: undefined,
      turbinesValue: undefined,
      roiValue: undefined
    };
  },

  created () {
    this.env = process.env;
    this.init();
  },

  watch: {
    selectedSiteId () {
      this.modelOutputs();
    }
  },
  methods: {
    init () {
      localStorage.lang = this.lang = this.$router.history.current.params.lang;

      this.modelTranslation =
        this.lang === 'en' ? modelTranslationEn : modelTranslationNl;
      // Subscribe to changes in the global storage
      this.modelOutputs();
      this.$store.subscribe(this.onStoreChange); 
    },

    onStoreChange (mutation) {
      switch (mutation.type) {
        case 'siteOwner/setSiteSelected':
        case 'inputs/updateParentAnswers':
          this.modelOutputs();
      }
    },
    modelOutputs () {
      this.roi();
      this.answers = this.$store.state.inputs.parents[this.selectedSiteId || this.$store.state.siteOwner.selectedSiteId]?.answers || {};
      this.panelsValue = this.answers['gaid448'];
      this.turbinesValue = this.answers['gaid451'];
      // todo: try to shorten the logic
      if (
        this.answers.gaid32 &&
        !this.answers.gaid33 &&
        this.answers.gaid1) {
        this.imgSrc = '/assets/model.energy.icons/rooftop.svg';
        this.modelSubDescription = this.modelTranslation.solar.roof;
      } else if (
        this.answers.gaid32 &&
        !this.answers.gaid33 &&
        this.answers.gaid2) {
        this.imgSrc = '/assets/model.energy.icons/land.svg';
        this.modelSubDescription = this.modelTranslation.solar.land;
      } else if (
        this.answers.gaid33 &&
        !this.answers.gaid32 &&
        !this.answers.gaid168) {
        this.imgSrc = '/assets/model.energy.icons/small-wind.svg';
        this.modelSubDescription = this.modelTranslation.wind.small;
      } else if (
        this.answers.gaid32 &&
        !this.answers.gaid33 &&
        this.answers.gaid3) {
        this.imgSrc = '/assets/model.energy.icons/water.svg';
        this.modelSubDescription = this.modelTranslation.solar.water;
      } else if (
        this.answers.gaid33 &&
        !this.answers.gaid32 &&
        this.answers.gaid167) {
        this.imgSrc = '/assets/model.energy.icons/small-wind.svg';
        this.modelSubDescription = this.modelTranslation.wind.small;
      } else if (
        this.answers.gaid33 &&
        !this.answers.gaid32 &&
        this.answers.gaid168) {
        this.imgSrc = '/assets/model.energy.icons/big-wind.svg';
        this.modelSubDescription = this.modelTranslation.wind.small;
      } else if (
        this.answers.gaid32 &&
        this.answers.gaid33 &&
        this.answers.gaid167) {
        this.imgSrc = '/assets/model.energy.icons/small-solar-wind.svg';
        this.modelSubDescription =
          this.modelTranslation.solar.small || this.modelTranslation.wind.small;
      } else if (
        this.answers.gaid32 &&
        this.answers.gaid33 &&
        !this.answers.gaid168) {
        this.imgSrc = '/assets/model.energy.icons/small-solar-wind.svg';
        // this.modelSubDescription = this.modelTranslation.solar.small || this.modelTranslation.wind.small
      } else if (
        this.answers.gaid32 &&
        this.answers.gaid33 &&
        this.answers.gaid168) {
        this.imgSrc = '/assets/model.energy.icons/big-solar-wind.svg';
        this.modelSubDescription =
          this.modelTranslation.solar.large || this.modelTranslation.wind.large;
      }
      if (!this.answers.gaid32 && !this.answers.gaid33) {
        this.imgSrc = undefined;
        this.modelSubDescription = undefined;
      }
    },
    roi(){
      let initialCost = this.$store.state.inputs.parents[this.selectedSiteId]?.answers['gaid405'];
      let savedAyear = this.$store.state.inputs.parents[this.selectedSiteId]?.answers['gaid407'];
      if(initialCost != (undefined || null) && savedAyear != (undefined || null || 0)){
        initialCost = parseInt(initialCost.replaceAll(',', ''));
        this.roiValue = (savedAyear /initialCost) * 100;
      }
    }
  },

  computed: {
    token () {
      return localStorage.token;
    }
  }
};
</script>


<style>


.numbers-two {
  margin: 1px 80px 21px 70px;
}

.numbers-three {
  margin: -40px 80px 21px 170px;
}


</style>
