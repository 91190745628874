<template>
  <div class='profile-c'>
    <div v-if='shouldMinimize === false' class='profile'>
      <Questionnaire
        v-if='role === 0'
        page='map'
        ref='questionnaire'
        :questionnaire='currentQuestionnaire'
        :activatedInputBox='activatedInputBox'
        :inputOutputBoxHeight='inputOutputBoxHeight'
        :draggable='false'
        :siteId='selectedSiteId'
        @onStepFinish='onStepFinish'
        @redraw="$emit('redraw')"
        @minimize='minimizeBox()'
      />

      <div class='output-box'>
        <DraggableBox
          class='freeArea output-box box-style'
          :isResizable='false'
          page='map'
          :w='outputWidth'
          :x='outputLeft'
          :y='outputTop'
          :h='inputOutputBoxHeight'
          :disableDragging="true"
          :style="{
            position: 'initial',
          }"
        >
          <template slot='header'>
            <div class='white-box'>
              <div class='charts'>
                <div
                  class='chart-tab'
                  :class="i === selectedTab ? 'selected' : ''"
                  v-for='(tab, i) of tabs'
                  :key='i'
                  @click='selectTab(i)'
                >
                  <p class='light-text'>{{ tab.title }}</p>
                  <div class='chart-image'>
                    <img class='tabs-img' :src='tab.imgSrc' />
                  </div>

                  <span class='info-box'>
                    <div class='text-space'>
                      <p>{{ tab.info }}</p>
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <Feasibility v-show='selectedTab === 0' />
            <Model v-show='selectedTab === 1' />
            <Metrics v-show='selectedTab === 2' />
            <Constrains v-show='selectedTab === 3' />
          </template>
        </DraggableBox>
      </div>
    </div>

    <div class='minimized-box' v-else>
      <!-- Icon + texts -->
      <div class='rotated-window'>
        <div class='space'>
          <span> <b> Project 1</b> </span>
          <span>Site 1</span>
        </div>
        <img
          src='/assets/window.svg'
          style='transform: rotate(180deg)'
          @click='minimizeBox()'
        />
      </div>
      <!-- charts images -->
      <div class='minimize-charts'>
        <div
          :class="i === selectedTab ? 'selected' : ''"
          v-for='(tab, i) of tabs'
          :key='i'
          @click='selectTab(i)'
        >
          <div class='chart-image'>
            <img class='tabs-img' v-if='i !== 3' :src='tab.imgSrc' />
          </div>
          <p class='light-text' v-if='i !== 3'>{{ tab.title }}</p>
        </div>

      </div>
      <div class='dotted-line'></div>
    </div>
  </div>
</template>

<script>
import ids from '@common/ids.yaml';
import DraggableBox from '@components/DraggableBox';
import site_questionnaire from '@common/questionnaire/site.yaml';
import RequestHelper from '@common/helpers/request.helper.js';
import Questionnaire from '../questionnaire/Questionnaire';
import Constrains from '@layouts/civilization/Constrains';
import Feasibility from '@layouts/civilization/Feasibility';
import Metrics from '@layouts/civilization/Metrics';
import Model from '@layouts/civilization/Model';

export default {
  name: 'ConnectedProfileBoxes',
  components: {
    Questionnaire,
    Model,
    Metrics,
    Feasibility,
    Constrains,
    DraggableBox
  },
  props: {
    projectDevId: String
  },
  data () {
    return {
      shouldMinimize: false,
      ids: ids,
      currentQuestionnaire: { ...site_questionnaire },
      lang: 'en',
      siteSize: 0,
      outputTop: (window.innerHeight - Number(690)) / 2,
      outputLeft: window.innerWidth - Number(450),
      outputWidth: Number(360),
      inputOutputBoxHeight: Number(690),
      role: localStorage.loggedUser
        ? JSON.parse(localStorage.loggedUser).userType
        : 0,
      activatedInputBox: false,

      requestHelper: new RequestHelper(this.$router),

      tabs: [
        {
          imgSrc: '/assets/donut-chart.svg',
          title: 'Feasibility',
          info: 'Overview: Great project'
        },
        {
          imgSrc: '/assets/column-chart.svg',
          title: 'Economics',
          info: 'You save: 8,040 €/year'
        },
        {
          imgSrc: '/assets/green-half-chart.svg',
          title: 'Metrics',
          info: `Site size: `
        },
        {
          imgSrc: '/assets/padlock.svg',
          title: 'Constrains',
          info: 'RegTech: Locked'
        }
      ],
      selectedTab: 0
    };
  },
  created () {
    const loggedUser = JSON.parse(localStorage.loggedUser).resultBoxCoordinates;

    if (!loggedUser) {
      if (window.innerWidth > 767 && window.innerWidth <= 1100) {
        this.outputTop = loggedUser
          ? loggedUser?.top
          : window.innerHeight - 600;
        this.outputLeft = loggedUser
          ? loggedUser?.left
          : window.innerWidth - 150;
      }
    } else if (
      this.$router.currentRoute.path.includes('/connected-profile/boxes')
    ) {
      this.outputTop = 500;
      this.outputLeft = 710;
    }
  },

  mounted () {
    this.init();
  },

  computed: {
    userSites () {
      return this.$store.state.siteOwner.userSites;
    },
    userProjects () {
      return this.$store.state.siteOwner.userProjects;
    },

    mapInstances () {
      return this.$store.state.map.instances;
    },

    selectedSiteId () {
      return this.$store.state.siteOwner.selectedSiteId;
    },

    answers () {
      return this.inputParents[this.selectedSiteId]?.answers || {};
    },

    selectedProjectId () {
      return this.$store.state.siteOwner.selectedProjectId;
    },

    inputParents () {
      return this.$store.state.inputs.parents;
    }
  },

  methods: {
    updateUserStateSelectedSite () {
      // Update user in the DB
      if (localStorage.token && this.selectedSiteId)
        this.requestHelper.putQuery('userState', {
          lastSelectedSite: this.selectedSiteId
        });
    },

    async init () {
      localStorage.lang = this.lang = this.$router.history.current.params.lang;

      // Subscribe to changes in the global storage
      this.$store.subscribe(this.onStoreChange);
    },

    onStoreChange (mutation) {
      switch (mutation.type) {
        // When a a site is selected.
        case 'siteOwner/setSiteSelected':
          this.updateUserStateSelectedSite();
          this.getSiteSize();
          break;

        case 'map/initInstance':
          this.getSiteSize();
          break;
        case 'inputs/updateParentAnswers':
          this.getSiteSize();
          break;
      }
    },
    getSiteSize () {
      this.siteSize = this.inputParents[this.selectedSiteId]?.answers['gaid39'] || this.inputParents[this.selectedSiteId]?.answers['gaid291'];
      this.tabs[2].info = ` Site size: ${this.siteSize}sq.m`;
    },

    redraw () {
      this.$emit('redraw');
    },

    onStepFinish () {
      this.$router.push(`/${this.lang}/site-owner/matching`).catch(() => {});
    },

    selectTab (id) {
      this.selectedTab = id;
    },

    minimizeBox () {
      this.shouldMinimize = !this.shouldMinimize;
    }
  }
};
</script>


<style lang='scss'>
@import '@styles/profile.scss';
@import '@styles/main.scss';

.profile {
  display: flex;
  justify-content: center;
}

.box-style {
  border-radius: 14px 14px 14px 0;
}

.tabs-img {
  margin-top: 15px;
}

.minimized-box {
  width: 320px;
  height: 201px;

  .minimize-charts {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-left: 34px;
    margin-top: -25px;

    p {
      color: #5694E8;
      font-weight: 400;
      margin-bottom: 25px;
      margin-top: 10px;
      font-size: 13px;
    }
  }

  .space {
    margin-left: 15px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    color: #B5B8CC;
    text-align: start;
  }
}

.rotated-window {
  display: flex;
  justify-content: space-between;
  margin-right: 13px;
  padding-bottom: 25px;

  :hover {
    cursor: pointer;
  }
}

.dotted-line {
  border: none;
  border-top: 8px dotted #2783FF;
  height: 1px;
  width: 77%;
  margin-left: 37px;
  margin-top: -11px;
}

//  Extra small devices (phones, 600px and down) 
  @media only screen and (max-width: 600px) {
   .scale {
      zoom: 0.5;
    }
  }

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .scale {
    zoom: 56%;
  }
}

/* Large devices (laptops/desktops, 992px and up)*/
 @media only screen and (min-width: 768px) {
   .scale {
     zoom: 65%;

   }
 }

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .scale {
    zoom: 80%;
  }
}

 @media only screen and (min-width: 2550px) {
   .scale {
     zoom: 120%;
    }
  }
</style>
