<template>
    <div class='authorization' v-if="loginType === 'login' || loginType === 'accept-invitation' ||
    loginType === 'forgotten-password' || loginType === 'reset-password' ">
          <div @click='onClose($event, true)'
               :class="this.$router.currentRoute.path.includes('home') ? 'modal-overlay' : 'popup-box dimming'" />
      <div :class="this.$router.currentRoute.path.includes('home') ? 'modal' : 'map-modal'">

        <div class='col-5 background-img  d-xs-none d-sm-none  d-md-none d-lg-none d-xl-block'>
          <img src='/assets/login-image.png' />
          <p>{{ translation['image-title'] }}</p>
        </div>

        <div class='container'>
          <div class='row'>
            <div class='col-xs-12  col-sm-10  col-md-8  col-lg-7  col-xl-6'></div>

            <div class='col-12 form'>
              <Login v-if="loginType === 'login'" @openForgotten="loginType = 'forgotten-password'"
                     @login='login($event)' />
              <ForgottenPassword v-else-if="loginType === 'forgotten-password'" @openLogin="loginType = 'login'" />
              <AcceptInvitation v-else-if="loginType  === 'accept-invitation'" @startNow="$emit('startNow')"
                                @cancel='changeLoginTypeByRoute()'
                                @accept-invitation='acceptInvitation($event)' />
              <ResetPassword v-else-if="loginType === 'reset-password'" @startNow="$emit('startNow')"
                             @openLogin="loginType = 'login'" />
              <p v-if='errorMessage'>{{ errorMessage }} </p>

              <p
                v-show="loginType !== 'forgotten-password' && loginType !== 'reset-password' &&  loginType !== 'accept-invitation' "
                @click="$emit('startNow')" class='start-now-link clickable'
                v-if="env.VUE_APP_CONTEXT === 'acceptance' && this.$router.currentRoute.path.includes('home')">
                {{ translation.account.description }}
                <span class='yellow-line' v-if="this.$router.currentRoute.path.includes('home')">
                                {{ translation.account['underline-description'] }}
                 </span>
              </p>
            </div>

          </div>

        </div>

        <div class='col-1'>
          <button @click='onClose()'>

            <img src='/assets/X-image.svg' />
          </button>
        </div>

      </div>

    </div>
</template>

<script>
import Login from '@components/authorization/Login';
import AcceptInvitation from '@components/authorization/AcceptInvitation';
import axios from '@axios';
import {
  getUserTypeRoute
} from '@src/helpers/routes.helper.js';
import translationsEn from '@content/en/auth/auth.yaml';
import translationsNl from '@content/nl/auth/auth.yaml';
import ForgottenPassword from '@components/authorization/ForgottenPassword';
import ResetPassword from '@components/authorization/ResetPassword';

export default {
  components: {
    Login,
    AcceptInvitation,
    ForgottenPassword,
    ResetPassword

  },
  created () {
    this.env = process.env;
    this.init();
  },

  /**
   * 3 UserTypes
   *
   * 1 = siteOwners
   * 2 = projectDevelopers
   * 3 = admin
   */
  mounted () {
    document.body.classList.add('no-scroll');

    const loginType = this.$router.history.current.params.loginType;

    if (loginType && loginType !== '') this.changeLoginTypeByRoute();

    else this.changeRouteByLoginType();
  },
  destroyed () {
    document.body.classList.remove('no-scroll');
  },
  props: {
    defaultPage: {
      type: String,
      default: 'login'
    },
    data: Object,
    lang: String
  },

  data () {
    return {
      langs: localStorage.lang,
      translation: {},
      errorMessage: '',
      env: {},
      loginType: this.defaultPage,
      showResetPass: true
    };
  },

  computed: {
    userType () {
      return this.$store.state.user.userType;
    }
  },

  watch: {

    loginType () {
      this.changeRouteByLoginType();
    }
  },

  methods: {
    init () {
      this.translation = localStorage.lang === 'en' ? translationsEn : translationsNl;
    },

    async acceptInvitation (credentials) {
      axios
        .put('/.netlify/functions/ownerInvitation', {
          ...credentials,
          ...this.data
        })
        .then(({
          data
        }) => {
          this.doLogin(data);
        });
    },

    login (credentials) {
      axios
        .post('/.netlify/functions/loginQuery', {
          ...credentials,
          ...this.data
        })
        .then(({
          data
        }) => {
          this.doLogin(data);
        });
    },

    doLogin (data) {
      if ('error' in data) {
        this.errorMessage = data.error;
      } else {
        if (data.userType !== this.userType)
          this.$store.commit('user/setType', data.userType || 0);

        localStorage.token = data.token;
        localStorage.loggedUser = JSON.stringify(data);

        this.$emit('close', localStorage.token);
      }
    },

    changeRouteByLoginType () {
      if (!this.$router.currentRoute.path.includes('home')) return;
      if (this.loginType === this.$router.history.current.params.loginType)
        return;
      this.$router.replace({
        name: 'Libero',
        params: {
          userType: getUserTypeRoute(this.userType),
          loginType: this.loginType
        }
      });
    },

    changeLoginTypeByRoute () {
      this.loginType = this.$router.history.current.params.loginType;
    },

    onClose () {
      this.$emit('close');
     this.$router.push(`/${this.langs}/home/${['site-owner', 'project-developer', 'admin'][this.userType]}`);
    }
  }
};
</script>

<style lang='scss'>
@import "@styles/main.scss";
@import "@styles/colors.scss";
@import "@styles/authorization-inputs.scss";
@import "@styles/authorization.scss";

.authorization {
  .modal-overlay {
    background: $black 0 0 no-repeat padding-box;
    opacity: 0.2;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .modal {
    display: flex;
    position: fixed;
    border-radius: 15px;
    background-color: white;
    top: 35px;
    left: 130px;
    width: calc(100vw - 260px);
    height: 90vh;

    .form {
      display: flex;
      flex-direction: column;

      .start-now-link {
        font-size: 12px;
        color: $headers;
        font-weight: 700;

        .yellow-line {
          border-bottom: 3px solid $yellow;
        }
      }

      .login {
        display: flex;
      }
    }

    .col-1 {
      button {
        border: none;
        background-color: transparent;
      }

      padding: 0;
      width: 16px;
      height: 16px;
      top: 20px;
    }

    .background-img {
      padding: 0;

      img {
        width: 100%;
        height: 101%;
        float: left;
      }

      p {
        position: absolute;
        top: 210px;
        left: 66px;
        font-family: $font__IBM;
        font-size: 30px;
        color: $white;
        font-weight: 700;
        width: 320px;
      }
    }
  }

  .dimming {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));

  }

  .map-modal {
    display: flex;
    position: fixed;
    border-radius: 15px;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11000;
    width: 1120px;
    height: 640px;

    .col-1 {
      button {
        border: none;
        background-color: transparent;
      }

      padding: 0;
      width: 16px;
      height: 16px;
      top: 20px;
    }

    .background-img {
      padding: 0;

      img {
        width: 100%;
        height: 100%;
        float: left;
      }

      p {
        position: absolute;
        top: 210px;
        left: 66px;
        font-family: $font__IBM;
        font-size: 30px;
        color: $white;
        font-weight: 700;
        width: 320px;
      }
    }
  }

  @media only screen and (max-width: 1160px) {
    .modal {
      margin: 0;
      padding: 0;
      top: 0;
      left: 0;
      border-radius: 0;
      height: 100vh;
      width: 100vw;
    }

  }

  @media only screen and (min-width: 1160px) {
    .map-modal {
      display: flex;
      position: fixed;
      border-radius: 15px;
      background-color: white;
      top: 50%;
      left: 50%;
      right: 10%;
      width: 1120px;
      height: 640px;
      transform: translate(-50%, -50%);
      z-index: 11000;
      overflow: auto;
    }
  }
}
</style>
