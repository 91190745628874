export const MapLayer = Object.freeze({

    Cadasters: 'Cadasters',
    SearchAreas: 'SearchAreas',
    GridCongestion: 'GridCongestion',
    ResRegions: 'ResRegions',
    Municipalities: 'Municipalities',
    Provinces: 'Provinces',
    Grid: 'Grid',
    GridSubs: {
        Liander: 'Liander',
        Enexis: 'Enexis',
        Stedin: 'Stedin',
        Enduris: 'Enduris',
        WestlandInfra: 'WestlandInfra',
        TenneT: 'TenneT'
    },
    RestrictedAreas: 'RestrictedAreas',
    RestrictedAreasSubs: {
        NatureAreas: 'nature2000',
        ParkAreas: 'park',
        NNNAreas: 'nnn',
        CulturalAreas: 'cultural',
    },
    EnergyProjects: 'EnergyProjects',

});
