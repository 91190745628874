<template>
  <div class='lang-switcher'>
    <div class='logo'>
      <router-link :to='`/${$router.history.current.params.lang}`'>
        <img src='/assets/logo.png' />
      </router-link>
    </div>

    <div class='switcher'>
      <router-link :to="getURL('en')">
        <a :class="this.currentLang === 'nl' ? 'lang-button active' : 'lang-button'">
          {{ langTranslation['nl-lang'] }}
        </a>
      </router-link>
      <router-link :to="getURL('nl')">
        <a :class="this.currentLang === 'en' ? 'lang-button active' : 'lang-button'">
          {{ langTranslation['en-lang'] }}
        </a>
      </router-link>
    </div>
  </div>
</template>

<script>
import languageNl from "@content/nl/general/navigation.yaml";
import languageEn from "@content/en/general/navigation.yaml";
export default {
  name: 'LanguageSwitcher',

  props : {
    contentEn: undefined,
    contentNl: undefined
  },
  data() {
    return {
      lang: '',
      langTranslation: {},
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    $route() {
      this.init();
    }
  },
  computed: {
    currentLang() {
      return this.$route.path.substr(1, 2);
    },
    currentPage() {
      return this.$route.path;
    }
  },
  methods: {
    init() {
      localStorage.lang = this.lang = this.$router.history.current.params.lang;
      this.langTranslation = localStorage.lang === "en" ? languageEn : languageNl;
      // this.html = marked(data);
    },
    getURL(prevLanguage) {
      return this.currentPage.replace(
        prevLanguage,
        prevLanguage === 'nl' ? 'en' : 'nl'
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@styles/main.scss";

</style>
