<template>
  <div class='info-card'>
    <img alt='' class='info-icon' :src='icon' />

    <p class='info-text'>
      {{ text }}
    </p>

    <button class='notification-button' :class='color'
      @click='contactFormOpened = true'>    
      {{ buttonText }}
      <img alt='' :src='buttonIcon' />
    </button>
     <ContactForm  v-if="contactFormOpened" gaid='gaid520'
      @closeContactForm='contactFormOpened=false'/>  
  </div>
</template>

<script>
import ContactForm from '@layouts/matching/ContactForm'
export default {
  name: 'InfoCard',
  components:{
    ContactForm
  },

  props: {
    text: String,
    buttonText: String,
    color: String,
    buttonIcon: String
  },

  data () {
    return {
      icon: '',

      contactFormOpened: false,
    };
  },
  mounted () {
    switch (this.color) {
      case 'red':
        this.icon = '/assets/red-exclamation.svg';
        break;
      case 'green':
        this.icon = '/assets/green-info.svg';
    }
  },
  methods: {
    onTabChange (tab) {
      if (tab === undefined) return;
      this.$emit('change-tab', tab);
    }
  },

};
</script>

<style lang='scss'>
@import "@styles/colors.scss";
@import '@styles/main.scss';
@import '@styles/reject-site.scss';
@import '@styles/popup.scss';

.info-card {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;

  .info-icon {
    float: left;
    margin-left: 0;
  }

  .info-text {
    font-size: 16px;
    font-weight: 500;
    color: #505158;
    text-align: left;
     margin: auto 15px;
   }

  .notification-button {
    width: 194px;
    height: 50px;
    border-radius: 7px;
    color: $white;

    &.green {
      background-color: $secondary;
    }
    &.red {
      background-color: $red;
    }
  }
}
</style>
