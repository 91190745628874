
// TODO: Create a proper model.
export const devMatchDummy =
{
    expanded: false,
    gaRating: 4.9,
    keyConditions: {
        title: 'Exclusivity 48 hours',
        description: '(During this period your project will be exclusively reviewed by this partner. Your project cannot be shared with other companies)',
        warnings: [
            'Project developer does a site visit before sending an offer.',
            'Requires verified owner identity'
        ]
    }
}
