<!--
  Generic button component.
-->

<template>
  <div>
    <div :ref='`button-${gaid}`' class='btn-content'>
      <button
        :disabled='readOnly'
        class='toggle-button ga-input'
        :class='{ active: activeLocal, "toggle-button": page !== "project", "toggle-button-boring": page === "project" }'
        @click='onClick'
        :gaid='gaid'
        :group='group'
        :multiSelectGroup='multiSelectGroup'
        type='button'
      >
        <div class='input-button d-flex' v-bind:class="{ 'center-text': !image }">
          <div class='d-flex' v-if='image'>
            <img :src='`/assets/${image}`' />
            <div class='d-flex img-value-container'>
              <div class='d-flex values'>
                <span class='value'>
                  {{ value }}
                </span>
              </div>
            </div>
          </div>
          <div v-else class='value-container'>
            <span class='d-flex ml-3 mr-3 value'>
              {{ value }}
            </span>
          </div>
        </div>
      </button>
    </div>
    <popup-controller v-if='error || definition' :refName='`button-${gaid}`' :width='250'
                      :border-color='error ? "orange" : "#55b364"' :text='error || definition' />
  </div>
</template>

<script>
import PopupController from '../../popup/popup-controller';

export default {
  components: { PopupController },
  props: {
    page: String,
    type: String,

    gaid: String,
    group: String,
    multiSelectGroup: String,
    readOnly: Boolean,

    active: Boolean,

    value: String,
    definition: String,
    error: String,

    image: String
  },

  watch: {
    active () {
      this.activeLocal = this.active;
    },

    activeLocal () {
      if (this.readOnly) return;
      // Commiting on nextTick assures that the commit is executed after
      // the template has been re-rendered.
      this.$nextTick(() => this.$store.commit('inputs/emitValueChange'));
    },

  },

  data () {
    return {
      hover: false,
      lang: undefined,
      activeLocal: this.active,
      XY: []
    };
  },

  mounted () {
    localStorage.lang = this.lang = this.$router.history.current.params.lang;
    this.activeLocal = this.active;
  },

  methods: {
    onClick () {
      if (this.readOnly) return;
      // Allow deactivation only for multiselect buttons
      // In other words, if a button which is in a group is activated, it
      // cannot be deactivated when clicked a second time. In this case,
      // the only way to deactivate it will be to click on another button
      // of the same group.
      // Buttons w/ groups = radio buttons.
      if (!this.activeLocal || !this.group)
        this.activeLocal = !this.activeLocal;

      this.$emit('click', this.activeLocal);
    }
  }
};
</script>

<style lang='scss' scoped>
//@import "@styles/colors.scss";
@import "@styles/main.scss";
@import "@styles/questionnaire.scss";

</style>
