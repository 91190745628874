<template>
  <div class='popup-box dimming' @click='closeSuccessfulPayment($event, true)'>
     <div class='popup box-style'>

      <div class='popup-content'>
        <button class='close-btn' @click='closeSuccessfulPayment()'>
          <img src='/assets/guide-close-icon.svg' alt='img' />
        </button>
        <div class='popup-top-section'>
              <div class="row">
                <span class="title">{{ translations.success }}</span>
              </div>
              <div class="row">
                <p class="successful-info">{{ translations["info-text"] }}</p>
              </div>
        </div>

    <div class ='popup-bot-section'> 
          <div class="row list" v-for="(item, index) in translations.list.items" :key="index" >
          
              <img src= '/assets/green-correct.svg'  v-if="index!=3"/>
              
              <img :src="translations.list.exclamation" v-if="index==3" class='exclamation'/>
              
              <div  class="item">{{ item }}</div>
          </div>
          <div class="row buttons">
              <button class="view">{{ translations.buttons.view }}</button>
              <button class="return" @click='closeSuccessfulPayment()'>{{ translations.buttons.return }}</button>
          </div>
  </div>
      </div>

      </div>
    </div>
  
</template>

<script>
import successfulPaymentNl from '@content/nl/developer/successful-payment.yaml'
import successfulPaymentEn from '@content/en/developer/successful-payment.yaml'

export default {
  data () {
    return {
      translations: localStorage.lang === "en" ? successfulPaymentEn : successfulPaymentNl
    };
  },
  computed: {
    rejectAllowed() {
      return this.checkbox !== false;
    },
  },
  methods: {
    closeSuccessfulPayment (ev, isBackgroundClick) {
      if (isBackgroundClick && !ev.target.className.includes('popup-box'))
        return;

      this.$emit('closeSuccessfulPayment');
    },
  }
};

</script>

<style lang='scss' scoped>
@import '@styles/successful-payment.scss';
@import '@styles/main.scss';
@import '@styles/popup.scss';
</style>
