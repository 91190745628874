<template>
  <div class="main">
    <div class="row">
      <div class="col-6 columns" v-for="(parent, index) of children" :key="index">
        <div class="parent">
          {{ ids[index][lang].name }} ({{ index }})
        </div>
        <div
            class="children"
            v-for="(child, i) of parent"
            @click="selectedId = child"
            :key="i"
        >
          <div> {{ ids[child][lang].name }} ({{ child }})</div>
          <div v-show="selectedId === child" class="details">
            <div>id: {{ child }}</div>
            <div>type: {{ ids[child].type || 'n/a' }}</div>
            <div>audience: {{ ids[child].audience || 'n/a' }}</div>
            <div>matching: {{ ids[child].matching || 'n/a' }}</div>
            <div>name: {{ ids[child][lang].name }}</div>
            <div>definition: {{ ids[child][lang].definition }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ids from '@common/ids.yaml'

export default {
  data () {
    return {
      lang: '',
      ids: ids,
      children: {},
      details: '',
      selectedId: '',
    }
  },
  created () {
    localStorage.lang = this.lang = this.$router.history.current.params.lang
    this.displayChildren()
  },
  methods: {
    displayChildren () {
      let orphans = []
      let skip = []
      for (const key of Object.keys(this.ids)) {
        const id = this.ids[key]
        if (skip.includes(key)) {
          orphans = orphans.filter((v) => v !== key)
          continue
        }

        if (id.children) {
          this.children[key] = [...id.children]
          skip = skip.concat(id.children)
          continue
        }

        orphans.push(key)
      }
      this.children['gaid159'] = orphans
    },
  },
}
</script>

<style lang="scss">
.main {
  .row {
    display: flex;
    justify-content: flex-start;
    padding-left: 50px;
    text-align: left;

    .children {
      cursor: pointer;
      font-size: 15px;
      font-weight: 500;
    }

    .columns {
      margin: 50px 0 50px 0;
      text-align: left;
      width: 50%;

      .children {
        cursor: pointer;
        margin-left: 20px;
        font-size: 15px;

        .details {
          padding: 0 0 20px 20px;
        }
      }
    }
  }
}
</style>