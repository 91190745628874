<template>
    <ul>
       <li :class='{ valid: hasLength }'>{{ requirements.symbols }}</li>
       <li :class='{ valid: hasNumber }'>{{ requirements.number }}</li>
       <li :class='{ valid: hasLowerCase && hasUpperCase }'>{{ requirements.letter }}</li>
       <li :class='{ valid: isConfirmed }'>{{ requirements.confirmed }}</li>
    </ul>
</template>
<script>
import requirementsEn from '@content/en/auth/translations.yaml'
import requirementsNl from '@content/nl/auth/translations.yaml'

export default {
  name: 'password-validator',

  props: {
    password: String,
    confirmPassword: String,
  },

  data () {
    return {
      requirements: {},
    };
  },

  computed:{
     isConfirmed () {
      return this.confirmPassword.length>0 && this.confirmPassword===this.password;
    },
    hasLowerCase () {
      return this.password.match(/[a-z]/g);
    },
    hasUpperCase () {
      return this.password.match(/[A-Z]/g);
    },
    hasNumber () {
      return this.password.match(/[0-9]/g);
    },
    hasLength () {
      return this.password.length >= 6;
    },

    passRules () {
      return this.hasLength && this.hasLowerCase && this.hasNumber && this.hasLength;
    }
  },

  mounted () {
    this.init();
  },

  methods: {
      init(){
        this.lang = this.$route.params.lang;
        this.requirements = this.lang === "en" ? requirementsEn.requirements : requirementsNl.requirements;
      },
      isValid(){
        return this.passRules;
      }
  }
};
</script>
<style lang='scss'>
  li {
          align-items: start;
          justify-content: left;
          text-align: left;
          font-size: 14px;
          list-style: circle;
          color:#65687E ;


          .valid {
            color: green;
          }

          &.valid {
            color: green;
          }
        }
</style>
