export const Voltage = Object.freeze({

  LV: 'LV',
  MV: 'MV',
  HV: 'HV',
  HV_MV: 'HV_MV',
  MV_LV: 'MV_LV',
  IV: 'IV',

});
