<template>
  <div class='new-navigation' :class="{ 'not-inverted': notInverted }">
    <div class='row ml'>
      <div class='col-8 logo'>
        <router-link :to='`/${$router.history.current.params.lang}`'>
          <img :src='logo' />
        </router-link>
      </div>
      <div class='col-6 language'>
        <router-link :to="getURL('en')">
          <button>
            <div class='row'>
              <p>{{ nlLang }}</p>
            </div>
          </button>
        </router-link>
        <router-link :to="getURL('nl')">
          <button>
            <div class='row'>
              <p class='en'>{{ enLang }}</p>
            </div>
          </button>
        </router-link>
      </div>
    </div>

    <div class='scroll-links' :key='activatedLink'>
      <router-link :id='`nav${component.tag.replace("#", "-")}`' v-for='(component, i) of components'
                   :key='i'
                   :to='link' v-scroll-to='component.tag'
                   :class="{
        inverted: areInverted[i],
        'router-link-exact-active active': activatedLink === i
        }">
        <div class='active-green-line' />
        {{ component.name }}
      </router-link>
    </div>

    <!-- mobile !-->
    <div class='ml-responsive'>
      <button class='show-navigation-button' @click='showNavigation = !showNavigation'>
        <div v-if='showNavigation'>
          <img src='/assets/close.svg' />
        </div>
        <div v-else>
          <img src='/assets/mobile-navigation.svg' />
        </div>
      </button>
      <div class='navigation-mobile-mode' :class="{ 'burger-navigation': showNavigation === true }">
        <router-link :to='`/${$router.history.current.params.lang}`'>
          <img :src='logo' />
        </router-link>

        <AuthButtons :lang="lang" className="login"/>

        <div class='mobile-navigation' :key='user'>
          <button v-for='(component, i) of components' @click='showNavigation = false ' :key='i'>
            <router-link :to='component.tag' v-scroll-to='component.tag' :class='{ inverted: areInverted[i]}'>
              <div class='active-green-line' />
              {{ component.name }}
            </router-link>
          </button>
        </div>

        <div class='ml-mobile'>
          <router-link :to="getURL('en')">
            <button @click='showNavigation = false'>
              <div class='active-green-line' />
              <div class='row'>
                <p>{{ nlLang }}</p>
              </div>
            </button>
          </router-link>
          <router-link :to="getURL('nl')">
            <button @click='showNavigation = false'>
              <div class='active-green-line' />
              <div class='row'>
                <p class='en'>{{ enLang }}</p>
              </div>
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navigation from '@content/nl/general/navigation.yaml';
import navigationEn from '@content/en/general/navigation.yaml';
import AuthButtons from '@layouts/home/components/AuthButtons';

export default {
  name: 'NewNavigation',
  components:{
     AuthButtons
  },
  computed: {
    currentPage () {
      return this.$route.path;
    },
    user () {
      return this.$store.state.user.userType;
    },
    link () {
      return this.currentPage;
    }
  },
  data () {
    return {
      logo: '',
      nlLang: '',
      enLang: '',
      showNavigation: false,
      components: [],
      areInverted: [],
      headerImageHeight: 0,
      activatedLink: 0,
      sectionElements: [],
      navElementsHeight: [],
      onRouteHash: false
    };
  },
  props: {
    notInverted: {
      type: Boolean,
      default: false
    },
    lang: String
  },
  created () {
    window.addEventListener('resize', this.init);

    if (this.$route.hash === '#our-platform')
      this.onRouteHash = true;

    this.checkScroll();
    window.onscroll = () => this.checkScroll();
    this.init();
  },
  destroyed () {
    window.removeEventListener('resize', this.init);
  },
  mounted () {
    if (this.onRouteHash)
      document.getElementById('our-platform').scrollIntoView({ behavior: 'smooth' });
    this.onRouteHash = false;
  },
  watch: {
    lang () {
      this.init();
    },
    user () {
      this.navElementsHeight = [];
      this.init();
    }
  },
  methods: {
    init () {
      this.headerImageHeight = window.innerHeight;
      localStorage.lang = this.lang = this.$router.history.current.params.lang;
      let data = this.lang === 'en' ? navigationEn : navigation;
      this.logo = data.logo;
      this.nlLang = data['nl-lang'];
      this.enLang = data['en-lang'];
      this.components = this.$store.state.user.userType === 1 ? data.navigation.developer : data.navigation.siteOwner;
    },

    getURL (prevLanguage) {
      return this.currentPage.replace(
        prevLanguage,
        prevLanguage === 'nl' ? 'en' : 'nl'
      );
    },

    setupNavElements () {
      this.activatedLink = 0;
      this.navElementsHeight = [];
      this.sectionElements = [];
      this.areInverted = [];

      this.components.forEach((component) => {
        let nav = document.querySelector(`a#nav${component.tag.replace('#', '-')}`);
        if (nav) {
          const pos = nav.getBoundingClientRect();
          this.navElementsHeight.push((pos.bottom + pos.top) / 2);
        }
        nav = document.querySelector(`div${component.tag}`);
        if (nav) this.sectionElements.push(nav);
        this.areInverted.push(false);
      });
    },

    checkScroll () {
      if (this.navElementsHeight.length === 0) this.setupNavElements();
      this.sectionElements.forEach((el, i) => {
        this.areInverted[i] = this.getScrollPosition() > this.headerImageHeight - this.navElementsHeight[i];
      });

      this.sectionElements.forEach((element, i) => {
        if (element?.offsetTop - 110 <= this.getScrollPosition())
          this.activatedLink = i;
      });
      this.$forceUpdate();
    },

    getScrollPosition () {
      return pageYOffset;
    }

  }

};
</script>

<style lang='scss' scoped>
@import "@styles/mobile-mode.scss";
@import "@styles/mobile-mode/navigation-mobile.scss";
@import "@styles/colors.scss";

.new-navigation {
  background: none;
  width: 170px;
  height: 100vh;
  z-index: 2;
  position: absolute;

  &.not-inverted {
    .scroll-links {
      a {
        color: $subtext;
      }
    }

    div.language {
      button {
        p {
          color: $subtext;
          opacity: 1;
        }
      }
    }
  }

  .ml {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .logo {
      a {
        opacity: 1;
      }
    }

    .language {
      opacity: 1;

      a {
        opacity: 0.6;

        &.router-link-active {
          opacity: 1;
        }
      }

      button {
        border: none;
        outline: none;
        background: none;

        p {
          margin: 0;
          color: white;
          opacity: 1;
          font-weight: 700;
          font-size: 12px;
        }
      }
    }
  }

  .scroll-links {
    margin-top: 75px;
    position: fixed;
    font-weight: 700;
    margin-left: 55px;
    display: flex;
    flex-direction: column;
    text-align: left;

    a {
      padding: 10px 0;
      position: relative;
      color: white;
      opacity: 0.6;
      outline: none;
      font-size: 14px;

      &.router-link-exact-active.active {
        opacity: 1;

        .active-green-line {
          display: block;
        }
      }

      .active-green-line {
        display: none;
      }

      &.inverted {
        color: $subtext;

        .active-green-line {
          display: none;
        }
      }
    }

    &.router-link-exact-active.router-link-active.inverted {
      opacity: 0.6 !important;
    }
  }
}
</style>
