<template>
  <div />
</template>

<script>

export default {
  name: 'popup-controller',

  props: {
    width: Number,
    text: String,
    borderColor: String,
    refName: String
  },

  data () {
    return {
      overElement: false
    };
  },

  beforeDestroy () {
    let refs = this.$parent.$refs[this.refName];
    if (!refs) return;
    if (Array.isArray(refs)) refs = refs[0];
    if (!refs) return;
    refs.removeEventListener('mouseenter', this.showInformation);
    refs.removeEventListener('mouseleave', this.hideInformation);
  },

  mounted () {
    this.init();
  },

  methods: {
    init () {
      let refs = this.$parent.$refs[this.refName];
      if (!refs) return;
      if (Array.isArray(refs)) refs = refs[0];
      if (!refs) return;
      refs.addEventListener('mouseenter', this.showInformation);
      refs.addEventListener('mouseleave', this.hideInformation);
    },
    hideInformation () {
      const element = document.getElementsByClassName('definition-popup')[0];
      element.style = 'display: none';
    },
    showInformation (event) {
      const element = document.getElementsByClassName('definition-popup')[0];
      element.style = `width: ${this.width || 100}px; border-color: ${this.borderColor}; top: ${window.scrollY + event.clientY}px; left: ${event.clientX}px; display: block`;
      element.firstChild.innerText = this.text;
    }
  }
};
</script>
