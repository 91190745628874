<template>
  <div class='scale'
       ref='draggableContainer'
       id='draggable-container'
       @mousedown='dragMouseDown'
       @touchstart='dragMouseDown'
       @touchmove='elementDrag'
       @touchend='closeDragElement'
       :style='{
      position: page === "map" ? "initial" : "absolute",
      "z-index": disableDragging ? 400 : 401,
      top: `${y}px`,
      left: `${x}px`,
      height: `${h}px`,
      width: `${w}px`,
    }'
  >
    <div id='draggable-header'>
      <slot name='header' />
    </div>
    <slot name='main' />
    <slot name='footer' />
  </div>
</template>

<script>
export default {
  name: 'DraggableBox',
  data: function() {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    };
  },
  props: {
    w: Number,
    h: Number,
    x: Number,
    y: Number,
    disableDragging: Boolean,
    page: String
  },

  methods: {
    dragMouseDown: function(event) {
      if (this.disableDragging) return;
      if (['INPUT', 'IMG', 'BUTTON'].includes(event.target.tagName) ||
        event.target.className.includes('input-button') ||
        event.target.className.includes('value') ||
        event.target.className.includes('step-description') ||
        event.target.className.includes('step-title')
      ) return;
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX || event.touches[0].clientX;
      this.positions.clientY = event.clientY || event.touches[0].clientY;

      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function(event) {
      if (this.disableDragging) return;
      if (['INPUT', 'IMG', 'BUTTON'].includes(event.target.tagName) ||
        (event.target.className &&
          (event.target.className.includes('input-button') ||
            event.target.className.includes('value') ||
            event.target.className.includes('step-description') ||
            event.target.className.includes('step-title')))) return;
      event.preventDefault();
      const x = event.clientX || event.touches[0].clientX;
      const y = event.clientY || event.touches[0].clientY;
      this.positions.movementX = this.positions.clientX - x;
      this.positions.movementY = this.positions.clientY - y;
      this.positions.clientX = x;
      this.positions.clientY = y;
      // set the element's new position:
      this.$refs.draggableContainer.style.top =
        this.$refs.draggableContainer.offsetTop -
        this.positions.movementY +
        'px';
      this.$refs.draggableContainer.style.left =
        this.$refs.draggableContainer.offsetLeft -
        this.positions.movementX +
        'px';
    },
    closeDragElement () {
      if (this.disableDragging) return;
      this.$emit('dragstop', {
        top: parseInt(this.$refs.draggableContainer.style.top),
        left: parseInt(this.$refs.draggableContainer.style.left)
      });
      document.onmouseup = null;
      document.onmousemove = null;
      document.ontouchmove = null;
      document.ontouchend = null;
    },
  }
};
</script>

<style>
#draggable-container {
  z-index: 50 !important;
}

#draggable-header {
  z-index: 999;
}

</style>
