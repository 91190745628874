<!--
    Static texts work with ids from the ids.yaml.

    When a value is entered, after a certain timeout,
    the input emits an object:
    {
        [assigned gaid]: "Entered value"
    }
    ... Which is then processed as needed by the parent components.
-->

<template>
  <div class='form-group'>
    <label v-if='showSavedState() === true && showActionLabels'> {{ translations.saving }}</label>
    <label v-else-if='showSavedState() === false && showActionLabels'> {{ translations.saved }}</label>
    <!-- type: text -->

    <ShowHidePassword v-if='type === "password-showable"' :field='`input-${gaid}`' style=" right:35px; top:20px"/>
    <input
      :disabled='readOnly'
      :ref='`input-${gaid}`'
      v-if="
        type === 'text' ||
          type === 'password' ||
          type === 'password-showable' ||
          type === 'project-selector' ||
          type === 'number' ||
          type === 'email'
      "
      :class="page === 'project' && type === `text` ? 'input-box text' : ''"
      class='ga-input'
      :type="type === 'number' ? 'text' : type === 'password-showable' ? 'password' : type"
      :pattern="type === 'number' ? '[\\d.]+' : '.*'"
      :autocomplete='ids[gaid].autocomplete ||  "off"'
      :gaid='gaid'
      :group='group'
      :multiSelectGroup='multiSelectGroup'
      :placeholder='placeholder'
      onclick='this.select()'
      @blur='onBlur'
      @keyup='onKeyUp'
      @keydown='$event.keyCode === 13 ? $event.preventDefault() : false'
      @keypress="onNumberKeypress($event)"
    />

    <!-- type: textarea -->
    <textarea
      :disabled='readOnly'
      v-if="type === 'textarea'"
      class='ga-textarea'
      :type='type'
      :gaid='gaid'
      :group='group'
      :multiSelectGroup='multiSelectGroup'
      :placeholder='placeholder'
      :rows='rows'
      @blur='onBlur'
      @keyup='onKeyUp' 
    />
  </div>
</template>

<script>
import ids from '@common/ids.yaml';
import translationsEn from '@content/en/general/inputs.yaml';
import translationsNl from '@content/nl/general/inputs.yaml';
import ShowHidePassword from '@components/authorization/ShowHidePassword';

class StaticTextError extends Error {
  constructor (message) {
    super(message);
  }
}

export default {
  name: 'ga-text',
  components: { ShowHidePassword },

  props: {
    page: String,
    type: String,
    gaid: String,
    group: String,
    readOnly: Boolean,

    value: undefined,

    /**
     * By default, text inputs emit { gaidX: "Entered Value" }
     * some seconds after text is entered.
     * When preventAutoEmit is true, the emit has to be triggred
     * manually.
     */
    preventAutoEmit: {
      type: Boolean,
      default: false
    },

    multiSelectGroup: String,
    showActionLabels: { type: Boolean, default: true }
  },

  watch: {
    lang () {
      this.translations = this.lang === 'en' ? translationsEn : translationsNl;
      this.placeholder =
        this.ids[this.gaid][this.lang].placeholder || this.ids[this.gaid][this.lang].name;
    },

    value () {
      this.changeValue();
      if (this.readOnly) return;
      this.$store.commit('inputs/emitValueChange');
    }
  },

  data () {
    return {
      ids: ids,
      lang: undefined,
      translations: undefined,

      placeholder: undefined,

      /**
       * The timeout which triggers the emiting of new data after
       * the defined keyUpTime.
       */
      timeout: undefined,

      /**
       * Can be either true, false or undefined.
       *
       * true: shows "Saving..." text.
       * false: shows "Saved." text.
       * undefined: shows no text.
       */
      inTimeout: undefined,
      tempValue: undefined,

      rows: undefined
    };
  },

  mounted () {
    this.init();
  },

  methods: {
    init () {
      localStorage.lang = this.lang = this.$router.history.current.params.lang;
      this.validate();

      this.translations = this.lang === 'en' ? translationsEn : translationsNl;
      this.placeholder =
        this.ids[this.gaid][this.lang].placeholder || this.ids[this.gaid][this.lang].name;
      this.keyUpTime = 1000;
      this.rows = this.ids[this.gaid].rows;

      this.changeValue();
    },

    onNumberKeypress(event){
      if (this.type !== 'number') return false;
      const c = event.charCode;
      if ((c === 8 || c === 0 || c === 13) ? null : (c >= 48 && c <= 57) || c === 46) return;
      event.preventDefault();
    },

    validate () {
      if (this.type === 'textarea' && !this.ids[this.gaid].rows) {
        throw new StaticTextError(
          `Property "rows" is missing from ${this.gaid}`
        );
      }
    },

    changeValue () {
      const allTextareas = document.querySelectorAll(`[gaid='${this.gaid}']`);

      for (let element of allTextareas) {
        element.value = this.value || '';
      }
      this.tempValue = this.value;
    },

    onBlur (e) {
      this.emitChanges(e.target.value, this.preventAutoEmit);
    },

    showSavedState () {
      // Call emitChanges with the value
      if (!this.tempValue || this.tempValue.length === 0) return undefined;
      return this.inTimeout;
    },

    /**
     * Triggers emitChanges 1 second after the last keyUp.
     */
    onKeyUp (e) {
      if (this.readOnly) return;
      // Trigger emitChanges immediately if the input is emptied.
      this.tempValue = e.target.value;
      if (!e.target.value || e.target.value === '') {
        this.emitChanges(e.target.value);
        return;
      }

      // Reset the timer.
      clearTimeout(this.timeout);
      this.inTimeout = true;

      // Set a timeout to call emitChanges after the defined
      // keyUpTime.
      this.timeout = setTimeout(() => {
        this.emitChanges(e.target.value, this.preventAutoEmit);
        this.inTimeout = false;
      }, this.keyUpTime);
    },

    /**
     * Called either automatically or manually when the input's value
     * needs to be emitted.
     *
     * @param data - the input's data
     * @param emitOnSavedIndicator - if true, the method emits a special
     * "onSavedIndicator" event, whereby the parent component takes different
     * actions than for the standard "change" event.
     */
    emitChanges (data, emitOnSavedIndicator) {
      if (this.readOnly) return;
      const compiled = { [this.gaid]: data };

      if (emitOnSavedIndicator) {
        this.$emit('onSavedIndicator', compiled);
      } else {
        this.$emit('change', compiled);
      }
    },

    triggerManualDataEmit () {
      if (this.readOnly) return;
      // Get input value
      const element = document.querySelectorAll(`[gaid='${this.gaid}']`)[0];
      // Call emitChanges with the value
      this.emitChanges(element.value);
    }
  }
};
</script>

<style lang='scss' scoped>
@import '@styles/boring-page.scss';
@import '@styles/reject-site.scss';

.form-group {
  position: relative;
   margin-bottom: 0 !important;
  margin-top: 10px;

  .eye {
    border: none;
    background-color: none;
    position: relative;
    z-index: 11;
    right: -100px;
    top: -40px;
  }

  .eye:hover {
    border: none;
    background-color: none;
  }

  input {
    width: 280px;
    min-height: 46px;
    margin: auto;
  }

  input:first-child {
    margin-top: 10px;
  }

  label {
    margin-top: -10px;
    margin-left: 55px;
    position: absolute;
    background-color: white;
    font-size: 14px;
    color: #55b364;
    padding: 0 10px;
    border: 1px solid;
    border-radius: 10px;

    &.custom-control-label {
      right: -40px;
      color: #65687E
    }
  }

  input:not(:placeholder-shown), input:focus:invalid, input:focus{
    color: #55b364;
    border-color: #55b364;

    label {
      color: #55b364;
    }
  }

  input:invalid {
    color: $red;
    border-color: $red;

    label {
      color: $red;
      border-color: $red;
    }
  }
  .ga-textarea{
    width: 280px;
    border-radius: 10px;
    resize: none;
    border: 2px solid #d3d5e3;
    height: 250px;
    color: #9597ac;
  }
}
</style>
