function getDefaultState() {
  return {
    instances: {},

    mapName: '',
    oldMapName: ''
  };
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    initInstance(state, data) {
      state.instances[data.mapName] = data.instance;
    },

    /**
     * sets the current tab name
     */
    updateMapName(state, currentTab) {
      state.oldMapName = String(state.mapName);
      state.mapName = currentTab || '';
    },

    resetState(state) {
      Object.assign(state, getDefaultState());
    },

    resetMap() {
    },

    drawSite() {
    }
  }
};
