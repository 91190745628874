<template>
  <div class='popup-box dimming' @click='closeAccountSettings($event, true)'>
    <div class='popup box-style'>
      <div class='popup-content'>

        <button class='close-btn' @click='closeAccountSettings()'>
          <img src='/assets/guide-close-icon.svg' alt='x' />
        </button>

<!--        Navigation-->
        <div class='vertical-line'>
          <div class='texts'>
            <p class='grey-header'> {{ translation.header }}</p>
            <!--            todo change class names, small-green-text2 to something understandable-->
            <p class='openTab' @click='openTab("pass")'
               v-bind:class="showPage === 'pass' ? 'small-green-text2' : 'small-grey-text1'">
              {{ translation.changePassword }}</p>
            <p class='openTab' @click='openTab("email")'
               v-bind:class="showPage === 'email' ? 'small-green-text2' : 'small-grey-text1'">
              {{ translation.changeEmail }}</p>
          </div>
        </div>

        <!-- Change Email Tab  -->

        <div v-if='showPage === "email"'>
          <div class='row'>
            <div class='header'>{{ translation.title }}</div>
          </div>
          <div class='inputs'>
            <input type='email' class='email' placeholder='New Email address' v-model='email' />
            <ShowHidePassword field="confirmEmailPassword"/>
            <input id='confirEmailPassword' type="password" placeholder='Confirm password'
                   v-model='emailPassword'
                   ref='confirmEmailPassword'
                   class='confirm-password-email'/>
            <button class='change-email' @click='execute()'>
              {{ translation['button'] }}
            </button>
            <p v-if='successMessage' class='green-text'>{{ successMessage }}</p>
          </div>
        </div>

        <!-- Change Password Tab  -->

        <div v-if='showPage === "pass"'>
          <div class='row'>
<!--            todo add in translations-->
            <div class='header'>Change Password</div>
          </div>
          <div class='inputs'>
            <ShowHidePassword field="currentPass"/>
            <input id='password' class='pass' type="password" placeholder='Current Password'
                   v-model='password' @change='passRules' ref="currentPass"/>
            <ShowHidePassword field="newPassword"/>
            <input id='newPassword' type="password" placeholder='New Password'
                   v-model='newPassword' class='new-password' ref="newPassword"/>
            <PasswordValidator :password='newPassword'
                               :confirmPassword='confirmPassword' ref='passRule' />
            <ShowHidePassword field="confirmPassword"/>
            <input id='confirmPassword' type="password" placeholder='Confirm New Password'
                   v-model='confirmPassword'  ref='confirmPassword' />
            <button class='change-email' @click='execute()'>
              {{ 'Change password' }}
            </button>
            <!-- <p v-if='successMessage' class='green-text'>{{ successMessage  }}</p> -->
            <p v-if="actionSuccess" class='green-text'>Successfully change your password</p>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import accountSettingsEN from '@content/nl/general/account-settings.yaml';
import accountSettingsNL from '@content/en/general/account-settings.yaml';
import RequestHelper from '../../../common/helpers/request.helper';
import PasswordValidator from '@components/inputs/types/dynamic/password-validator';
import ShowHidePassword from '@components/authorization/ShowHidePassword';

export default {
  props: {
    site: Object
  },
  components: {
    PasswordValidator,
    ShowHidePassword,
  },
  data () {

    return {
      translation: this.lang === 'en' ? accountSettingsEN : accountSettingsNL,

      password: '',
      emailPassword: '',
      email: '',
      confirmPassword: '',
      newPassword: '',
      // successMessage: undefined,
       actionSuccess: undefined,
      checkbox: false,
      active: false,
      showPage: 'pass',
      passRules: '',
      LoginAllowed: '',
      requestHelper: new RequestHelper(this.$router)

    };

  },

  methods: {
    closeAccountSettings (ev, isBackgroundClick) {
      if (isBackgroundClick && !ev.target.className.includes('popup-box'))
        return;

      this.$emit('closeAccountSettings');
    },

    openTab (tab) {
      this.showPage = tab;
    //  this.successMessage = undefined,
    },

    execute () {
      if (this.showPage !== 'pass' || this.$refs.passRule.isValid()) {
        this.requestHelper.putQuery('changePassword',
          {
            password: this.showPage === 'email' ? this.emailPassword : this.password,
            email: JSON.parse(localStorage.loggedUser).email,
            newPassword: this.newPassword,
            confirmPassword: this.confirmPassword,
             actionSuccess: this.actionSuccess,
            showPage: this.showPage
          },
          (response) => {
            if (response.data) {
              localStorage.token = response.data.token;
              let loggedUserObject = JSON.parse(localStorage.loggedUser);
              loggedUserObject.email = this.email;
              localStorage.loggedUser = JSON.stringify(loggedUserObject);
              this.$store.commit('siteOwner/updateEmail');
                 this.actionSuccess = 'email';
              }else{
                this.actionSuccess = 'pass';
           
              

              // switch (this.showPage) {
              //   //todo get the messages from the translations
              //   case 'pass': {
              //     this.successMessage = 'Password successfully changed!';
              //     break;
              //   }
              //   case 'email': {
              //     this.successMessage = 'Email successfully changed!';
              //     break;
              //   }
              // }
            }
          }, 
          
          // (error) => {
          //   switch (this.showPage) {
          //     //todo get the messages from the translations
          //     case 'pass': {
          //       this.successMessage = 'Wrong password!';
          //       break;
          //     }
          //     case 'email': {
          //       if (error.data.error === 'wrong-password')
          //         this.successMessage = 'Wrong password!';
          //       else if (error.data.error === 'email-used')
          //         this.successMessage = 'Email already registered!';
          //       break;
          //     }
          //   }
               
          // }
        );
      }
    }

  }

};
</script>

<style lang='scss' scoped>
@import '@styles/main.scss';
@import '@styles/popup.scss';

.texts {
  float: left;
  margin: 5px;
  cursor: pointer;

  .small-grey-text1 {

    color: #65687E;
    font-size: 14px;
    text-align: initial;
  }

  .small-green-text2 {
    color: #55B364;
    font-size: 14px;
    text-align: initial;
  }

  .grey-header {
    color: #65687E;
    font-size: 19px;
    margin-top: 20px;
  }

  .green-text {
    color: #55B364;
  }

}

.vertical-line {
  float: left;
  border-right: 1px solid #00000029;
  height: 460px;
  padding-right: 20px;
  padding-top: 20px;
}

.header {
  margin-left: 55px;
  margin-top: 40px;
  color: #26272E;
  font-size: 20px;
  font-weight: 700;
}

.inputs {
  margin-left: 214px;
  margin-bottom: 130px;
  
margin-right: 180px;
  .pass {
    position: relative;
    // bottom: 30px;
    top: 1px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .eye {
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 11;
     right: 250px;

    &.passwordField {
      top: 95px;
    }

    &.newPasswordField {
      top: 142px;
    }

    &.confirmPasswordField {
      top: 288px;
    }

    &.emailPasswordField {
      top: 172px;
    }
  }

  .green-text {
    float: left;
    color: green;
    margin-left: 20px;
  }

  .email {
    margin-bottom: 13px;
    margin-top: 30px;
  }

  .confirm-password {
    margin-top: -12px;

  }
}

.change-email {
  font-weight: 600;
  background: $primary;
  border-radius: 10px;
  color: $white;
  font-family: $font__IBM;
  margin-top: 40px;
  margin-bottom: 15px;
  border: none;
  outline-color: none;
  outline: none;
  width: 305px;
  height: 46px;
  display: block;
}
</style>
