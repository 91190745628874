<template>
  <div class="container" id="contact">
    <div class="contact">
      <div class="row">
        <div class="contact-content">
          <div class="contact-us-background">
            <p class="title">{{ title }}</p>
            <div class="contact-links">
              <div class="links">
                <a :href="`mailto:${mail}`" target="_blank">
                  <p class="links">
                    <img class="icon" src="/assets/email.svg" />
                    {{ mail }}
                  </p>
                </a>

                <a
                  :href="contact.linkedin"
                  target="_blank"
                  class="linkedin-link"
                >
                  <p class="links">
                    <img
                      style="width: 20px; height: 20px"
                      class="icon"
                      src="/assets/linked-in-white.png"
                    />
                    {{ linkedinName }}
                  </p>
                </a>
              </div>
            </div>
          <div class='buttons'>
            <button class="white-button"  @click='contactFormOpened = true'> 
              
               <p class="text">{{contact.button}}</p>
            </button> 

            <div class="calendly-contact">
             <a target="_blank" href="https://calendly.com/auxgeo">
                <img class="icon" src="/assets/calendly.svg" />
                <p>{{contact.moreInfo}}</p>
                <div class="green-button-arrow">
                  <img src="/assets/arrow-right-white.png" />
                </div>
              </a>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <ContactForm
      v-if="contactFormOpened"
      gaid="gaid520"
      @closeContactForm="contactFormOpened = false"
    />
  </div>
</template>

<script>
import team from '@content/nl/developer/contact.yaml';
import teamEn from '@content/en/developer/contact.yaml';
import ContactForm from '@layouts/matching/ContactForm';

export default {
  name: 'Contact',
  components: {
    ContactForm,
  },
  data() {
    return {
      team: {},
      contact: {},
      title: '',
      url: '',
      mail: '',
      helloText: '',
      introduction: '',
      description: '',
      linkedin: '',
      linkedinName: '',
      moreInfo: '',
      button: '',
      contactButton: '',
      contactFormOpened: false,
      calendly: {},
    };
  },
  props: {
    lang: String,
  },
  mounted() {
    this.init();
  },
  watch: {
    lang() {
      this.init();
    },
  },
  methods: {
    init() {
      let data = this.lang === 'en' ? teamEn : team;
      this.team = data;
      this.contact = data;
      this.title = data.title;
      this.mail = data.mail;
      this.helloText = data.helloText;
      this.introduction = data.introduction;
      this.description = data.description;
      this.linkedin = data.linkedin;
      this.linkedinName = data.linkedinName;
      this.moreInfo = data.moreInfo;
      this.button = data.button;
      this.contactButton = data.contactButton;
      this.url = data.url;
       
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/main.scss';
@import '@styles/home-page.scss';
@import '@styles/mobile-mode.scss';


.row{
  display: flex;
     flex-direction: column;
     justify-content: flex-start;
    // border-radius: 20px 0;
    width: 100%;
    // margin-bottom: 38px;
    // height: 530px;
    // padding: 30px;
    // overflow: hidden;
}


.contact-us-background {
  // width: 1160px;
  height: 350px;
  margin-top: 63px;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('/assets/contact-us.png');
}

.title {
  font-family: "Ibm plex sans", sans-serif;
  font-size: 60px;
  color: #ffffff;
  text-shadow: 0px 4px 4px #00000069;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 50px;
  font-weight: bold;
}
.links {
  color: #ffffff;
  font-size: 20px;
  font-weight: 100;
  text-shadow: 0px 4px 2px #00000069;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: -30px;
  margin-bottom: 35px;
   a:hover{
 color:white;

 }

}
.white-button {
  width: 318px;
  height: 55px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 13px;
  margin-top: -13px;
}
.text {
  font-size: 14px;
  color: #9597ac;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  margin-bottom: 35px;
}

.calendly-contact > a {
  &:hover {
    text-decoration: none;
  }
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  width: 318px;
  height: 55px;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 20px;
  img {
    &.icon {
      width: 28px;
      margin-right: 25px;
      margin-left: 35px;
    }
  }
  p {
    margin: 0;
    color: #9597ac;
    font-size: 12px;
  width: 130px;;
  }
  .green-button-arrow {
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    right: -1px;
    border-radius: 0px 10px 10px 0px;
    background-color: $primary;
    box-shadow: 0px 6px 30px $light-box-shadow;
    width: 80px;
    height: 55px;
    border: none;
    outline: none;
  }
}



</style>
