<template>
  <div class="popup-box dimming" @click="close($event, true)">
    <div class="popup box-style">
      <!-- </div> -->
      <div class="popup-content">
        <button class='close-btn' @click='close()'>
          <img src='/assets/guide-close-icon.svg' alt='img' />
        </button>
        <div class="popup-top-section">
          <div class="row">
            <p class="popup-header">{{ translations['popup-header'] }}</p>
          </div>
          <div class="row">
            <p class="grey-text">
              {{ translations['grey-text'] }}
            </p>

            <input
              v-if="!token && !sent"
              type="email"
              class="email"
              placeholder="Email address"
              v-model="emailText"
            />

            <input
              v-if="!token && !sent"
              type="tel"
              class="phoneNumber"
              placeholder="Phone number"
              v-model="phoneNumberText"
            />

            <div class="text-box" v-if="!sent">
              <p>{{ translations['text-box'] }}</p>
              <GaInput :key="gaid" :gaid="gaid" @data="onData($event)" />
            </div>
          </div>
        </div>
        <div class="sent-text">
          <p v-if="sent">Email sent.</p>
        </div>
        <div class="popup-bot-section">
          <div class="popup-buttons">
            <button class="submit-button" @click="sendContactForm" v-if="!sent">
              {{ translations['button-text'] }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contactFormNl from '@content/nl/admin/contact-form.yaml';
import contactFormEn from '@content/en/admin/contact-form.yaml';
import GaInput from '@components/inputs/ga-input';
import RequestHelper from '@common/helpers/request.helper.js';

export default {
  data() {
    return {
      sent: false,
      requestHelper: new RequestHelper(this.$router),
      messageText: '',
      emailText: '',
      phoneNumberText: '',
      // token: localStorage.token todo use loggedUser.email instead of token,
      token: undefined,

      translations: localStorage.lang === 'en' ? contactFormEn : contactFormNl,
    };
  },
  components: {
    GaInput,
  },
  props: {
    site: Object,
    gaid: String
  },

  methods: {
    close(ev, isBackgroundClick) {
      if (isBackgroundClick && !ev.target.className.includes('popup-box'))
        return;

      this.$emit('closeContactForm');
    },
    sendContactForm() {
      const data = {
        message: this.messageText,
        email: this.emailText,
        phoneNumber: this.phoneNumberText,
      };
      this.requestHelper.postQuery(`sendContactForm`, data, () => {this.sent = true;});
    },
    onData(ev) {
      this.messageText = ev[this.gaid];
    },
  },
};
</script>

<style lang='scss' scoped>
@import '@styles/main.scss';
@import '@styles/reject-site.scss';
@import '@styles/popup.scss';

.submit-button {
  width: 131px;
  height: 42px;
  background-color: #2783ff;
  color: #ffffff;
  font-size: 12px;
}

.sent-text {
  p {
    text-align: center;
    color: #55b364;
    font-size: 25px;
  }
}

.email {
  box-shadow: 0 3px 6px #00000029;
  border: 0;
  border-radius: 10px 0 10px 0;
  margin-right: 20px;
  margin-left: 0px;
  padding: 10px;
}
.phoneNumber {
  box-shadow: 0 3px 6px #00000029;
  border: 0px;
  border-radius: 10px 0 10px 0;
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px;
}
</style>
