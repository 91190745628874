<template>
  <div>
    <div class='section stacked'>
      <img src='/assets/siteTypeChart.svg' alt=''>
      <p><span class='text'>5% solar</span></p>
    </div>
    <div id='stacked-chart'></div>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  props: {
    data: Array
  },
  data () {
    return {
      textPercentage: ['% of all energy', '7%', '5%', '13%', '11%', '10%'],
      textEnergyType: ['Wind onshore', 'Wind offshore', 'Solar rooftop', 'Solar Land', 'Solar Water'],
      textYears: ['2017', '2018', '2019', '2020', '2021'],
      running: false,
      redraw: false
    };
  },
  watch: {
    data () {
      this.drawChart();
    }
  },
  mounted () {
    this.drawChart();
  },
  methods: {
    removeOld () {
      const el = document.querySelector(`#stacked-chart`);
      if (el.firstChild) el.removeChild(el.firstChild);
    },
    drawChart () {
      if (this.running) {
        this.redraw = true;
        return;
      }
      this.removeOld();
      this.running = true;
      let width = 260, height = 180, spacing = 80;
      let xScale = d3.scaleTime()
        .domain([d3.min(this.data, function(d) {
          return d.year;
        }), d3.max(this.data, function(d) {
            return d.year;
          }
        )])
        .range([0, width - spacing]);

      let yScale = d3.scaleTime()
        .range([height - spacing, 0]);

      let svg = d3.select('#stacked-chart').append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', 'translate(' + (spacing) + ',' + spacing / 1.5 + ')');

      let stack = d3.stack().keys(['windOnShore', 'windOffShore', 'solarRooftop', 'solarLand', 'solarWater']);
      let colors = ['#02AAB0', '#307B3C', '#93C47D', '#1D226F', '#2783FF'];
      let stackData = stack(this.data);
      yScale.domain([0, d3.max(stackData[stackData.length - 1], function(d) {
        return d[1];
      })]);

      let area = d3.area()
        .x(function(d) {return xScale(d.data.year);})
        .y0(function(d) {return yScale(d[0]);})
        .y1(function(d) {return yScale(d[1]);});

      let series = svg.selectAll('g.series')
        .data(stackData)
        .enter()
        .append('g')
        .attr('class', 'series');

      series.append('path')
        .style('fill', function(d, i) {return colors[i];})
        .attr('d', function(d) {return area(d);});

      let x = -80;
      let xAdd = 0;
      let firstPercent = this.textPercentage[1];
      svg
        .selectAll('w')
        .data(this.textPercentage)
        .join('text')
        .text(function(d) {return d;})
        .attr('transform', function(d) {
          if (d === firstPercent) {
            xAdd = 92;
          }
          x += xAdd;
          xAdd = 35;
          return `translate(${x},${-10})`;
        })
        .attr('fill', '#B5B8CC')
        .attr('font-size', 12)
        .style('font-weight', 400);

      x = 0;
      xAdd = 0;
      svg
        .selectAll('w')
        .data(this.textYears)
        .join('text')
        .text(function(d) {return d;})
        .attr('transform', function() {
          x += xAdd;
          xAdd = 38;
          return `translate(${x},${120})`;
        })
        .attr('fill', '#1D226F')
        .attr('font-size', 12)
        .style('font-weight', 400);

      let y = 20;
      let yAdd = 0;
      svg
        .selectAll('w')
        .data(this.textEnergyType)
        .join('text')
        .text(function(d) {return d;})
        .attr('transform', function() {
          y += yAdd;
          yAdd = 20;
          return `translate(${-80},${y})`;
        })
        .attr('font-size', 12)
        .style('font-weight', 400)
        .style('fill', function(d, i) {return colors[colors.length - 1 - i];});

      this.running = false;
      if (this.redraw) this.drawChart();
    }
  }
};
</script>

<style>
#stacked-chart {
  margin-top: -10px;
  margin-left: -60px;
  margin-bottom: 30px;
}
</style>
