<template>
  <div>
    <div class='shadow' v-if='showAuth' @click='onClose()' />
    <div :class='className'>
      <p class='login-text'>{{ translation['account-text'] }}</p>

      <button class='login-button' v-if='!isLogged'
              @click='
            showAuth = true;
            isSignUp = false;
            loginDirectly()
          '>
        {{ translation['login-button-text'] }}
      </button>

      <button v-if='isLogged' class='sign-button'
              @click='isSignUp = true; loginDirectly()'>
        {{ translation['map-button-text'] }}
      </button>
      <button class='logout-button' v-if='isLogged' @click='logout()'>{{ translation['logout-button'] }}</button>
    </div>

    <Authorization
      v-if='showAuth'
      @close='onAuthorizationClose($event)'
      @startNow="$emit('startNow')"
      :defaultPage="isSignUp ? 'register' : 'login'"
      :userType='userType'
    />
  </div>


</template>

<script>
import Authorization from '@layouts/home/components/Authorization';
import headerTranslationsNl from '@content/nl/general/header.yaml';
import headerTranslationsEn from '@content/en/general/header.yaml';
import { logout } from '@common/helpers/logout.helper.js';
import { getUserTypeRoute } from '@helpers/routes.helper';

export default {
  components: {
    Authorization
  },
  data () {
    return {
      showAuth: false,
      isLogged: false,
      isSignUp: false,
      showButtons: '',
      translation: {}
    };
  },
  props: {
    lang: String,
    isMobile: String,
    className: String
  },

  computed: {
    userType () {
      return this.$store.state.user.userType;
    }
  },

  mounted () {
    localStorage.lang = this.lang = this.$router.history.current.params.lang;
    this.init();
    this.loginDirectly();
  },
  watch: {
    lang () {
      this.init();
    }
  },

  methods: {
    init () {
      this.isLogged = !!localStorage.token;
      this.translation = this.lang === 'en' ? headerTranslationsEn : headerTranslationsNl;
      this.initShowAuth();
    },

    initShowAuth () {
      this.showAuth =
        this.$router.history.current.params.loginType &&
        this.$router.history.current.params.loginType !== '';
    },

    onAuthorizationClose (isLogged) {
      this.showAuth = false;
      if (isLogged) {
        this.isLogged = true;

        this.$router.push({
          name: ['SiteOwner', 'ProjectDeveloper', 'Admin'][this.userType]
        });
      }
    },

    loginDirectly () {
      if (!localStorage.loggedUser || !localStorage.token) return;
      const loggedUserType = getUserTypeRoute(this.userType);
      this.$router.push(`/${this.lang}/${loggedUserType}`).catch(() => {});
    },

    logout () {
      logout(this);
      this.isLogged = false;
      location.reload();
      //this.$forceUpdate();
    },
    onClose () {
      this.showAuth = false;

      this.$router.replace({
        name: 'Libero',
        params: {
          loginType: undefined,
          userType: getUserTypeRoute(this.userType)
        }
      });
    }

  }
};
</script>

<style scoped lang='scss'>

.shadow {
  background: #000;
  opacity: .3;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100
}

</style>
