<template>
  <div class='login'>
    <div class='components col-12 freeArea'>
      <div class='above-texts'>
        <img class='logo' src='/assets/logo.png' />
        <p class='header-text'>{{ translation.login.title }}</p>
      </div>
      <div class='inputs'>
        <input
          type='email'
          class='email'
          placeholder='Email address'
          v-model='email'
        />
        <ShowHidePassword field="loginPass"/>
        <input
          id='pass'
          type='password'
          placeholder='Password'
          v-model= 'password'
          ref="loginPass"
        />
        <div class='checkbox custom-control custom-checkbox'>
          <input
            type='checkbox'
            class='custom-control-input'
            id='termsAndConditions'
            v-model='checkbox'
          />
          <label class='custom-control-label' for='termsAndConditions'>{{ translation.login['remember-text'] }}</label>
          <label class='green m-auto float-right clickable' @click="$emit('openForgotten')">{{ translation.login['forgot-password']
            }}</label>
        </div>

        <button
          class='green'
          :disabled='!LoginAllowed'
          :style="{ cursor: LoginAllowed ? 'pointer' : 'not-allowed' }"
          @click='loginUser()'
        >
          {{ translation.login.button }}
        </button>
      </div>
    </div>

  </div>
</template>
<script>
import translationsEn from '@content/en/auth/auth.yaml';
import translationsNl from '@content/nl/auth/auth.yaml';
import { logout } from '@common/helpers/logout.helper.js';
import ShowHidePassword from './ShowHidePassword.vue';

export default {
  components: {ShowHidePassword},
  data () {
    return {
      translation: {},
      password: '',
      email: '',
      showPassword: false,
      checkbox: false,
      active: false,
    };
  },
  computed: {
    LoginAllowed () {
      return !(!this.email || !this.password);
    }
  },
  created () {
    this.init();
  },
  methods: {
    init () {
      this.translation = localStorage.lang === 'en' ? translationsEn : translationsNl;
    },
    loginUser () {
       this.$emit('login', { email: this.email, password: this.password });
    },

    logout () {
      logout(this);
    }
  }
};
</script>
