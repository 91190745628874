var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup-box dimming",on:{"click":function($event){return _vm.closeGuideBox($event, true)}}},[_c('div',{staticClass:"guide-popup box-style"},[_c('div',{staticClass:"padding"},[(_vm.user === 1)?_c('Chevron',{attrs:{"currentTab":_vm.selectedTab,"tabs":[
          {
            link: _vm.tabs[0].navigation,
            title: _vm.tabs[0].navigation,
            backgroundActive: `url('/assets/chevron.tabs/active_dev-map.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/dev-map.svg') no-repeat`
          },
          {
            link: _vm.tabs[6].navigation,
            title: _vm.tabs[6].navigation,
            backgroundActive: `url('/assets/chevron.tabs/active_my-profile.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/my-profile.svg') no-repeat`
          },
          {
            link: _vm.tabs[2].navigation,
            title: _vm.tabs[2].navigation,
            backgroundActive: `url('/assets/chevron.tabs/active_boring-page.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/boring-page.svg') no-repeat`
          },
          { link: _vm.tabs[5].navigation,
            title: ' ',
            backgroundActive: `url('/assets/chevron.tabs/guide-dropdown.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/guide-dropdown.svg') no-repeat`
          },
          {
            link: _vm.tabs[1].navigation,
            title: _vm.tabs[1].navigation,
            backgroundActive: `url('/assets/chevron.tabs/active_boring-page.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/boring-page.svg') no-repeat`
          },
          {
            link: _vm.tabs[7].navigation,
            title: _vm.tabs[7].navigation,
            background: `url('/assets/chevron.tabs/matching.svg') no-repeat`,
            backgroundActive: `url('/assets/chevron.tabs/active_matching.svg') no-repeat`
          },
          {
            link: _vm.tabs[3].navigation,
            title: _vm.tabs[3].navigation,
            background: `url('/assets/chevron.tabs/review.svg') no-repeat`,
            backgroundActive: `url('/assets/chevron.tabs/active_review.svg') no-repeat`
          },
          {
            link: _vm.tabs[4].navigation,
            title: _vm.tabs[4].navigation,
            background: `url('/assets/chevron.tabs/project-launch.svg') no-repeat`,
            backgroundActive: `url('/assets/chevron.tabs/active_project-launch.svg') no-repeat`
          }
        ]},on:{"change-tab":function($event){return _vm.changeTab($event)}}}):_c('Chevron',{attrs:{"currentTab":_vm.selectedTab,"tabs":[
          {
            link: _vm.tabs[0].navigation,
            title: _vm.tabs[0].navigation,
            backgroundActive: `url('/assets/chevron.tabs/active_map.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/map.svg') no-repeat`
          },
          { link: _vm.tabs[5].navigation,
            title: ' ',
            backgroundActive: `url('/assets/chevron.tabs/guide-dropdown.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/guide-dropdown.svg') no-repeat`
          },
          {
            link: _vm.tabs[7].navigation,
            title: _vm.tabs[7].navigation,
            backgroundActive: `url('/assets/chevron.tabs/active_map.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/map.svg') no-repeat`
          },
          {
            link: _vm.tabs[1].navigation,
            title: _vm.tabs[1].navigation,
            backgroundActive: `url('/assets/chevron.tabs/active_boring-page.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/boring-page.svg') no-repeat`
          },
          {
            link: _vm.tabs[2].navigation,
            title: _vm.tabs[2].navigation,
            backgroundActive: `url('/assets/chevron.tabs/active_matching.svg') no-repeat`,
            background: `url('/assets/chevron.tabs/matching.svg') no-repeat`
          },
          {
            link: _vm.tabs[3].navigation,
            title: _vm.tabs[3].navigation,
            background: `url('/assets/chevron.tabs/review.svg') no-repeat`,
            backgroundActive: `url('/assets/chevron.tabs/active_review.svg') no-repeat`
          },
          {
            link: _vm.tabs[4].navigation,
            title: _vm.tabs[4].navigation,
            background: `url('/assets/chevron.tabs/project-launch.svg') no-repeat`,
            backgroundActive: `url('/assets/chevron.tabs/active_project-launch.svg') no-repeat`
          }
        ]},on:{"change-tab":function($event){return _vm.changeTab($event)}}}),_c('div',{staticClass:"guide-content"},[(_vm.selectedSection.images)?_c('div',{staticClass:"img-column"},_vm._l((_vm.images),function(img,index){return _c('img',{key:index,attrs:{"src":`/assets/${img}`}})}),0):_vm._e(),_c('div',{staticClass:"instructions"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.selectedSection.title))]),_c('ul',{staticClass:"bullets"},_vm._l((_vm.selectedSection.description),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0)])]),_c('div',{staticClass:"tags"},_vm._l((_vm.tabSections),function(section,index){return _c('span',{key:index,staticClass:"tag",class:{ active: _vm.selectedSectionId === section.section },on:{"click":function($event){return _vm.selectSection(section.section)}}},[_c('span',{staticClass:"titles"},[_vm._v(_vm._s(section.name))])])}),0),_c('button',{staticClass:"close-btn",on:{"click":function($event){return _vm.closeGuideBox()}}},[_c('img',{attrs:{"src":"/assets/guide-close-icon.svg","alt":"img"}})])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }