<!--
  static-input works with fixed/hardcoded objects ("gaids" taken from ids.yaml) 
  and each input has to be placed manually. 
  
  The value of each input is taken semantically (ids.gaid34[lang].name) since 
  all gaid objects have the same structure.
-->

<template>
  <div>
    <!-- type: boolean -->
    <staticButton
      v-if="type === 'boolean'"
      :readOnly='readOnly'
      :type='type'
      :gaid='gaid'
      :group='group'
      :image='image'
      :multiSelectGroup='multiSelectGroup'
      :active='value'
      :page="page"
      @click='onData($event)'
    />

    <!-- type: text & textarea & project-selector -->
    <staticText
      v-if="
        type === 'text' ||
          type === 'password' ||
          type === 'password-showable' ||
          type === 'textarea' ||
          type === 'project-selector' ||
          type === 'number' ||
          type === 'email'
      "
      :readOnly='readOnly'
      :type='type'
      :gaid='gaid'
      :group='group'
      :value='value'
      :page="page"
      :multiSelectGroup='multiSelectGroup'
      @change='onData($event)'
      @onSavedIndicator='onSavedIndicator'
      :preventAutoEmit='preventAutoEmit'
      :showActionLabels='showActionLabels'
      ref='childInput'
    />
    <staticCheckbox 
      v-if="type === 'checkbox'"
      :type='type'
      :gaid='gaid'
      :value='value'
      :text="text"
      @change='onData($event)'
    />
  </div>
</template>

<script>
import ids from '@common/ids.yaml';
import staticButton from '@components/inputs/types/static/types/static-button';
import staticText from '@components/inputs/types/static/types/static-text';
import staticCheckbox from '@components/inputs/types/static/types/static-checkbox'

class StaticInputError extends Error {
  constructor (message) {
    super(message);
  }
}

export default {
  components: {
    staticButton,
    staticText,
    staticCheckbox
  },

  props: {
    page: String,
    gaid: String,

    /**
     * Used for toggles. When multiple inputs have the same group,
     * only one of them can be active at a time.
     * */
    group: String,

    /** The default value */
    defaultValue: String,

    /** The id of the next container. */
    preventAutoEmit: Boolean,

    parentId: String,

    multiSelectGroup: String,

    readOnly: Boolean,
    text: String,
    showActionLabels: { type: Boolean, default: true }
  },

  watch: {
    parentId () {
      this.onParentChange();
    }
  },

  computed: {
    globalParents () {
      return this.$store.state.inputs.parents;
    }
  },

  data () {
    return {
      ids: ids,
      type: undefined,

      value: undefined,

      /** Taken from the ids.yaml */
      image: undefined
    };
  },

  mounted () {
    this.init();
  },

  methods: {
    init () {
      this.type = ids[this.gaid].type;
      this.image = ids[this.gaid].image;
      this.initValue();
      this.validate();

      this.$store.subscribe(this.onStoreChange);
    },

    onStoreChange (mutation) {
      switch (mutation.type) {
        case 'inputs/updateParentAnswers':
          this.onParentChange();
          break;
      }
    },

    initValue () {
      this.value = this.globalParents[this.parentId]?.answers[this.gaid];
      if (!this.value && this.defaultValue) {
        this.value = this.defaultValue;
        if (this.globalParents[this.parentId])
          this.globalParents[this.parentId].answers[this.gaid] = this.value;
      }
    },

    validate () {
      if (!this.type) {
        throw new StaticInputError(`${this.gaid} has no type specified.`);
      }

      if (!this.gaid) {
        throw new StaticInputError('Property "gaid" is required.');
      }
    },

    onParentChange () {
      this.initValue();
    },

    /**
     * Triggered every time an input emits new data.
     */
    onData (data) {
      if (!data) data = {};
      this.value = data[this.gaid];

      this.$emit('data', data);
    },

    triggerManualDataEmit () {
      this.$refs.childInput.triggerManualDataEmit();
    },

    onSavedIndicator (data) {
      this.$emit('onSavedIndicator', data);
    }
  }
};
</script>

<style lang='scss'></style>
