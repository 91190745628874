<template>
  <div class='input-box'>
    <DraggableBox
      class='freeArea input-box box-style'
      :class='{
        edit: startingStep,
        active: activatedInputBox,
        inactive: !activatedInputBox,
      }'
      :page='page'
      :x='left'
      :y='top'
      :h='inputOutputBoxHeight'
      :w='width'
      @dragstop='moveBox($event)'
      :disableDragging='!!startingStep || draggable'

    >
      <template slot='header'>
        <div v-if='!startingStep' class='mb-0 header-input-box'>
          <div class='col-6 mb-0 left-header'>
            <p>{{ projectName }}</p>
            <p class='color'> {{ siteName ? siteName : 'New Site' }}
              <!-- {{ currentStep }} -->
            </p>
          </div>
          <div class='col-6 right-header'>
            <!-- <button @click="minimizeBox()"> -->
            <!-- <img
              class='icon'
              v-show='minimizeInputBox'
              src='/assets/arrow-down.svg'
            /> -->
            <!-- <img
              class="icon"
              :class="minimizeInputBox === false ? 'rotated-arrow' : ''"
              src="/assets/arrow-down.svg"
            /> -->
            <!-- </button> -->
          </div>

          <div class='img-window' @click="$emit('minimize', true)">
            <img src='/assets/window.svg'>
          </div>
        </div>
        <div class='steps-content'>
          <div class='steps-container' :class="{ 'mt-3': startingStep }">
            <!-- Starting form generation -->
            <form
              v-for='(step, i) of questionnaire'
              :key='i'
              class='step-container d-none'
              :page='page + siteId'
              :step='i'
            >
              <p v-if='startingStep' class='step-title edit'>
                {{ ids[step.title][lang].name }}
              </p>
              <p v-else class='step-title'>{{ ids[step.title][lang].name }}</p>
             <span v-if='startingStep' class='step-description sub'>{{
                  (ids[step.description] ? ids[step.description][lang].definition : '')
                }}</span>
              <span v-else class='step-description'>{{
                  (ids[step.description] ? ids[step.description][lang].definition : '')
                }}</span>
              <!-- scroll arrow image -->
              <img v-if='!step.disableScrollArrows && (step.inputs || []).length > 5' :id='`scrollUp${i}`'
                   :class='`scroll-arrow ${upBlue ? "scroll-blue" : ""}`'
                   src='/assets/Icon ionic-ios-arrow-down.svg'
                   style='transform: rotate(180deg)' @click='scroll(-1)' />
              <div :ref='`page${i}`' :class='step.disableScrollArrows ? "" : "scrollable-content"'>

                <!-- todo find another way to restart the gif on page open instead of v-if  -->
                <img
                  v-if='currentStepId === i && step.gif'
                  style='width: 100%'
                  :src='`/assets/${step.gif}.gif`'
                  alt=''
                />
                <!-- Label for size calculation -->
                <div v-if='inputParents[siteId] && (ids[step.description] ? ids[step.description].sizeLabel : false)'>
                  <!-- Invisible calculated size field for auto saving-->
                  <span class='step-description size mt-0 mb-0 pt-0'>
                  {{ questionnaireTranslations.sizeCalculated }}
                  {{ inputParents[siteId].answers['gaid39'] ? inputParents[siteId].answers['gaid548'] : inputParents[siteId].answers['gaid291'] }} sq.m
                  </span>
                  <span 
                    :style="page === 'project' ? 'font-weight: 300; color: #1D226F; font-size: 12px;' : ''"
                    class='step-description'
                  >
                    {{ questionnaireTranslations.sizeManual }}
                  </span>
                </div>

                <div v-for='(input, index) of step.inputs' :key='index'>
                  <ul v-if='ids[input.gaid].passwordChecks'>
                    <li :class='{ valid: hasLength }'>
                      {{ registrationTranslations.requirements.symbols }}
                    </li>
                    <li :class='{ valid: hasNumber }'>
                      {{ registrationTranslations.requirements.number }}
                    </li>
                    <li :class='{ valid: hasLowerCase && hasUpperCase }'>
                      {{ registrationTranslations.requirements.letter }}
                    </li>
                    <li :class='{ valid: isConfirmed }'>
                      {{ registrationTranslations.requirements.confirmed }}
                    </li>
                  </ul>

                  <!-- Buttons and text fields -->
                  <gaInput
                    v-if='
                    ids[input.gaid].type === `boolean` ||
                    ids[input.gaid].type === `text` ||
                    ids[input.gaid].type === `password` ||
                    ids[input.gaid].type === `password-showable` ||
                    ids[input.gaid].type === `textarea` ||
                    ids[input.gaid].type === `number` ||
                    ids[input.gaid].type === `email` ||
                    ids[input.gaid].type === `checkbox`
                  '
                    :parentId="
                    ids[input.gaid].saveTo === 'user' ? 'user' : siteId
                  "
                    :key='input.gaid'
                    :group='step.group'
                    :multiSelectGroup='step.multi'
                    :gaid='input.gaid'
                    :page='page'
                    @hasMinLength='hasMinLength($event)'
                    @hasLowerCase='lowerCase($event)'
                    @hasUpperCase='upperCase($event)'
                    @hasNumber='number($event)'
                    @isConfirmed='confirmed($event)'
                  />

                  <!-- The google address suggestion text field -->
                  <GoogleAddress
                    v-if='ids[input.gaid].type === `google-address-suggest`'
                  />
                  <!-- The project selector buttons -->

                  <div
                    v-if='ids[input.gaid].type === `project-selector`'
                    class='project-selector'
                  >
                    <!-- Create a new project -->
                    <gaInput
                      :gaid='input.gaid'
                      :group='step.group'
                      :key='input.gaid'
                      :parentId='siteId'
                      :preventAutoEmit='true'
                      :preventStorageChange='true'
                      @onSavedIndicator='
                      onNewProjectInputEntered($event, input.gaid)
                    '
                      @data='onNewProjectInputData($event, input.gaid)'
                      ref='newProjectInput'
                    />
                    <gaInput
                      v-for='project of userProjects'
                      dynamicType='boolean'
                      :group='step.group'
                      :valueAnswer='input.gaid'
                      :key='project._id'
                      :gaid='project._id || input.gaid'
                      :dynamic='true'
                      :dynamicObject='project'
                      :parentId='siteId'
                      @data='onProjectSelection'
                      :ref="'project' + project._id"
                    />
                  </div>
                </div>
                <div v-if='loading || errorMessage' class='mt-auto'>
                  <div v-if='loading'>
                    <p>{{ registrationTranslations.loading }}</p>
                    <img src='/assets/spinner.svg' />
                  </div>
                  <p class='error' v-if='errorMessage'>
                    <img src='/assets/exclamation-small.svg' />
                    {{ errorMessage }}
                  </p>
                </div>
                <!-- scroll arrow image -->
              </div>
              <img v-if='!step.disableScrollArrows && (step.inputs || []).length > 5'
                   :class='`scroll-arrow ${downBlue ? "scroll-blue" : ""}`'
                   :id='`scrollDown${i}`'
                   src='/assets/Icon ionic-ios-arrow-down.svg' @click='scroll(1)' />
            </form>


            <div
              :class="
                startingStep
                  ? 'bottom-buttons edit col-12 mb-4'
                  : 'bottom-buttons col-12'
              "
            >
              <!-- Previous button -->
              <button
                v-if='
                  currentStep === 1 ||
                  (startingStep && currentStepId === startingStep)
                '
                :class='{ edit: startingStep }'
                class='prev-step'
                disabled
              >
                <img src='/assets/arrow-left-white.svg' />
                {{ questionnaireTranslations['previous-step'] }}
              </button>
              <button
                v-else
                :class='{ edit: startingStep }'
                class='prev-step'
                @click='tryPreviousStep()'
              >
                <img src='/assets/arrow-left-white.svg' />
                {{ questionnaireTranslations['previous-step'] }}
              </button>

              <!-- Next or finish buttons -->
              <button
                v-if='hasNextStep'
                class='next-step single'
                @click='tryNextStep()'
                :disabled='disableNext'
              >
                {{ questionnaireTranslations['next-step'] }}
                <img src='/assets/arrow-right-white.png' />
              </button>
              <button
                v-else
                class='next-step single'
                @click="onStepFinish()"
                :disabled='disableNext'
              >
                {{ questionnaireTranslations.finish }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </DraggableBox>

    <!--    <HelpProvider-->
    <!--        step="4"-->
    <!--        @close="helper = false"-->
    <!--        :lang="lang"-->
    <!--    />-->
  </div>
</template>

<script>
import ids from '@common/ids.yaml';
import gaInput from '@components/inputs/ga-input';
import DraggableBox from '@components/DraggableBox';
import questionnaireTranslationsEn from '@content/en/owner/questionnaire.yaml';
import questionnaireTranslationsNl from '@content/nl/owner/questionnaire.yaml';
import registrationTranslationsEn from '@content/en/auth/translations.yaml';
import registrationTranslationsNl from '@content/nl/auth/translations.yaml';
import { Project } from '@common/models/site-owner/project.model.js';
import RequestHelper from '../../../common/helpers/request.helper';
import GoogleAddress from './components/GoogleAddress';

export default {
  name: 'Questionnaire',
  components: {
    gaInput,
    DraggableBox,
    GoogleAddress
  },
  props: {
    activatedInputBox: Boolean,
    inputOutputBoxHeight: Number,
    siteId: String,
    startingStep: String,
    partly: Boolean,
    page: String,
    questionnaire: Object,
    projectDevId: String,
    defaultTop: Number,
    defaultLeft: Number,
    draggable: Boolean
  },
  watch: {
    lang () {
      this.init();
    },
    questionnaire () {
      if (this.startingStep) {
        this.currentStepId = this.startingStep;
        this.stepStack = [];
      }
      this.$nextTick(() => {
        this.updateStepContainer();
      });
    },
    currentSavedStep () {
      if (this.page === 'map') this.currentStepId = this.currentSavedStep;
    },
    startingStep () {
      if (this.startingStep) this.currentStepId = this.startingStep;
      this.stepStack = [];
      this.updateStepContainer();
    },
    siteId () {
      this.stepStack = [];
      this.errorMessage = undefined;
      this.$nextTick(() => {
        this.updateStepContainer();
      });
      this.projectName = this.inputParents[this.selectedProjectId]?.answers['gaid230'] ||
        'New Project';
      this.siteName = this.inputParents[this.siteId]?.answers['gaid185'];
    },
    continueToNext () {
      if (this.continueToNext === true) {
        this.tryNextStep();
      }
    },
    inputParents () {
      this.projectName = this.inputParents[this.selectedProjectId]?.answers['gaid230'] ||
        'New Project';
      this.siteName = this.inputParents[this.selectedProjectId]?.answers['gaid185'];
    }

  },
  created () {
    this.registrationTranslations =
      localStorage.lang === 'en'
        ? registrationTranslationsEn
        : registrationTranslationsNl;

    const coords = JSON.parse(localStorage.loggedUser || '{}').siteBoxCoordinates;
    if (!coords) {
      if (window.innerWidth > 767 && window.innerWidth <= 1100) {
        this.top = coords ? coords?.top : window.innerHeight - 600;
        this.left = coords ? coords?.left : 100;
      }
    } else {
      this.top =
        this.page !== 'map' ? Number(100) : coords?.top || this.top;
      this.left =
        this.page !== 'map' ? Number(1300) : coords?.left || this.left;

    }
  },

  mounted () {
    this.init();
  },
  computed: {
    userProjects () {
      return this.$store.state.siteOwner.userProjects;
    },
    inputParents () {
      return this.$store.state.inputs.parents;
    },
    mapInstances () {
      return this.$store.state.map.instances;
    },
    currentSavedStep () {
      return this.$store.state.siteOwner.currentStepId;
    },
    selectedProjectId () {
      return this.$store.state.siteOwner.selectedProjectId;
    },
    userSites () {
      return this.$store.state.siteOwner.userSites;
    }
  },
  data () {
    return {
      hover: false,
      ids: ids,
      currentStepId: undefined,
      lang: 'en',
      currentStep: 1,
      questionnaireTranslations: {},
      registrationTranslations: {},

      disableNext: false,
      justConfirmed: false,
      stepStack: [],
      answeredStepStackForward: [],
      requestHelper: new RequestHelper(this.$router),
      top: (window.innerHeight - Number(690)) / 2,
      left: Number(100),
      width: Number(360),
      hasNextStep: true,
      passwordGaid: undefined,
      confirmPasswordGaid: undefined,
      continueToNext: false,
      // used when in connected-profile/boxes to remove the absolute position of draggablebox
      inProfileBoxes: false,
      loading: false,
      errorMessage: undefined,
      hasLength: false,
      hasLowerCase: false,
      hasUpperCase: false,
      hasNumber: false,
      isConfirmed: false,
      siteName: '',
      projectName: '',
      downBlue: true,
      upBlue: false
    };
  },
  methods: {
    hasMinLength (length) {
      this.hasLength = length;
    },
    lowerCase (lowerCase) {
      this.hasLowerCase = Boolean(lowerCase);
    },
    upperCase (upperCase) {
      this.hasUpperCase = Boolean(upperCase);
    },
    number (hasNumber) {
      this.hasNumber = Boolean(hasNumber);
    },
    confirmed (isConfirmed) {
      this.isConfirmed = isConfirmed;
    },
    init () {
      if (this.startingStep) this.currentStepId = this.startingStep;
      else this.currentStepId = this.currentSavedStep;

      // Subscribe to changes in the global storage
      this.$store.subscribe(this.onStoreChange);

      this.questionnaireTranslations =
        localStorage.lang === 'en'
          ? questionnaireTranslationsEn
          : questionnaireTranslationsNl;
      this.updateStepContainer();

      this.projectName = this.inputParents[this.selectedProjectId]?.answers['gaid230'] ||
        'New Project';
    },

    onStoreChange (mutation) {
      switch (mutation.type) {
        // When a question is answered for a specific site.
        case 'inputs/updateParentAnswers':

          this.updateAnswers(mutation);
          if (mutation.payload.newAnswers)
            this.hasModelDataChanges(mutation.payload.newAnswers);
          this.tryDeletePathChange();
          break;

        // When an input's value is changed.
        case 'inputs/emitValueChange':
          this.updateStepContainer();
          break;

        case 'siteOwner/setCurrentStepId':
          this.projectName = this.inputParents[this.selectedProjectId]?.answers['gaid230'] ||
            'New Project';
          this.siteName = this.inputParents[this.siteId]?.answers['gaid185'];
          break;
      }
    },
    scroll (direction) {
      const el = this.$refs[`page${this.currentStepId}`][0];
      this.scrollElement(el, el.scrollTop + (57.4 * direction));
    },
    scrollToTop () {
      const el = this.$refs[`page${this.currentStepId}`][0];
      this.scrollElement(el, 0);
    },
    scrollElement (el, h) {
      if (!el) return;
      el.scrollTo(0, h);
      this.arrowColor(el);
    },
    arrowColor (el) {
      const top = el.scrollTop;
      if (top === undefined) return;
      const bottom = ((this.questionnaire[this.currentStepId]?.inputs?.length || 0) - 5) * 56;
      this.downBlue = top <= bottom;
      this.upBlue = top > 50;

    },
    updateAnswers (mutation) {
      const answer = mutation.payload.newAnswers;
      if (!answer) return;
      if(answer.gaid543 && this.siteId === this.selectedProjectId){
        this.requestHelper.putQuery(`projects?id=${this.selectedProjectId}`, {
          _id: this.selectedProjectId,
          answers: answer
        });
      }
      // const emitAnswer = { solar: answer.gaid32, wind: answer.gaid33 };
      // this.$emit('updateAnswers', emitAnswer);
      if (this.projectDevId) {
        if (!mutation.payload || !localStorage.token) return;

        this.requestHelper.putQuery('projectDevQuery', {
          answers: this.inputParents[this.siteId]?.answers
        });
      } else {
        if (
          !this.siteId ||
          this.siteId !== mutation.payload.parentId ||
          !localStorage.token ||
          this.siteId.startsWith('s-')
        )
          return;
        if(this.siteId != this.selectedProjectId){
          this.requestHelper.putQuery('sitesQuery', {
            id: this.siteId,
            answers: this.inputParents[this.siteId]?.answers,
            objType: this.inputParents[this.siteId]?.objType
          });
        }
      }
    },

    hasModelDataChanges (newAnswers) {
      for (const answer in newAnswers) {
        if (ids[answer]?.model === true) {
          this.sendModelData();
          return;
        }
      }
    },

    async sendModelData () {
      const siteId = this.siteId;
      if (!this.inputParents[siteId] || !localStorage.token) return;

      const site = this.userSites.find(site => site.options.id === siteId);
      if (!site) return;

      if (!this.inputParents[siteId]?.answers['gaid429'] || !this.inputParents[siteId]?.answers['gaid430']) {
        const siteBounds = site._bounds;
        const NORTH = siteBounds._northEast.lat;
        const EAST = siteBounds._northEast.lng;
        const SOUTH = siteBounds._southWest.lat;
        const WEST = siteBounds._southWest.lng;

        const res = await this.requestHelper.getQuery(`nearestGrid?north=${NORTH}&east=${EAST}&south=${SOUTH}&west=${WEST}`);
        if (res.data.length === 0) return;

        this.inputParents[siteId].answers.gaid429 = res.data[0].distance;
        this.inputParents[siteId].answers.gaid430 = res.data[0].company;
      }

      if (!this.inputParents[siteId]?.answers['gaid167'] && !this.inputParents[siteId]?.answers['gaid168']) return;

      const answers = {};
      for (const key of Object.keys(this.inputParents[siteId].answers))
        if (ids[key]?.model === true)
          answers[key] = this.inputParents[siteId]?.answers[key];

      if (!answers) return;

      const center = site.getCenter();
      this.requestHelper.postQuery(`modelDataQuery`, {
          id: siteId,
          answers,
          coordinates: site.toGeoJSON().geometry.coordinates,
          center: [center.lat, center.lng]
        },
        (response) => {
          if (response.status !== 200 ||
            response.data && !response.data.gaid401) return;
          this.saveModelData(response.data, siteId);
        }
      );
    },
    saveModelData (answers, siteId) {
      this.$store.commit('inputs/updateParentAnswers', {
        parentId: siteId,
        objType: 'site',
        newAnswers: answers
      });
    },
    moveBox (data) {
      const { top, left } = data;
      if (this.top === top && this.left === left) return;

      this.top = top;
      this.left = left;
      // this.updateUserStatus();
    },

    updateUserStatus () {
      if (!localStorage.loggedUser) return;
      const loggedUser = JSON.parse(localStorage.loggedUser);

      if (this.page === 'map')
        loggedUser.siteBoxCoordinates = {
          top: this.top,
          left: this.left

        };

      // Last selected site is tracked by the global store
      delete loggedUser.lastSelectedSite;
      if (localStorage.token)
        this.requestHelper.putQuery('userState', loggedUser, ({ data }) => {
          localStorage.loggedUser = JSON.stringify({ ...loggedUser, ...data });
        });
    },

    onNewProjectInputEntered (data, id) {
      if (!data[id] || data[id] === '') return;

      const commitData = {
        parentId: this.siteId,
        newAnswers: data
      };

      this.$store.commit('inputs/updateParentAnswers', commitData);

      this.updateStepContainer();
    },

    async onNewProjectInputData (data, id) {
      // Do not create a new project if its name is empty
      if (!data[id] || data[id] === '') return;

      const newProject = await Project.createInstance(
        { answers: data },
        this.requestHelper
      );

      // Store in the global projects.
      this.$store.commit('siteOwner/pushToUserProjects', newProject);

      const commitData = {
        parentId: newProject._id,
        objType: 'project',
        newAnswers: data
      };

      // Store answer data for this project
      this.$store.commit('inputs/updateParentAnswers', commitData);

      // Trigger dynamic project input selection
      const selectNewButton = () => {
        this.$refs[`project${newProject._id}`][0].onData({
          [newProject._id]: true
        });
      };

      /**
       * Set an immediate timeout to trigger the button selection after the button has been
       * rendered.
       *
       * From stackoverflow (https://stackoverflow.com/questions/779379):
       * What setTimeout does is add a new event to the browser event queue and the rendering
       * engine is already in that queue (not entirely true, but close enough) so it gets
       * executed before the setTimeout event.
       */
      setTimeout(selectNewButton);
    },

    onProjectSelection (data) {
      // Update the selected project id
      for (let key in data) {
        // Checking if it's a true boolean (in order to ignore the text input for new project)
        if (typeof data[key] == 'boolean' && data[key]) {
          this.$store.commit('siteOwner/setSelectedProjectId', key);
          break;
        }
      }

      // Assign the current site to the selected project
      this.$store.commit('siteOwner/setSiteProjectId', {
        siteId: this.siteId,
        projectId: this.selectedProjectId
      });

      if (localStorage.token)
        this.requestHelper.putQuery('sitesQuery', {
          id: this.siteId,
          projectId: this.selectedProjectId
        });
    },

    currentStepData () {
      // if (this.currentStepId === 'qgaid101')
      //   this.mapInstances['map']?.pm.enableDraw('Polygon');
      // else this.mapInstances['map']?.pm.disableDraw('Polygon');

      if (
        this.questionnaire[this.currentStepId] &&
        this.questionnaire[this.currentStepId].registration
      ) {
        for (const i of this.questionnaire[this.currentStepId].inputs) {
          if (this.ids[i.gaid].type === 'password-showable') {
            this.passwordGaid = i.gaid;
            this.confirmPasswordGaid = this.ids[i.gaid].confirmGaid;
            break;
          }
        }
      } else {
        delete this.passwordGaid;
        delete this.confirmPasswordGaid;
      }

      if (
        this.siteId &&
        this.page === 'map' &&
        localStorage.token &&
        !this.siteId.startsWith('s-')
      )
        this.requestHelper.putQuery('sitesQuery', {
          currentStepId: this.currentStepId,
          id: this.siteId
        });
      this.updateStepContainer();
      this.scrollToTop();
    },

    /**
     * Finds the path of answered questions forward
     */
    getAnsweredPathForward (currentStepId, stack) {

      // Find the answer
      const answer = this.questionnaire[currentStepId].inputs?.find(
        (gaid) => this.inputParents[this.siteId]?.answers[gaid.gaid]
      );

      // If there isn't answer and there are inputs we have found the final step that isn't answered in the path
      if (!answer && this.questionnaire[currentStepId].inputs) {
        this.answeredStepStackForward = stack;
        return;
      }

      let next = answer?.next;
      // If there is no answer next, set it to the default next
      if (!next) next = this.questionnaire[currentStepId].next;

      // If it's answered and there is a next step, add it to the stack and continue with the next question
      if (next) {
        stack.push(next);
        this.getAnsweredPathForward(next, stack);
        // If there isn't, set the stack and finish
      } else if (!answer && !this.questionnaire[currentStepId].inputs)
        this.answeredStepStackForward = stack;
    },

    tryPreviousStep () {
      if (this.stepStack.length > 0) {
        this.currentStepId = this.stepStack.pop();

        if (!this.currentStepId || !this.questionnaire[this.currentStepId]) {
          return;
        }

        this.currentStep--;

        if (
          (this.questionnaire[this.currentStepId].registration &&
            localStorage.token &&
            !(
              !this.inputParents['user'] ||
              !this.inputParents['user']['gaid432']
            )) ||
          (this.questionnaire[this.currentStepId].confirmation &&
            JSON.parse(localStorage.loggedUser).confirmed) ||
          this.questionnaire[this.currentStepId].registrationConfirmation
        ) {
          this.tryPreviousStep();
          return;
        }

        if (this.page === 'map')
          this.$store.commit('siteOwner/setCurrentStepId', this.currentStepId);
        this.errorMessage = undefined;
        this.currentStepData();
        this.getAnsweredPathForward(this.currentStepId, []);
        this.$forceUpdate();
      }
    },

    tryNextStep () {
      let currentStep = this.questionnaire[this.currentStepId];

      if (!currentStep) return;

      if (
        (currentStep.registration || currentStep.confirmation) &&
        this.continueToNext === false
      ) {
        if (currentStep.triggerFunction) this[currentStep.triggerFunction]();
        return;
      }

      this.errorMessage = undefined;

      let nextStepId = currentStep.inputs?.find(
        (gaid) => this.inputParents[this.siteId]?.answers[gaid.gaid]
      )?.next;
      // Get the next step if it's not answered
      if (!nextStepId) nextStepId = currentStep.next;

      if (nextStepId) {
        // Check if there is an if statement and if it's not completed, go next step
        while (
          this.questionnaire[nextStepId]?.if ||
          this.questionnaire[nextStepId]?.registration ||
          this.questionnaire[nextStepId]?.confirmation ||
          this.questionnaire[nextStepId]?.registrationConfirmation
          ) {
          const nextStep = this.questionnaire[nextStepId];

          if (nextStep.registration) {
            if (
              !(
                localStorage.token &&
                (!this.inputParents['user'] ||
                  !this.inputParents['user']['gaid432'])
              )
            ) {
              break;
            }
          }

          if (
            nextStep.confirmation &&
            !JSON.parse(localStorage.loggedUser).confirmed
          )
            break;

          if (nextStep.registrationConfirmation && this.justConfirmed) {
            this.justConfirmed = false;
            break;
          }

          if (nextStep.if) {
            let ifs = nextStep.if;
            if (typeof ifs === 'string') ifs = [ifs];

            let found = false;
            for (const i of ifs)
              if (this.inputParents[this.siteId]?.answers[i] === true) {
                found = true;
                break;
              }

            if (found) break;
          }

          nextStepId = this.questionnaire[nextStepId].next;
        }

        if (!this.stepStack) this.generateStepStack();
        this.stepStack.push(this.currentStepId);

        this.currentStepId = nextStepId;
        this.continueToNext = false;
        if (this.page === 'map')
          this.$store.commit('siteOwner/setCurrentStepId', this.currentStepId);
        this.currentStep++;
        this.currentStepData();
      }

      // Runs a specific function
      if (
        !currentStep.registration &&
        !currentStep.confirmation &&
        currentStep.triggerFunction
      )
        this[currentStep.triggerFunction]();

      /**
       * Trigger the project input component to emit its value.
       *
       * This action, in turn, triggers everything else that
       * should follow the creation of a new project (saving
       * in the DB, assigning the site to it, rendering it as
       * a button in the UI & then selecting it, etc.)
       */
      if (this.$refs.newProjectInput)
        this.$refs.newProjectInput[0]?.triggerManualDataEmit();
    },

    inviteOwner () {
      this.requestHelper.postQuery('ownerInvitation', {
        email: this.inputParents[this.siteId]?.answers['gaid277'],
        projectName:
          this.inputParents[this.selectedProjectId]?.answers['gaid230'],
        siteId: this.siteId,
        lang: this.lang
      });
    },

    inviteRepresentative () {
      this.requestHelper.postQuery('ownerInvitation', {
        email: this.inputParents[this.siteId]?.answers['gaid538'],
        projectName:
          this.inputParents[this.selectedProjectId]?.answers['gaid230'],
        siteId: this.siteId,
        lang: this.lang
      });
    },

    onStepFinish(){
      this.$emit('onStepFinish');
      let currentStep = this.questionnaire[this.currentStepId];
      if (currentStep.triggerFunction) this[currentStep.triggerFunction]();
    },

    async register () {
      if (this.loading) return;
      this.loading = true;
      await this.requestHelper.postQuery(
        'registerQuery',
        {
          email: this.inputParents['user']?.answers['gaid431'],
          password: this.inputParents['user']?.answers['gaid432'],
          answers: {
            gaid273: this.inputParents['user']?.answers['gaid273'],
            gaid274: this.inputParents['user']?.answers['gaid274']
          }
        },
        ({ data }) => {
          //then create sites
          this.loading = false;
          if ('error' in data) {
            this.errorMessage = data.error;
            return;
          }
          //login the user
          localStorage.token = data.token;
          localStorage.loggedUser = JSON.stringify(data);
          this.continueToNext = true;
          this.recreateUserData();
          this.$nextTick(() => this.$emit('redraw'));
        },
        () => {
          this.errorMessage = 'An error occurred, please try again later!';
          this.loading = false;
        }
      );
    },

    async login () {
      if (this.loading) return;
      this.loading = true;
      await this.requestHelper.postQuery(
        'loginQuery',
        {
          email: this.inputParents['user']?.answers['gaid431'],
          password: this.inputParents['user']?.answers['gaid519']
        },
        ({ data }) => {
          //then create sites
          this.loading = false;
          if ('error' in data) {
            this.errorMessage = data.error;
            return;
          }
          //login the user
          localStorage.token = data.token;
          localStorage.loggedUser = JSON.stringify(data);
          this.continueToNext = true;
          this.$nextTick(() => this.$emit('redraw'));
        },
        () => {
          this.errorMessage = 'An error occurred, please try again later!';
          this.loading = false;
        }
      );
    },

    async confirm () {
      if (this.loading) return;
      this.loading = true;
      await this.requestHelper.getQuery(
        `confirmAccount?confirmationCode=${this.inputParents['user']?.answers['gaid435']}`,
        ({ data }) => {
          this.loading = false;
          //then create sites
          if ('error' in data) {
            this.errorMessage = data.error;
            return;
          }
          localStorage.token = data.token;
          localStorage.loggedUser = JSON.stringify(data);
          this.justConfirmed = true;
          this.$store.commit('inputs/confirmedAccount');
          //confirmation done, go to next step
          this.continueToNext = true;
          this.stepStack = this.stepStack.reduce((step) => {
            !this.questionnaire[step]?.registration;
          });
        },
        () => {
          this.errorMessage = 'An error occurred, please try again later!';
          this.loading = false;
        }
      );
    },

    async recreateUserData () {
      //create projects
      let promises = [];
      for (const key in this.inputParents) {
        if (key.startsWith('p-')) {
          const promise = Project.createInstance(
            { answers: this.inputParents[key].answers },
            this.requestHelper
          ).then(async (project) => {
            // Store in the global projects.
            this.$store.commit('siteOwner/pushToUserProjects', project);

            const commitData = {
              parentId: project._id,
              objType: 'project',
              newAnswers: this.inputParents[key].answers
            };

            // Store answer data for this project
            this.$store.commit('inputs/removeParent', key);
            this.$store.commit('siteOwner/removeUserProject', key);
            this.$store.commit('inputs/updateParentAnswers', commitData);

            // find all sites for the project
            await this.recreateSites(project, key);
          });
          promises.push(promise);
        }
      }
      await Promise.all(promises);
      this.$store.commit('map/resetMap');
    },

    async recreateSites (project, oldProjectId) {
      let promises = [];
      for (const siteKey in this.inputParents) {
        if (
          siteKey.startsWith('s-') &&
          this.inputParents[siteKey].answers[oldProjectId]
        ) {
          // change the site's project
          delete this.inputParents[siteKey].answers[oldProjectId];
          const site = this.userSites.find(
            (site) => site.options?.id === siteKey
          );
          this.mapInstances['map']?.removeLayer(site);

          const coordinates = site._latlngs?.map((cords) =>
            cords.map((coordinate) => [coordinate.lat, coordinate.lng])
          );

          this.inputParents[siteKey].answers[project._id] = true;

          let promise = this.requestHelper.postQuery(
            'sitesQuery',
            {
              answers: this.inputParents[siteKey].answers,
              coordinates,
              currentStepId: site.options.currentStepId,
              projectId: project._id,
              type: 'polygon',
              color: site.options.color
            },
            (response) => {
              const siteBounds = site.pm._layer._bounds;

              site.options.id = response.data._id;
              site.options.projectId = project._id;

              const NORTH = siteBounds._northEast.lat;
              const EAST = siteBounds._northEast.lng;
              const SOUTH = siteBounds._southWest.lat;
              const WEST = siteBounds._southWest.lng;

              const parents = {
                [site.options.id]: {
                  answers: response.data.answers,
                  users: response.data.users,
                  objType: 'site'
                }
              };
              this.$store.commit('inputs/removeParent', siteKey);
              this.$store.commit('siteOwner/removeUserSite', siteKey);

              this.$store.commit('inputs/addParents', parents);
              this.$store.commit('siteOwner/pushToUserSites', site);

              if (this.$store.state.siteOwner.selectedSiteId === siteKey)
                this.$store.commit(
                  'siteOwner/setSiteSelected',
                  site.options.id
                );

              this.requestHelper.getQuery(
                `nearestGrid?north=${NORTH}&east=${EAST}&south=${SOUTH}&west=${WEST}`,
                (res) => {
                  if (res.data.length > 0) {
                    this.inputParents[site.options.id].answers.gaid429 =
                      res.data[0].distance;
                    this.inputParents[site.options.id].answers.gaid430 =
                      res.data[0].company;
                  }
                }
              );
            }
          );
          promises.push(promise);
        }
      }
      await Promise.all(promises);
    },

    /**
     * Manages showing the correct step in the questionnaire
     * and triggers input validations.
     */
    updateStepContainer () {
      // Hide All Steps
      for (let stepContainer of document.querySelectorAll(
        `.step-container[page='${this.page}${this.siteId}']`
      ))
        stepContainer.classList.add('d-none');

      let stepContainer = document.querySelector(
        `.step-container[page='${this.page}${this.siteId}'][step='${this.currentStepId}']`
      );
      this.generateStepStack();
      this.setupHasNextStep();
      this.dependencies();

      if (stepContainer) {
        // Show current step.
        stepContainer.classList.remove('d-none');
        this.disableNext = this.isNextDisabled();
      }
    },

    setupHasNextStep () {
      const step = this.questionnaire[this.currentStepId];
      this.hasNextStep = false;

      if (!step) return;
      if (step.next) {
        //todo check for ifs in the next step
        this.hasNextStep = true;
        return;
      }

      step.inputs?.forEach((gaid) => {
        if (gaid.next) this.hasNextStep = true;
      });
    },
    dependencies () {
      const inputs = this.questionnaire[this.currentStepId]?.inputs;
      if (!inputs) return;

      inputs.forEach((gaid) => {
        const dependency = ids[gaid.gaid]?.dependencies;

        if (!dependency) return;

        dependency.forEach((dep) => {
          if (dep.type === 'difference') {
            const calculated =
              this.inputParents[this.siteId]?.answers[dep.gaid];
            const manual = this.inputParents[this.siteId]?.answers[gaid.gaid];
            const percentDifference = parseInt(dep.max);
            const percent = (calculated * percentDifference) / Number(100);
            const max = parseInt(calculated) + parseInt(percent);
            const min = calculated - percent;

            if (manual && (manual > max || manual < min))
              this.errorMessage = dep.warning;
            else this.errorMessage = undefined;
          }
        });
      });
    },

    /**
     * Disable/enable next button based on whether the current step has any
     * mandatory inputs unanswered.
     */
    isNextDisabled () {
      if (!this.currentStepId || this.currentStepId === '') return false;
      // Get the step container and the inputs
      const inputs = document
        .querySelector(
          `.step-container[page='${this.page}${this.siteId}'][step='${this.currentStepId}']`
        )
        ?.getElementsByClassName('ga-input');

      if (!inputs) return false;

      // For each rendered input...
      for (let input of inputs) {
        const gaid = input.getAttribute('gaid');

        const answered =
          this.inputParents[this.siteId]?.answers[gaid] ||
          this.inputParents['user']?.answers[gaid];

        const id = this.ids[gaid];

        if (!id) return false;

        const emailValid = id.type !== 'email' || input.validity.valid;
        const minLengthValid = !id.validations?.minLength || this.hasLength;
        const numberValid = !id.validations?.number || this.hasNumber;
        const upperCaseValid = !id.validations?.upperCase || this.hasUpperCase;
        const lowerCaseValid = !id.validations?.lowerCase || this.hasLowerCase;
        const mustMatchValid = !id.validations?.mustMatch || this.isConfirmed;
        const mandatoryValid = !id.validations?.mandatory || answered;
        const invalidInput = !(
          emailValid &&
          minLengthValid &&
          numberValid &&
          upperCaseValid &&
          lowerCaseValid &&
          mustMatchValid &&
          mandatoryValid
        );

        // True if no other inputs of the same group are answered.
        const invalidGroup = this.getInvalidGroup(input);

        if (invalidInput || invalidGroup) return true;
      }
      // If there is no site selected
      return !this.siteId;
    },

    getInvalidGroup (input) {
      if (!input) return;
      let groupType;
      let groupId;

      const toggleGroupAttribute = input.getAttribute('group');
      const multiSelectGroupAttribute = input.getAttribute('multiSelectGroup');

      if (toggleGroupAttribute) {
        groupId = toggleGroupAttribute;
        groupType = 'group';
      } else if (multiSelectGroupAttribute) {
        groupId = multiSelectGroupAttribute;
        groupType = 'multiSelectGroup';
      }

      if (groupId && this.ids[groupId]?.validations?.mandatory) {
        const groupMembers = document.querySelectorAll(
          `[${groupType}='${groupId}']`
        );

        for (let groupMember of groupMembers) {
          const gaid = groupMember.getAttribute('gaid');
          const answered = this.inputParents[this.siteId]?.answers[gaid];
          // If one group member is answered, return false.
          if (answered) return false;
        }

        // If the group is mandatory and no member is answered, return true.
        return true;
      }

      // If the passed input has no group, or if the group isn't mandatory, return false.
      return false;
    },

    /**
     * Get all possible future steps in the currently selected path
     * This function is slow, try not to use it when it's not needed
     */
    getFutureSteps (currentStepId, currentStack) {
      if (currentStepId === undefined) return currentStack;
      const step = this.questionnaire[currentStepId];
      if (!step) return currentStack;
      if (
        (!!step.registration &&
          !JSON.parse(localStorage.loggedUser).confirmed) ||
        !step.if
      )
        currentStack.add(currentStepId);

      if (step.if) {
        let ifs = step.if;
        if (typeof ifs === 'string') ifs = [ifs];

        for (const i of ifs)
          if (this.inputParents[this.siteId]?.answers[i] === true) {
            currentStack.add(currentStepId);
            break;
          }
      }

      // Get the next step and if there is one, do the same for it
      let next = this.questionnaire[currentStepId]?.next;
      if (next) return this.getFutureSteps(next, currentStack);

      // Do the same for all paths in the current step
      this.questionnaire[currentStepId]?.inputs?.forEach((gaid) => {
        if (this.inputParents[this.siteId]?.answers[gaid.next])
          currentStack = this.getFutureSteps(gaid.next, currentStack);
      });

      return currentStack;
    },

    /**
     * Try to delete the changed path
     */
    tryDeletePathChange () {
      // Check all 'if's and remove their answers if the 'if' is not answered
      for (let step in this.questionnaire) {
        const stepObj = this.questionnaire[step];
        if (!stepObj) continue;

        if (stepObj.if) {
          let ifs = stepObj.if;
          if (typeof ifs === 'string') ifs = [ifs];

          let del = true;
          for (const i of ifs)
            if (this.inputParents[this.siteId]?.answers[i] === true) {
              del = false;
              break;
            }

          if (del)
            stepObj.inputs?.forEach(
              (gaid) =>
                delete this.inputParents[this.siteId]?.answers[gaid.gaid]
            );
        }
      }

      // If this isn't a multiple path question and there isn't a stack, no need to make more checks
      if (
        this.questionnaire[this.currentStepId]?.next ||
        !this.answeredStepStackForward ||
        this.answeredStepStackForward.length === 0
      )
        return;

      // Find the next step
      const next =
        this.questionnaire[this.currentStepId]?.next ||
        this.questionnaire[this.currentStepId]?.inputs?.find(
          (gaid) => this.inputParents[this.siteId]?.answers[gaid.gaid]
        )?.next;

      // If there is no next step, return
      if (!next) return;

      // Get all possible next steps
      const possibleNextSteps = this.getFutureSteps(next, new Set());

      // Find the first common step between the answered and the possible steps
      // If there isn't one, we remove all questions anyways
      const first = this.answeredStepStackForward.find((value) =>
        possibleNextSteps.has(value)
      );

      if (first === this.answeredStepStackForward[0]) return;

      // Remove all answered questions for the steps until the found step
      for (let step of this.answeredStepStackForward) {
        if (this.currentStepId === step) continue;
        if (step === first) break;

        this.questionnaire[step].inputs?.forEach(
          (gaid) => delete this.inputParents[this.siteId]?.answers[gaid.gaid]
        );
      }

      // Clear the answered questions stack
      this.answeredStepStackForward = [];
    },

    generateStepStack () {
      if (!this.stepStack) this.stepStack = [];
      // If the user is on the first step or the stack is already defined
      if (this.stepStack.length > 0) return;
      let currentStep = 'qgaid1';
      if (this.currentStepId !== currentStep) this.stepStack.push(currentStep);
      // Goes through all steps to recreate the step stack, used when going to the previous question
      while (this.currentStepId !== currentStep) {
        const currentStepConf = this.questionnaire[currentStep];
        currentStep = currentStepConf?.next;

        if (localStorage.loggedUser &&
          currentStepConf &&
          (currentStepConf.registration ||
            currentStepConf.registrationConfirmation ||
            (currentStepConf.confirmation &&
              JSON.parse(localStorage.loggedUser).confirmed)))
          this.stepStack.pop();

        // Find the next step
        if (!currentStep)
          currentStep = currentStepConf?.inputs?.find(
            (gaid) => this.inputParents[this.siteId]?.answers[gaid.gaid]
          )?.next;

        if (!currentStep) break;

        this.stepStack.push(currentStep);
      }
      if (this.stepStack.length > 1) this.stepStack.pop();
      this.currentStep = this.stepStack.length + 1;
    },

    /**
     * Try to delete the changed path
     */
    initStepStack () {
      if (!this.currentStepId) this.stepStack = ['qgaid1'];
    }
  }
};
</script>

<style lang='scss'>
@import '@styles/main.scss';
@import '@styles/questionnaire.scss';
</style>
