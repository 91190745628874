<template>
  <div class='font'>
    <AuthButtons :lang='lang' className='row login-buttons' />
    <Navigation :lang='lang' :key='`nav${lang}`' />
    <Header :lang='lang' @startNow='startNow' :key='`header${lang}`' />
    <OurPlatform :lang='lang' :key='`platf${lang}`' />
    <RenewableEnergy :lang='lang' v-if='userType === 0' :key='`erg${lang}`' />
    <Helper :lang='lang' v-if='userType === 0' :key='`help${lang}`' />
    <ProcessForDev :lang='lang' v-if='userType === 1' :key='`pdev${lang}`' />
    <Process :lang='lang' v-if='userType === 0' :key='`pso${lang}`' />
    <OurStory :lang='lang' v-if='userType === 0' :key='`stry${lang}`' />
    <Team :lang='lang' :key='`team${lang}`' />
    <!-- <Knowledge :lang='lang' v-if='userType === 0' /> -->
    <!-- News hide (17.10.22) because of Jaap content  -->
    <!-- <News :lang='lang' :key='`news${lang}`'/>  -->
    <Partners :lang='lang' v-if='userType === 0' :key='`part${lang}`' />
    <Contact :lang='lang' :key='`cont${lang}`' />
    <FAQ :lang='lang' v-if='userType === 0' :key='`faq${lang}`' />
    <GetStarted
      v-if="env.VUE_APP_CONTEXT === 'acceptance'"
      :lang='lang'
      @startNow='startNow'
      :key='`start${lang}`'
    />
    <Footer :lang='lang' :key='`footer${lang}`' />
  </div>
</template>

<script>
import AuthButtons from '@layouts/home/components/AuthButtons';
import Navigation from '@layouts/home/components/Navigation';
import Header from '@layouts/home/components/Header';
import RenewableEnergy from '@layouts/home/components/RenewableEnergy';
import Helper from '@layouts/home/components/Helper';
import OurPlatform from '@layouts/home/components/OurPlatform .vue';
import Process from '@layouts/home/components/Process';
import OurStory from '@layouts/home/components/OurStory';
import Team from '@layouts/home/components/Team';
// import Knowledge from '@layouts/home/components/Knowledge';
// import News from '@layouts/home/components/News';
import Partners from '@layouts/home/components/Partners';
import FAQ from '@layouts/home/components/FAQ';
import GetStarted from '@layouts/home/components/GetStarted';
import Footer from '@components/Footer.vue';
import ProcessForDev from '@layouts/home/components/ProcessForDev';
import Contact from '@layouts/home/components/Contact';
import chevronTabNl from '@content/nl/i/1.yaml';
import chevronTabEn from '@content/en/i/1.yaml';

export default {
  components: {
    AuthButtons,
    Navigation,
    Header,
    OurPlatform,
    RenewableEnergy,
    Helper,
    Process,
    OurStory,
    Team,
    // Knowledge,
    // News,
    Partners,
    FAQ,
    GetStarted,
    Footer,
    ProcessForDev,
    Contact
  },
  data () {
    return {
      lang: '',
      env: {},
      section: localStorage.lang === 'en' ? chevronTabEn.navigation : chevronTabNl.navigation,
      subSection: localStorage.lang === 'en' ? chevronTabEn.sections[0].section : chevronTabNl.sections[0].section
    };
  },

  computed: {
    userType () {
      return this.$store.state.user.userType;
    }
  },

  watch: {
    $route () {
      this.init();
    }
  },
  created () {
    this.init();
    this.env = process.env;
  },
  methods: {
    init () {
      localStorage.lang = this.lang = this.$router.history.current.params.lang;
    },
    startNow () {
      if (localStorage.token) {
        this.$router.push(`/${this.lang}/${this.userType ? 'dev/profile' : 'site-owner'}`).catch(()=> {});
      } else {
        if (this.userType === 0) {
          delete localStorage.token;
          this.$router.push({
            path: `/${localStorage.lang}/site-owner/i`,
            query: { section: this.section, subsection: this.subSection }
          });
          this.$store.commit('siteOwner/setSiteSelected');
        } else {
          this.$router.push({ path: `/${localStorage.lang}/project-developer/register` });
        }
      }
    }
  }
};
</script>

<style scoped lang='scss'>
@import "@styles/main.scss";
@import "@styles/mobile-mode.scss";

.font {
  font-family: $font__IBM;
  font-weight: 500;
  overflow-x: hidden;
}


</style>
