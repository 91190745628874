var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"draggableContainer",staticClass:"scale",style:({
    position: _vm.page === "map" ? "initial" : "absolute",
    "z-index": _vm.disableDragging ? 400 : 401,
    top: `${_vm.y}px`,
    left: `${_vm.x}px`,
    height: `${_vm.h}px`,
    width: `${_vm.w}px`,
  }),attrs:{"id":"draggable-container"},on:{"mousedown":_vm.dragMouseDown,"touchstart":_vm.dragMouseDown,"touchmove":_vm.elementDrag,"touchend":_vm.closeDragElement}},[_c('div',{attrs:{"id":"draggable-header"}},[_vm._t("header")],2),_vm._t("main"),_vm._t("footer")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }